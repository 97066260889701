@import "../common/_mixin";
@import "../common/commonCode.scss";

.policyPage {
	margin: 0 0 8rem;
	@include desktop {
		margin: 3rem 5rem 8rem;
	}
	.title {
		font-size: 2rem;
		font-weight: $bold;
		line-height: 1.25;
		margin-bottom: 2.2rem;
		padding-bottom: 0.9rem;
		border-bottom: 0.2rem solid #666;
		color: #555;
		@include desktop {
			font-size: 2.5rem;
			border-bottom: 0.3rem solid #666;
		}
	}
	.content {
		p, div {
			font-size: 1.4rem;
			line-height: 1.6;
			color: #333;
			@include desktop {
				font-size: 1.6rem;
			}
		}
	}
}
