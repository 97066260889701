@import "../common/commonCode.scss";
@import "../common/_mixin";

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	src: url('../../public/fonts/Pretendard-Regular.woff2') format('woff2');
}

.invitation-page {
	text-align: left;
	//margin: 0 -2rem; // header fixed 일때
	margin: -5rem -2rem 0; // header relative일때
}

.invitation {
	background: #F5F5F7;
	overflow-x: hidden;

	em {
		font-style: normal;
	}

	.container {
		box-sizing: border-box;

		@include desktop {
			max-width: 400px;
			margin: 0 auto;
		}
	}

	.btn-move-application {
		display: block;
		width: 28.1rem;
		height: 5.5rem;
		margin: 0 auto;
		padding: 0;
		background: rgb(230,38,201);
		background: linear-gradient(90deg, rgba(230,38,201,1) 0%, rgba(133,29,253,0.98) 100%);
		border: 0;
		border-radius: 0.5rem;
		color: #fff;
		font-size: 1.7rem;
		font-weight: 700;
		line-height: 5.5rem;
		text-align: center;
		cursor: pointer;
	}

	%inviteTopContentTitle {
		margin-bottom: 2.8rem;
		color: #111;
		font-size: 2.8rem;
		font-weight: 700;
		line-height: 1.39;
		text-align: center;
		letter-spacing: -0.02em;
	}

	%inviteBottomContentTitle {
		color: #888;
		font-size: 2.8rem;
		font-weight: 700;
		line-height: 3.9rem;
		letter-spacing: -0.02em;
		text-align: center;

		strong {
			display: inline-block;
			color: #111;
		}
	}

	%inviteBottomContentDesc {
		color: #333;
		font-size: 1.6rem;
		line-height: 2.6rem;
		letter-spacing: -0.02em;
		text-align: center;
	}

	&__head {
		padding: 1.15rem 2.95rem 6.15rem;
		background: rgb(223,217,255);
		background: linear-gradient(180deg, rgba(223,217,255,1) 0%, rgba(245,245,247,1) 100%);
		color: #111;
		text-align: center;

		&-lottie {
			width: 3.7rem;
			height: 3.7rem;
			margin: 0 auto;
		}

		&-title {
			padding: 1.6rem 0 0.9rem;
			font-size: 2.6rem;
			font-weight: 700;
			line-height: 3.5rem;
			letter-spacing: -0.02em;
		}

		&-desc {
			font-size: 1.4rem;
			line-height: 2.4rem;
			opacity: 0.8;
		}

		&-ticket {
			position: relative;
			margin: 1.85rem 0 0;

			@include desktop {
				max-width: 31.6rem;
				margin-left: auto;
				margin-right: auto;
			}

			img {
				width: 100%;
			}

			.btn-move-application {
				position: absolute;
				left: 50%;
				bottom: 5.05rem;
				width: 25rem;
				height: 5rem;
				padding-left: 5.85rem;
				font-size: 1.6rem;
				line-height: 5rem;
				transform: translateX(-12.5rem);
				text-align: left;
				letter-spacing: -0.02em;
        
        &--junior{
          padding-left: 5rem;
        }

				span {
					display: inline-block;
					padding-right: 1.4rem;
					background:url($imgURL + '/event/friends/lnk_arr.svg') no-repeat right 0 top 50%;
					background-size: 0.64rem auto;
					vertical-align: top;
				}
			}
		}
	}


	&__gifts {
		padding: 0 0 9.3rem;

		&-title {
			@extend %inviteTopContentTitle;
		}

		.gift-list {
			font-size: 0;
			text-align: center;
		}

		.gift-item {
			display: inline-block;
			width: 7.2rem;
			vertical-align: top;

			& + .gift-item {
				margin-left: 2.95rem;
			}

			&__bg {
				display: block;
				width: 100%;
				margin: 0 0 1.5rem;
				border-radius: 1.5rem;
				vertical-align: top;
			}

			&__txt {
				margin: 0 -1rem;
				color: #555;
				font-size: 1.3rem;
				line-height: 2rem;
				letter-spacing: -0.02em;
			}
		}
	}

	&__reviews {
		padding: 0 0 9.3rem;

        &--junior {
          padding-bottom: 5rem;
        }

		&-title {
			@extend %inviteTopContentTitle;
		}

		.slide-row {
			@include desktop {
				overflow: hidden;
			}
		}

		.swiper-container
		{
			overflow: visible;
		}

		.slide-item{
			width: 24rem;
			height: 34rem;
			margin: 0 0.75rem;
			padding: 2.7rem 0 0;
			border-radius: 1.5rem;
			color: #fff;
			text-align: center;

			&:first-of-type {
				margin-left: 0;
			}

			&__img {
				width: 100%;
				margin-bottom: -2.4rem;
			}

			&__title {
				font-size: 1.9rem;
				font-weight: 500;
				letter-spacing: -0.02em;
				line-height: 2.5rem;
			}

			&__desc {
				padding: 1rem 0 0.95rem;
				font-size: 1.4rem;
				line-height: 2.1rem;
			}

			&__reviewer {
				font-size: 0.9rem;
				opacity: 0.5;
			}
		}
    
    &--junior {
      .slide-item{
        height: 35.2rem;
        
        &__img {
          display: block;
          width: 8.3rem;
          margin: 0 auto 2rem;
        }
      }
    }

		.review-pagination {
			padding: 2rem 0 0;
			font-size: 0;
			text-align: center;

			.swiper-pagination-bullet {
				display: inline-block;
				width: 0.6rem;
				height: 0.6rem;
				margin: 0 0.4rem;
				background: #D8D8D8;

				&-active {
					background: #969696;
				}
			}
		}
	}
  
  &__youtube {
    margin: 0 0 9.5rem;
    padding: 0 4rem;
    
    .youtube-video{
      width:29.5rem;
      height: 16.5rem;
      margin:0 auto;
    }
    
    iframe {
      width:100%;
      height: 100%;
      border-radius: 1.5rem;
      overflow: hidden;
    }
  }

	&__steps {
		padding: 0 0 10rem;

        &--junior {
          padding-bottom: 11rem;
        }

		.container {
			padding: 0 3.5rem;

			@include  desktop {
				padding: 0;
			}
		}

		&-title {
			@extend %inviteTopContentTitle;
		}

		.step-item {
			margin: 0 0 4rem;
			padding: 2rem 1rem 1.95rem 5.55rem;
			background-color: #e1e5ea;
			background-repeat: no-repeat;
			border-radius: 1.5rem;

			&,
			& button {
				color: #464a5d;
				font-size: 1.5rem;
				font-weight: 500;
				line-height: 2.25rem;
				letter-spacing: -0.03em;
			}

			&--apply {
				padding: 0;

				button {
					display: block;
					margin: 0;
					padding: 2rem 1rem 1.95rem 5.55rem;
					background: transparent url($imgURL + '/event/friends/ico_apply.svg') no-repeat 2.7rem 50%;
					background-size: 1.7rem auto;
					border: 0;
					text-align: left;
					cursor: pointer;
				}
			}

			&--study {
				background-image: url($imgURL + '/event/friends/ico_study.svg');
				background-position: 2.74rem 50%;
				background-size: 1.66rem auto;
			}

			&--call {
				background-image: url($imgURL + '/event/friends/ico_call.svg');
				background-position: 2.68rem 50%;
				background-size: 1.7rem auto;
			}

			& + .step-item {
				position: relative;

				&:before {
					content: '';
					display: block;
					position: absolute;
					left: 50%;
					top: -3.1rem;
					width: 5rem;
					height: 2rem;
					background: url($imgURL + '/event/friends/step_arr.svg') no-repeat 50% 50%;
					background-size: auto 1.3rem;
					transform: translateX(-2.5rem);
				}
			}

			em {
				font-weight: 700;
			}
		}
	}

	&__report {
		padding: 10.38rem 0 8rem;
		background: #fff;

       &--junior {
         padding-top: 8.5rem;
         padding-bottom: 9rem;
      }

      .container {
			padding: 0 4rem;

			@include desktop {
				padding: 0;
			}
		}

		&-title {
			@extend %inviteBottomContentTitle;
			margin-bottom: 4.35rem;
		}

		&-sub-title {
          font-size: 2rem;
          text-align: center;
        }

        .lottie-item {
          padding: 0 0.5rem;
        }

		.example-item {
			margin-top: 5.015rem;
			text-align: center;

			& + .example-item {
				margin-top: 6rem;
			}

			&:last-of-type {
				margin-bottom: 4rem;
			}

			&__title {
				display: inline-block;
				padding: 0 2.5rem;
				margin-bottom: 2.5rem;
				border: 0.075rem solid #666;
				border-radius: 2.45rem;
				color: #333;
				font-size: 1.5rem;
				font-weight: 700;
				line-height: 4.2rem;
				letter-spacing: -0.07em;
			}

			img {
              width: 100%;
              vertical-align: top;
			}
		}

		&-desc {
			@extend %inviteBottomContentDesc;
			margin: 3.7rem 0 3.8rem;
		}

	}

	&__tesol {
		padding: 6.4rem 0 10rem;
		background: #fff;

		.container {
			padding: 0 2.5rem;

			@include desktop {
				padding: 0;
			}
		}

		&-title {
			@extend %inviteBottomContentTitle;

			span {
				display: inline-block;
				margin-bottom: 1.6rem;
				color: #111;
				font-size: 2rem;
				line-height: 2.95rem;
			}
		}

		.desc-img {
			width: 100%;
			margin: 5rem 0 0 -0.2rem;
		}

      &--junior {
        .desc-img {
          width: calc(100% - 0.9rem);
          margin: 4.71rem 0 0 1.3rem;
        }
      }

		.desc-txt {
			margin: 3.6rem 0 3.9rem;
			@extend %inviteBottomContentDesc;
		}
	}

	&__class {
		padding: 10.4rem 0 7.5rem;

		&-title {
			margin-bottom: 3.9rem;
			@extend %inviteBottomContentTitle;
		}

		.slide-row {
			font-size: 0;
			white-space: nowrap;

			& + .slide-row {
				margin-top: 1rem;
			}

			&--first {
				.swiper-wrapper{
					transform: translateX(-19.8rem);
					animation: slideLeft 30s linear infinite;
				}

				.swiper-slide,
				.swiper-slide img {
					width: 24.7rem;
					height: 12rem;
				}
			}

			&--second {
				.swiper-wrapper {
					transform: translateX(-17.3rem);
					animation: slideLeft2 26s linear infinite;
				}

				.swiper-slide,
				.swiper-slide img {
					width: 20rem;
					height: 9rem;
				}

			}

			&--third {
				.swiper-wrapper {
					transform: translateX(-16.8rem);
					animation: slideLeft3 28s linear infinite;
				}

				.swiper-slide,
				.swiper-slide img {
					width: 17rem;
					height: 7rem;
				}
			}
		}

		.swiper-slide {
			display: inline-block;
			vertical-align: top;

			& + .swiper-slide {
				margin-left: 1rem;
			}

			img {
				border-radius: 1rem;
			}

		}

		&-desc {
			@extend %inviteBottomContentDesc;
			margin: 3.85rem 0 3.7rem;
		}
	}

	&__service {
		padding: 7.9rem 0 7.5rem;

		.lottie-item {

			&--ai {
				padding-bottom: 4.95rem;
				margin-bottom: 5rem;
			}

			&--schedule {
				margin-bottom: 3.8rem;
			}

			&__title {
				@extend %inviteBottomContentTitle;
			}

			&__animation {
				position: relative;
				width: 25rem;
				margin: 5.8rem auto 3.75rem;
				border-radius: 2.3rem;
				box-shadow: 0 0.15rem 2.45rem rgba(0, 0, 0, 0.05);

				.ico-badge {
					position: absolute;
					right: -4.5rem;
					top: -4.5rem;
					width: 9rem;
				}
			}

			&__desc {
				@extend %inviteBottomContentDesc;
			}
		}
	}

	&__close {
      padding: 7.7rem 0 10rem;
      font-size: 0;

		.lottie-item {

			&__title {
				@extend %inviteBottomContentTitle;

				strong {
					margin-top: 1.5rem;
				}
			}

			&__animation {
				width: 25.5rem;
				margin: 3.9rem auto 4rem;
			}
		}
	}

	&__notice {
		padding: 5.1rem 0 9.75rem;
		background: #fff;

		.container {
			padding: 0 2.2rem;

			@include desktop {
				padding: 0;
			}
		}

		.tit {
			margin: 0 0 1rem;
			font-size: 1.8rem;
			line-height: 2.4rem;
		}

		.list {
			color: #555;
			font-size: 1.4rem;
			line-height: 2.2rem;
			letter-spacing: -0.03em;

			& + .list:not(.sub) {
				margin-top: 0.8rem;
			}

			&.adj + .sub {
				margin-top: 0.5rem;
			}

			&.sub {
				padding-left: 0.7rem;
			}

			&:not(.sub) {
				position: relative;
				padding-left: 0.7rem;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 1rem;
					width: 0.2rem;
					height: 0.2rem;
					background: #555;
					border-radius: 50%;
				}
			}

			&.sub {
				color: #999;
				font-size: 1.3rem;
				line-height: 2rem;
			}
		}
	}

  &__junior-curriculum{
    position: relative;
    background-color: #FFFEEF;
    padding: 10.3rem 0 10rem;
    font-size: 0;
    
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 28.3rem;
      left: 0;
      width: 100%;
      height: 13.7rem;
      background: url($imgURL + '/web/junior/mo/main/bg_course.svg') no-repeat 56% 50%;
      background-size: contain;
    }
    &-title {
      @extend %inviteBottomContentTitle;
      margin-bottom: 5.8rem;
      strong {
        margin-top: 0;
      }
    }
    &-desc {
    	@extend %inviteBottomContentDesc;
      margin-bottom: 3.7rem;
    }
    .desc-img {
			width: 100%;
      margin-bottom: 17rem;
    }
  }
  
  &__junior-system{
    padding: 9.8rem 0 10rem;
    background-color: #0A236A;
    
    .container{
      overflow: hidden;
    }
    
    .title-area {
      position: relative;
      padding: 0 3rem;
      text-align: left;
  
      @include desktop {
        padding: 0;
      }
  
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -1.5rem;
        right: -15.8rem;
        width: 30.55rem;
        height: 30.55rem;
        background: url($imgURL + '/web/junior/mo/main/bg_reason1.svg') no-repeat 50% 50%;
        background-size: contain;
      }
    }
  
    .title {
      color: #ffffff;
      font-size: 2.8rem;
      line-height: 1.39;
      letter-spacing: -0.02em;
    }
  
    .sub-title {
      display: block;
      margin-bottom: 1.25rem;
      color: #fff;
      font-size: 2rem;
      font-weight: 700;
      line-height: 3.8rem;
      letter-spacing: -0.02em;
    }
    
    .swiper-container {
      margin-top: 5.65rem;
  
      &:before {
        content: '';
        position: absolute;
        top: 34.5rem;
        left: 2.65rem;
        width: 6rem;
        height: 6rem;
        background: url($imgURL + '/web/junior/mo/main/bg_reason2.svg') no-repeat 50% 50%;
        background-size: contain;
        z-index: 10;
      }
  
      .swiper-slide {
        width: 25rem;
        margin: 0 0.625rem;
      }
  
      .app-img {
        position: relative;
        width: 100%;
        height: auto;
      }
  
      p {
        display: block;
        padding: 3.8rem 0 3.45rem;
  

      }
      
      .swiper-pagination-bullet {
        display: none;
        width: 100%;
        height: auto;
        color: #E0E8FF;
        font-size: 1.5rem;
        line-height: 2.3rem;
        background: none;
        text-align: center;
        font-family: 'Pretendard', 'Roboto', 'Noto Sans KR', sans-serif;
        font-weight: 400;

        strong {
          display: inline-block;
          margin-top: 0.6rem;
          font-size: 1.8rem;
          font-weight: 700;
          line-height: 3rem;
        }
      }

      .swiper-pagination-bullet-active {
        display: block;
      }
    }
  }
}

@keyframes slideLeft {
	0% {
		transform: translateX(-19.8rem);
		opacity: 1;
	}
	100% {
		transform: translateX(-148.3rem);
		opacity: 1;
	}
}


@keyframes slideLeft2 {
	0% {
		transform: translateX(-17.3rem);
		opacity: 1;
	}
	100% {
		transform: translateX(-143.3rem);
		opacity: 1;
	}
}

@keyframes slideLeft3 {
	0% {
		transform: translateX(-16.8rem);
		opacity: 1;
	}
	100% {
		transform: translateX(-142.8rem);
		opacity: 1;
	}
}