/* common */
$imgURL:'https://img.uphone3.com';
$orange: #FF613C;
$green: #50CBB5;
$purple: #9732FC;
$black: #000;
$gra1: linear-gradient(to right, #9732fc, #fc4c4c);
$gra2: linear-gradient(to bottom, #9732fc, #fc4c4c);
$gra3: linear-gradient(to bottom, #9732fc, #fc4c4c);
$gra4: linear-gradient(to right, #00E599, #00BFE5);
$gra5: linear-gradient(to bottom, #00E599, #00BFE5);
$gra6: linear-gradient(to top, #fff, rgba(255,255,255,0.9) 65%, rgba(255,255,255,0.8) 85%, rgba(255,255,255,0) 100%);
$gra7: linear-gradient(128deg, rgba(50,169,252,1) 0%, rgba(0,191,229,1) 100%, rgba(0,213,255,1) 100%, rgba(0,0,0,1) 100%);
$gra8: linear-gradient(to bottom, #EDF6FD, #F9F2FF);

$mainColor01: #9732fc;
$mainColor02: #fc4c4c;
$mainColor03: linear-gradient(to left, #fc4c4c, #9732fc);

$badge01: linear-gradient(to left, #fc4c4c, #fc4c4c 15%, #9732fc 85%, #9732fc);
$badge02: linear-gradient(to left, #9732fc, #9732fc 15%, #4cdffc 85%, #4cdffc);
$badge03: linear-gradient(to right, #9732fc, #9732fc 15%, #4C69FC 85%, #4C69FC);

$subColor01: #4cdffc;
$subColor02: #4c69fc;
$subColor03: #fc4cc2;
$subColor04: #00e599;
$subColor05: #32A9FC;
$subColor06: #FFF6F6;
$subColor07: #FFF8ED;
$subColor08: #F2F9F6;
$subColor09: #EDF6FD;
$subColor10: #F9F2FF;
$subColor11: #F7F7F7;
$subColor12: #707070;

$thin: 100;
$regular: 400;
$medium: 500;
$bold: 700;
$extrabold: 800;


/* 스케줄 페이지 */
$holiday: rgba(255, 97, 60, 0.5);


/* 버튼 관련 */
$btnDisableBg: #555;
$btnDisableColor: #555; //디자인 가이드에 테두리 없음 요청이라 배경과 테두리 색 맞춰놓음 - 2020.12.9 남인식

.blueHighlight {
  color: #4C69FC;
  background: linear-gradient(to top, rgba(76,105,252,.1) 42%, transparent 42%);
}