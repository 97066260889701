@import "../common/commonCode.scss";
@import "../common/_mixin";

#TopBannerLMS~.orderPage {
  margin-top: 0;
}

//header--rf 에 인라인 스타일로 개발 되어있어 불가피하게 important 사용. header 재 리팩토링 필요
.orderPage .bcm-header {
  top: 0 !important;
}

.orderPage.p1 .bcm-header {
  @include desktop {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

.top-banner-lms--mo.order {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;

  &+.orderPage {
    padding-top: 75px;

    .bcm-header {
      top: 75px !important;
    }

    .order-floating {
      margin-top: 75px;
    }

  }
}

.top-banner-lms--pc.order {
  position: static !important;

  &+.orderPage .bcm-header {
    top: 0 !important;
  }
}

.orderPage {
  margin: 0 -2rem;
  //padding: 0.1rem 2.0rem 6rem;  // 풋터 추가되어 margin-bottom 0으로 수정
  padding: 0.1rem 2.0rem 0;

  &__emptyarea {
    height: 100vh;
  }

  .step1 {
    position: relative;
  }

  &.p2,
  &.p3 {
    &.on {
      min-height: calc(100vh - calc(env(safe-area-inset-top) + env(safe-area-inset-bottom)));
      overflow: hidden;
    }
  }

  &.p2.on {
    background: #F1F1F1;
  }

  &+.footer {
    @include desktop {
      margin: 0 -2rem;
    }
  }

  .movePage {
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: -0.3rem;
      right: -0.4rem;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      background: #FC4C4C;
    }
  }

  .slide1,
  .slide2,
  .slide3 {
    display: none;
  }

  .topBnrWrap {
    margin: 0.9rem -2rem 0;

    &.productList {
      margin-top: -1.7rem;
    }

    .item {
      max-width: 33.5rem;
    }

    .topBnr {
      width: 33.5rem;
      height: 7.5rem;
      border-radius: 1rem;
      background: url($imgURL + '/order/bg_intro.svg') no-repeat center;
      background-size: contain;
      text-align: left;
      padding: 1.5rem 0 0 2.5rem;

      .tit {
        font-size: 1.7rem;
        font-weight: $bold;
        letter-spacing: -0.05rem;
        line-height: 1.6;
        color: #fff;

        &.adj {
          font-size: 1.6rem;
          line-height: 1.4;

          span {
            display: inline-block;
            vertical-align: top;
            max-width: 17rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .sub {
        font-size: 1.2rem;
        color: #fff;
        opacity: .7;
        line-height: 1.4;
      }
    }

    .product {
      margin-right: 1rem;
    }
  }

  .visualWrap {
    padding: 3.1rem 0 0;

    .title {
      font-size: 3rem;
      font-weight: $bold;
      letter-spacing: -0.02em;
      line-height: 1.4;
      text-align: left;
      word-break: keep-all;
      word-wrap: break-word;
      color: #111;

      .ellipsis {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .notice {
      width: 100%;
      height: 4.3rem;
      border-radius: 2.4rem;
      background: #F5F6F9;
      text-align: left;
      position: relative;
      margin: 1.6rem 0;
      overflow: hidden;

      .swiper-wrapper {
        height: 4.3rem;
        display: block;

        .swiper-slide {
          font-size: 1.3rem;
          letter-spacing: -0.02em;
          color: #111;
          line-height: 3.4;
          padding-left: 2.5rem;
          text-indent: 1.9rem;

          span {
            font-weight: $bold;
          }

          &.i01 {
            background: url($imgURL + '/order/ico_bnf_01.svg') no-repeat left 2.8rem center/1.14rem 1.14rem;
          }

          &.i02 {
            background: url($imgURL + '/order/ico_bnf_02.svg') no-repeat left 2.8rem center/1.14rem 1.14rem;
          }

          &.i03 {
            background: url($imgURL + '/order/ico_bnf_03.svg') no-repeat left 2.8rem center/1.14rem 1.14rem;
          }

          &.i04 {
            background: url($imgURL + '/order/ico_bnf_04.svg') no-repeat left 2.8rem center/1.14rem 1.14rem;
          }

          &.i05 {
            background: url($imgURL + '/order/ico_bnf_05.svg') no-repeat left 2.8rem center/1.14rem 1.14rem;
          }
        }
      }

      .btnMore {
        font-size: 1.1rem;
        letter-spacing: -0.02em;
        line-height: 4;
        color: #555;
        position: absolute;
        top: 0;
        right: 2.5rem;
      }
    }
  }


  .productList {

    &.b2b {
      .product {
        height: auto;

        .priceWrap {
          margin-top: 1rem;

          .price {
            margin: 0;
          }
        }
      }
    }

    .noResult {
      width: 100%;
      text-align: center;
      margin: 5rem auto 6rem;

      img {
        width: 6.6rem;
      }

      .msg {
        display: block;
        font-size: 1.5rem;
        letter-spacing: -0.02em;
        color: #999;
        line-height: 1.53;
        margin-top: 0.5rem;
      }
    }

    .product {
      position: relative;
      //height: 15.65rem;
      margin: 1.5rem auto;
      border-radius: 0 1rem 1rem 1rem;
      padding: 1.5rem 3.5rem;
      text-align: left;
      overflow: hidden;
      z-index: 10;

      &.adj {
        padding: 2rem 3.5rem;
      }

      @include mobile {
        &:last-child {
          margin-bottom: 0;
        }
      }

      .select {
        border: 1px solid #fff;
        border-radius: 0 1rem 1rem 1rem;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        &.on {
          border: 0.25rem solid #fff;
        }
      }

      &.w48 {
        color: #11AB4E;
        background: #F2F9F6;

        .bdg {
          background: #80D2A1;
        }

        .select,
        .productInfo {
          border-color: #D0EEDF;

          li:before {
            background: #D0EEDF;
          }

          &.on {
            border-color: #11AB4E;
          }
        }
      }

      &.w24 {
        color: $mainColor02;
        background: #FFF6F6;

        .bdg {
          background: #FBA7A7;
        }

        .select,
        .productInfo {
          border-color: #FCDFDF;

          li:before {
            background: #FCDFDF;
          }

          &.on {
            border-color: $mainColor02;
          }
        }
      }

      &.w12 {
        color: $subColor02;
        background: #EDF6FD;

        .bdg {
          background: #98B8F8;
        }

        .select,
        .productInfo {
          border-color: #CCE1F2;

          li:before {
            background: #CCE1F2;
          }

          &.on {
            border-color: $subColor02;
          }
        }
      }

      &.w4 {
        color: #FC6E10;
        background: #FFF8ED;

        .bdg {
          background: #FDB27E;
        }

        .select,
        .productInfo {
          border-color: #F3E7D2;

          li:before {
            background: #F3E7D2;
          }

          &.on {
            border-color: #FC6E10;
          }
        }
      }

      .bdg {
        display: inline-block;
        padding: 0.3rem 0.5rem;
        color: #fff;
        font-weight: $medium;
        font-size: 1.1rem;
        border-radius: 0 0 0.5rem 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        .eng {
          font-size: 1.15rem;
          letter-spacing: 0;
        }
      }

      .status-item {
        &+.status-item {
          &:before {
            content: '';
            display: inline-block;
            width: .1rem;
            height: 0.7rem;
            margin: 0.2825rem 0.6rem 0;
            background: #fff;
            opacity: .5;
            vertical-align: top;

            @include desktop {
              height: 8px;
              margin: 5.03px 10px 0;
            }

          }
        }
      }

      .week,
      .mode {
        display: inline-block;
        font-size: 3rem;
        font-weight: $bold;
        margin: 0.1rem 0 0 0.5rem;

        span {
          font-size: 1.5rem;
          vertical-align: 0.2rem;
        }
      }

      .mode {
        font-size: 2.5rem;
        margin: 0.7rem 0 0 0.5rem;
      }

      .productInfo {
        //수강권 선택 내 정보영역
        width: 100%;
        height: 2.7rem;
        border-radius: 1.5rem;
        overflow: hidden;
        line-height: 2.4rem;
        background: #fff;
        border: 0.1rem solid #fff;
        margin: 0.8rem auto 0;
        padding: 0 1rem;

        li {
          font-size: 1.2rem;
          font-weight: $regular;
          color: #555;
          background: #fff;
          border-radius: 1.5rem;
          float: left;
          text-align: center;
          position: relative;

          &.min {
            width: 32%;
          }

          &.tCode {
            width: 32%;

            @include desktop {
              width: 32%;
            }
          }

          &.zone {
            width: 36%;

            @include desktop {
              width: 36%;
            }
          }

          &:last-child {
            &:before {
              display: none;
            }
          }

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            right: 0;
            top: 0.9rem;
            width: 0.1rem;
            height: 0.8rem;
          }
        }

        &.col2 {
          li {
            width: 50%;
          }
        }
      }

      .priceWrap {
        //margin-top: 3.5rem;
        display: inline-block;
        float: right;
        margin-right: 0.5rem;

        &+.per {
          display: inline-block;
          font-size: 2rem;
          font-weight: $regular;
          letter-spacing: 0;
          position: absolute;
          left: 43%;
          top: 2.3rem;

          span {
            font-size: 1.2rem;
          }

          &.adj {
            top: 2.8rem
          }
        }

        &~.badge {
          position: absolute;
          left: 55%;
          top: 2.5rem;
        }

        .price {
          display: inline-block;
          font-size: 1.2rem;
          letter-spacing: 0;
          color: #111;

          .mp {
            display: inline-block;
            margin-top: -0.3rem;
            font-size: 2rem;
            font-weight: $bold;
          }

          .rp {
            display: block;
            height: 1.3rem;
            color: #999;
            opacity: .5;
            text-decoration: line-through;
            margin-top: -0.2rem;
          }

          .sm {}
        }
      }

      .soldOut {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        background: rgba(17, 17, 17, 0.4);
        border-radius: 0 1rem 1rem 1rem;

        span {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
          height: 2.2rem;
          line-height: 1;
          color: #fff;
          text-align: center;
          font-size: 2.1rem;
          font-weight: $bold;
          letter-spacing: 0;
        }
      }

      .orderInfo {
        display: none;
      }
    }
  }

  .product .badge {
    display: inline-block;
    width: 2.5rem;
    height: 1.8rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.7rem;
    text-align: center;
    border: 0.1rem solid rgba(255, 255, 255, .5);
    border-radius: 0.5rem;
  }

  .orderInfo {
    .week {
      display: inline-block;
      position: relative;
      padding-right: 1rem;
      margin-right: 0.5rem;
      font-weight: $medium;

      &:after {
        content: '';
        position: absolute;
        top: 0.3rem;
        right: 0;
        width: 0.1rem;
        height: 1rem;
        background: rgba(255, 255, 255, .3);
      }
    }

    .medium {
      font-weight: $medium;
    }
  }

  .grayBox {
    width: 100%;
    text-align: left;
    background: #F7F7F7;
    border-radius: 0 2rem 2rem 2rem;
    padding: 2.5rem 2rem 2.1rem;
    margin-top: 3rem;
    margin-bottom: 6rem;

    .tit {
      font-size: 1.5rem;
      font-weight: $medium;
      letter-spacing: 0;
      margin: 0 6rem 1rem 0;
      color: #111;
      line-height: 1.53;
    }

    ul.desc {
      li {
        font-size: 1.3rem;
        letter-spacing: -0.02em;
        line-height: 1.6;
        color: #555;
        position: relative;
        margin-bottom: 0.4rem;
        padding-left: 0.7rem;
        word-break: keep-all;
        word-wrap: break-word;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 1.1rem;
          width: 0.2rem;
          height: 0.2rem;
          background: #555;
          border-radius: 50%;
        }
      }
    }
  }

  .orderInfo {
    cursor: pointer;
    position: fixed;
    overflow: hidden;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 0;
    background: $gra1;
    color: #fff;
    transition: 0.3s;
    z-index: 900;
    text-align: center;

    .price {
      font-size: 2rem;
      font-weight: $medium;
    }

    .total {
      font-size: 1.4rem;
      font-weight: $regular;
      margin-top: 0.6rem;

      .num {
        font-weight: 300;
      }
    }

    .txt {
      font-size: 1.7rem;
      font-weight: $medium;
    }

    &.on {
      height: 8.9rem;
      padding-top: 2.4rem;

      &.safe {
        height: calc(8.9rem + 1rem);
      }

      &.true {
        height: 5.5rem;
        padding-top: 1.6rem;

        &.safe {
          height: calc(5.5rem + 1rem);
        }
      }
    }
  }

  .dividerLine {
    height: 1rem;
    background: #F7F7F7;
    border-top: 0.1rem solid #F1F1F1;
    margin: 3.4rem -2rem 0;
  }

  .subTit {
    font-size: 1.6rem;
    font-weight: $bold;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 3.6rem 0 0;
    padding-bottom: 1.3rem;
    color: #111;
    overflow: hidden;

    .link {
      font-size: 1.3rem;
      font-weight: $regular;
      color: #555;
      margin-top: 0.5rem;
      float: right;

      .ico {
        width: 0.6rem;
        vertical-align: -0.1rem;
      }
    }

    .benefit {
      display: none;
    }
  }

  .bestPrd {
    .productList {
      margin: -1.5rem -2rem 0;

      .item {
        max-width: 33.5rem;
      }
    }
  }

  .bestPrd {
    .subTit {
      margin-top: 3.8rem;
    }

    .productList {
      .swiper-slide {
        &:nth-child(8n+1) .product {
          background: linear-gradient(123deg, #fc4c4d 0%, #8e51e7 100%);

          .productInfo li {
            &.min {
              color: #ec4b6c;
              background: linear-gradient(123deg, #ec4b6c 0%, #dd4a89 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #dd4a8a;
              }
            }

            &.tCode {
              color: #d9498f;
              background: linear-gradient(123deg, #d9498f 0%, #c549b9 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #c348ba;
              }
            }

            &.zone {
              color: #c349bc;
              background: linear-gradient(123deg, #c349bc 0%, #a14ae6 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        &:nth-child(8n+2) .product {
          background: linear-gradient(123deg, #8b52e7 0%, #4a97e5 100%);

          .productInfo li {
            &.min {
              color: #7758e6;
              background: linear-gradient(123deg, #7758e6 0%, #615ee5 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #615ee6;
              }
            }

            &.tCode {
              color: #5f5ee5;
              background: linear-gradient(123deg, #5f5ee5 0%, #4269e5 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #436ae5;
              }
            }

            &.zone {
              color: #426be5;
              background: linear-gradient(123deg, #426be5 0%, #478ae5 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        &:nth-child(8n+3) .product {
          background: linear-gradient(123deg, #4a99e5 0%, #60d2a0 100%);

          .productInfo li {
            &.min {
              color: #4da9e5;
              background: linear-gradient(123deg, #4da9e5 0%, #4fb7e4 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #4fb8e4;
              }
            }

            &.tCode {
              color: #4fb9e4;
              background: linear-gradient(123deg, #4fb9e4 0%, #53cbde 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #53ccdd;
              }
            }

            &.zone {
              color: #54ccdb;
              background: linear-gradient(123deg, #54ccdb 0%, #5dd1b2 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        &:nth-child(8n+4) .product {
          background: linear-gradient(123deg, #61d29d 0%, #b7d43c 100%);

          .productInfo li {
            &.min {
              color: #65d58a;
              background: linear-gradient(123deg, #65d58a 0%, #6ad876 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #69d774;
              }
            }

            &.tCode {
              color: #6bd872;
              background: linear-gradient(123deg, #6bd872 0%, #7dd85c 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #7ed85c;
              }
            }

            &.zone {
              color: #7fd75a;
              background: linear-gradient(123deg, #7fd75a 0%, #a6d544 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        &:nth-child(8n+5) .product {
          background: linear-gradient(123deg, #b7d43c 0%, #61d29d 100%);

          .productInfo li {
            &.min {
              color: #a4d546;
              background: linear-gradient(123deg, #a4d546 0%, #94d750 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #92d751;
              }
            }

            &.tCode {
              color: #8fd752;
              background: linear-gradient(123deg, #8fd752 0%, #75d961 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #73d861;
              }
            }

            &.zone {
              color: #71d862;
              background: linear-gradient(123deg, #71d862 0%, #65d587 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        &:nth-child(8n+6) .product {
          background: linear-gradient(123deg, #60d2a0 0%, #4a99e5 100%);

          .productInfo li {
            &.min {
              color: #5cd0b2;
              background: linear-gradient(123deg, #5cd0b2 0%, #59cfc6 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #59cfc7;
              }
            }

            &.tCode {
              color: #58ceca;
              background: linear-gradient(123deg, #58ceca 0%, #52cae4 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #52c9e4;
              }
            }

            &.zone {
              color: #51c7e4;
              background: linear-gradient(123deg, #51c7e4 0%, #4ca8e4 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        &:nth-child(8n+7) .product {
          background: linear-gradient(123deg, #4a97e5 0%, #8b52e7 100%);

          .productInfo li {
            &.min {
              color: #4789e4;
              background: linear-gradient(123deg, #4789e4 0%, #457be4 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #457ae4;
              }
            }

            &.tCode {
              color: #4578e5;
              background: linear-gradient(123deg, #4578e5 0%, #4965e5 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #4b65e5;
              }
            }

            &.zone {
              color: #4d65e5;
              background: linear-gradient(123deg, #4d65e5 0%, #7658e6 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        &:nth-child(8n+8) .product {
          background: linear-gradient(123deg, #8e51e7 0%, #fc4c4d 100%);

          .productInfo li {
            &.min {
              color: #a04be7;
              background: linear-gradient(123deg, #a04be7 0%, #b247dd 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #b348db;
              }
            }

            &.tCode {
              color: #b548d8;
              background: linear-gradient(123deg, #b548d8 0%, #cb49ab 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:before {
                background: #cc49aa;
              }
            }

            &.zone {
              color: #ce49a6;
              background: linear-gradient(123deg, #ce49a6 0%, #ec4b6b 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }

      .product {
        color: #fff; // 텍스트
        background: transparent;

        .priceWrap .price,
        .per {
          color: #fff; // 가격, 퍼센트 텍스트
        }

        .priceWrap .price .rp {
          color: rgba(255, 255, 255, .5); // 원래 가격 텍스트
        }

        .priceWrap {
          &~.badge {
            top: 3rem;

            &.aos {
              top: 3.2rem;
            }
          }
        }

        .productInfo {
          border-color: transparent; // 정보 테두리

          li {
            font-weight: $medium;
          }
        }

        .select {
          border-color: transparent; // 수강권 테두리

          &.on {
            border-color: #fff; // 수강권 선택 됐을 때 테두리
            border-width: 0.2rem;
            border-radius: 0 0.8rem 0.8rem 0.8rem;
            margin: 0.2rem;
            width: calc(100% - 0.4rem);
            height: calc(100% - 0.4rem);
          }
        }

        .bdg {
          display: none;
          background: linear-gradient(to right, #00c3e5, #00dfd0);
          padding: 0.3rem 1.5rem;

          span {
            font-size: 1.05rem;
          }
        }
      }
    }
  }

  .filterItemWrap {
    position: sticky;
    top: calc(0rem + env(safe-area-inset-top));
    z-index: 90;

    .subTit {
      margin-top: 2.7rem;
      padding-top: 1.1rem;
      background: #FFF;
    }
  }

  .filterItem {
    background: #fff;
    border-top: 0.1rem solid #F1F1F1;
    margin: -0.1rem 0 2.5rem;

    li {
      //overflow: hidden;
      padding: 1.3rem 0 1.2rem;
      border-bottom: 0.1rem solid #F1F1F1;

      &:after {
        display: block;
        content: '';
        clear: both;
      }

      span {
        display: inline-block;
        float: left;
        font-size: 1.3rem;
        text-align: left;
        color: #555;

        &.tit {
          width: 23%;
          font-weight: $medium;
          text-indent: 0.5rem;
          cursor: default;
          color: #111;
        }

        &:nth-child(2) {
          width: 27%;
        }

        &:nth-child(3) {
          width: 26%;
        }

        &:nth-child(4) {
          width: 24%;
        }

        &.timeZone {
          width: 36.5%;
          margin-bottom: 1.5rem;

          &:nth-child(4) {
            margin-left: 23%;
            margin-bottom: 0;
          }

          &:nth-child(5) {
            width: max-content;
            margin-bottom: 0;
          }

          .time {
            float: none;
            font-size: 1.2rem;
            letter-spacing: -0.05rem;
          }
        }

        &.on {
          color: $purple;
          font-weight: $bold;
          position: relative;

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -0.4rem;
            top: 0.6rem;
            width: 0.2rem;
            height: 0.2rem;
            background: $purple;
            border-radius: 50%;
          }

          span {
            color: $purple;

            &.badge {
              background: $purple;
              color: #fff;
            }
          }

          .time {
            color: $purple;
          }
        }

        &.badge {
          width: 3.05rem;
          height: 1.45rem;
          margin: 0 0 -0.1rem 0.3rem;
          font-size: 0.9rem;
          font-weight: $medium;
          line-height: 1.6rem;
          text-align: center;
          color: #fff;
          background: #555;
          border-radius: 1.5rem;
          float: none;
        }

        &.more {
          width: auto;
          font-size: 1.1rem;
          line-height: 1.2;
          color: $purple;
          border: 0.1rem solid rgba(151, 50, 252, .3);
          border-radius: 2rem;
          padding: 0.3rem 1.2rem;
          margin: -0.3rem 0 -0.4rem -0.4rem;
          text-align: center;
        }

        &.filterItem__time {
          position: relative;

          .balloon15per {
            display: block;
            position: absolute;
            z-index: 101;
            right: 0.2rem;
            bottom: 0.6rem;
            width: 6rem;
            height: 6.5rem;
            box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, .16);
            border-radius: 1rem 1rem 1rem 0;

            //width: 8.8rem;
            //height: 8.8rem;
            //background: url('https://img.uphone3.com/order/balloon15per_no.svg') 0 0 no-repeat;
            //background-size: 100% auto;
            img {
              width: 100%;
              height: 100%;

              &.pc {
                display: none;
              }
            }

            @include desktop {
              right: 48px;
              bottom: 19px;
              width: 80px;
              height: 80px;
              box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
              border-radius: 20px 20px 20px 0;

              img {
                &.mo {
                  display: none;
                }

                &.pc {
                  display: block;
                }
              }

              //background: url('https://img.uphone3.com/order/balloon15per_pc.svg') 0 0 no-repeat;
              //background-size: 100% auto;
            }
          }

          &.last {
            margin-left: 3.1rem;

            @include desktop {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .benefit {
    .subTit {
      border-bottom: 0.1rem solid #F1F1F1;
    }

    .list {
      margin: 2.2rem 0 3.45rem;

      li {
        text-align: left;
        font-size: 1.4rem;
        color: #555;
        margin: 0 0 1.4rem 0.2rem;
        padding-left: 0.9rem;
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0.1rem;
          top: 0.8rem;
          width: 0.2rem;
          height: 0.2rem;
          background: #555;
          border-radius: 50%;
        }
      }
    }
  }

  .device-support {
    margin-bottom: 6rem;

    .subTit {
      margin-top: 3.9rem;
      border-bottom: 0.1rem solid #F1F1F1;
    }

    &__notice {
      margin: 2rem 0 0;

      li {
        position: relative;
        padding-left: 1.15rem;
        color: #555;
        font-size: 1.4rem;
        text-align: left;
        line-height: 2rem;
        word-break: keep-all;

        &+li {
          margin-top: 1rem;
        }

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0.3rem;
          top: 0.9rem;
          width: 0.2rem;
          height: 0.2rem;
          background: #555;
          border-radius: 50%;
        }
      }
    }

    &__reference {
      margin-top: 1rem;
      padding: 1.45rem 2.2rem;
      background: #F8F8F8;
      border-radius: 1.2rem;

      li {
        position: relative;
        padding-left: .6rem;
        color: #555;
        font-size: 1.3rem;
        line-height: 2.03rem;
        word-break: keep-all;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0.1rem;
          top: 0.85rem;
          width: 0.2rem;
          height: 0.2rem;
          background: #555;
          border-radius: 50%;
        }
      }
    }
  }

  .bottomPop {

    .topWrap {
      padding: 1.3rem 0;

      .reset {
        top: 1.8rem;
        left: 0;
      }

      .clsBtn {
        position: absolute;
        top: 1.5rem;
        right: 0.2rem;
        width: 1.98rem;
        height: 1.98rem;
        background: url($imgURL + '/common/btn_close.svg') no-repeat center/cover;
      }
    }

    .contents {
      margin: 0 0 4.6rem;

      .title {
        margin-top: 3rem;
      }

      .btnWrap {
        .btn.on {
          background: #F9F2FF;
        }

        .btn:not(.on) {
          margin-top: 0.5rem;
        }
      }
    }

    .btn.view {
      &.safe {
        height: calc(5.6rem + 1rem); // 기존 높이 + 1rem
      }
    }
  }

  .orderProduct {
    font-size: 1.4rem;
    color: #555;
    text-align: left;
    background-color: #fff;
    margin: 1.0rem -2.0rem;
    padding: 0;

    .list {
      position: relative;
      padding: 2.1rem 2.0rem 1.8rem;
      border-bottom: 0.1rem solid #F1F1F1;

      .title {
        font-size: 1.8rem;
        font-weight: $medium;
        color: #111;
      }

      .sInfo {
        position: absolute;
        top: 2.1rem;
        right: 5.3rem;
        font-size: 1.5rem;
        letter-spacing: -0.05rem;
        width: 23rem;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .clsBtn {
        position: absolute;
        top: 2.0rem;
        right: 2.0rem;

        img {
          height: 0.8rem;
        }
      }
    }

    .body {
      padding: 2.5rem 2.0rem;
    }

    .weekBadge {
      display: inline-block;
      width: 2.5rem;
      height: 1.8rem;
      margin-right: 0.3rem;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 1.7rem;
      text-align: center;
      color: $purple;
      border-radius: 0.5rem;
      border: 0.1rem solid rgba(151, 50, 252, .8);
      vertical-align: 0.1rem;

      &.total {
        vertical-align: 0.3rem;
      }
    }

    .body.bp {
      padding: 2.0rem 2rem 3.3rem;
    }

    .table {
      display: table;
      width: 100%;

      .tr {
        display: table-row;

        .th {
          display: table-cell;
          width: 24%;
          line-height: 1.46;
        }

        .td {
          display: table-cell;
          padding: 0.8rem 0;
          line-height: 1.46;
          word-break: keep-all;
          word-wrap: break-word;
          color: #111;

          .zoneInfo,
          .dateInfo {
            margin-top: 0.8rem;
            color: #999;
            font-size: 1.2rem;
            letter-spacing: -0.05rem;
            padding-left: 0.5rem;
            text-indent: -0.7rem;
            word-break: keep-all;
            word-wrap: break-word;
          }

          /*
					.dateInfo {
						color: #999;
					}
					*/

          span:not(.weekBadge) {
            display: block;
            line-height: 1.6;
          }
        }
      }

      .tr.disInfo {
        font-size: 1.3rem;

        .th {
          color: #999;
          font-weight: normal;
        }

        .td {
          color: #999;

          &.textalignR {
            font-weight: normal;
          }
        }
      }

      .textalignR {
        text-align: right;
        font-weight: $medium;
      }
    }

    .table.price {

      .th {
        width: 40%;
      }

      .td {
        padding: 0.7rem 0 0.6rem;
      }
    }

    .table.topLine {
      margin-top: 0.6rem;
      border-top: 0.1rem solid #EEE;
      padding-top: 1rem;

      div {
        vertical-align: top;
      }

      .th,
      .td {
        padding: 1.2rem 0 0;
      }

      .th {
        font-weight: $medium;
        color: #111;
      }

      .td {
        font-weight: $regular !important;
        color: #555;
      }

      .td.t {
        font-size: 1.8rem;
        font-weight: $medium !important;
      }

      .tr:last-child div {
        padding: 0.3rem 0 0;
        font-weight: $medium;
      }
    }

    .discount {
      margin: 0.8rem 0 0;

      .inputWrap {
        margin-top: 2.9rem;
        font-size: 1.6rem;

        .infoBox {
          position: relative;
          overflow: hidden;
          color: #111;

          .txt1 {
            float: left;
          }

          .info {
            float: right;

            .b {
              font-weight: $bold;
            }
          }
        }

        .desc {
          font-size: 1.3rem;
          letter-spacing: -0.02rem;

          li {
            margin: 0.4rem 0 0;
            padding-left: 0.7rem;
            //text-indent: -0.3rem;
            color: #555;
            line-height: 1.62;
            word-break: keep-all;
            word-wrap: break-word;
            position: relative;

            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              left: 0;
              top: 1.1rem;
              width: 0.2rem;
              height: 0.2rem;
              background: #555;
              border-radius: 50%;
              /*
							//텍스트라서 iOS-Android 간격이 다름
							content: '\00B7';
							display: inline-block;
							margin-right: 3px;
							*/
            }
          }
        }

        .selectBox {
          margin-top: 0.3rem;

          .inputArea {
            margin-top: 0;
          }
        }

        .inputArea {
          position: relative;
          margin: 1.3rem 0 0.7rem;
          overflow: hidden;

          .inputBox {
            float: left;
            width: 74%;
            background-color: #F4F4F4;
            border-radius: 1.0rem;

            .mileage {
              width: 90%;
              padding: 0 0.5rem;
              font-size: 1.7rem;
              color: $purple;
              font-weight: 600;
              text-align: right;
            }

            input {
              margin: 0;
            }

            input:focus {
              border: none;
            }
          }

          .btn {
            float: right;
            width: 25%;
            margin: 0;
            background: #333;
          }
        }
      }

      .inputWrap:first-child {
        margin-top: 0;
      }

    }

    .totalPrice {
      font-size: 1.6rem;
      color: $purple;
    }

    .txtList {
      margin-left: 0.5rem;
      margin-bottom: 1.0rem;
      font-size: 1.3rem;
      text-indent: -0.6rem;
      line-height: 1.4;
      word-break: keep-all;
      word-wrap: break-word;
    }

  }

  .orderProduct.true {
    .clsBtn {
      transform: rotate(180deg);
      top: 2.5rem;

      @include desktop {
        top: 2.7rem;
      }
    }
  }

  .orderProduct.false {

    .body {
      display: none;
    }

    .clsBtn {
      /*
			transform: rotate(180deg);
			top: 2.5rem;
			*/
    }
  }

  .step2,
  .step4 {
    #header {
      .headerInner {
        display: block;
        height: 5rem;
        line-height: 1;

        .historyBack {
          display: block;
          position: absolute;
          top: 1.3rem;
          left: 1rem;
          width: 2.4rem;
          height: 2.4rem;
          padding: 0;
        }

        .title {
          width: 75%;
          margin: 0 auto;
          padding-top: 1.5rem;
          font-size: 1.7rem;
          font-weight: $medium;
          text-align: center;
          color: #111;
        }
      }

      .btnOrder {
        display: none;
      }
    }
  }

  .step2 {
    .c3 {
      .btn {
        height: 6.0rem;
      }
    }

    .btnWrap {
      &.pay {
        &.c4 {

          .btn:first-child,
          .btn:nth-child(2n) {
            margin-bottom: 0;
          }
        }

        .btn {
          height: 6rem;
          color: #333;
          //border-color: #EEEEEE;
          font-weight: $regular;

          &.on {
            color: $purple;
            border-color: $purple;
          }

          &--tpay {
            img {
              height: 1.8rem;
              vertical-align: -0.25rem;
              margin-right: 0.35rem;
            }
          }
        }
      }

      &--bill-flag.pay.c2 {
        .btn {
          width: 31.343%;

          &--npay {
            width: 65.671%;
            margin-left: 2.986%;
          }
        }
      }
    }

    .cardInfo {
      color: #111;
      margin-top: 0.7rem;

      .red {
        color: $mainColor02 !important;
      }
    }

    .orderProduct {
      .list {
        .clsBtn {
          width: 1.9rem;
          height: 1.2rem;
        }
      }
    }
  }

  .step3 {
    .orderProduct {
      &:last-child {
        .topLine {
          .td {
            font-weight: $medium !important;
          }
        }
      }

      &.top {
        text-align: center;
        margin-top: -5.1rem;
        padding: 6.0rem 2.0rem 2.0rem;

        .checkIcon {
          width: 6rem;
        }

        .title {
          margin: 1.0rem 0 1.5rem;
          font-size: 2.4rem;
          font-weight: 600;
          color: #111;
        }

        .subInfo {
          line-height: 1.57;
        }

        .tubInfo {
          font-size: 1.3rem;

        }

        .classWrap {
          position: relative;
          width: 100%;
          margin: 3rem 0;
          padding: 2rem 2rem 3.4rem;
          overflow: hidden;
          background: #F7F7F7;
          border-radius: 0 2.0rem 2.0rem 2.0rem;

          .title {
            display: inline-block;
            font-size: 1.4rem;
            font-weight: $regular;
            text-align: left;
            color: #111;
          }

          .timeData {
            font-size: 3.5rem;
            font-weight: $bold;
            letter-spacing: 0;
            color: #111;
          }

          .courseData {
            font-size: 1.8rem;
            font-weight: $bold;
            letter-spacing: -0.02em;
            color: #111;
          }

          .guide {
            margin: 3rem 1.5rem -2.8rem;
            padding: 2rem 0;
            border-top: 0.1rem solid #ccc;

            span {
              display: block;
              font-size: 1.3rem;
              line-height: 1.6;
              color: #555;
            }
          }

          &~.btnWrap {
            margin: -1rem 0 2rem;
            padding: 2.5rem 0 2rem;
            background: $gra1;
            border-radius: 1.5rem;

            .tit {
              margin-bottom: 1.5rem;
              font-size: 1.6rem;
              font-weight: $medium;
              color: #fff;
            }

            a {
              display: inline-block;
              margin: 0.5rem;
              width: 13.5rem;
              height: 5rem;
              background: #fff;
              border-radius: 1rem;
              font-size: 1.4rem;
              font-weight: $medium;
              color: #111;
              line-height: 5rem;

              img {
                margin-right: 0.3rem;
                width: auto;
                height: 2.1rem;
                vertical-align: -0.4rem;
              }
            }
          }

          &~.comment {
            font-size: 1.2rem;
            color: #555;
            line-height: 1.6;
            text-align: left;

            .txt {
              display: inline-block;
              position: relative;
              padding-left: 0.7rem;

              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0.9rem;
                width: 0.2rem;
                height: 0.2rem;
                background: #555;
                border-radius: 50%;
              }
            }
          }

          .value {
            padding-right: 1.0rem;
            font-size: 1.8rem;
            font-weight: 600;
            text-align: right;
          }

          .chgBtn {
            position: absolute;
            top: 2.3rem;
            right: 2.0rem;
            width: 3.6rem;
            height: 3.0rem;
            color: #fff;
            font-size: 1.3rem;
            line-height: 3.0rem;
            background-color: $purple;
            border-radius: 1.0rem;
          }

          select {
            width: 50%;
            padding-right: 5.0rem;
            background-color: transparent;
            border: none;
            text-align-last: right;

            option {
              direction: rtl;
            }
          }

          &+.comment {
            font-size: 1.1rem;
            color: #555;
            letter-spacing: -0.02em;
            text-align: left;
            margin-top: -2.2rem;
            line-height: 2.1rem;
          }

          &.time {
            .dot.adj {
              img {
                margin: 1.8rem 0 0.5rem;
              }
            }
          }
        }

        .infoList {
          margin: 1.0rem 0 2.0rem;
          color: #999;
          font-size: 1.1rem;
          line-height: 1.5;
          letter-spacing: -0.02em;

          span {
            color: #555;
            text-decoration: underline;
            line-height: 1.5;
          }
        }

        .dot {
          img {
            width: 0.25rem;
          }
        }
      }
    }
  }

  .msk {
    position: fixed;
    top: -10rem;
    left: -3.0rem;
    width: calc(100% + 6.0rem);
    height: calc(100% + 10rem);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 900;
  }

  .msk.hidden {
    display: none;
  }

  .popupWrap.full {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;
    padding: 5rem 0 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;

    .infoPop {
      height: 100%;
    }

    .content {
      padding: 0 2rem calc(5.3rem + env(safe-area-inset-bottom));
      font-size: 1.3rem;
      text-align: left;
      line-height: 1.4;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      height: calc(100% - 5rem);

      @include desktop {
        font-size: 1.4rem;
      }
    }

    .title {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 5rem;
      padding: calc(1.6rem + env(safe-area-inset-top)) 0 0;
      background: #fff;
      font-size: 1.7rem;
      font-weight: $medium;
      text-align: center;
    }

    .clsBtn {
      position: absolute;
      z-index: 20;
      top: calc(1.5rem + env(safe-area-inset-top));
      right: 2.1rem;
      width: 1.98rem;
      height: 1.98rem;
      background: url($imgURL + '/common/btn_close.svg') no-repeat center/cover;
    }

    .cardInfo {
      div {
        border-bottom: 0.1rem solid #eee;
        padding: 0.8rem 1.9rem 0.5rem;
        overflow: hidden;
        display: flex;
        align-items: center;

        @include desktop {
          padding: 1.6rem 1.9rem 1.5rem;
        }

        &.topLine {
          border-top: 0.2rem solid #111;
          margin-top: 3rem;

          @include desktop {
            margin-top: 3rem;
          }
        }

        p {
          float: left;
          color: #111;
          font-weight: $medium;
          width: 10.4rem;
          display: table-cell;
          vertical-align: middle;

          @include desktop {
            width: 11.2rem;
          }
        }

        ul {
          width: calc(100% - 10.4rem);
          float: left;
          color: #555;

          @include desktop {
            width: calc(100% - 11.2rem);
          }

          li {
            margin-bottom: 0.2rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      b {
        display: block;
        margin: 1.5rem 0 1rem;
        font-weight: $medium;
        font-size: 1.5rem;
        color: #111;

        @include desktop {
          margin: 2.3rem 0 1rem;
          font-size: 1.6rem;
        }
      }

      b~ul,
      ul.naver {
        width: 100%;

        li {
          position: relative;
          margin-bottom: 0.6rem;
          padding-left: 0.8rem;
          line-height: 1.6;
          word-break: keep-all;
          word-wrap: break-word;
          color: #555;

          @include desktop {
            margin-bottom: 0.8rem;
            padding-left: 0.6rem;
            line-height: 1.5;
          }

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0.9rem;
            width: 0.2rem;
            height: 0.2rem;
            background: #555;
            border-radius: 50%;
            /*
						//텍스트라서 iOS-Android 간격이 다름
						content: '\00B7';
						display: inline-block;
						margin-right: 3px;
						*/
          }
        }
      }
    }

    &.nPay {
      .topLine {
        border-bottom: 0;
      }

      ul.naver {
        width: calc(100% + 3.8rem);
        margin: 0 -1.9rem;
      }

      &.mo {
        height: auto;
        max-height: 100vh;
        top: unset;
        border-radius: 2rem 2rem 0 0;
        overflow: hidden;

        .topLine {
          padding-left: 0;
          padding-right: 0;
          border-top: none;
        }

        ul.naver {
          margin: 0;

          li {
            font-size: 1.4rem;
            letter-spacing: -0.02em;
            line-height: 1.6;
            color: #555;
            position: relative;
            margin-bottom: 0.4rem;
            padding-left: 0.7rem;
            word-break: keep-all;
            word-wrap: break-word;
          }
        }

        .content {
          height: auto;
          padding-top: 1rem;
        }

        .title {
          font-size: 2.3rem;
          font-weight: 700;
          letter-spacing: -0.02em;
          color: #111;
          margin: 3.3rem 0 2.4rem 2rem;
          text-align: left;
        }
      }
    }

    .content.course {
      .tit {
        font-size: 3.2rem;
        font-weight: $bold;
        letter-spacing: -0.09rem;
        line-height: 1.25;
        color: #111;
        margin-top: -0.6rem;
        margin-right: -2rem;

        span {
          color: #ccc;
        }
      }

      .desc {
        .descImg {
          margin: 3.3rem -2rem 3rem;
          height: 25.4rem;
          overflow: hidden;
          text-align: center;
          background: url($imgURL + '/order/img_allCourse.png') no-repeat center/cover;

          &+ul {
            margin-left: -0.1rem;

            li {
              position: relative;
              margin-bottom: 1.1rem;
              padding-left: 1.1rem;
              font-size: 1.5rem;
              line-height: 1.47;
              letter-spacing: -0.05rem;
              color: #555;
              word-break: keep-all;
              word-wrap: break-word;

              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                left: 0.4rem;
                top: 1.1rem;
                width: 0.2rem;
                height: 0.2rem;
                background: #555;
                border-radius: 50%;
              }
            }
          }
        }
      }

      .courseIntro {
        margin: 5rem -2rem;
      }
    }

    &.orderGuidance {
      padding-top: 0;
      overflow-x: hidden;
      overflow-y: auto;

      .clsBtn {
        position: absolute;
        top: calc(1.3rem + env(safe-area-inset-top));
        right: 2rem;
        width: 2.4rem;
        height: 2.4rem;
        background: url($imgURL + '/common/btn_close_common.svg') no-repeat center/cover;
        z-index: 10;
        cursor: pointer;
      }

      .content {

        //@supports (-webkit-touch-callout: none) {
        &.safari {
          height: 92vh;
          padding-bottom: 5rem;
        }

        //}
        height: auto;
        padding-bottom: 10rem;
        overflow-y: auto;
        word-break: keep-all;
        word-wrap: break-word;

        .tit {
          margin-right: -0.8rem;
          padding-top: calc(8.7rem + env(safe-area-inset-top)); // mobile web - app 다름
          font-size: 2.9rem;
          font-weight: $bold;
          color: #111;
          line-height: 1.38;
          letter-spacing: -0.03em;
        }

        .desc {
          margin-top: 1rem;
          margin-right: -0.8rem;
          font-size: 1.5rem;
          letter-spacing: -0.03em;
          line-height: 1.47;
          color: #555;
        }

        .descBox {
          margin: 3rem -2rem 1.7rem;

          img {
            width: 100%;
          }

          .row {
            margin: 2.9rem 0 -0.6rem;
            height: 5.3rem;
            overflow: hidden;

            &:last-child {
              margin-bottom: 3.2rem;
            }

            p {
              letter-spacing: normal;

              &:not(.rate) {
                margin-top: 1rem;
                float: left;
                font-size: 1.5rem;

                .subTxt {
                  font-size: 1.2rem;
                  color: rgba(153, 153, 153, .5);
                }
              }

              &.rate {
                float: right;
                font-size: 4rem;
                font-weight: 900;
                line-height: 1.35;
                color: #fff;
                opacity: .95;

                .percent {
                  margin-right: -0.6rem;
                  font-weight: $thin;
                }

                .txt {
                  font-size: 3.5rem;
                  font-weight: $thin;
                }
              }
            }

            &:nth-child(2) .rate {
              opacity: .55;
            }

            &:nth-child(3) .rate {
              opacity: .3;
            }

            &:nth-child(4) .rate {
              opacity: .25;
            }
          }
        }

        ul {
          margin-right: -0.4rem;

          li {
            margin-right: -0.5rem;
            padding-left: 0.7rem;
            position: relative;
            font-size: 1.2rem;
            color: #555;

            span {
              color: #FC4C4C;
            }

            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0.6rem;
              width: 0.3rem;
              height: 0.3rem;
              background: #555;
              border-radius: 50%;
            }
          }
        }

        // 검은테마
        &.black {
          background: #0D0D0D;

          .clsBtn {
            width: 2.2rem;
            height: 2.2rem;
            right: 2rem;
            background-image: url($imgURL + '/common/btn_close_w.svg');
          }

          .tit {
            color: #fff;
          }

          .desc {
            color: #6F6F6F;
          }

          .descBox {
            margin-left: 0;
            margin-right: 0;
            border-top: 0.1rem solid rgba(255, 255, 255, .1);
          }

          ul {
            li {
              color: #6F6F6F;

              &:before {
                background: #6F6F6F;
              }
            }
          }
        }
      }

      // mobile web - app 다름
      .swiper-pagination {
        position: absolute;
        top: calc(2.1rem + env(safe-area-inset-top));
        left: 2rem;

        .swiper-pagination-bullet {
          margin-right: 0.3rem;
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 50%;
          background: transparent;
          border: 0.1rem solid rgba(153, 153, 153, .3);
          //border: 0.1rem solid rgba(204,204,204,.2);
          font-size: 1.1rem;
          color: rgba(153, 153, 153, .4);
          //color: rgba(204,204,204,.3);
          line-height: 2.4rem;
          text-align: center;
          cursor: pointer;

          &.swiper-pagination-bullet-active {
            background: $purple;
            border-color: $purple;
            color: #fff;
          }

          &:focus {
            outline: none;
          }
        }
      }

      &.aos {
        .swiper-pagination .swiper-pagination-bullet {
          line-height: 2.2rem;
        }
      }
    }
  }

  .selectBox {
    select {
      width: 100%;
      height: 5.0rem;
      padding: 0 1.5rem;
      margin: 10px 0;
      border: none;
      background-color: #f4f4f4;
      border-radius: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.3;
      color: #999;

      @include desktop {
        padding: 0 2rem;
      }

      option {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.adj {
        color: #111;

        option {
          color: #999;
        }
      }
    }
  }

  .subTitWrap {
    position: sticky;
    top: 2rem;
    z-index: 90;
    background: #fff;
  }

  .tag {
    .tt {
      margin: 1rem 2rem 1.4rem;
    }

    .list {
      background: #fff;
      border: 0.1rem solid #DBCDFA;
      font-size: 1.1rem;
      font-weight: $medium;
      color: $purple;
      padding: .6rem 0.8rem .5rem 1rem;

      .clsBtn {
        margin-left: 0.6rem;
      }
    }
  }

  .filter {
    background-color: $purple;
    font-size: 1.2rem;
    font-weight: $medium;
    letter-spacing: normal;
    box-shadow: -0.3rem -0.3rem 1rem rgba(0, 0, 0, 0.18);
    bottom: 1.5rem;

    img {
      width: 1.834rem;
      margin: 1.2rem 0 0.3rem;
    }

    &.on {
      bottom: 9.9rem;

      &.safe {
        bottom: calc(9.9rem + 1.5rem);
      }

      &.true {
        bottom: 6.5rem;

        &.safe {
          bottom: calc(6.5rem + 1.5rem);
        }
      }
    }

    &~.bottomPop {
      .topWrap {
        padding: 1.5rem 0 1.7rem;

        .clsBtn {
          //width: 2.4rem;
          //height: 2.4rem;
        }
      }

      .contents {
        max-height: calc(100vh - 22.8rem); // jiny님 요청 UI 가이드 예외처리

        .title {
          font-size: 1.6rem;
          margin: 2.2rem 0 0.8rem;
        }

        .btn .txt {
          line-height: 1.3;

          .sm {
            font-size: 1.2rem;
          }
        }

        .timezone {
          .btnWrap .btn {
            height: 6.2rem;
          }
        }

        .chkBox {
          position: static;
          width: 50%;
          float: left;
          margin: 1.2rem 0 0.3rem;

          span {
            padding-left: 2.5rem;
            color: #111;
          }

          &:last-child {
            span {
              padding-left: 3rem;
            }

            span:before {
              left: 0.5rem;
            }
          }

          &.disable {
            span {
              color: #999;
            }
          }
        }
      }
    }
  }

  .halfPopup {
    width: 100%;
    height: calc(100% - 12.5rem);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: #19191A;
    border-radius: 2rem 2rem 0 0;
    position: fixed;
    bottom: 0;
    margin-left: -2rem;
    padding: 0 2rem calc(6rem + env(safe-area-inset-bottom));
    z-index: 910;

    .clsBtn {
      position: sticky;
      top: 1.3rem;
      right: 0;
      width: 2.4rem;
      height: 2.4rem;
      background: url($imgURL + '/common/btn_close_w.svg') no-repeat center/cover;
      float: right;
      z-index: 2;
    }

    .contentWrap {
      position: relative;

      .imgW100 {
        width: 100%;
        height: auto;
      }

      .tit {
        width: 105%;
        max-width: 34.05rem;
        margin-top: -4.8rem;
        margin-left: -0.3rem;
      }

      .desc {
        max-width: 32.5rem;
      }

      .subTxt {
        margin: 1.3rem auto 3.8rem;
        font-size: 1.5rem;
        font-weight: $medium;
        letter-spacing: -0.02em;
        line-height: 1.67;
        color: #777;
        text-align: center;
      }
    }
  }

  .filterPC {
    display: none;
  }

  /* 여기서부터 데스크탑 미디어쿼리 */
  @include desktop {
    width: 100%;
    //max-width: 1000px;	// PC 필터 100%로 sticky 되어야 해서 수정
    //margin: 0 auto;
    margin: -5rem auto 0;
    padding: 10rem 0 0;

    &.p1 {
      padding-top: 0;
    }

    &.p4 {
      padding-top: 0;
    }

    .max {
      max-width: 1000px;
    }

    .grayBox {
      max-width: 1000px;
      margin: auto;
      margin-top: 9rem;
      margin-bottom: 18rem;
    }

    .visualWrap {
      max-width: 1000px;
      overflow: hidden;
      margin: auto;
      margin-bottom: 1.2rem;
      padding-top: 8.5rem;

      .title {
        float: left;
        width: 53%;
        font-size: 5rem;
        line-height: 1.2;
        letter-spacing: -0.01em;
      }

      .notice {
        cursor: pointer;
        float: right;
        width: 40%;
        height: 10rem;
        margin: 1.2rem 0 0;
        border-radius: 3rem;

        .swiper-wrapper {
          height: 10rem;

          .swiper-slide {
            padding-left: 4.2rem;
            font-size: 1.6rem;
            line-height: 10rem;

            &.i01,
            &.i02,
            &.i03,
            &.i04,
            &.i05 {
              background-size: 1.6rem;
              background-position: left 4rem top 4.3rem;
            }

          }
        }

        .btnMore {
          right: 4rem;
          font-size: 1.4rem;
          line-height: 10rem;
          color: #999999;

          &::after {
            font-weight: $regular;
            content: ' +'
          }
        }
      }

      &.b2b {
        .title {
          float: none;
          width: 100%;

          .ellipsis {
            display: inline-block;
            height: 7.4rem;
            max-width: 53.3rem;
            vertical-align: middle;
          }
        }
      }
    }

    .subTitWrap {
      //sticky 브라우저 넓이 100% 되어야함
      margin: 0 -2rem;
      top: 0;
      z-index: 110; // gnb 100 보다 높게
    }

    .subTit {
      position: relative;
      margin: 0 auto;
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      font-size: 3rem;
      line-height: 4.5rem;
      border-bottom: 1px solid #f1f1f1;
      background: #fff;
      max-width: 1000px;

      .benefit {
        cursor: pointer;
        display: block;
        position: absolute;
        bottom: 1.7rem;
        right: 0.4rem;
        font-size: 1.6rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: -0.032rem;
        text-align: right;
        color: #111111;

        &:after {
          display: block;
          position: absolute;
          top: 0.2rem;
          left: 100%;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 100%;
          content: "";
          background-color: #fc4c4c;
        }
      }
    }

    .tag {
      //position: relative;
      //top: 20.6rem;
      //top: 12.6rem;
      padding: 2rem 0 1rem;
      max-width: 1000px;
      margin: auto;
      overflow: hidden;

      .list {
        position: relative;
        margin: 0 0.5rem;
        padding: 0.9rem 3.9rem 0.9rem 1.9rem;
        font-size: 1.6rem;
        line-height: 1.3;
        font-weight: $regular;
        border-radius: 3rem;
        border-color: #dbcdfa;
        background-color: #ffffff;
        margin-bottom: 1rem;

        .clsBtn {
          cursor: pointer;
          position: absolute;
          top: 0.8rem;
          right: 1.2rem;
          width: 1.2rem;
          height: 1.2rem;

          svg {
            width: 100%;
            height: 100%;

            line,
            path {
              stroke: #cccccc;
              stroke-width: 0.15rem;
              stroke-dasharray: 0;
              stroke-linecap: round;
            }
          }
        }
      }

      .filterPC {
        cursor: pointer;
        display: block;
        padding: 0.8rem 2rem 1rem 1.5rem;
        color: #fff;
        background-color: #9732fc;
        border-color: #9732fc;
        margin-left: 0;
        margin-right: 1.5rem;
        float: left;

        img {
          width: 2.2rem;
          margin: 0 0.5rem 0;
          //vertical-align: middle;
        }
      }

      .tt {
        margin: 0;
        max-width: 83.7rem;
        padding-right: 0;
        float: left;
      }
    }

    .orderInfo {
      display: none;
    }

    .productList {
      position: relative;
      max-width: 1000px;
      margin: 4.7rem auto 0;

      &.col3 {
        .product {
          width: 100%;
        }

        &.rcmd {
          .product {
            max-width: 32rem;
            height: 25rem; // 수강권 높이값
            margin-right: 2rem;

            &:last-child {
              margin-right: 0;
            }

            .priceWrap+.per.adj {
              top: 5rem;
              right: 3rem;
            }
          }
        }
      }

      .product {
        display: inline-block;
        width: 23.5rem;
        height: 22rem;
        margin-top: 0;
        margin-right: 2rem;
        padding: 3.9rem 2rem 0;
        border-radius: 0 2rem 2rem 2rem;

        &:nth-child(4n) {
          margin-right: 0;
          margin-bottom: 1.5rem;
        }

        .orderInfo {
          position: absolute;
          z-index: 2;
          right: 3rem;
          left: 3rem;
          bottom: -9.4rem; // pc 결제하기 버튼
          width: auto;
          height: 9.4rem;
          padding-top: 2rem;
          border-radius: 1rem;
          cursor: pointer;

          .price {
            font-size: 2.2rem;
            font-weight: $medium;
          }

          &.billFlag {
            padding-top: 3.5rem;
          }

          &.b2b {
            height: 9.4rem;

            &.free {
              height: 6rem;

              .price {
                line-height: 2rem;
              }
            }
          }

          &:not(.free) {
            padding-top: 2.4rem;
          }
        }

        &:hover {
          .orderInfo {
            bottom: 3rem;
          }
        }

        .bdg {
          padding: 0.4rem 1.6rem;
          font-size: 1.4rem;
          border-radius: 0 0 1rem 0;
          text-align: center;

          span {
            height: 19px;
            font-weight: $regular;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.028rem;
            text-align: center;
            color: #ffffff;
          }
        }

        .week,
        .mode {
          font-weight: 700;
          font-size: 4rem;
          margin-left: 0;
          margin-top: 0.3rem;
          padding-bottom: 0.4rem;
          display: block;

          span {
            font-size: 1.8rem;
            font-family: 'Noto Sans KR';
          }
        }

        .mode {
          font-size: 3.4rem;
          padding-bottom: 0.4rem;
          margin-top: 1rem;
        }

        .productInfo {
          display: block;
          position: relative;
          top: inherit;
          right: inherit;
          border: none;
          height: 5rem;
          line-height: 5rem;
          margin-top: 1.2rem;
          border-radius: 1rem;
          overflow: hidden;

          li {
            font-size: 1.4rem;
            font-weight: $regular;
            color: #111;

            &:before {
              top: 2rem;
              height: 1rem;
            }
          }
        }

        .priceWrap {
          display: block;
          float: none;
          width: 100%;
          text-align: left;
          overflow: hidden;
          margin-top: 2.3rem;

          .per {
            position: absolute;
            font-size: 2.2rem;

            span {
              font-size: 1.2rem;
            }
          }

          .price {
            font-size: 1.4rem;
            letter-spacing: -0.05rem;

            .mp {
              font-size: 3rem;
              letter-spacing: 0;
              margin-top: 0;
            }

            .rp {
              height: 1.4rem;
              color: #111;
              opacity: .5;
              text-indent: 0.4rem;
              margin-bottom: 0.4rem;
            }

            .sm {
              font-weight: $medium;
            }
          }

          &+.per {
            position: absolute;
            left: auto;
            right: 1.9rem;
            top: 6.4rem;
            font-size: 2.2rem;

            span {
              font-size: 1.2rem;
            }
          }
        }

        &.w48 {

          .week,
          .mode {
            border-bottom: 1px solid rgba(17, 171, 78, 0.05);
          }
        }

        &.w24 {

          .week,
          .mode {
            border-bottom: 1px solid rgba(252, 76, 76, 0.05);
          }
        }

        &.w12 {

          .week,
          .mode {
            border-bottom: 1px solid rgba(76, 105, 252, 0.05);
          }
        }

        &.w4 {

          .week,
          .mode {
            border-bottom: 1px solid rgba(252, 110, 16, 0.05);
          }
        }

        .soldOut {
          span {
            font-size: 2rem;
          }
        }

        .orderInfo {
          display: block;
        }
      }

      .noResult {
        margin: 9.2rem auto 1.5rem;
        padding-left: 3.5rem;

        img {
          width: 9.4rem;
        }

        .msg {
          font-size: 2.4rem;
          font-weight: $regular;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -0.048rem;
          margin-top: 2rem;
        }
      }
    }

    .bottomPop {
      top: 0;
      left: 0;
      right: 0;
      width: 40rem;
      height: 61rem;
      padding-left: 0;
      padding-right: 0;
      margin: auto;
      border-radius: 2.0rem;
      overflow: hidden;

      &.commonPop {
        &.center {
          &.bill4week {
            width: 340px;
            height: unset;

            .contents {
              padding: 45px 30px 30px 30px !important;
              overflow: hidden;
            }
          }

          .popTitle.bill4week {
            font-size: 20px;
            margin-bottom: 28px;
          }

          .popDesc.bill4week {
            padding: 29px 0 26px;

            li {
              margin-left: 20px;
              margin-bottom: 13px;
              font-size: 14px;
              line-height: 1.36;
              color: #555555;

              svg {
                margin-bottom: 0;
              }
            }
          }

          .btn.complete.bill4week {
            height: 70px;
            padding: 0;
            margin: 0;
            font-size: 16px;
            border-radius: 0 15px 15px 15px;

            span {
              font-size: 14px;
            }
          }

          .popNotice.bill4week {
            margin-top: 23px;
            font-size: 14px;
            line-height: 1.3;

            span {
              cursor: pointer;
            }
          }
        }
      }

      .view {
        left: 2rem;
        bottom: 2rem;
        right: 2rem;
        width: auto;
        margin: auto;
        font-family: 'Noto Sans KR';
        border-radius: 0 0.8rem 0.8rem 0.8rem;
      }

      .contents {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .filter {
      display: none;

      &~.bottomPop {
        .topWrap {
          padding-top: 1.6rem;
          padding-bottom: 1.2rem;

          .topTitle {
            font-weight: $medium;
          }

          .clsBtn {
            right: 1.5rem;
            //top: 1.5rem;
            //width: 2rem;
            //height: 2rem;
            cursor: pointer;
          }
        }

        .contents {
          margin-top: 0.3rem;

          .title {
            margin-top: 3.3rem;
            margin-bottom: 0.8rem;
          }

          .btnWrap {
            .btn {
              height: 6rem;
              margin-bottom: 0;

              &:not(.on) {
                margin-top: 0.5rem;
              }
            }

            .txt {
              font-size: 1.6rem;

              .sm {
                font-size: inherit;
              }
            }
          }

          .timezone {
            .btnWrap {
              .btn {
                height: 7.6rem;
              }
            }
          }

          .chkBox {
            margin-top: 2.6rem;
            cursor: pointer;

            span {
              padding-left: 3rem;
            }
          }
        }
      }
    }

    .benefitPopup {
      top: 0;
      left: 0;
      right: 0;
      width: 40rem;
      height: 61rem;
      margin: auto;
      padding: 0 2rem;
      border-radius: 2.0rem;
      overflow: hidden;

      .popTitle {
        position: absolute;
        top: 0;
        left: 0;
        height: 5rem;
        padding-top: 1.3rem;
      }

      .clsBtn {
        cursor: pointer;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        margin-top: 0;
      }

      .contentWrap {
        position: absolute;
        left: 0;
        top: 5rem;
        height: 100%;
        margin-top: 0;
        padding: 0 2rem 5rem;
        overflow-y: auto;

        .conTitle {
          margin-top: 3rem;
          font-size: 2.4rem;
        }

        .listTitle {
          font-size: 1.4rem;
          margin-top: 4rem;

          .rightDesc {
            font-size: 1.4rem;
            font-weight: $regular;
            line-height: 1.43;
            letter-spacing: -0.028rem;
          }
        }

        .listWrap {
          padding: 4rem 2rem;
          border-radius: 0.8rem;

          .bnfList {
            .sub {
              margin-top: 1rem;
              margin-right: 2rem;
              margin-bottom: 3rem;
            }

            >li {
              padding: 0;
              margin-right: 0;
              margin-bottom: 3rem;

              span {
                font-size: 1.8rem;
                letter-spacing: -0.02em;
              }

              &::before {
                top: 1rem;
              }

              &:last-child {
                .sub {
                  margin-bottom: 0;
                }
              }
            }

            p {
              width: 1.8rem;
              height: 2rem;
            }

            .ico1 {
              background-position: left top 0.4rem;
              background-size: 1.6rem;
            }

            .ico2 {
              background-position: left top 0.2rem;
              background-size: 1.4rem 1.6rem;
            }

            .ico3 {
              background-position: left top 0.2rem;
              background-size: 1.4rem 1.4rem;
            }

            .sub {
              .li {
                font-weight: $regular;
                font-size: 1.4rem;
                line-height: 1.71;
                letter-spacing: -0.028rem;

                &::before {
                  top: 1.1rem;
                }
              }
            }

            &.retake {
              .ico2 {
                background-position: left top 0.3rem;
                background-size: 1.4rem 1.4rem;
              }

              .ico3 {
                background-position: left top 0.3rem;
                background-size: 1.6rem 1.6rem;
              }
            }

            &.subList {
              >li {
                .sub {
                  margin-right: 2rem;
                  margin-bottom: 3rem;
                }

                &:last-child {
                  .sub {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          &:last-child {
            margin-bottom: 5rem;
          }
        }
      }
    }

    .popupWrap {
      padding-top: 0;

      &.full,
      &.nPay {
        top: 0;
        left: 0;
        right: 0;
        width: 40rem;
        height: 61rem;
        margin: auto;
        border-radius: 2.0rem;
        overflow: hidden;

        .infoPop {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
        }

        .title {
          position: absolute;
          height: 5rem;
          padding-top: 1.7rem;
        }

        .tit {
          z-index: 10;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 5rem;
          padding-top: 1.3rem;
          background: #fff;
          font-size: 1.7rem;
          text-align: center;
          margin: 0;
        }

        .clsBtn,
        .btnClose {
          cursor: pointer;
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          z-index: 20;
        }

        .content {
          position: absolute;
          left: 0;
          top: 5rem;
          height: calc(100% - 5rem);
          padding-top: 0;
          padding-bottom: 9rem;
        }

        .btnClose+.desc {
          position: relative;
          top: 5rem;
          margin-top: 2rem;
          border-top: 0.2rem solid #111;
          padding-top: 2rem;
        }
      }

      &.full {
        .content.course {
          .tit {
            position: relative;
            height: auto;
            font-size: 3rem;
            line-height: 1.35;
            margin-top: -1rem;
            margin-left: 1rem;
            text-align: left;
          }

          .desc {
            .descImg {
              margin: 3.2rem -2rem 2.9rem;
              height: 29rem;
              background-image: url($imgURL + '/order/img_allCourse_pc.png');
              background-position-x: -14.5rem;

              &+ul {
                li {
                  margin-bottom: 1.4rem;
                  padding-left: 1.7rem;
                  font-size: 1.4rem;
                  line-height: 1.6;

                  &:before {
                    left: 1.2rem;
                  }
                }
              }
            }
          }
        }

        &.orderGuidance {
          overflow: hidden;
          height: 70rem;

          .clsBtn {
            right: 1.3rem;
          }

          .relative {
            position: relative;
            overflow: hidden;
          }

          .swiper-container {
            position: static;
            height: 70rem;

            .content {
              position: relative;
              top: 0;
              height: 70rem;
              min-height: unset;
              padding: 0 3rem;

              .tit {
                position: relative;
                height: auto;
                padding-top: 10.1rem;
                font-size: 2.9rem;
                text-align: left;
                background: none;
              }

              .descBox {
                margin: 2.9rem -3rem 1.7rem;

                .row:not(:last-child) {
                  margin-bottom: -0.4rem;
                }
              }

              ul {
                li {
                  margin-bottom: 0.8rem;
                  line-height: 1.3;
                  //&:before {
                  //	top: 0.5rem;
                  //}
                }
              }

              &.black {
                .descBox {
                  margin-left: 0;
                  margin-right: 0;
                }
              }
            }
          }

          .swiper-pagination {
            top: 5rem;
            left: 3rem;

            .swiper-pagination-bullet {
              margin-right: 0.5rem;
              width: 3rem;
              height: 3rem;
              font-size: 1.3rem;
              line-height: 2.8rem;
            }
          }

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
            width: 2.2rem;
            height: 8.2rem;
            top: 50%;
            margin: -4.1rem 0 0 0;
            background-size: 100%;
            background-position: center;
            cursor: pointer;
          }

          .swiper-button-prev {
            left: -0.1rem;
            background-image: url($imgURL + '/common/btn_swiper_prev.svg');
          }

          .swiper-button-next {
            left: unset;
            right: -0.1rem;
            background-image: url($imgURL + '/common/btn_swiper_next.svg');
          }

          &:hover {

            .swiper-button-prev,
            .swiper-button-next {
              display: block;
            }
          }
        }
      }
    }

    //결제하기 start
    &.p2 {
      width: 40rem;
      padding-top: 0;

      .step2 {
        &:before {
          display: block;
          position: relative;
          padding-top: 79px;
          margin-top: 3.2rem;
          content: "결제하기";
          font-family: 'Noto Sans KR';
          font-size: 3.6rem;
          font-weight: $bold;
          letter-spacing: -0.072rem;
          text-align: left;
          color: #111111;
        }

        #headerP {
          width: 40rem;
          position: relative;
          top: -152px;
          margin: auto;
          margin-bottom: -79px;
          border-bottom: 0;

          .headerInner {
            .logo {
              width: 10.6rem;
              height: 2.4rem;
              overflow: hidden;
              background-size: auto 2.4rem;
              margin-top: 3rem;
            }

            #gnbP {
              display: none;
            }
          }

          .btnOrder {
            display: none;
          }
        }

        .orderProduct {
          margin: 3.6rem 0 0;
          font-size: 1.6rem;

          .list {
            padding: 1rem 0;
            border-bottom: 0.2rem solid #111;

            .title {
              height: 3.3rem;
              font-family: 'Noto Sans KR';
              font-size: 2.2rem;
              line-height: 3.3rem;
              font-weight: $medium;
              font-style: normal;
              letter-spacing: -0.044rem;
              color: #343434;
            }

            .clsBtn {
              cursor: pointer;
              right: 0.2rem;

              svg {
                width: 1.6rem;
                height: 0.9rem;

                path {
                  stroke: #999;
                }
              }
            }

            .sInfo {
              right: 2.7rem;
              font-size: 1.6rem;
              color: #111111;
            }
          }

          .body {
            padding: 4rem 0 0;
          }

          .table {
            .tr {
              .th {
                padding: 0.1rem 0 1.6rem;
              }

              .td {
                padding: 0.1rem 0 1.6rem 0.3rem;

                .dateInfo,
                .zoneInfo {
                  margin-top: 0.7rem;
                  padding-left: 0.8rem;
                  font-family: 'Noto Sans KR';
                  font-size: 1.4rem;
                  font-weight: $regular;
                  line-height: 1.43;
                  letter-spacing: -0.028rem;
                  color: #999999;
                }
              }

              &.disInfo {
                font-size: 1.4rem;
              }
            }

            &.price {
              .tr {

                .th,
                .td {
                  padding-bottom: 1.6rem;
                }

                &:last-child {

                  .th,
                  .td {
                    padding-bottom: 0;
                  }
                }
              }
            }

            &.topLine {
              margin-top: 2rem;
              padding-top: 2rem;

              .tr {

                .th,
                .td {
                  padding-bottom: 0;
                }

                &:last-child {

                  .th,
                  .td {
                    font-size: 1.4rem;
                    color: #111;
                  }
                }

                .th {
                  font-weight: $regular;
                }
              }
            }
          }

          .txtList {
            margin-left: 0.8rem;
            margin-bottom: 0.8rem;
            text-indent: -0.7rem;
            font-size: 1.4rem;
            line-height: 1.43;
            color: #999;
          }

          .discount {
            margin-top: 0.4rem;

            .inputWrap {
              font-size: 1.8rem;
              color: #111;

              .infoBox {
                .txt1 {}
              }

              .selectBox {
                font-size: 1.6rem;

                .inputArea {
                  margin-top: 0;
                }
              }

              .inputArea {
                margin-top: 1.3rem;
                margin-bottom: 2rem;

                .inputBox {
                  width: 65%;
                  font-size: 1.6rem;
                }

                .btn {
                  width: 32.5%;
                  font-size: 1.6rem;
                }
              }

              .desc {
                li {
                  margin: 0 0 0.8rem;
                  padding-left: 0.8rem;
                  font-size: 1.4rem;
                  font-weight: $regular;
                  line-height: 1.43;
                  letter-spacing: -0.028rem;
                  text-align: left;
                  color: #999999;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  &:before {
                    top: 0.9rem;
                    left: 0.2rem;
                    background: #999;
                  }
                }
              }
            }
          }

          &~.orderProduct {
            margin: 5.4rem 0 0;

            .body {
              padding-bottom: 1.6rem;
            }
          }
        }

        .btnWrap {
          &.pay {
            .btn {
              width: 100%;
              margin: 0 0 0 3.5%;

              &:first-child {
                margin-left: 0;
              }
            }

            &.c4 {
              .btn {
                width: 48.25%;
                margin: 1rem 0 0 3.5%;

                &:nth-child(odd) {
                  margin-left: 0;
                }
              }
            }

            &.c3 {
              .btn {
                width: 31%;
              }
            }

            &.c2 {
              .btn {
                width: 48.25%;
              }
            }
          }

          &--bill-flag.pay.c2 {
            .btn {
              width: 31%;

              &--npay {
                width: 65.5%;
              }
            }
          }
        }

        .cardInfo {
          margin: 2.2rem 0 0.2rem;
          font-size: 1.4rem;
          cursor: pointer;

          svg {
            margin-left: 0.6rem;

            line {
              stroke: #999999;
            }
          }
        }
      }

      .orderInfo {
        display: block;
        position: relative;
        z-index: 1;
        min-height: 6rem;
        margin-top: 5.6rem;
        margin-bottom: 18rem;
        padding: 1.8rem 0;
        border-radius: 0 0.8rem 0.8rem 0.8rem;

        .price {
          font-size: 2rem;
          font-weight: $bold;
        }

        .total {
          font-size: 1.6rem;
          font-weight: $regular;
          margin-top: 1rem;
        }
      }

      .orderProduct {
        .weekBadge {
          width: 3.1rem;
          height: 2rem;
          font-size: 1.3rem;
          line-height: 1.8rem;
          border-radius: 0.3rem;
          border-color: rgba(151, 50, 252, .5);

          &.total {
            vertical-align: 0.1rem;
          }

          &.safari {
            line-height: 2rem;
          }
        }
      }
    }

    //결제하기 end

    &.p3 {
      width: 40rem;
      padding-top: 0;

      &.on {
        background: #fff;
      }

      .step3 {
        .order-payment-header {
          margin-top: 3rem;

          &__logo {
            margin-bottom: 4rem;
          }
        }

        .orderProduct {
          font-size: 1.6rem;

          &.top {
            margin-top: 0;
            padding-bottom: 16rem;

            .checkIcon {
              width: 8rem;
            }

            .title {
              font-size: 3.6rem;
              margin: 2.5rem 0 1.6rem;
              text-indent: 0.5rem;
            }

            .subInfo {
              font-size: 1.6rem;
            }

            .classWrap {
              margin: 4rem 0;
              border-radius: 0 4rem 4rem 4rem;
              padding: 2.6rem 0 4.5rem;

              .title {
                font-size: 1.6rem;
                letter-spacing: -0.03em;
                text-indent: -0.1rem;
                margin: 1.6rem 0;
              }

              &.time {
                .app {
                  .bi img {
                    width: 10.6rem;
                  }
                }

                .dot {
                  &.adj {
                    img {
                      margin: 1.7rem 0 0;
                    }
                  }

                  img {
                    width: 0.4rem;
                    margin: 1.2rem 0 0;
                  }
                }

                .timeData {
                  font-size: 3.4rem;
                  margin-top: -1.5rem;

                  span {
                    font-size: 2.6rem;
                    font-weight: $regular;
                    vertical-align: 0.1rem;
                  }
                }

                .courseData {
                  font-size: 2rem;
                  margin-top: -0.7rem;
                }

                .guide {
                  margin: 3rem 4.6rem -2.8rem;

                  span {
                    font-size: 1.6rem;
                  }
                }
              }

              &~.btnWrap {
                padding: 3rem 0 2.5rem;
                border-radius: 2rem;

                .tit {
                  font-size: 1.8rem;
                }

                a {
                  width: 15.2rem;
                  font-size: 1.5rem;
                }
              }

              &~.comment {
                font-size: 1.4rem;

                .txt {
                  &:before {
                    top: 1.1rem;
                  }
                }
              }
            }
          }


          .list {
            padding: 1rem 0;
            border-bottom: 0.2rem solid #111;

            .title {
              height: 3.3rem;
              font-family: 'Noto Sans KR';
              font-size: 2.2rem;
              line-height: 3.3rem;
              font-weight: $medium;
              font-style: normal;
              letter-spacing: -0.044rem;
              color: #343434;
            }

            .sInfo {
              right: 2.7rem;
              font-size: 1.6rem;
              color: #111111;
            }
          }

          .body {
            padding: 4rem 0 0;
          }

          .table {
            .tr {
              .th {
                padding: 0.1rem 0 1.6rem;
                color: #555;
                font-weight: $regular;
              }

              .td {
                padding: 0 0 1rem 0.2rem;

                .dateInfo,
                .zoneInfo {
                  margin-top: 0.7rem;
                  padding-left: 0.8rem;
                  font-family: 'Noto Sans KR';
                  font-size: 1.4rem;
                  font-weight: $regular;
                  line-height: 1.43;
                  letter-spacing: -0.028rem;
                  color: #999;

                  li {
                    margin-bottom: 0.7rem;
                  }
                }
              }

              &.disInfo {
                font-size: 1.4rem;

                .th,
                .td {
                  padding-bottom: 0.8rem;
                  color: #999;
                }
              }
            }

            &.price {
              .tr:not(.disInfo) {

                .th,
                .td {
                  padding-bottom: 1.6rem;
                }

                &:last-child {

                  .th,
                  .td {
                    padding-bottom: 0;
                    color: #111;
                  }
                }
              }
            }

            &.topLine {
              margin-top: 1rem;
              padding-top: 2rem;
            }
          }

          .txtList {
            margin-bottom: 0.8rem;
            font-size: 1.4rem;
            line-height: 1.43;
            color: #999;
          }

          .discount {
            .inputWrap {
              font-size: 1.8rem;
              color: #111;

              .infoBox {
                .txt1 {}
              }

              .selectBox {
                font-size: 1.6rem;
              }

              .inputArea {
                margin-top: 1rem;
                margin-bottom: 2rem;

                .inputBox {
                  width: 65%;
                  font-size: 1.6rem;
                }

                .btn {
                  width: 32.5%;
                  font-size: 1.6rem;
                }
              }

              .desc {
                li {
                  margin: 0 0 0.8rem 0;
                  font-size: 1.4rem;
                  font-weight: $regular;
                  line-height: 1.43;
                  letter-spacing: -0.028rem;
                  text-align: left;
                  color: #999999;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          &~.orderProduct {
            margin: 4.9rem 0 0;

            &:last-child {
              margin-top: 6rem;

              .topLine {
                .td {
                  font-weight: $medium !important;
                }
              }
            }
          }
        }

        .btnWrap {
          &.pay {
            .btn {
              width: 31%;
              margin: 0 3.5% 0 0;

              //border-color: #eee;
              .txt {}

              &:last-child {
                margin-right: 0;
              }
            }

            &.c2 {
              .btn {
                width: 48.25%;
              }
            }
          }
        }
      }

      .orderInfo {
        display: block;
        position: relative;
        z-index: 1;
        min-height: 6rem;
        margin-top: 8rem;
        margin-bottom: 18rem;
        padding: 1.8rem 0;
        border-radius: 0 0.8rem 0.8rem 0.8rem;

        .txt {
          font-size: 2rem;
          font-weight: $bold;
        }
      }
    }
  }
}

@include desktop {
  .orderPage {

    // 상단 배너
    .topBnrPC {
      margin: 0 -2rem 0;
      height: 42rem;

      .slide1,
      .slide2,
      .slide3 {
        display: flex;
        align-items: center;
        flex-direction: column
      }

      .swiper-slide {
        padding-top: 15.4rem;
        text-align: center;
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
      }

      .slide1 {
        padding-top: 2.6rem;
        background: #181819 url($imgURL + '/order/bg_dark_gra.png') repeat-x top left;
        padding-bottom: 2.8rem;

        .subTxt {
          font-size: 1.5rem;
          letter-spacing: -0.02em;
          color: #BABABA;
          margin-top: -2.5rem;
          margin-bottom: 2rem;

          br {
            display: none;
          }
        }

        .desc {
          width: 100rem;
          height: 14.4rem;
        }
      }

      .slide3 {
        background: #EEFAFF url($imgURL + '/order/bg_slide3.png') no-repeat center/cover;

        &.b2b {
          padding-top: 19.3rem;
        }

        .subTxt {
          font-size: 3rem;
          color: #111;
          margin-bottom: 1.5rem;
          letter-spacing: -0.03em;

          span {
            font-weight: $bold;
          }
        }

        .tit {
          font-size: 4.6rem;
          color: #111;
          font-weight: $bold;
          letter-spacing: -0.05em;

          span {
            color: #29E599;
          }
        }

        .point {
          font-size: 2rem;
          font-weight: $medium;
          color: #29E599;
          margin: 3.2rem 0 -2.7rem;
        }
      }

      ul.desc3 {
        background: rgba(255, 255, 255, .7);
        border-radius: 1rem;
        width: 100rem;
        height: 16.7rem;
        margin: 5rem auto 0;
        padding: 3.5rem 3rem 0 2rem;

        li {
          width: 31.6rem;
          float: left;
          position: relative;

          .ico {
            display: block;
            position: absolute;
            top: 0.5rem;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: url($imgURL + '/order/ico_b2c_1.png') no-repeat center;
            background-size: contain;

            &.ico1 {
              left: 0.7rem;
              width: 2.7rem;
              height: 1.8rem;
            }

            &.ico2 {
              top: 0.4rem;
              left: 1.2rem;
              width: 2rem;
              height: 2.1rem;
              background-image: url($imgURL + '/order/ico_b2c_2.png');
            }

            &.ico3 {
              top: 1rem;
              left: 4rem;
              width: 2.3rem;
              height: 1rem;
              background-image: url($imgURL + '/order/ico_b2c_3.png');
            }

            &.ico4 {
              left: 4.3rem;
              width: 2.4rem;
              height: 2.31rem;
              background-image: url($imgURL + '/order/ico_b2c_4.png');
            }

            &.b2b {
              &.ico1 {
                top: 0.3em;
                left: -0.1rem;
                width: 2.8rem;
                height: 2.312rem;
                background-image: url($imgURL + '/order/ico_b2b_1.png');
              }

              &.ico2 {
                top: 0.7em;
                width: 1.48rem;
                height: 1.976rem;
                background-image: url($imgURL + '/order/ico_b2b_2.png');
              }

              &.ico3 {
                top: 0.3rem;
                left: 4.4rem;
                width: 2.2rem;
                height: 2.2rem;
                background-image: url($imgURL + '/order/ico_b2b_3.png');
              }
            }
          }

          .desc {
            display: block;
            font-size: 2.2rem;
            color: #111;
            font-weight: $bold;
            letter-spacing: -0.02rem;
            margin-top: 2.8rem;
          }

          .sub {
            display: block;
            font-size: 1.5rem;
            color: #999;
          }

          &:last-child {
            padding-left: 4rem;

            &:before {
              display: none;
            }
          }

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            right: -0.2rem;
            top: 0.5rem;
            width: 0.1rem;
            height: 8.7rem;
            background: #F1F1F1;
          }
        }
      }

    }

    // pc만 노출되는 배너
    .bnrCardInfo {
      width: 100%;
      display: none;
      position: fixed;
      top: 0;
      z-index: 800;
      height: 4.6rem;
      background: #fbfcff; // 디자인된 수치rgba(247,249,255,.5);
      border-top: 0.1rem solid #F1F1F1;
      text-align: center;
      margin: 0 -2rem;

      &.true {
        display: block;
        position: fixed;
        width: 100%;
      }

      .clickArea {
        display: inline-block;
        vertical-align: top;
        cursor: pointer;

        .txt {
          display: inline-block;
          padding: 0 1.4rem 0 0;
          background: url($imgURL + '/order/ico_arrow.png') no-repeat right 0 top 50%;
          background-size: 0.7rem;
          font-size: 1.5rem;
          line-height: 4.5rem;
          letter-spacing: -0.05rem;
          text-indent: -0.5rem;
          vertical-align: top;

          span {
            color: $purple;
          }
        }
      }
    }

    .subTit {
      .link {
        font-size: 1.8rem;
        line-height: 2.5;
        letter-spacing: -0.05em;
        cursor: pointer;

        .ico {
          width: 0.8rem;
          vertical-align: -0.1rem;
          margin-right: 0.2rem;
          margin-left: 0.4rem;
        }
      }

      &+.desc {
        max-width: 1000px;
        margin: 0 auto;
      }
    }

    .bestPrd {
      .productList {
        margin: 4rem auto 0;

        .item {
          max-width: 32rem;

          .product {
            .priceWrap+.per {
              position: absolute;
              top: 5rem;
              margin-right: 0;
              left: auto;
              right: 3rem;
            }
          }
        }

        &.b2b {
          .product {
            &.free {
              .productInfo {
                margin-top: 1.6rem;
              }
            }

            &:not(.free) {
              .productInfo {
                margin-top: 1.3rem;
              }
            }
          }
        }
      }
    }

    .bestPrd {
      .subTit {
        font-size: 3.2rem;
      }

      .productList {
        .product {

          .week,
          .mode {
            border-bottom: 0.1rem solid rgba(255, 255, 255, .07);
          }

          .badge {
            color: #fff;
            border-color: rgba(255, 255, 255, .2);
          }
        }

        .swiper-slide {

          &:nth-child(8n+1) .product,
          &:nth-child(8n+8) .product {
            .orderInfo {
              color: #D3499C;

              .totalWeek:after {
                background: rgba(211, 73, 156, .3);
              }
            }
          }

          &:nth-child(8n+2) .product,
          &:nth-child(8n+7) .product {
            .orderInfo {
              color: #6C5BE5;

              .totalWeek:after {
                background: rgba(108, 91, 229, .3);
              }
            }
          }

          &:nth-child(8n+3) .product,
          &:nth-child(8n+6) .product {
            .orderInfo {
              color: #4DAFE4;

              .totalWeek:after {
                background: rgba(77, 175, 228, .3);
              }
            }
          }

          &:nth-child(8n+4) .product,
          &:nth-child(8n+5) .product {
            .orderInfo {
              color: #65D581;

              .totalWeek:after {
                background: rgba(101, 213, 129, .3);
              }
            }
          }
        }

        .product {
          .select {
            &.on {
              border-color: transparent; // 수강권 선택 됐을 때 테두리
            }
          }

          .orderInfo {
            background: #fff;
          }
        }
      }
    }

    .prdList {
      .subTit {
        border-bottom: none;
        padding-top: 4.5rem;
        padding-bottom: 1.5rem;
      }
    }

    .benefit {
      .subTit {
        padding-top: 8.5rem;
        padding-bottom: 1.9rem;
      }
    }

    .productList {
      .relative {
        position: relative;
        margin: 0 -2rem;

        .swiper-container {
          position: static;
          max-width: 1000px;
        }
      }

      &.col3 {
        .product {
          padding: 3rem;
          height: 25rem; // 수강권 높이값!!!

          .select {
            border-width: 0;
            border-radius: 0 2rem 2rem 2rem;
          }
        }
      }

      &.viewPrd {
        .product {

          .week,
          .mode {
            height: 5.1rem;
            margin-top: 0.4rem;
            font-size: 4rem;

            span {
              font-size: 1.8rem;
            }
          }

          .priceWrap {
            margin-top: 2.3rem;

            .price {
              font-size: 1.4rem;

              .rp {
                margin-top: 0;
                margin-bottom: 0.7rem;
              }

              .mp {
                font-size: 3rem;
                letter-spacing: normal;
                margin-top: -0.5rem;
              }
            }
          }

          .per {
            top: 6rem;
            font-size: 2.2rem;

            span {
              font-size: 1.2rem;
            }
          }

          .orderInfo {
            right: 2rem;
            left: 2rem;

            //bottom: 2rem;
            .total {
              margin-top: 0.5rem;
            }
          }

          &:hover {
            .orderInfo {
              bottom: 2rem;
            }
          }

          .select {
            border-width: 0;
            border-radius: 0 2rem 2rem 2rem;
          }

          .productInfo.D {
            li {
              width: 40%;
              font-size: 1.3rem;

              &.zone {
                width: 60%;
              }
            }
          }
        }

        &.col3 {
          .product {
            max-width: 32rem;
            height: 19rem;

            .orderInfo {
              height: 8rem;
              padding-top: 1.5rem;
              right: 3rem;
              left: 3rem;

              .total {
                margin-top: 0.4rem;
              }
            }

            &:hover {
              .orderInfo {
                bottom: 3rem;
              }
            }

            &:nth-child(3n) {
              margin-right: 0;
              margin-bottom: 1.5rem;
            }

            &:nth-child(4n) {
              margin-right: 2rem;
              margin-bottom: 1.5rem;
            }

            .per {
              top: 5rem;
              right: 3rem;
            }
          }
        }
      }

      &.b2b {
        &.viewPrd {
          .product {
            padding: 2.7rem 1.9rem;
            height: 22rem;

            &.free {
              height: 17rem;
            }

            .mode {
              font-size: 3.4rem;
            }

            .priceWrap {
              margin-top: 2.4rem;
            }

            .productInfo {
              padding: 0;

              li {
                font-size: 1.3rem;
                line-height: 5.2rem;
              }

              &.col2 {
                .tCode {
                  width: 43%;
                }

                .zone {
                  width: 57%;
                }
              }
            }

            &.dummy {
              background: #F8F8F8;

              .imgWrap {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
              }
            }
          }

          &.b2b.col3 {
            .product {
              .orderInfo {
                height: 9.4rem;
                padding-top: 2rem;
                right: 2rem;
                left: 2rem;

                &.free {
                  height: 6rem;
                }
              }

              &:hover {
                .orderInfo {
                  bottom: 2rem;
                }
              }
            }
          }
        }

        .product {
          height: 23rem;

          &.free {
            height: 18rem;
          }

          .priceWrap {
            margin-top: 1.9rem;
          }

          .productInfo {
            .min {
              width: 19.3%;
            }

            .tCode {
              width: 32.2%;
            }

            .zone {
              width: 48.5%;
            }
          }
        }
      }

      .product {
        .badge {
          width: 3.1rem;
          height: 2rem;
          margin-left: 0.4rem;
          font-size: 1.3rem;
          color: #111;
          line-height: 1.4;
          text-align: center;
          border: 0.1rem solid rgba(153, 153, 153, .5);
          border-radius: 0.3rem;

          &.safari {
            line-height: 1.6;
          }
        }
      }
    }

    .filterItemWrap {
      position: relative;
      top: 0;
      margin: 0 -2rem;
      background: #fff;
      margin-bottom: -0.8rem;
    }

    .filterItem {
      top: 0;
      max-width: 100rem;
      margin: 0 auto;

      li {
        padding: 2.1rem 0 1rem;

        span {
          font-size: 1.8rem;

          &.tit {
            width: 22.3%;
            text-indent: 1rem;
          }

          &:nth-child(2) {
            width: 18.8%;
          }

          &:nth-child(3) {
            width: 18.8%;
          }

          &:nth-child(4) {
            width: 19.5%;
          }

          .option {
            border: 0.1rem solid transparent;
            border-radius: 3rem;
            padding: 0.9rem 1.9rem;
            margin-top: -1.1rem;
            cursor: pointer;
          }

          .badge {
            margin: 0 0 0 0.4rem;
            padding: 0;
            width: 4.4rem;
            height: 1.9rem;
            font-size: 1.2rem;
            color: #fff;
            line-height: 1.4;
            vertical-align: 0.2rem;
            background: #555;

            &.safari {
              line-height: 1.6;
            }
          }

          &.on {
            .option {
              font-weight: $regular;
              border-color: $purple;

              &.badge {
                font-weight: $medium;
                background: $purple;
                margin-right: -0.8rem;
              }
            }

            &:before {
              display: none;
            }
          }

          &.timeZone {
            margin-bottom: 0;

            &:nth-child(4) {
              margin-left: 0;
            }

            .time {
              font-size: 1.4rem;
              letter-spacing: -0.03rem;
            }
          }
        }

        &.course {
          span {
            &.on {
              width: 20%;
            }

            &.more {
              display: none;
            }
          }

        }
      }
    }

    .dividerLine {
      display: none;
    }

    .benefit {
      .list {
        max-width: 100rem;
        margin: 4.3rem auto 6.75rem;
        overflow: hidden;

        li {
          width: 33%;
          float: left;
          margin-left: 0.4%;
          margin-right: 0.4%;
          margin-bottom: 3.25rem;
          font-size: 1.6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:nth-child(3n) {
            width: 31.6%;
          }
        }
      }
    }

    .device-support {
      max-width: 1000px;
      margin: 0 auto 18rem;

      .subTit {
        padding-top: 0;
      }

      &__notice {
        margin-top: 4rem;

        li {
          padding-left: 0.9rem;
          font-size: 1.6rem;
          line-height: 2.6rem;

          &+li {
            margin-top: 0.5rem;
          }

          &:before {
            left: 0.2rem;
            top: 1.2rem;
          }

        }
      }

      &__reference {
        padding: 1.8rem 2rem;
        margin-top: 1.7rem;
        border-radius: 2rem;

        li {
          padding-left: 1rem;
          font-size: 1.4rem;
          line-height: 2.4rem;

          &:before {
            top: 1.2rem;
            left: 0.2rem;
          }
        }
      }
    }

    // swiper
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      background-image: url($imgURL + '/common/swiper_prev_ico_visual.svg');
      left: -115rem;
      right: 0;
      margin: auto;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      background-image: url($imgURL + '/common/swiper_next_ico_visual.svg');
      left: 0;
      right: -115rem;
      margin: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 5rem;
      height: 5rem;
      margin-top: -1.5rem;
      top: 50%;
      background-size: 100%;
      background-position: center;
    }

    .productList {

      .swiper-button-prev,
      .swiper-button-next {
        width: 10.6rem;
        height: 10.6rem;
        background-size: 100%;
        background-position: center;
        top: 50%;
        margin-top: -7rem;
      }

      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        background-image: url($imgURL + '/common/swiper_prev_ico_sub.svg');
        left: -100rem;
        right: 0;
      }

      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        background-image: url($imgURL + '/common/swiper_next_ico_sub.svg');
        left: 0;
        right: -100rem;
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        //opacity: 1;
      }
    }

    .orderInfo {
      .week {
        padding-right: 0.7rem;
        margin-right: 0.1rem;
        font-weight: $regular;

        &:after {
          top: 0.8rem;
          height: 1.2rem;
        }

        &.safari:after {
          top: 0.3rem;
        }

        &.edge:after {
          top: 0.5rem;
        }
      }

      .totalWeek {
        position: relative;
        padding-right: 0.7rem;
        margin-right: 0.2rem;

        &:after {
          content: '';
          position: absolute;
          top: 0.6rem;
          right: 0;
          width: 0.1rem;
          height: 1rem;
          background: rgba(255, 255, 255, .3);
        }

        &.safari:after {
          top: 0.4rem;
        }
      }

      .medium {
        font-weight: $regular;
      }
    }

  }
}


.orderResultPage {
  .step1 {
    height: calc(100vh - 15rem); // test
  }
}

//수강권 구매후 UX 개선

.order-first-date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21.5rem;
  height: 4.3rem;
  margin: 1.5rem auto 3rem;
  background-color: #F9F2FF;
  border-radius: 2.25rem;

  img {
    width: 1.35rem;
    height: 1.35rem;
    margin-top: 0.16rem;
  }

  p {
    margin: 0 0.5rem;
    font-size: 1.3rem;
    color: #111111;
  }

  span {
    font-size: 1.3rem;
    font-weight: $bold;
    color: #111111;
  }

  @include desktop {
    width: 24.8rem;
    height: 5rem;
    margin: 1.1rem auto 4rem;

    p {
      font-size: 1.5rem;
    }

    span {
      font-size: 1.5rem;
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.order-summary {
  padding: 3rem 2rem;
  margin: 3rem 0 5rem;
  background: #F7F7F7;
  border-radius: 0 2rem 2rem 2rem;

  @include desktop {
    padding: 5rem 3.5rem;
    margin: 4rem 0 8rem;
    border-radius: 0 4rem 4rem 4rem;
  }

  &__item {

    display: flex;
    flex-wrap: wrap;

    &+& {
      margin-top: 1.5rem;

      @include desktop {
        margin-top: 1.6rem;
      }
    }

    dt,
    dd {
      padding: 0;
      margin: 0;
      font-size: 1.4rem;
      line-height: 2.05rem;
      text-align: left;
      letter-spacing: -0.04rem;
      word-spacing: -0.02rem;

      @include desktop {
        font-size: 1.6rem;
        line-height: 2.45rem;
      }

    }

    dt {
      width: 8.5rem;
      color: #555;
    }

    dd {
      width: calc(100% - 8.5rem)
    }

    @include desktop {
      dt {
        width: 9.5rem;
      }

      dd {
        width: calc(100% - 9.5rem)
      }
    }

    &-address {
      margin-top: 0.5rem;
      color: #555555;
      font-size: 1.2rem;
      line-height: 1.2;
    }
  }

  &__notice {
    padding-top: 1.5rem;
    margin-top: 1.5rem;
    border-top: .1rem solid #eee;
    text-align: left;

    @include desktop {
      padding-top: 2rem;
      margin-top: 2rem;
    }

    li {
      position: relative;
      padding-left: .7rem;
      color: #999;
      font-size: 1.2rem;
      line-height: 1.6rem;

      @include desktop {
        padding-left: .6rem;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: -0.04rem;
        word-spacing: -0.02rem;
      }

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: .7rem;
        width: .2rem;
        height: .2rem;
        background: #999;
        border-radius: 50%;

        @include desktop {
          top: .9rem;
        }

      }
    }
  }
}


.order-confirm {
  &__notice {
    color: #111;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 2.5rem;

    @include desktop {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }

  .btn--submit {
    margin-top: 1.95rem;

    @include desktop {
      margin-top: 2rem;
    }
  }

  .btn-txt {
    display: inline-block;
    margin-top: 0.5rem;
    background: none;
    padding: 0;
    border: 0;
    color: #999;
    font-size: 1.5rem;
    line-height: 2.25rem;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;

    @include desktop {
      margin-top: 1rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }

  }
}

.order-matching {
  padding: 3rem 0;
  margin-top: 1.95rem;
  border-top: .1rem solid #eee;

  @include desktop {
    padding: 4rem 0;
  }

  .recommend-item {
    position: relative;

    &+.recommend-item {
      margin-top: 3rem;

      @include desktop {
        margin-top: 4rem;
      }
    }

    &__title {
      display: block;
      margin-bottom: .95rem;
      padding-right: 7rem;
      color: #111;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: left;
      line-height: 2.25rem;

      @include desktop {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.3rem;
      }

    }

    &__time,
    &__course {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 8rem;
      padding: 1rem;
      border: .1rem solid #ccc;
      border-radius: 1rem;

      @include desktop {
        min-height: 9rem;
        padding: 1.5rem;
      }
    }

    &__time {
      justify-content: center;

      &-value {
        display: inline-block;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, #9732FC, #FC4C4C);
        -webkit-background-clip: text;
        color: #9732FC;
        font-size: 3.7rem;
        font-weight: 700;
        line-height: 4.35rem;

        @include desktop {
          font-size: 4rem;
          line-height: 4.7rem;
        }

        .division {
          display: inline-block;
          margin-left: .3rem;
          font-size: 2.3rem;
          font-weight: 400;
          vertical-align: middle;

          @include desktop {
            margin-left: .5rem;
            font-size: 2rem;
            line-height: 2.4rem;
          }
        }

      }

      &-message {
        color: #555;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.3rem;

        @include desktop {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }
      }
    }

    &__course {

      &-column {
        text-align: left;

        &--img {
          width: 6rem;
          height: 6rem;
          border-radius: 0.75rem;
          overflow: hidden;

          @include desktop {
            border-radius: .5rem;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        &--info {
          max-width: calc(100% - 6rem);
          padding-left: 1.5rem;

          @include desktop {
            padding-left: 2rem;
          }
        }
      }

      &-subject {
        display: block;
        margin-bottom: 0.1rem;
        color: #111;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.3rem;

        @include desktop {
          font-size: 1.8rem;
          line-height: 2.7rem;
        }
      }

      &-unit {
        color: #555;
        font-size: 1.2rem;
        font-weight: 400;

        @include desktop {
          font-size: 1.3rem;
        }
      }
    }

    &__btn-change {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      padding: 0 .9rem 0 0;
      background: transparent url($imgURL + '/matching/btn_arr.png') no-repeat right 0 top 50%;
      background-size: .4rem auto;
      color: #555;
      font-size: 1.3rem;
      line-height: 2.7rem;
      cursor: pointer;

      @include desktop {
        top: .45rem;
        padding-right: 1.3rem;
        backgroud-size: .5rem auto;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
}

.order-app-popup {
  text-align: center;

  &.bottomPop {
    padding: 6rem 2rem;

    @include desktop {
      top: 0;
      left: 0;
      right: 0;
      width: 40rem;
      height: 44.5rem;
      padding: 6rem 2.2rem 0;
      background: rgb(151, 50, 252);
      background: linear-gradient(90deg, rgba(151, 50, 252, 1) 0%, rgba(252, 76, 76, 1) 100%);
      margin: auto;
      border-radius: 2.0rem;
      overflow: hidden;
    }
  }


  &__title {
    color: #111;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.95rem;

    @include desktop {
      color: #fff;
    }
  }

  &__desc {
    margin: .45rem 0 2rem;
    color: #111;
    font-size: 1.6rem;
    line-height: 2.35rem;

    @include desktop {
      margin: .2rem 0 3rem;
      color: rgba(255, 255, 255, .7);
    }
  }

  .app-qr {
    display: none;

    @include desktop {
      display: block;
    }

    &__img {
      width: 20rem;
      height: 20rem;
      margin: 0 auto 2rem;
      padding: 1rem;
      background: #fff;
      border-radius: 1rem;

      img {
        width: 100%;
        height: 100%;
      }

    }

    &__notice {
      display: inline-block;
      position: relative;
      padding-left: .6rem;
      color: rgba(255, 255, 255, .7);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: .9rem;
        width: .2rem;
        height: .2rem;
        background: rgba(255, 255, 255, .7);
        border-radius: 50%;
      }
    }
  }

  a.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    @include desktop {
      display: none;
    }
  }

  .btn {
    @include desktop {
      display: none;
    }
  }

  .btn-txt-close {
    display: inline-block;
    padding: 0;
    margin-top: 0.7rem;
    background: none;
    border: 0;
    color: #999;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;

    @include desktop {
      display: none;
    }
  }

  .btn-close {
    position: absolute;
    top: calc(1.3rem + env(safe-area-inset-top));
    right: 2rem;
    width: 2.4rem;
    height: 2.4rem;
    background: url($imgURL + '/common/btn_close_common.svg') no-repeat;
    background-size: contain;
    border: 0;
    cursor: pointer;

    @include desktop {
      top: 1.3rem;
      right: 1.3rem;
      background-image: url($imgURL + '/common/btn_close_w.svg');
    }

    .blind {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      clip-path: inset(50%);
      width: 1px;
      height: 1px;
      margin: -1px;
      overflow: hidden;
    }
  }
}

@media (min-width:700px) and (max-width: 1024px) {
  .titWrap {
    margin: 0 1rem 1.7rem !important;
  }
}