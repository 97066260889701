@import "../common/_mixin";
@import "../common/commonCode.scss";

.previewBookLT {
	position: absolute;
	top: 0;
	width: 100%;
	margin: 0 -2rem;
	background-color: #000;
	//z-index: 1010;
	@include desktop{
		max-width: 375px;
		left: 50%;
		margin-left: -187.5px;
	}
	.tmWrap {
		position: fixed;
		width: 100%;
		top: calc(0 + env(safe-area-inset-top));
		left: 0;
		padding-top: .4rem;
		overflow: hidden;
		z-index: 10;
		@include desktop{
			max-width: 375px;
			left: 50%;
			margin-left: -187.5px;
		}
		&.sticky {
			//background-color: rgba(0, 0, 0, .16);
		}

		.menu {
			float: left;
			margin: .6rem 0 0.9rem 1rem;
		}

		.clsBtn {
			float: right;
			margin: .6rem 1rem 0 0;
		}
	}

	.menuList {
		position: fixed;
		width: 100%;
		min-height: calc(39.8rem + env(safe-area-inset-top));
		padding-top: env(safe-area-inset-top);
		//top: .4rem;
		background-color: #fff;
		border-radius: 3rem;
		overflow: hidden;
		z-index: 1010;
		transition: .2s;
		@include desktop{
			max-width: 375px;
		}
		&.hidden {
			display: block !important;
			height: 0;
			min-height: 0;
			top : calc(env(safe-area-inset-top) * -1);
		}

		.clsBtn {
			position: absolute;
			top: calc(1rem + env(safe-area-inset-top));
			left: 1rem;
		}

		ul {
			padding: 5.8rem 0 3.5rem;

			li {
				height: 7.5rem;
				padding-top: 1.5rem;
				font-size: 1.7rem;
				color: #111;
				text-align: center;

				span {
					font-size: 1.45rem;
					color: #999;
				}

				&.on {
					background-color: #FBFBFB;
					color: #9732FC;
					font-weight: $bold;

					span {
						color: #9732FC;
						font-weight: $medium;
					}
				}

				&.on {
					background-color: #fbfbfb;

					span {
						color: $purple;
					}
				}
			}
		}
	}

	.circleBtn {
		width: 3.8rem;
		cursor: pointer;
	}

	.conWrap {
		position: relative;
		height: 100vh;
		background: $gra3;
		border-radius: 3rem;
		color: #fff;
		text-align: center;

		.txt1 {
			padding: 16vh 3rem 0;
			font-size: 3.3rem;
			font-weight: $bold;
			line-height: 1.5;
			letter-spacing: -0.02rem;
		}

		.txt2 {
			margin: 4rem auto .3rem;
			font-size: 1.5rem;
			font-weight: $regular;
			opacity: 0.8;
		}

		.txt3 {
			padding: 0 3rem;
			font-size: 2.05rem;
			font-weight: $medium;
			letter-spacing: 0;
		}

		.txt4 {
			font-size: 1.9rem;
			font-weight: $medium;
		}

		.hoLine {
			width: 5rem;
			margin: 4.5rem auto;
			border-bottom: 1px solid #fff;
		}

		.balloon {
			width: 5.5rem;
			margin-top: 3rem;
		}

		.arrow {
			position: absolute;
			width: 2.6rem;
			left: 50%;
			bottom: 7rem;
			margin-left: -1.3rem;
			cursor: pointer;
		}

		&.bottom {
			margin-top: 0;
			height: 100vh;

			.txt2 {
				margin: 4rem auto 0.5rem;
			}

			.txt4 {
			}
		}
	}

	.w {
		margin: .4rem auto;
		padding: 5.9rem 2rem 7rem;
		background-color: #FAFAFA;
		border-radius: 3rem;

		.title {
			display: inline-block;
			padding-bottom: .2rem;
			font-size: 2.6rem;
			font-weight: $bold;
			background: $gra1;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.subTitle {
			margin-top: 0.2rem;
			font-size: 1.3rem;
			color: #999;
			font-weight: $regular;
		}

		.contentBox {
			position: relative;
			margin-top: 2rem;
			padding: 3rem 2.5rem 3.5rem;
			background-color: #fff;
			border-radius: 0 2rem 2rem 2rem;
			box-shadow: 0 .5rem 1.5rem #f0f0f0;

			.txt1 {
				font-size: 1.5rem;
				color: #999;
				text-align: center;
			}

			.subject {
				padding-bottom: 3rem;
				margin-bottom: 2.5rem;
				font-size: 3rem;
				font-weight: $bold;
				color: #111;
				text-align: center;
				border-bottom: 1px solid #eee;
			}

			.tip {
				margin-bottom: 1rem;
				font-size: 1.3rem;
				line-height: 1.9rem;

				span {
					width: 3rem;
					height: 1.9rem;
					display: inline-block;
					background-color: #111;
					border-radius: 0 .5rem .5rem .5rem;
					font-size: 1.2rem;
					font-weight: $bold;
					color: #fff;
					text-align: center;
					margin-right: .7rem;
				}
			}

			ul {
				li {
					padding: 0.5rem 0;
					font-size: 1.5rem;
					color: #555;
					font-weight: $medium;

					span {
						margin-left: .5rem;
						font-size: 1.3rem;
						color: #999;
						font-weight: $regular;
					}

					&:before {
						content: '\00B7';
						display: inline-block;
						margin-right: 5px;
					}
				}
			}

			.no {
				position: absolute;
				top: 1rem;
				left: -.55rem;
				width: 4.3rem;
				height: 3.3rem;
				padding-top: .8rem;
				background-color: #f2f2f2;
				border-radius: .3rem;
				font-size: 1.5rem;
				font-weight: $bold;
				text-align: center;
				z-index: 2;
			}

			.boxSh {
				display: block;
				content: '';
				position: absolute;
				top: 1.3rem;
				left: -.9rem;
				width: .9rem;
				height: 3.3rem;
				background-color: #d9d9d9;
				border-radius: .3rem 0 0 .3rem;
				z-index: 1;
			}

			.sntn {
				font-size: 2.5rem;
				line-height: 1.3;
				font-weight: $bold;
			}

			.desc {

				&.tt{
					display: none;
				}
			}

			.tern {
				display: inline-block;
				margin: 2.5rem 0 1.1rem;
				padding: .2rem .6rem;
				border: 1px solid #ddd;
				font-size: 1.2rem;
				font-weight: $regular;
				color: #999;
			}

			.ssntn {
				position : relative;
				font-size: 1.5rem;
				font-weight: $regular;
				color: #555;
				padding-left: 1rem;

				span {
					font-weight: $medium;
					color: #111;
				}

				&:before {
					display: inline-block;
					position : absolute;
					left: 0;
					content: '\00b7';
					margin-right: 0.5rem;
				}
			}
		}

		.speakerWrap {
			overflow: hidden;

			.speaker {
				clear: both;
				max-width: 88%;
				margin-bottom: 2rem;
				font-size: 1.6rem;
				font-weight: $regular;
				line-height: 1.4;
				color: #111;

				&:last-child {
					margin-bottom: 0;
				}

				.part {
					margin-bottom: 1rem;
					font-size: 1.1rem;
					font-weight: $regular;
					color: #999;

					span {
						display: inline-block;
						width: 2.2rem;
						height: 2.2rem;
						padding: 0.2rem 0 0;
						font-size: 1.3rem;
						font-weight: $bold;
						color: #fff;
						text-align: center;
						border-radius: 1.1rem;
					}

					&.a span {
						margin-left: .5rem;
						background-color: #9732FC;
					}

					&.b span {
						margin-right: .5rem;
						background-color: #111;
					}
				}

				&.A {
					float: right;
					text-align: right;

					.sentence {
						background-color: #fff;
						border-radius: 2rem .5rem 2rem 2rem;
					}
				}

				&.B {
					float: left;
					text-align: left;

					.sentence {
						background-color: #F5F5F5;
						border-radius: .5rem 2rem 2rem 2rem;
					}
				}

				.sentence {
					padding: 1.5rem 2rem;
					text-align: left;
				}
			}
		}

		&.pd, &.qu {
			.contentBox {
				padding-top: 5rem;
			}
			.trans {
				margin :3rem 0 2.5rem;
			}
		}

		&.md {
			.subTitle {
				margin-bottom: 3rem;
			}
		}
	}

	.leveltestBook {
		.tmWrap {
			//position: absolute;
		}

		.conWrap {
			background: $gra5;
		}

		.w {
			min-height: 100vh;
			text-align: left;

			.title {
				margin: 0;
				font-size: 2.4rem;
				background: $gra4;
				-webkit-background-clip: text;
			}

			.subTitle {
				margin-top: 0.35rem;
				margin-bottom: 5.4rem;
			}

			.contentBox {

				ul {
					li {
						span {
							margin-left: 1rem;
						}
					}
				}
			}

			&.dp {
				.img {
					img {
						max-width: 100%;
					}
				}

				.contentBox {
					padding: 0 0 0 1rem;;
					background-color: transparent;
					box-shadow: none;
				}
			}

			&.st{

				.img{
					line-height: 0;
					overflow: hidden;
					border-radius:0 2rem 2rem 2rem;
					width: calc(100vw - 4rem);
					height: calc(calc(100vw - 4rem) * 0.89);
					@include desktop{
						width: auto;
						height: auto;
					}
					img{
						height: 100%;
						object-fit: cover;
						@include desktop{
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
	}

	.timePrg {

		position: fixed;
		left: 50%;
		bottom: 20rem;
		margin-left: -4rem;
		transition: 0.3s;
		opacity: 0;
		&.true {
			opacity: 1;
		}

		&.sticky {
			bottom: 8.8rem;
		}

		.fdInfo {
			display: none;
			position: absolute;
			top: -8rem;
			margin-left: -7rem;
			width: 22rem;
			padding: 2rem 2.3rem;
			border-radius: 2rem;
			background: $gra4;
			font-size: 1.4rem;
			color: #fff;
			font-weight: $medium;
			text-align: center;

			&:after {
				position: absolute;
				content: '';
				bottom: -1rem;
				left: 50%;
				margin-left: -1rem;

				width: 0px;
				height: 0px;
				border-top: 1rem solid #00D2BF;
				//border-bottom: 1rem solid none;
				border-right: 1rem solid transparent;
				border-left: 1rem solid transparent;
			}
			&.true{
				display: block;
			}
		}

		.lottiePrg {
			position: absolute;
			top: 0;
			left: 0;
			width: 8rem;
			height: 8rem;
		}

		.pCircle {

		}

		.sFace {

		}
	}

}
