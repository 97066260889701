@import "../common/commonCode.scss";
$imgURL: 'https://img.uphone3.com';
@import "../common/_mixin";

.step1, .step2, .step3, .step4 {
	display: none;
}

// 공지사항
.list .step1, .view .step2, .lesson .step1 {
	display: block;
}

// faq
.p1 .step1, .p2 .step2, .p3view .step3 {
	display: block
}

.faqPage .step1, .step2{
	@include desktop {
		display: block
	}
}

.lsCenter {
	position: relative;

	.lnkTel {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
}

.NoticePage {
	// 공지리스트
	//margin: -0rem -2rem -5rem;
	.loading {
		vertical-align: middle;
		background: #fff;
		position: fixed;
		left: 0;
		z-index: 100000;
		right: 0;
		bottom: 0;
		top: 5rem;
		height: calc(100vh - 5rem);;

		img {
			vertical-align: middle;
			text-align: center;
			margin: 0 auto;
		}
	}

	.historyNon {
		p {
			position: absolute;
			top: 50%;
			width: 100%;
			margin-top: -5.1rem;
			padding-top: 7.1rem;
			background: url($imgURL + "/mypage/bg_noticeNon.svg") no-repeat top center / 6.6rem;
			font-size: 1.5rem;
			font-weight: 300;
			color: #999999;
		}
	}


	.listW {
		margin: 0 -2rem;
		padding: 2.1rem 2rem;
		border-bottom: 1px solid #EEEEEE;

		.tit {
			font-size: 1.5rem;
			font-weight: 300;
			text-align: left;
			padding-bottom: 0.5rem;
			cursor: pointer;
			line-height: 1.54;

			.icoNotice {
				display: inline-block;
				width: 3rem;
				height: 1.6rem;
				line-height: 1.6rem;
				margin-left: 0.4rem;
				border-radius: 1rem;
				background: $mainColor02;
				font-size: 1rem;
				text-align: center;
				color: #fff;
				vertical-align: 0.2rem;
			}

			.icoCtgry {
				margin-right: .5rem;
				font-weight: 700;
			}
		}

		.data {
			font-size: 1.2rem;
			color: #999999;
			text-align: left;
		}
	}

	// view단
	.viewW {
		//padding: 0 2rem;
		text-align: left;
		margin-bottom: 1rem;

		.top {
			border-bottom: 1px solid #EEEEEE;
			padding: 2rem 0 1.6rem;

			.tit {
				font-size: 1.8rem;
				font-weight: 700;
				color: #111111;
				line-height: 1.56;
				margin: 1rem 0 1.1rem;
			}

			.data {
				font-size: 1.2rem;
				color: #999999;
			}
		}

		.cnt {
			padding-top: 3.1rem;
			color: #555;
			font-size: 1.5rem;
			p {
				line-height: 1.54 !important;
			}
		}
	}
}

.faqPage, .NoticePage {
	.boardLink {
		color: #555;
		font-size: 1.5rem;
		line-height: 1.53;
		text-decoration: underline;
		margin-bottom: 1.1rem;
	}
}
#TopBannerLMS{
	@include desktop{
		&.guide{
			position: sticky !important;
			position:-webkit-sticky !important;
		}
	}
}
//faqPage
.faqPage {
	margin: -5rem -2rem -5rem;
	text-align: left;
	#header,
	.bcm-header.is-not-fixed{
		position: relative;

		@include desktop {
			border-bottom: 1px solid #eee;
		}
	}

	.bcm-header__inner {
		justify-content: flex-end;

		@include desktop {
			justify-content: flex-start;
		}
	}

	.moTitle{
		display: block;
		position: relative;
		padding: 3rem 2rem 0;
		@include desktop {
			display: none;
		}
	}
	&.p3view,
	&.p2{
		.moTitle{
			text-align: center;
			font-size: 2rem;
			padding-bottom: 2rem;
			.historyBack{
				display: block;
			}
		}
	}

	.stepWrap{
			z-index: 9;
		@include desktop{
			max-width: 1000px;
			margin: 180px auto 18rem;
		}
	}
	.pcTitle {
		display: none;
		font-size: 5.2rem;
		@include desktop {
			display: block;
		}
	}
	.loading {
		vertical-align: middle;
		background: #fff;
		position: fixed;
		left: 0;
		z-index: 100000;
		right: 0;
		bottom: 0;
		top: 5rem;
		height: calc(100vh - 5rem);;

		img {
			vertical-align: middle;
			text-align: center;
			margin: 0 auto;
		}
	}

	.step1, .step2 {
		padding: 0 2rem;

		@include desktop {
			padding: 0;
		}
	}

	dd {
		margin: 0;
	}

	dl:first-child dt.tit {
		padding-top: 3rem
	}

	//pc 카테고리
	dl.pcCategories{
		display: flex;
		margin: 5rem 0;
		flex-wrap: wrap;
		//border: 1px solid #EEEEEE;

		dd.list{
			width: calc(100% / 7);
			cursor: pointer;
			border: 1px solid #EEE;
			height: 6rem;
			display: flex;
			align-items: center;
			justify-content: center;

			&:not(:nth-child(7n)){
				border-right: 0;
			}
			&:last-child{
				border-right: 1px solid #EEE;
			}

			&:nth-child(n+8):nth-child(-n+14) {
				border-top: 0;
			}

			p{
				background: none;
				padding: 0;
				font-size: 1.7rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&.active{
				background: #9732FC;
				border: #9732FC;
				p{
					color: #fff;
					font-size: $medium;
				}
			}
		}
	}

	dt.tit {
		font-size: 1.8rem;
		font-weight: 700;
		color: #111;
		padding-bottom: 0.55rem;
	}

	dd.list, div.list {
		border-bottom: 1px solid #EEEEEE;

		p.question {
			height: 5rem;
			line-height: 5rem;
			background: url($imgURL + "/mypage/arrow_right.png") no-repeat right center / .9rem 1.4rem;
			font-size: 1.5rem;
			color: #555;
			padding-right: 2rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.bulQ {
				margin-right: 0.6rem;
				color: #CCCCCC;
			}
		}
	}

	div.list {

		.answer{
			@include desktop {
				display: none;
			}

			img {
				@include desktop {
					max-width: 300px;
				}
			}

		}
		p{
			@include desktop {
				background: none;
				height: auto;
			}
		}

		p.question{
			@include desktop {
				height: 7.6rem;
				padding: 0 3rem;
				line-height: 7.6rem;
				font-size: 1.7rem;
				color: #111;
				background: url($imgURL + "/mypage/fqa_arrow_down.png") no-repeat 97% center ;
				cursor: pointer;
			}
			.bulQ{
				@include desktop {
					margin-right: 1rem;
				}
			}
		}

		&.on{
			@include desktop {
				background: #F7F7F7;
			}
			.answer{
				@include desktop {
					display: block;
					padding: 0 4.5rem 3rem;
					font-size: 1.7rem;
					line-height: 1.588;
					color: #555;
				}
			}
			p.question {
				@include desktop {
					background: url($imgURL + "/mypage/ico_arrow_up.png") no-repeat 97% center;
				}
			}
		}

	}

	dd.list:last-child {
		margin-bottom: 5.5rem;
		@include desktop {
			margin-bottom: 0;
		}
	}

	.askBox {
		height: 8rem;
		background: #F7F7F7;
		border-radius: 2rem;
		display: flex;
		align-items: center;
		margin-top: 5rem;
		font-size: 1.7rem;
		cursor: pointer;

		span{
			padding: 0 2rem 0 3rem;
		}
		p{
			color: #999;
			flex: 1;
			background: url($imgURL + "/mypage/arrow_right.png") no-repeat 96% center / .9rem 1.4rem;
		}
	}
	.step3 {
		background: #EEEEEE;
	}

	.viewW {
		padding: 0 2rem;
		background: #ffffff;

		.tit {
			padding-top: 2rem;
			font-size: 1.8rem;
			font-weight: 700;
			color: #111;
			line-height: 1.5;
		}

		.cnt {
			padding: 2.1rem 0 4rem;
			border-bottom: 1px solid #EEEEEE;
			color: #555;
			font-size: 1.5rem;
			line-height: 1.533;

			@include desktop {
				font-size: 1.7rem;
				line-height: 1.588;
			}

			img {
				max-width: 100%;

				@include desktop {
					max-width: 300px;
				}
			}

		}

		.helpbox {
			padding: 1.5rem 0 4rem;

			p {
				font-size: 1.5rem;
				font-weight: 500;
				color: #111;
				line-height: 3.5rem;

				span.btnHelp {
					float: left;
					width: 6.8rem;
					height: 3.5rem;
					line-height: 3.5rem;
					margin-right: .65rem;
					border: 1px solid #BBBBBB;
					border-radius: 2rem;
					font-size: 1.4rem;
					font-weight: 300;
					text-align: center;
				}

				span.btnHelp:last-child {
					margin-right: 0
				}
			}

			p.txtCheck {
				color: #9732FC;
				font-size: 1.5rem;
				font-weight: 300;
				padding-left: 1.5rem;
				background: url($imgURL + "/mypage/bul_check_purple.png") no-repeat left center / 1.2rem .9rem;
			}
		}

		.helpbox::after {
			display: block;
			content: '';
			clear: both
		}
	}

	.otherWrap {
		margin-top: 1rem;
		padding: 4rem 2rem;
		background: #fff;

		dt {
			font-size: 1.5rem;
			color: #111;
			font-weight: 500;
		}

		dt:not(.addHelp) {
			padding-bottom: 1.3rem;
		}

		dd.box {
			border-radius: .8rem;
			background: #F7F7F7;
			margin-bottom: 1rem;

			&.linkTab{
				background: #F7F7F7 url($imgURL + "/mypage/arrow_right.png") no-repeat right 2rem center / .9rem 1.4rem;
			}

			div {
				padding: 1.7rem 2rem;

				p {
					font-size: 1.2rem;
					color: #999;
					font-weight: 300;
				}

				p:not(.tit) {
					line-height: 1.5;
				}

				.tit {
					margin-bottom: .7rem;
					font-size: 1.5rem;
					color: #111;
				}
			}

		}

		.addHelp {
			margin: 4.1rem 0 1rem;
		}
	}
	#footer{
		margin: 0;
	}
	.noticeWrap {
		background: #F7F7F7;
		border-radius: 0 2rem 2rem 2rem;
		margin: 5rem 0;
		padding: 2.6rem 4.1rem;
		font-size: 1.5rem;
		color: #555;
		letter-spacing: -0.05rem;
		.info {
			width: 1.8rem;
			margin-right: 0.6rem;
			vertical-align: -0.3rem;
		}
		.move {
			display: inline-block;
			margin: 0 1.9rem;
			padding: 0 0.1rem;
			font-weight: 500;
			line-height: 1;
			color: #111;
			border-bottom: 0.1rem solid #111;
		}
	}
}

// 문의하기
.askPage {
	margin-top: -5rem;
	padding-top: 5rem;
	select {
		font-family: 'Noto Sans KR';
	}

	.loading {
		vertical-align: middle;
		background: #fff;
		position: fixed;
		left: 0;
		z-index: 990;
		right: 0;
		bottom: 0;
		//top: 5rem;
		top: 0;
		//height: calc(100vh - 5rem);;

		img {
			vertical-align: middle;
			text-align: center;
			margin: 0 auto;
		}
	}
	h3 {
		font-size: 1.8rem;
		line-height: 1.8rem;
		color: #111111;
		margin: 0 0 1rem 0;
		text-align: left;
	}

	.top {
		height: auto;
		padding: 3rem 2rem 0;
		background: #ffffff;
		text-align: left;
	}

	.contentInfo{
		height: auto;
	    padding: 3rem 2rem 0;
	    background: #ffffff;
	    text-align: left;
	}

	.mid {
		text-align: left;
		padding: 0 2rem;

		.txtArea {
			width: 100%;
			height: 20rem;
			border: 1px solid #CCCCCC;
			border-radius: 1rem;
			color: #999999;
			font-size: 1.5rem;
			margin-top: 1rem;
			background: #fff;
			font-family: 'Noto Sans KR';
		}

		.desc {
			font-size: 1.5rem;
			color: #555555;
			line-height: 1.5rem;
		}

		.icoPush {
			margin-top: 1.1rem;

			.txt {
				color: #555555;
				font-size: 1.5rem;
				line-height: 2.8rem;
			}

			.switch {
				display: inline-block;
				width: 5rem;
				height: 2.8rem;
				line-height: 2.8rem;
				vertical-align: middle;
			}

			.switch.on {
				background: url($imgURL + "/mypage/ico_switch_on.svg") no-repeat center / 5rem 2.8rem;
			}

			.switch.off {
				background: url($imgURL + "/mypage/ico_switch_off.svg") no-repeat center / 5rem 2.8rem;
			}
		}
	}

	.mt-4 {
		margin-top: 4rem;
	}

	.btn {
		line-height: 5.6rem;
		margin: 6rem 2rem 2rem;
		width: auto;
	}

	.listPage {
		.listW {
			padding: 2.1rem 2rem;
			border-bottom: 1px solid #EEEEEE;
			text-align: left;

			.tit {
				font-size: 1.5rem;
				font-weight: 300;

				padding-bottom: 1rem;
				cursor: pointer;

				display: inline-block;
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

			}

			.data {
				font-size: 1.2rem;
				color: #999999;
				text-align: left;
			}

			.txtGray {
				font-size: 1.2rem;
				color: #999999
			}

			.txtPP {
				font-size: 1.2rem;
				color: #9732FC
			}
		}
	}

	.viewpage {
		h3 {
			padding: 3rem 2rem 1.8rem;
			font-size: 1.8rem;
			color: #111111;
			margin: 0;

			.date {
				float: right;
				font-size: 1.2rem;
				font-weight: 300;
				color: #999999;
			}
		}

		.cnt {
			padding: 0 2rem;
			text-align: left;
			color: #555555;
			font-size: 1.5rem;
			padding-bottom: 3rem;
			font-weight: 300;

		}

		&.anwser {
			background: #EEEEEE;

			.pd-2 {
				background: #fff;
				padding: 0 2rem;

				h3 {

					padding: 3rem 0 1.8rem 0;
					background: #fff;
					border-top: 1px solid #F1F1F1;

				}

				.cnt {
					padding: 0 0 4rem 0;

					img {
						max-width: 100%;
					}

					iframe {
						max-width: 100%;
					}
				}
			}


			.otherWrap {
				margin-top: 1rem;
				padding: 4rem 2rem;
				background: #fff;
				text-align: left;

				dt {
					font-size: 1.5rem;
					color: #111;
					font-weight: 500;
					margin-bottom: 1rem;
				}

				dd.box {
					border-radius: .8rem;
					background: #F7F7F7 url($imgURL + "/mypage/arrow_right.png") no-repeat right 2rem center / .9rem 1.4rem;
					margin: 0 0 1rem;
					text-align: left;

					div {
						padding: 1.7rem 2rem;

						p {
							font-size: 1.2rem;
							color: #999;
							font-weight: 300;
						}

						.tit {
							margin-bottom: .7rem;
							font-size: 1.5rem;
							color: #111;
						}
					}

				}
			}


		}

	}

	.historyNon .tbl-cell p {
		padding-top: 7.1rem;
		background: url($imgURL + "/mypage/bg_askNon.svg") no-repeat top center/6.6rem;
	}
}
