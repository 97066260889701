@import "../common/commonCode.scss";
@import "../common/_mixin";

//orderPage reset
.orderPage {
  &.p1 {
    margin-top: 0;
  }

  &.p2 {
    background: #F1F1F1;

    .step2:before {
      display: none;
    }
  }

  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  em {
    font-style: normal;
  }

  label {
    margin: 0;
  }

  .blind {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  .divider-line {
    height: 1rem;
    background: #f7f7f7;
    border-top: 0.1rem solid #f1f1f1;
    margin: 0 -2rem;
  }
}

//상단 배너 + 수강권
.order-ad {
  padding-bottom: 6rem;

  //상단 배너
  .order-banners {
    margin: 1rem -2rem 0;

    &__item {
      max-width: 33.5rem;
      height: 7.5rem;
      border-radius: 1rem;
      background: url($imgURL + "/order/bg_intro.svg") no-repeat center;
      background-size: contain;
      text-align: left;
      padding: 1.5rem 2.5rem 0;

      .txt {
        color: #fff;

        &--tit {
          font-size: 1.7rem;
          font-weight: $bold;
          letter-spacing: -0.05rem;
          line-height: 1.6;

          &.adj {
            font-size: 1.6rem;
            line-height: 1.4;

            span {
              display: inline-block;
              vertical-align: top;
              max-width: 17rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        &--sub {
          font-size: 1.2rem;
          opacity: 0.7;
          line-height: 1.4;
        }
      }
    }
  }
}

// 상단, 중간 수강권 공통 스타일
.card-tickets,
.list-tickets {

  .ticket-item {

    &__top-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }

    &__price-box {
      display: inline-block;
    }

    &__price-inner {
      position: relative;
      display: inline-block;
    }

    &__full-price {
      position: absolute;
      left: 0;
      bottom: 78%;
      width: 100%;
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.2rem;
      text-align: left;
      line-height: 1.8rem;
    }

    &__current-price {
      color: #111;
      font-size: 1.8rem;
      font-weight: 700;

      span {
        font-size: 1.2rem;
        font-weight: 400;
      }

    }
  }
}

//상단 카드형식수강권
.card-tickets {
  margin: 3.5rem -2rem 0;

  &:first-of-type {
    margin-top: 1rem;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 2rem;
    margin-bottom: 0.9rem;

    &-title {
      font-size: 1.6rem;
      font-weight: 700;
      text-align: left;
      padding: 0.3525rem 0;
      line-height: 1.8rem;

      strong {
        font-size: 1.3rem;
        font-weight: $regular;
      }
    }

    &-btn {
      padding: 0 1.05rem 0 0;
      background: url($imgURL + "/common/ico_next_g5.svg") no-repeat 100% 60%;
      background-size: 0.6rem auto;
      border: 0;
      font-size: 1.3rem;
      font-weight: 400;
      color: #555;
      line-height: 2.35rem;
      letter-spacing: -0.04em;
    }
  }

  &__reference {
    margin-top: 1rem;
    padding: 0 2rem;
    color: #555;
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-align: left;
    letter-spacing: -0.04em;
  }

  &__slide {
    max-width: 33.5rem;
  }

  .ticket-item {
    position: relative;
    max-width: 33.5rem;

    &__sold-out {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background: rgba(17, 17, 17, .4);
      border-radius: 0 1rem 1rem 1rem;
      color: #fff;
      font-size: 2.1rem;
      font-weight: 700;
      z-index: 300;

      &~.btn-order {
        display: none;
      }
    }

    &__badge {
      position: absolute;
      left: -0.1rem;
      top: -0.1rem;
      height: 2.3rem;
      padding: 0 1.5rem;
      border-radius: 0 0 0.5rem 0;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 2.3rem;
      z-index: 200;

      span {
        display: inline-block;
        vertical-align: top;

        &+span {
          position: relative;
          margin-left: 3.1rem;

          &:before {
            content: '';
            position: absolute;
            left: -1.56rem;
            top: 50%;
            width: .1rem;
            height: .7rem;
            background: #fff;
            transform: translateY(-0.35rem);
          }
        }
      }

      img {
        height: .9rem;
      }
    }

    &__top-row {
      padding: 0 0.5rem;
    }

    &__inner {
      position: relative;
      padding: 2.5rem 3.5rem 1.9rem;
      text-align: left;
      border-radius: 0 1rem 1rem 1rem;
      cursor: pointer;

      &--active {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 1px;
          top: 1px;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border: 1px solid #fff;
          border-radius: 0 0.8rem 0.8rem 0.8rem;
          z-index: 2;
        }
      }
    }

    &__prd-name {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3.55rem;

      &--weeks {
        font-size: 3rem;
      }

      span {
        font-size: 1.5rem;

        &.division {
          padding-left: 0.3rem;
        }
      }
    }

    &__discount-rate {
      padding-right: 0.5rem;
      font-size: 2rem;
      font-style: normal;

      span {
        font-size: 1.2rem;
      }
    }

    &__current-price {
      font-size: 2rem;
    }

    &__monthly-info {
      display: flex;
      align-items: center;
      position: relative;
    }

    &__month {
      display: inline-block;
      height: 1.8rem;
      padding: 0 0.45rem;
      margin: 0 0.3rem 0 0.35rem;
      border: 0.1rem solid #fff;
      border-radius: 0.5rem;
      font-size: 1rem;
      line-height: 1.7rem;
      transform: translateY(0.2rem);
    }

    &__summary {
      margin-top: 0.2rem;
      background: #fff;
      border: 0.1rem solid #fff;
      border-radius: 1.5rem;
      font-size: 0;
      text-align: center;

      &-item {
        display: inline-block;
        height: 2.5rem;
        color: #555;
        font-size: 1.2rem;
        vertical-align: middle;
        line-height: 2.5rem;

        &--minutes {
          width: 31.6%;
        }

        &--days {
          width: 31.6%;
        }

        &--zone {
          width: 36.8%;
        }

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2)~.ticket-item__summary-item {
          width: 50%;
        }

        &+li {
          position: relative;

          &:before {
            content: "";
            display: inline-block;
            position: absolute;
            left: -0.05rem;
            top: 0.9rem;
            width: 0.1rem;
            height: 0.8rem;
          }
        }
      }
    }
  }

  //normal
  &--normal {

    .ticket-item {
      &__inner {
        border-width: 0.1rem;
        border-style: solid;

        &--active:before {
          left: -0.1rem;
          top: -0.1rem;
          width: calc(100% - 0.2rem);
          height: calc(100% - 0.2rem);
        }
      }

      &__sold-out {
        left: -0.1rem;
        top: -0.1rem;
        width: calc(100% + 0.2rem);
        height: calc(100% + 0.2rem);
      }

      &__prd-name {
        font-size: 2.5rem;
      }

      &__summary-item {
        &--minutes {
          width: 24%;
        }

        &--days {
          width: 31%;
        }

        &--zone {
          width: 45%;
        }
      }

      &--voice {
        color: #4c69fc;

        .ticket-item__inner--active {
          &:before {
            border-color: #4c69fc;
          }
        }
      }
    }

    .ticket-item--voice {
      .ticket-item__badge {
        background: #98b8f8;
      }

      .ticket-item__inner {
        background: #edf6fd;
        border-color: #cce1f2;
      }

      .ticket-item__summary {
        border-color: #cce1f2;

        &-item+li:before {
          background: #cce1f2;
        }
      }
    }

    .ticket-item--face {
      color: #fc4c4c;

      .ticket-item__inner--active {
        &:before {
          border-color: #fc4c4c;
        }
      }

      .ticket-item__badge {
        background: #fba7a7;
      }

      .ticket-item__inner {
        background: #fff6f6;
        border-color: #fcdfdf;
      }

      .ticket-item__summary {
        border-color: #fcdfdf;

        &-item+li:before {
          background: #fcdfdf;
        }
      }
    }
  }

  //best
  &--best {

    .ticket-item {
      color: #fff;

      &__inner {
        border-width: 0.1rem;
        border-style: solid;
      }

      &__sold-out {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      &__badge {
        background: rgba(255, 255, 255, 0.3);

        &-black {
          background: rgba(0, 0, 0, 0.3);
          left: 0;
          top: 0;
        }
      }

      &__current-price {
        color: #fff;
      }

      &__summary-item {
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent;
      }
    }

    //nth
    .card-tickets__slide:nth-child(8n + 1) {

      .ticket-item {
        &__inner {
          background: linear-gradient(123deg, #fc4c4d 0%, #8e51e7 100%);
        }

        &__summary-item {
          color: #ce49a5;

          &--minutes {
            background: linear-gradient(123deg, #ec4b6c 0%, #dd4a89 100%);
          }

          &--days {
            background: linear-gradient(123deg, #d9498f 0%, #c549b9 100%);

            &:before {
              background: #dd4a8a;
            }
          }

          &--zone {
            background: linear-gradient(123deg, #c349bc 0%, #a14ae6 100%);

            &:before {
              background: #c348ba;
            }
          }
        }
      }
    }

    .card-tickets__slide:nth-child(8n + 2) {

      .ticket-item {

        &__inner {
          background: linear-gradient(123deg, #8b52e7 0%, #4a97e5 100%);
        }

        &__summary-item {
          color: #665de6;

          &--minutes {
            background: linear-gradient(123deg, #7758e6 0%, #615ee5 100%);
          }

          &--days {
            background: linear-gradient(123deg, #5f5ee5 0%, #4269e5 100%);

            &:before {
              background: #615ee6;
            }
          }

          &--zone {
            background: linear-gradient(123deg, #426be5 0%, #478ae5 100%);

            &:before {
              background: #436ae5;
            }
          }
        }
      }
    }

    .card-tickets__slide:nth-child(8n + 3) {
      .ticket-item {

        &__inner {
          background: linear-gradient(123deg, #4a99e5 0%, #60d2a0 100%);
        }

        &__summary-item {
          color: #4eb5e4;

          &--minutes {
            background: linear-gradient(123deg, #4da9e5 0%, #4fb7e4 100%);
          }

          &--days {
            background: linear-gradient(123deg, #4fb9e4 0%, #53cbde 100%);

            &:before {
              background: #4fb8e4;
            }
          }

          &--zone {
            background: linear-gradient(123deg, #54ccdb 0%, #5dd1b2 100%);

            &:before {
              background: #53ccdd;
            }
          }
        }
      }
    }

    .card-tickets__slide:nth-child(8n + 4) {
      .ticket-item {

        &__inner {
          background: linear-gradient(123deg, #61d29d 0%, #b7d43c 100%);
        }

        &__summary-item {
          color: #68d670;

          &--minutes {
            background: linear-gradient(123deg, #65d58a 0%, #6ad876 100%);
          }

          &--days {
            background: linear-gradient(123deg, #6bd872 0%, #7dd85c 100%);

            &:before {
              background: #69d774;
            }
          }

          &--zone {
            background: linear-gradient(123deg, #71d862 0%, #65d587 100%);

            &:before {
              background: #7ed85c;
            }
          }
        }
      }
    }

    .card-tickets__slide:nth-child(8n + 5) {

      .ticket-item {

        &__inner {
          background: linear-gradient(123deg, #b7d43c 0%, #61d29d 100%);
        }

        &__summary-item {
          color: #68d670;

          &--minutes {
            background: linear-gradient(123deg, #a4d546 0%, #94d750 100%);
          }

          &--days {
            background: linear-gradient(123deg, #8fd752 0%, #75d961 100%);

            &:before {
              background: #92d751;
            }
          }

          &--zone {
            background: linear-gradient(123deg, #71d862 0%, #65d587 100%);

            &:before {
              background: #73d861;
            }
          }
        }
      }
    }

    .card-tickets__slide:nth-child(8n + 6) {

      .ticket-item {

        &__inner {
          background: linear-gradient(123deg, #60d2a0 0%, #4a99e5 100%);
        }

        &__summary-item {
          color: #4eb5e4;

          &--minutes {
            background: linear-gradient(123deg, #5cd0b2 0%, #59cfc6 100%);
          }

          &--days {
            background: linear-gradient(123deg, #58ceca 0%, #52cae4 100%);

            &:before {
              background: #59cfc7;
            }
          }

          &--zone {
            background: linear-gradient(123deg, #51c7e4 0%, #4ca8e4 100%);

            &:before {
              background: #52c9e4;
            }
          }
        }
      }
    }

    .card-tickets__slide:nth-child(8n + 7) {

      .ticket-item {

        &__inner {
          background: linear-gradient(123deg, #4a97e5 0%, #8b52e7 100%);
        }

        &__summary-item {
          color: #665de6;

          &--minutes {
            background: linear-gradient(123deg, #426be5 0%, #478ae5 100%);
          }

          &--days {
            background: linear-gradient(123deg, #5f5ee5 0%, #4269e5 100%);

            &:before {
              background: #615ee6;
            }
          }

          &--zone {
            background: linear-gradient(123deg, #7758e6 0%, #615ee5 100%);

            &:before {
              background: #436ae5;
            }
          }
        }
      }
    }

    .card-tickets__slide:nth-child(8n + 8) {

      .ticket-item {

        &__inner {
          background: linear-gradient(123deg, #8e51e7 0%, #fc4c4d 100%);
        }

        &__summary-item {
          color: #ce49a5;

          &--minutes {
            background: linear-gradient(123deg, #a04be7 0%, #b247dd 100%);
          }

          &--days {
            background: linear-gradient(123deg, #b548d8 0%, #cb49ab 100%);

            &:before {
              background: #b348db;
            }
          }

          &--zone {
            background: linear-gradient(123deg, #ce49a6 0%, #ec4b6b 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:before {
              background: #cc49aa;
            }
          }
        }
      }
    }
  }

  //period
  &--period {

    .ticket-item {
      &__sold-out {
        left: -0.1rem;
        top: -0.1rem;
        width: calc(100% + 0.2rem);
        height: calc(100% + 0.2rem);
      }

      &__month {
        border-color: rgba(0, 0, 0, 0.2);
        color: #555;
      }

      &__inner {
        border-width: .1rem;
        border-style: solid;

        &--active {
          &:before {
            left: -0.1rem;
            top: -0.1rem;
            width: calc(100% - 0.2rem);
            height: calc(100% - 0.2rem);
            border-color: #23dad0;
          }
        }
      }

      &--w48 {
        color: #11ab4e;

        .ticket-item {
          &__badge {
            background: #80d2a1;
          }

          &__inner {
            background: #f2f9f6;
            border-color: #d0eedf;

            &--active {
              &:before {
                border-color: #d0eedf;
              }
            }
          }

          &__discount-rate {
            color: #11ab4e;
          }

          &__summary {
            border-color: #d0eedf;

            &-item+li:before {
              background: #d0eedf;
            }
          }

        }
      }

      &--w24 {
        color: $mainColor02;

        .ticket-item {
          &__badge {
            background: #fba7a7;
          }

          &__inner {
            background: #fff6f6;
            border-color: #fcdfdf;

            &--active {
              &:before {
                border-color: $mainColor02;
              }
            }
          }

          &__discount-rate {
            color: $mainColor02;
          }

          &__summary {
            border-color: #fcdfdf;

            &-item+li:before {
              background: #fcdfdf;
            }
          }
        }
      }

      &--w12 {
        color: $subColor02;

        .ticket-item {
          &__badge {
            background: #98b8f8;
          }

          &__inner {
            background: #edf6fd;
            border-color: #cce1f2;

            &--active {
              &:before {
                border-color: $subColor02;
              }
            }
          }

          &__discount-rate {
            color: $subColor02;
          }

          &__summary {
            border-color: #cce1f2;

            &-item+li:before {
              background: #cce1f2;
            }
          }
        }
      }

      &--w4 {
        color: #fc6e10;

        .ticket-item {
          &__badge {
            background: #fdb27e;
          }

          &__inner {
            background: #fff8ed;
            border-color: #f3e7d2;

            &--active {
              &:before {
                border-color: #fc6e10;
              }
            }
          }

          &__discount-rate {
            color: #fc6e10;
          }

          &__summary {
            border-color: #f3e7d2;

            &-item+li:before {
              background: #f3e7d2;
            }
          }
        }
      }
    }
  }

  //--b2b
  &--b2b {
    .ticket-item {
      &__inner {
        padding: 2.066rem 3.5rem 1.96rem;
      }

      &__summary {
        margin-top: 0.6rem;
      }
    }
  }
}

//중간 리스트 형식 수강권
.list-tickets {
  padding: 3.1rem 0 0;
  margin-top: 6.1rem;
  background: url($imgURL + '/order/ico_regular_arr.svg') no-repeat 50% 0;
  background-size: auto 2.2rem;

  &.no-arrow {
    margin-top: 0;
    padding-top: 0;
    background: none;
    @include desktop {
      margin-top: 5rem;

    }
  }

  &__title {
    display: block;
    margin-bottom: 1.95rem;
    color: #111;
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 2.8rem;
    text-align: center;
  }

  .ticket-item {
    position: relative;

    &+.ticket-item {
      margin-top: 1rem;
    }

    &__inner {
      min-height: 7.5rem;
      padding: 2.66rem 3rem 2.4rem 3.9rem;
      border: 0.1rem solid #ddd;
      border-radius: 0 1rem 1rem 1rem;
      background: #fff;
      cursor: pointer;

      &--closed {
        background: #FFFFFF;
        border-color: rgba(221, 221, 221, 0.2);

        @include bcmMobile {
          background: #FAFAFA;
        }
      }

      &--active {
        background: #f9f2ff;
        border-color: #9732fc;

        .ticket-item__prd-name {
          color: #9732fc;
        }
      }
    }

    &__sold-out {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(17, 17, 17, .4);
      border-radius: 0 1rem 1rem 1rem;
      color: #fff;
      font-size: 2.1rem;
      font-weight: 700;
      z-index: 400;

      &~.btn-order {
        display: none;
      }
    }

    &__badge {
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 1rem;
      background: rgb(151, 50, 252);
      background: linear-gradient(90deg,
          rgba(151, 50, 252, 1) 0%,
          rgba(252, 76, 76, 1) 100%);
      border-radius: 0 0 0.5rem 0;
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      font-style: normal;
      line-height: 1.8rem;

      &--closed {
        background: #555555;

        font-size: 1.4rem;
        font-weight: $medium;
        padding: 0 2.4rem !important;

        @include bcmMobile {
          font-size: 0.9rem;
          padding: 0 1.4rem !important;
        }
      }

      span {
        display: inline-block;
        vertical-align: top;

        &+span {
          position: relative;
          margin-left: 1.7rem;

          &:before {
            content: '';
            position: absolute;
            left: -0.9rem;
            top: 50%;
            width: .1rem;
            height: .6rem;
            background: #fff;
            opacity: 0.7;
            transform: translateY(-0.3rem);
          }
        }
      }

      img {
        height: .9rem;
      }
    }

    &__prd-name {
      color: #111;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2rem;

      &--weeks {
        font-size: 1.8rem;
      }

      span {

        &.division {
          position: relative;
          display: inline-block;
          margin-left: 2.1rem;
          color: #555;
          font-size: 1.4rem;
          font-weight: 400;
          vertical-align: top;

          &:before {
            content: '';
            position: absolute;
            left: -1.1rem;
            top: 50%;
            width: 0.1rem;
            height: 1.25rem;
            background: #ddd;
            transform: translateY(-0.55rem);
          }
        }
      }

      &--closed {
        color: #C1C1C1;

        span {
          &.division {
            color: #CFCFCF;

            &:before {
              background: #E5E5E5;
            }
          }
        }
      }
    }

    &__discount-rate {
      color: #fc4c4c;
      font-size: 1.8rem;
      font-weight: 400;
    }

    &__month {
      margin: 0 0.4rem 0 1.2rem;
      font-size: 1.2rem;
    }

    &__price-box {
      min-width: 7.5rem;
      text-align: right;
    }

    &__button {
      color: #FFFFFF;
      font-size: 2rem;
      font-weight: $medium;
      padding: 1.4rem 2.4rem;
      border-radius: 4rem;

      @include bcmMobile {
        font-size: 1.4rem;
        padding: 1rem 1.8rem;
        border-radius: 2rem;
      }

      &--closed {
        background: linear-gradient(90deg,
            rgba(151, 50, 252, 1) 0%,
            rgba(252, 76, 76, 1) 100%);
      }
    }
  }
}

.order-type {
  margin-bottom: 5rem;

  &__tab {
    margin-bottom: -0.8rem;
    padding-bottom: 0.8rem;
    overflow-x: auto;
    overflow-y: hidden;

    .tab-list {
      position: relative;
      font-size: 0;
      white-space: nowrap;
      background: #fbfbfb;
    }

    .tab-item {
      display: inline-block;
      vertical-align: top;

      width: 28%;

      &:first-child:nth-last-child(1) {
        width: 100%;
      }

      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2)~.tab-item {
        width: 50%;
      }

      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3)~.tab-item {
        width: 33.3333%;
      }

      &:first-child:nth-last-child(4),
      &:first-child:nth-last-child(4)~.tab-item {
        width: 25%;
      }

      &__btn {
        display: block;
        width: 100%;
        min-height: 4.4rem;
        background: transparent;
        border: 0;
        color: #555;
        text-align: center;
        cursor: pointer;

        .main-txt {
          font-weight: 400;
        }

        .sub-txt {
          color: rgba(85, 85, 85, 0.7);
        }
      }

      &--active {
        .tab-item__btn {
          position: relative;
          background: #9732fc;
          border-radius: 3.5rem;
          color: #fff;
          font-weight: 700;
          box-shadow: 0 0.2rem 0.4rem rgba(59, 0, 119, .2);

          .main-txt {
            font-weight: 700;
          }

          .sub-txt {
            color: rgba(255, 255, 255, 0.7);

            +.sub-txt:before {
              background: #fff;
            }
          }
        }
      }
    }

    &--b2c {
      .tab-list {
        border-radius: 3.5rem;
      }

      .tab-item {

        &__btn {
          padding: 1.55rem 0 1.45rem;
          font-size: 1.6rem;
          line-height: 2rem;

          .main-txt {
            display: block;
          }

          .sub-txt {
            display: inline-block;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;

            &+.sub-txt {
              position: relative;
              margin-left: 1.1rem;

              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                left: -0.65rem;
                top: 50%;
                width: 0.1rem;
                height: 0.8rem;
                background: #555;
                transform: translateY(-0.4rem);
                opacity: 0.3;
              }
            }
          }
        }
      }
    }

    &--b2b {
      .tab-list {
        border-radius: 2.4rem;
      }

      .tab-item {
        &__btn {
          height: 4.4rem;
          padding: 0;
          font-size: 1.5rem;
          line-height: 4.4rem;
        }

        &--active {
          .tab-item__btn {
            border-radius: 2.4rem;
          }
        }
      }
    }
  }

  &__message {
    margin-top: 2rem;
    text-align: center;
    letter-spacing: -0.04em;

    strong {
      font-weight: 400;
    }

    &--version {
      padding: 1rem 2.85rem;
      background: #f8f8f8;
      border-radius: 1.2rem;
      color: #777;
      font-size: 1.2rem;
      line-height: 1.7rem;

      strong {
        color: #111;
      }
    }

    &--info {
      font-size: 1.3rem;
      line-height: 1.9rem;

      p:first-of-type {
        display: inline-block;
        padding-left: 2.1rem;
        background: url($imgURL + '/order/ico_caution.png') no-repeat 0 0.2rem;
        background-size: auto 1.6rem;
      }

      strong {
        color: #FC4C4C;
        box-shadow: inset 0 -0.7rem 0 rgba(252, 76, 76, 0.1);
      }
    }
  }
}

.order-form {
  padding: 4rem 0 6rem;
}

.order-tab-area {

  &__title {
    display: block;
    margin-bottom: 1.95rem;
    color: #111;
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 2.8rem;
    text-align: center;
  }

  &+& {
    margin-top: 5.9rem;
  }

  &__select {
    width: 100%;
    height: 5rem;
    margin: 0;
    padding: 0 1.4rem;
    background-color: #fff;
    background-size: 1rem auto;
    background-position: right 1.4rem top 50%;
    border: .1rem solid #ddd;
    border-radius: 1rem;
    color: #111;
    font-size: 1.5rem;
    line-height: 5rem;
  }

  &__notice {
    margin-top: 1.5rem;
    color: #777;
    font-size: 1.2rem;
    line-height: 1.7rem;
    text-align: center;
    letter-spacing: -0.05rem;

    strong {
      color: inherit;
      font: inherit;
    }
  }
}

.order-timezone {

  &__bar {
    display: flex;
    align-items: center;
    margin: 2.15rem 0 2rem;

    &-title {
      width: 20.8955%;
      min-width: 7rem;
      padding-right: 1.5rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      text-align: left;
      white-space: nowrap;

      span {
        padding-left: 0.15rem;
        color: #a408e0;
        font-weight: 700;
      }
    }

    &-graph {
      position: relative;
      width: 100%;
      height: 0.8rem;
      border-radius: 10rem;
      background: #ddd;

      .current {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        height: 1.6rem;
        top: 50%;
        left: 0;
        padding: 0 0.8rem;
        background: rgb(239, 62, 96);
        background: linear-gradient(90deg,
            rgba(239, 62, 96, 1) 0%,
            rgba(152, 0, 245, 1) 100%);
        border: 0;
        border-radius: 3.5rem;
        color: #fff;
        font-weight: 400;
        line-height: 1.6rem;
        transform: translateY(-0.8rem);
        transition: all 0.25s ease-out;
        transform-origin: center center;

        &--zone-a {
          width: 100%;
        }

        &--zone-b {
          width: 66.6%;
          left: 11.3207%;
        }

        &--zone-c {
          width: 33.9622%;
          left: 22.6415%;
        }

        &--zone-d {
          width: 16.9811%;
        }

        &--zone-d2 {
          width: 33.9622%;
          left: 66.0378%;
          animation: orderTimeBar 0.3s ease-out;
        }
      }
    }
  }

  &__desc {
    padding: 2rem;
    background: #f8f8f8;
    border-radius: 1.2rem;
    text-align: left;

    .item {
      display: block;
      padding-left: 2.5rem;
      color: #555;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.7rem;
      letter-spacing: -0.04em;

      &+.item {
        margin-top: 0.4rem;
      }

      &:last-of-type {
        margin-bottom: 1.2rem;
      }

      &--inside {
        background: url($imgURL + '/order/bar_zone.png') no-repeat 0 0.4rem;
        background-size: 2rem auto;
        background-position: left;

        span {
          font-weight: $bold;
          color: $purple;
        }
      }

      &--outside {
        background: url($imgURL + '/order/bar_gray.png') no-repeat 0 0.5rem;
        background-size: 2rem auto;
      }
    }

    p {
      position: relative;
      padding-left: 0.75rem;
      color: #777;
      font-size: 1.2rem;
      line-height: 1.7rem;
      letter-spacing: -0.03em;

      &:before {
        content: "·";
        position: absolute;
        left: 0.15rem;
        top: -0.05rem;
      }
    }
  }
}

@keyframes orderTimeBar {
  0% {
    width: 0;
  }

  100% {
    width: 33.9622%;
  }
}

.order-package {
  padding: 3rem 0 5.95rem;

  &__title {
    padding-bottom: 1.44rem;
    color: #111;
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 1.38;
    letter-spacing: -0.04em;
    text-align: center;

    span {
      color: #555;
      font-size: 1.4rem;
      font-weight: 400;
      text-align: left;
      letter-spacing: -0.04em;
    }
  }

  &__list {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -2rem;

    li {
      margin: 1rem 0.25rem 0;
      padding: 0.85rem 2.5rem;
      border-radius: 1.8rem;
      color: #111;
      font-size: 1.2rem;
      line-height: 1.8rem;
      letter-spacing: -0.04em;
      word-break: keep-all;

      span {
        font-weight: 700;
      }

      &:nth-of-type(5n) {
        background: #fff6f6;

        span {
          color: #f937c2;
        }
      }

      &:nth-of-type(5n - 1) {
        background: #f2f9f6;

        span {
          color: #23af4e;
        }
      }

      &:nth-of-type(5n - 2) {
        background: #fff8ed;

        span {
          color: #fc6e10;
        }
      }

      &:nth-of-type(5n - 3) {
        background: #edf6fd;

        span {
          color: #4c69fc;
        }
      }

      &:nth-of-type(5n - 4) {
        background: #f9f2ff;

        span {
          color: #9500fc;
        }
      }
    }
  }
}

.order-foot {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 2rem 1.5rem;
  border-top: 0.1rem solid #EEE;
  background: #fff;
  z-index: 99; // header--rf z-index 100으로 설정되어 더 낮게 수치 변경 (900 -> 99)
  transition: 0.3s ease-out;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

  &--active {
    bottom: 0;
  }

  &__bill-info {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: transparent;

    .bill-info__inner {
      position: absolute;
      bottom: 13rem;
      left: 1rem;
      padding: 1.3rem 2.35rem 1.3rem 1rem;
      background-color: #ffffff;
      border-radius: 1rem;
      border: 0.1rem solid #00BFE5;

      img {
        position: absolute;
        top: 1.3rem;
        right: 1rem;
        width: 1rem;
        height: 1rem;
      }

      li {
        position: relative;
        padding-left: 0.7rem;
        font-size: 1.1rem;
        color: #777777;
        text-align: left;
        line-height: 1.6;

        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0.75rem;
          width: 0.2rem;
          height: 0.2rem;
          background: #999;
          border-radius: 50%;
        }
      }
    }
  }

  &__top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0 1.4rem;

    &--price {
      padding: 1.5rem 0 2rem;
    }

    &--bill {
      padding: .7rem 0 0.5rem;
    }
  }

  &__price-description {
    font-size: 1.4rem;
    color: #555555;
    vertical-align: middle;

    img {
      width: 1.8rem;
      height: 1.8rem;
      margin-left: 0.4rem;
      vertical-align: sub;
      cursor: pointer;
    }
  }

  &__price-wrap {
    position: relative;

    p {
      font-size: 1.4rem;
      line-height: 1.57;
      vertical-align: sub;

      strong {
        font-size: 2.2rem;
        font-weight: $bold;
        line-height: 1.35;
        color: #111111;
        vertical-align: sub;
      }
    }

    .red {
      font-size: 1.1rem;
      color: #F9424D !important;
      text-align: right;

      &:before {
        display: inline-block;
        position: relative;
        top: 0.2rem;
        left: -.2rem;
        content: '*';
        width: 1rem;
        height: 1rem;
        color: #F9424D;
      }
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    margin-bottom: 1rem;
    background-color: #EEEEEE;
  }

  &__bottom-wrap {
    display: flex;
    align-items: center;
  }

  &__bill-flag {
    width: 43%;
    margin-top: -0.5rem;
    text-align: left;

    input[type="checkbox"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      background: none;
      z-index: -100;
    }

    label {
      display: inline-block;
      position: relative;
      min-height: 2rem;
      padding: 0.05rem 1rem 0 2.5rem;
      margin: 0;
      color: #555;
      font-size: 1.3rem;
      line-height: 1.9rem;
      letter-spacing: -0.03em;
      text-align: left;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.7rem;
        width: 2rem;
        height: 2rem;
        background: #ccc url($imgURL + '/order/ico_check_white.svg') no-repeat 50% 50%;
        background-size: 1.2rem auto;
        border-radius: 0.6rem;
      }

      em {
        color: #F9424D;
      }

      .percent {
        font-weight: 700;
      }
    }

    :checked+label:before {
      background-color: #4C69FC;
    }
  }

  &__button {
    width: 60% !important;
  }

  .full {
    width: 100% !important;
    ;
  }

  .btn {
    margin: 0;
    border: 0;
    letter-spacing: -0.03em;
  }

  .bill {
    background: linear-gradient(to right, #00E599, #00BFE5);
  }

  .no-price {
    margin-top: 1.5rem;
  }
}

//결제하기
.order-payment {
  padding: 1rem 0 6rem;

  &-header {
    display: none;
  }

  .payment-product {
    margin: 0;
    padding: 2rem;
    background: #fff;
    border-radius: 1rem;
  }

  .payment-book {
    margin: 1rem 0 0 0;
    padding: 2rem;
    background: #fff;
    border-radius: 1rem;
    text-align: left;

    &__box {
      border-top: 0.1rem solid #eeeeee;
      margin-top: 2rem;
      padding-top: 1rem;

      &-title {
        margin: 0;
        font-size: 1.3rem;
        line-height: 3;
        color: #999999;
        font-weight: 400;
      }

      &-content {
        font-size: 1.5rem;
        color: #555555;
        font-weight: 400;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }

      input {
        margin: 0.5rem 0 0 0;
      }

      input:read-only {
        color: #000000;
        background: #F4F4F4;
      }

      .nickname-box {
        display: flex;

        .magnifying-glass {
          position: absolute;

          width: 2rem;
          height: 2rem;

          margin-top: 2rem;
          margin-left: 26rem;

          @include desktop {
            margin-top: 2rem;
            margin-left: 32rem;
          }
        }

        .magnifying-glass-padding {
          padding-right: 3.5rem
        }
      }
    }

  }

  .payment-radio-list {
    margin-top: 2rem;

    &__item {
      display: inline-block;
      margin-right: 2rem;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
        z-index: -1;
      }

      input[type="radio"] {
        position: absolute;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        overflow: hidden;
      }

      label {
        display: inline-block;
        position: relative;
        min-height: 1.8rem;
        margin: 0;
        padding-left: 2rem;
        background: url($imgURL + '/order/ico_radio_circle_off.svg') no-repeat 0 0.15rem;
        background-size: 1.5rem auto;
        color: #555;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.8rem;
        cursor: pointer;
      }

      :checked+label {
        background-image: url($imgURL + '/order/ico_radio_circle_on.svg');
      }
    }
  }

  .payment-sect {
    margin: 1rem 0 0;
    background: #fff;
    border-radius: 1rem;

    &+& {
      margin-top: 1rem;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding-right: 3.1rem;

      .right-column {
        padding-top: 0.3rem;
      }

      .btn-toggle {
        position: absolute;
        right: 0;
        top: 0.34rem;
        width: 1.75rem;
        height: 1.75rem;
        background: url($imgURL + '/order/ico_up.svg') no-repeat 50% 50%;
        background-size: contain;
        padding: 0;
        border: 0;
        transform: translateX(0.07rem);
        cursor: pointer;
      }
    }

    &__body {
      padding-top: 1.45rem;
    }

    &__title {
      color: #111;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.35rem;
    }

    &--hide {
      &:not(.payment-caution) {
        padding: 1.2rem 1.93rem 1.45rem 2rem;
      }

      .btn-toggle {
        top: 0.3rem;
        transform: rotate(180deg);
      }

      .payment-sect__body {
        display: none;
      }
    }

    &--open {
      &:not(.payment-caution) {
        padding: 2rem;
      }

      .payment-sect__body {
        display: block;

        p {
          position: relative;
          padding-left: 0.7rem;
          padding-bottom: 1rem;
          font-size: 1.2rem;
          letter-spacing: -0.02em;
          color: #999999;
          line-height: 1.7rem;
          text-align: left;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0.75rem;
            width: 0.2rem;
            height: 0.2rem;
            background: #999;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .payment-product {
    &__title {
      margin-bottom: 0.8rem;
      padding: 0 3rem;
      color: #111;
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
      line-height: 2.7rem;
      word-break: keep-all;
    }

    &__info-group {
      padding: 1.54rem 2.5rem 1.4rem;
      background: #f9f5fc;
      border-radius: 1rem;
      text-align: left;
    }

    &__info-row {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.3rem;
      line-height: 1.9rem;
      text-align: left;

      &+.payment-product__info-row {
        margin-top: 0.86rem;
      }
    }

    &__info-title {
      width: 7.8rem;
      color: #777;
    }

    &__info-cont {
      width: calc(100% - 7.8rem);

      span {
        display: block;
        width: 100%;
      }
    }

    &__notice {
      margin-top: 1.54rem;
      font-size: 1.2rem;

      li {
        position: relative;
        padding-left: 0.7rem;
        color: #999;
        line-height: 1.7rem;
        letter-spacing: -0.03em;
        text-align: left;

        &+li {
          margin-top: 0.5rem;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0.75rem;
          width: 0.2rem;
          height: 0.2rem;
          background: #999;
          border-radius: 50%;
        }
      }
    }
  }

  .payment-discount {
    &__summary {
      color: #555;
      font-weight: 400;
      font-size: 1.6rem;
      letter-spacing: -0.05rem;
      text-overflow: ellipsis;
      white-space: nowrap;

      em {
        color: #9732fc;
        font-weight: 700;
      }

      span {
        font-size: 1.2rem;
        letter-spacing: -0.02em;
        vertical-align: bottom;
      }
    }

    &.payment-sect--open .payment-discount__summary {
      em {
        color: #111;
      }
    }

    &__notice {
      margin: 1.5rem 0 0;
      font-size: 1.2rem;
      letter-spacing: -0.02rem;

      li {
        position: relative;
        padding-left: 0.7rem;
        margin: 0;
        color: #999;
        line-height: 1.7rem;
        letter-spacing: -0.03em;
        word-break: keep-all;
        word-wrap: break-word;
        text-align: left;

        &+li {
          margin-top: 0.55rem;
        }

        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0.75rem;
          width: 0.2rem;
          height: 0.2rem;
          background: #999;
          border-radius: 50%;
        }
      }
    }

    &__select {
      select {
        width: 100%;
        height: 4.4rem;
        margin: 0 0 1rem;
        padding: 0 1.5rem;
        background-color: #F4F4F4;
        background-size: 1rem auto;
        border: 0;
        border-radius: 1rem;
        color: #999;
        font-size: 1.5rem;
        line-height: 4.4rem;
      }

      .box {
        width: 100%;
        height: 4.4rem;
        margin: 0 0 1rem;
        padding: 0 1.5rem;
        background-color: #F4F4F4;
        background-size: 1rem auto;
        border: 0;
        border-radius: 1rem;
        color: #999;
        font-size: 1.5rem;
        line-height: 4.4rem;
        text-align: left;
        text-overflow: ellipsis;
      }
    }

    &__input-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .input-column {
        position: relative;
        width: 68.3%;
        background-color: #f4f4f4;
        border-radius: 1rem;

        input {
          margin: 0;
        }

        input:focus {
          border: none;
        }

        &--reserve {

          input {
            padding-right: 4.2rem;
            color: #9732FC;
            font-weight: $bold;
            text-align: right;
          }

          .won {
            position: absolute;
            right: 0;
            top: 0;
            width: 3.75rem;
            height: 100%;
            font-size: 1.5rem;
            line-height: 5rem;
            text-align: left;
          }

          .readonly {
            position: absolute;
            right: 4.2rem;
            top: 0;
            height: 100%;
            font-size: 1.5rem;
            line-height: 5rem;
            text-align: left;
            color: #9732FC;
            font-weight: $bold;
          }
        }
      }

      .btn {
        width: 29.83%;
        margin: 0;
        padding: 0;
        background: #333;
        border-color: #333;
      }

      .btn.disabled {
        background: #555555;
        border-color: #555555;
        color: #999999;
      }
    }

    .error-message {
      margin: 1rem 0 0;
      color: #fc4c4c;
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }

  .payment-price {

    &.payment-sect--hide {
      padding-bottom: 1.12rem;
    }

    &__summary {
      display: inline-block;
      color: #555;
      font-weight: 400;
      font-size: 1.6rem;
      letter-spacing: -0.05rem;
      text-align: right;

      span {
        color: #9732FC;
      }

      .total-row {
        font-size: 1.4rem;
        line-height: 1.375;
      }

      .month-row {
        display: flex;
        align-items: center;
        margin-bottom: 0.3rem;

        &__weeks {
          display: inline-block;
          width: 2.5rem;
          height: 1.8rem;
          margin-right: 0.3rem;
          margin-bottom: -0.2rem;
          font-size: 1rem;
          letter-spacing: 0;
          line-height: 1.7rem;
          text-align: center;
          color: #9732FC;
          border-radius: 0.5rem;
          border: 0.1rem solid rgba(151, 50, 252, 0.8);
        }

        &__price {
          display: inline-block;
          font-size: 1.6rem;
          font-weight: 700;
        }
      }
    }

    &__row-item {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.45rem;
      font-size: 1.4rem;
      line-height: 2.05rem;

      &:first-of-type {
        margin-top: 0;
      }

      dt {
        width: 40%;
        color: #555;
        text-align: left;
      }

      dd {
        width: 60%;
        color: #111;
        font-weight: 500;
        text-align: right;

        span {
          color: #FF0000;
        }
      }

      &--dis-info {
        margin-top: 0.48rem;

        dt,
        dd {
          color: #999;
          font-size: 1.3rem;
          font-weight: 400;
          line-height: 1.9rem;
        }

        dt {
          padding-left: 1.6rem;
          background: url($imgURL + '/order/bullet_price.png') no-repeat 0.46rem 0.28rem;
          background-size: 0.85rem auto;
        }
      }
    }

    &__total {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 1.24rem;
      margin-top: 1.45rem;
      border-top: .1rem solid #EEE;

      dt {
        color: #111;
        font-size: 1.4rem;
        font-weight: 500;
      }

      .payment-price__summary {
        .month-row {
          margin-bottom: 0.4rem;
        }
      }
    }
  }

  .payment-method {
    text-align: left;

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {

      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3)~.payment-method__item {
        width: 31.18%;
        margin-right: 3.23%;

        .payment-method__btn {
          br {
            display: block;
          }
        }
      }

      &:first-child:nth-last-child(3)~.payment-method__item:nth-child(3n) {
        margin-right: 0;
      }

      &:first-child:nth-last-child(2n),
      &:first-child:nth-last-child(2n)~.payment-method__item {
        width: 48.47%;

        &:nth-child(2)~.payment-method__item {
          margin-top: 3.06%;
        }

        &:nth-child(even) {
          margin-left: 3.06%;
        }
      }

      &:first-child:nth-last-child(2)~.payment-method__item--credit {
        width: 0;
        flex-grow: 1;
        flex-basis: 0;

        .payment-method__btn {
          br {
            display: block;
          }
        }
      }

      &:first-child:nth-last-child(2).payment-method__item--n-pay {
        width: 65.76%;

        &:nth-child(even) {
          margin-left: 3.23%;
        }
      }

      &:first-child:nth-last-child(1) {
        width: 100%;
      }

      &--t-pay {
        img {
          height: 1.8rem;
          margin-right: 0.35rem;
        }
      }

      &--n-pay {
        img {
          width: 5rem;
        }
      }
    }

    &__btn {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 6rem;
      background: none;
      border: 1px solid #ddd;
      border-radius: 1rem;
      color: #333;
      font-size: 1.4rem;
      line-height: 2.1rem;
      letter-spacing: -0.01rem;
      word-break: keep-all;

      br {
        display: none;
      }

      &--active {
        background: #f9f2ff;
        border-color: #9500fc;
        color: #9500fc;
      }
    }

    .btn-popup {
      display: inline-block;
      padding: 0 1.55rem 0 0;
      margin-top: 1.2rem;
      background: transparent url($imgURL + '/order/btn_popup_arr.svg') no-repeat right 0 top 0.57rem;
      background-size: auto 1.15rem;
      border: 0;
      color: #111;
      font-size: 1.4rem;
      line-height: 2.05rem;
      letter-spacing: -0.05rem;
      cursor: pointer;

      &~.btn-popup {
        margin-top: 0.6rem;
      }

      span {
        color: #FC4C4C;
      }
    }
  }

  .payment-caution {
    padding: 2rem 0 0;
    background: transparent;

    .payment-sect__head {
      border-bottom: 0.1rem solid #E1E1E1;

      .btn-toggle {
        top: 0.24rem;
        right: 0.74rem;
        background-size: 1.3rem auto;
      }
    }

    .payment-sect__body {
      padding-top: 1.3rem;
    }

    &.payment-sect--hide {
      .payment-sect__body {
        display: none;
      }
    }

    &__title {
      padding-bottom: 0.45rem;
      color: #555;
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

    .txtList {
      margin-left: 0.5rem;
      color: #555;
      font-size: 1.3rem;
      text-indent: -0.6rem;
      line-height: 2.04rem;
      letter-spacing: -0.032em;
      text-align: left;
      word-break: keep-all;
      word-wrap: break-word;

      &+.txtList {
        margin-top: 0.4rem;
      }
    }

    li {
      text-align: left;
    }
  }

  .payment-foot {
    position: fixed;
    left: 0;
    bottom: calc(-100% - calc(env(safe-area-inset-bottom) / 2));
    width: 100%;
    z-index: 900; // 정기유도팝업 900보다 높은 수치여서 버튼이 위로 노출되어 정기유도팝업과 동일한 수치로 변경 (950 -> 900)
    transition: 0.3s ease-out;

    &--active {
      bottom: 0;
    }

    .btn {
      height: auto;
      padding: 1.55rem 0 calc(1.55rem + calc(env(safe-area-inset-bottom) / 2));
      border: 0;
      margin: 0;
      border-radius: 0;
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 2.5rem;

      strong {
        font-weight: 700;
      }
    }
  }
}

@include desktop {

  .orderPage {
    .divider-line {
      display: none;
    }

    &.p1 {
      margin: -5rem auto 0;
    }
  }

  .order-floating {
    top: 4.5rem;
    z-index: 790;

    &__check-list {
      padding: 1.2rem 0;
      justify-content: center;

      &:before {
        box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
      }
    }

    &__check-item {
      width: 15rem;

      &+li {
        margin-left: 1.1rem;
      }

      button {
        height: 4.4rem;
        font-size: 1.5rem;
      }
    }

    &__depth-list {
      padding: 1.5rem 0 2.1rem;
      text-align: center;

      &:before {
        box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.2);
      }
    }

    &__depth-item {

      &+li {
        margin-left: 5rem;
      }

      label {
        padding-left: 2.6rem;
        background-position: 0 0.2rem;
        background-size: auto 2rem;
        font-size: 1.5rem;
        line-height: 2.2rem;
      }
    }
  }

  .order-type {
    margin-bottom: 0;

    &__tab {
      background: #fff;
      padding: 0;
      margin: 0;
      text-align: center;
      overflow: visible;

      .tab-list {
        max-width: 1000px;
        margin: 0 auto;
        background: transparent;
        white-space: inherit;
      }

      .tab-item {
        width: 15.2rem;
        padding: 0 0.1rem;
      }


      &--b2c,
      &--b2b {
        .tab-list {
          border-radius: 0;
        }

        .tab-item {

          &__btn {
            height: 7.9rem;
            padding: 0;
            line-height: 7.9rem;
          }

          &--active {
            .tab-item__btn {
              background: transparent;
              border-radius: 0;
              color: #9732FC;
              box-shadow: none;
            }
          }
        }
      }

      &--b2c {

        .tab-item {

          &__btn {
            font-size: 2.4rem;

            .main-txt {
              display: inline-block;
              padding-left: 3.6rem;
              margin-right: 2rem;
              font-weight: 400;

              &--voice {
                background: url($imgURL + '/order/ico_voice_call_dark.png') no-repeat 0 2.75rem;
                background-size: 2.6rem auto;
              }

              &--face {
                background: url($imgURL + '/order/ico_face_call_dark.png') no-repeat 0 2.75rem;
                background-size: 2.6rem auto;
              }
            }

            .sub-txt {
              font-size: 1.5rem;
              line-height: 7.9rem;
              vertical-align: top;

              &+.sub-txt {
                margin-left: 1.4rem;

                &:before {
                  left: -0.8rem;
                  height: 1rem;
                  transform: translateY(-0.5rem);
                }
              }
            }
          }

          &--active {
            .tab-item__btn {
              .main-txt {
                font-weight: 700;

                &--voice {
                  background-image: url($imgURL + '/order/ico_voice_call_active.png');
                }

                &--face {
                  background-image: url($imgURL + '/order/ico_face_call_active.png');
                }
              }

              .sub-txt {
                color: #9732FC;

                &+.sub-txt:before {
                  background: #9732FC;
                }
              }

              &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 0.5rem;
                background: #9732FC;
              }
            }
          }
        }
      }

      &--b2b {
        .tab-item {
          &__btn {
            height: 8rem;
            font-size: 1.8rem;
          }

          &--active {
            .tab-item__btn {
              &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 0.5rem;
                background: #9732FC;
              }
            }
          }
        }
      }
    }

    &__message {
      max-width: 1000px;
      margin: 3rem auto 0;

      &+.order-type__message {
        margin-top: 5rem;
      }

      &--version {
        padding: 0;
        background: none;
        font-size: 1.6rem;
        line-height: 2.4rem;

        br {
          display: none;
        }
      }

      &--info {
        padding: 2rem 1rem;
        background: #F0F0F0;
        border-radius: 1rem;
        font-size: 1.6rem;
        line-height: 2.4rem;

        p:first-of-type {
          padding-left: 2.5rem;
          background-position: 0 0.2rem;
          background-size: auto 2rem;
        }

        strong {
          box-shadow: inset 0 -1rem 0 rgba(252, 76, 76, 0.1);
        }
      }
    }
  }

  .order-package {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;
    padding: 4.4rem 5.9em;
    margin: 10rem auto;
    background: #FEFCFF;
    border: .1rem solid #F1DEFF;
    border-radius: 2rem;

    &__title {
      width: 19.1rem;
      padding-bottom: 2.25rem;
      margin: 0;
      color: #8F56C7;
      font-size: 4rem;
      line-height: 5rem;
      text-align: left;
      letter-spacing: -0.03em;
      word-break: keep-all;

      span {
        display: inline-block;
        color: #C7AED9;
        font-size: 1.7rem;
        line-height: 2.5rem;
        letter-spacing: -0.02em;
      }
    }

    &__list {
      display: flex;
      justify-content: flex-start;
      width: calc(100% - 19.1rem);
      margin: 0;
      padding-left: 4.4rem;

      li {
        margin: 0.5rem 0.4rem;
        padding: 1.2rem 2.6rem;
        border-radius: 3.6rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        letter-spacing: -0.04em;
      }
    }
  }

  .order-ad {
    padding-bottom: 0;
  }

  .card-tickets {
    position: relative;
    max-width: 1000px;
    margin: 10rem auto 0;

    &:first-of-type {
      margin-top: 10rem;
    }

    &:last-of-type {
      padding-bottom: 12rem;
    }

    &__head {
      padding: 0 0 1.9rem;

      &-title {
        font-size: 3rem;
        line-height: 3.8rem;

        span {
          margin-left: 1rem;
          color: #555;
          font-size: 1.8rem;
          font-weight: 400;
          letter-spacing: -0.04em;
        }

        strong {
          font-size: 2rem;
          font-weight: $medium;
          letter-spacing: -0.04em;
          margin-left: 1rem;
        }
      }

      &-btn {
        padding: 0 1.5rem 0 0;
        color: #111;
        font-size: 1.8rem;
        line-height: 3.8rem;
        cursor: pointer;
      }

    }

    &__body {
      position: relative;
      margin: 0 -2rem;
    }

    .swiper-container {
      position: static;
      max-width: 1000px;
      overflow: hidden;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 10.6rem;
      height: 10.6rem;
      background-size: 100%;
      background-position: center;
      top: 50%;
      margin-top: -5.3rem;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      background-image: url($imgURL + '/common/swiper_prev_ico_sub.svg');
      left: -100rem;
      right: 0;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      background-image: url($imgURL + '/common/swiper_next_ico_sub.svg');
      left: 0;
      right: -100rem;
    }

    @media (min-width: 1025px) {
      .swiper-button-prev {
        transform: translateY(-50%);
        left: -30px;
        margin: 0;
        background-image: url($imgURL + '/common/swiper_prev_ico_sub.svg');
      }

      .swiper-button-next {
        transform: translateY(-50%);
        right: -30px;
        left: unset;
        margin: 0;
        background-image: url($imgURL + '/common/swiper_next_ico_sub.svg');
      }
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      //opacity: 1;
    }

    &__slide {
      max-width: 32rem;

      &:last-child {
        margin-right: 0;
      }

      &--purchase-banner {
        max-width: 49rem;
      }
    }

    .ticket-item {
      width: 100%;
      max-width: 32rem;
      height: 25rem; // 수강권 높이값
      margin-right: 2rem;

      &__inner {
        height: 100%;
        padding: 3rem 3rem 0;
        border: 0;
        border-radius: 0 2rem 2rem 2rem;
        cursor: inherit;

        &--active:before {
          border-radius: 0 1.9rem 1.9rem 1.9rem;
        }
      }

      &__badge {
        left: 0;
        top: 0;
        height: 2.6rem;
        padding: 0 1.6rem;
        font-size: 1.4rem;
        line-height: 2.6rem;
      }

      &__sold-out {
        border-radius: 0 2rem 2rem 2rem;
        font-size: 2rem;
      }

      &__prd-name {
        display: inline-block;
        padding-top: 0.6rem;
        font-size: 3.4rem;
        line-height: 4rem;

        &--weeks {
          padding-top: 0.4rem;
          font-size: 4rem;
        }

        span {
          font-size: 1.8rem;
        }
      }

      &__top-row {
        display: block;
        padding: 0;
      }

      &__monthly-info {
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: flex-end;
        border-width: 1px 0 0;
        border-style: solid;
      }

      &__discount-rate {
        position: absolute;
        right: 0;
        bottom: calc(100% + 0.6rem);
        padding: 0;
        font-size: 2.2rem;
        line-height: 2.5rem;
      }

      &__price-inner {
        padding-top: 2.05rem;
      }

      &__full-price {
        position: relative;
        display: block;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      &__current-price {
        display: block;
        margin-top: 0;
        font-size: 3rem;
        line-height: 1;

        span {
          display: inline-block;
          font-size: 1.4rem;
          vertical-align: middle;
        }
      }

      &__month {
        height: 2rem;
        margin: 0 0 0.2rem 0.6rem;
        padding: 0 0.5rem;
        border-radius: 0.3rem;
        font-size: 1.3rem;
        line-height: 1.8rem;
      }

      &__summary {
        margin-top: 1.6rem;
        border: 0;
        border-radius: 1rem;
        overflow: hidden;

        &-item {
          height: 5rem;
          font-size: 1.4rem;
          line-height: 5rem;

          &+li:before {
            top: 2rem;
            height: 1rem;
          }
        }
      }

      .btn-order {
        position: absolute;
        left: 3rem;
        bottom: -9.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 6rem);
        height: 9.4rem;
        padding: 0;
        border: 0;
        border-radius: 1rem;
        cursor: pointer;
        transition: 0.3s;
        z-index: 10;

        &--no-price {
          bottom: -6rem;
          height: 6rem;
        }

        &__txt {
          font-size: 2.2rem;
          font-weight: 500;
        }

        &__total-price {
          font-size: 1.4rem;
          font-weight: 400;
          margin-top: 0.6rem;

          span+span {
            position: relative;
            margin-left: 1.1rem;

            &:before {
              content: '';
              position: absolute;
              left: -0.6rem;
              top: 50%;
              width: 0.1rem;
              height: 1rem;
              background: #fff;
              opacity: 0.7;
              transform: translateY(-0.5rem);
            }
          }
        }
      }

      &:hover .btn-order {
        bottom: 3rem;
      }
    }

    &--best {

      .ticket-item__monthly-info {
        border-color: rgba(255, 255, 255, 0.07);
      }

      .btn-order {
        background: #fff;
      }

      .card-tickets__slide {
        &:nth-child(8n + 1) {
          .btn-order {
            color: #D3499C;

            &__total-price {
              span+span:before {
                background: #D3499C;
              }
            }
          }
        }

        &:nth-child(8n + 2) {
          .btn-order {
            color: #6C5BE5;

            &__total-price {
              span+span:before {
                background: #6C5BE5;
              }
            }
          }
        }

        &:nth-child(8n + 3) {
          .btn-order {
            color: #4DAFE4;

            &__total-price {
              span+span:before {
                background: #4DAFE4;
              }
            }
          }
        }

        &:nth-child(8n + 4) {
          .btn-order {
            color: #65D581;

            &__total-price {
              span+span:before {
                background: #65D581;
              }
            }
          }
        }

        &:nth-child(8n + 5) {
          .btn-order {
            color: #65D581;

            &__total-price {
              span+span:before {
                background: #65D581;
              }
            }
          }
        }

        &:nth-child(8n + 6) {
          .btn-order {
            color: #4DAFE4;

            &__total-price {
              span+span:before {
                background: #4DAFE4;
              }
            }
          }
        }

        &:nth-child(8n + 7) {
          .btn-order {
            color: #6C5BE5;

            &__total-price {
              span+span:before {
                background: #6C5BE5;
              }
            }
          }
        }

        &:nth-child(8n + 8) {
          .btn-order {
            color: #D3499C;

            &__total-price {
              span+span:before {
                background: #D3499C;
              }
            }
          }
        }
      }
    }

    &--recommend {

      .ticket-item__monthly-info {
        border-color: rgba(255, 255, 255, 0.07);
      }

      .btn-order {
        background: #fff;
      }

      .card-tickets__slide {

        &:nth-child(8n + 1) {
          .btn-order {
            color: #65D581;

            &__total-price {
              span+span:before {
                background: #65D581;
              }
            }
          }
        }

        &:nth-child(8n + 2) {
          .btn-order {
            color: #4DAFE4;

            &__total-price {
              span+span:before {
                background: #4DAFE4;
              }
            }
          }
        }

        &:nth-child(8n + 3) {
          .btn-order {
            color: #6C5BE5;

            &__total-price {
              span+span:before {
                background: #6C5BE5;
              }
            }
          }
        }

        &:nth-child(8n + 4) {
          .btn-order {
            color: #D3499C;

            &__total-price {
              span+span:before {
                background: #D3499C;
              }
            }
          }
        }

        &:nth-child(8n + 5) {
          .btn-order {
            color: #65D581;

            &__total-price {
              span+span:before {
                background: #65D581;
              }
            }
          }
        }

        &:nth-child(8n + 6) {
          .btn-order {
            color: #4DAFE4;

            &__total-price {
              span+span:before {
                background: #4DAFE4;
              }
            }
          }
        }

        &:nth-child(8n + 7) {
          .btn-order {
            color: #6C5BE5;

            &__total-price {
              span+span:before {
                background: #6C5BE5;
              }
            }
          }
        }

        &:nth-child(8n + 8) {
          .btn-order {
            color: #D3499C;

            &__total-price {
              span+span:before {
                background: #D3499C;
              }
            }
          }
        }
      }
    }

    &--period {
      .btn-order {
        background: $gra1;
        color: #fff;
      }
    }

    &--normal {
      &--minutes {
        width: 21.5%;
      }

      &--days {
        width: 30.7%;
      }

      &--zone {
        width: 47.8%;
      }
    }

    &--b2b {
      .ticket-item {
        height: auto;

        &__inner {
          padding-bottom: 3rem;
        }

        &__top-row {
          padding: 0;
        }

        &__prd-name {
          span {
            &.division {
              padding-left: 0.2rem;
            }
          }
        }

      }
    }
  }

  .list-tickets {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 10rem auto 0;
    padding-top: 3.8rem;
    background-size: auto 3.2rem;

    &__title {
      width: 100%;
      margin-bottom: 2.9rem;
      font-size: 3rem;
      line-height: 4.5rem;
    }

    .ticket-item {
      width: 49%;

      &:first-of-type:nth-last-of-type(1) {
        width: 60rem;
        margin-left: auto;
        margin-right: auto;
      }

      &+.ticket-item {
        margin-top: 0;
      }

      &+.ticket-item:not(:nth-of-type(2)) {
        margin-top: 2rem;
      }

      &:nth-of-type(2n) {
        margin-left: 2%;
      }

      &__inner {
        min-height: 12rem;
        padding: 4.1rem 5rem 4rem;
        border-radius: 0 2rem 2rem 2rem;
      }

      &__badge {
        padding: 0 2rem;
        border-radius: 0 0 1rem 0;
        font-size: 1.4rem;
        line-height: 2.6rem;
      }

      &__prd-name {
        font-size: 2.6rem;
        line-height: 3.8rem;

        span.division {
          font-size: 2rem;

          &:before {
            height: 1.9rem;
            transform: translateY(-0.95rem);
          }
        }
      }

      &__discount-rate {
        font-size: 2.6rem;
        line-height: 3rem;
      }

      &__month {
        margin: 0 0.6rem 0 2rem;
        font-size: 1.5rem;
      }

      &__full-price {
        bottom: 94%;
        font-size: 1.5rem;
      }

      &__current-price {
        font-size: 2.6rem;
        line-height: 3rem;

        span {
          font-size: 1.5rem;
        }
      }
    }
  }

  .order-form {
    padding: 0 0 10rem;
    margin: 0 -2rem;
    background: #FAFAFA;
    border-top: 1px solid #F1F1F1;
  }

  .order-tab-area {
    max-width: 1000px;
    margin: 10rem auto 0;

    &+.order-tab-area {
      margin-top: 10rem;
    }

    &__title {
      margin-bottom: 2.9rem;
      font-size: 3rem;
      line-height: 4.5rem;
    }

    &__select {
      display: block;
      width: 40rem;
      margin: 0 auto;
    }

    &__notice {
      margin-top: 3rem;
      font-size: 1.6rem;
      line-height: 2.4rem;

      br {
        display: none;
      }
    }
  }

  .order-timezone {
    &__bar {
      max-width: 541px;
      margin: 3.24rem auto 3.4rem;

      &-title {
        width: 91px;
        min-width: auto;
        padding-right: 2rem;
        font-size: 1.5rem;
      }

      &-graph {
        height: 0.9rem;

        .current {
          height: 1.9rem;
          font-size: 1.2rem;
          line-height: 1.9rem;
          transform: translateY(-0.95rem);
        }
      }

    }


    &__desc {
      padding: 3rem 4rem;
      background: #F0F0F0;
      border-radius: 2rem;

      br {
        display: none;
      }

      .item {
        display: inline-block;
        padding-left: 2.8rem;
        margin-bottom: 2rem;
        background-position: 0 50%;
        font-size: 1.6rem;
        line-height: 2.4rem;
        vertical-align: top;
        letter-spacing: -0.04em;

        &+.item {
          margin: 0 0 2rem 6rem;
        }
      }

      p {
        padding-left: 1rem;
        font-size: 1.6rem;
        line-height: 2.4rem;

        &:before {
          top: 0.05rem;
          left: 0.05rem
        }
      }
    }
  }

  //결제하기
  .orderPage.p2 {
    width: auto;
    margin: -5rem -2rem 0;
    padding: 3rem 0 0;

    .step2 {
      width: 40rem;
      margin: 0 auto;
    }
  }

  .order-payment {
    padding: 0 0 18rem;

    &-header {
      display: block;

      &__logo {
        width: 10.6rem;
        height: 2.4rem;
        margin: 0 0 5rem;

        a {
          display: block;
          width: 100%;
          height: 100%;
          background: url($imgURL + '/order/bi_color.png') no-repeat 50% 50%;
          background-size: contain;
        }
      }

      &__title {
        margin-bottom: 4rem;
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 5.4rem;
      }

    }

    .payment-product {
      padding: 3rem 2rem;
      border-radius: 2rem;

      &__title {
        margin-bottom: 1.9rem;
        font-size: 2.5rem;
        line-height: 3.7rem;
      }

      &__info-group {
        padding: 3rem;
        border-radius: 2rem;
      }

      &__info-row {
        font-size: 1.6rem;
        line-height: 2.6rem;

        &+.payment-product__info-row {
          margin-top: 1.3rem;
        }
      }

      &__info-title {
        width: 9.7rem;
      }

      &__info-cont {
        width: calc(100% - 9.7rem);
      }

      &__notice {
        margin-top: 2rem;

        li {
          font-size: 1.4rem;
          line-height: 2rem;

          &+li {
            margin-top: 0.8rem;
          }

          &:before {
            top: 0.86rem;
          }
        }
      }
    }

    .payment-sect {

      margin-top: 2rem;

      &:not(.payment-caution) {
        border-radius: 2rem;
      }

      &--hide {
        &:not(.payment-caution) {
          padding: 1.94rem 2rem;
        }

        .btn-toggle {
          top: 0.65rem;
          width: 1.9rem;
          height: 1.9rem;
        }
      }

      &--open {
        &:not(.payment-caution) {
          padding: 3rem 2rem;
        }

        .payment-sect__body {
          padding-top: 1.9rem;
        }
      }

      &__head {
        align-items: center;

        .right-column {
          padding: 0;
        }

        .btn-toggle {
          top: 0.75rem;
        }
      }

      &__title {
        font-size: 2.2rem;
        line-height: 3.3rem;
      }
    }

    .payment-discount {

      &__summary {
        font-size: 1.8rem;
        line-height: 3.3rem;

        span {
          font-size: 1.4rem;
        }
      }

      &__select {
        select {
          height: 5rem;
          font-size: 1.6rem;
          line-height: 5rem;
        }
      }

      &__input-row {

        .input-column {
          width: calc(100% - 14rem);

          &--reserve {
            input {
              padding-right: 3.4rem;
            }

            .won {
              width: 3.4rem;
              font-size: 1.6rem;
            }
          }
        }

        .btn {
          width: 13rem;
          font-size: 1.6rem;
          font-weight: 400;
        }
      }

      .error-message {
        font-size: 1.4rem;
      }

      &__notice {
        margin-top: 2rem;

        li {
          padding-left: 0.7rem;
          font-size: 1.4rem;
          line-height: 2rem;

          &+li {
            margin-top: 0.8rem;
          }

          &:before {
            top: 0.94rem;
            left: 0.1rem;
          }
        }
      }
    }

    .payment-price {

      .payment-sect__head {
        align-items: flex-start;

        .right-column {
          padding-top: 0.5rem;
        }
      }

      &__summary {
        font-size: 1.8rem;
      }

      .month-row {
        margin-bottom: 0.5rem;

        &__weeks {
          width: 3.1rem;
          height: 2rem;
          margin-right: 0.4rem;
          border-radius: 0.3rem;
          font-size: 1.3rem;
        }

        &__price {
          font-size: 1.8rem;
        }
      }

      &__row-item {
        margin-top: 1.6rem;
        font-size: 1.6rem;
        line-height: 2.4rem;

        &--dis-info {
          margin-top: 1rem;

          dt,
          dd {
            font-size: 1.4rem;
            line-height: 2rem;
          }

          dt {
            padding-left: 1.5rem;
            background-image: url($imgURL + '/order/bullet_price_pc.png');
            background-position: 0 0.2rem;
            background-size: 1rem auto;
          }

        }
      }

      &__total {
        padding-top: 2.2rem;
        margin-top: 2rem;

        dt {
          font-size: 1.6rem;
          font-weight: 400;
        }
      }
    }

    .payment-method {

      &__item {

        &:first-child:nth-last-child(3),
        &:first-child:nth-last-child(3)~.payment-method__item {
          width: 11.2rem;
          margin-right: 1.2rem;
        }

        &:first-child:nth-last-child(2n),
        &:first-child:nth-last-child(2n)~.payment-method__item {
          width: 17.4rem;

          &:nth-child(2)~.payment-method__item {
            margin-top: 1.2rem;
          }

          &:nth-child(even) {
            margin-left: 1.2rem;
          }
        }

        &:first-child:nth-last-child(2)~.payment-method__item--n-pay {
          width: 23.6rem;
          margin-left: 1.2rem;
        }

      }

      .btn-popup {
        margin-top: 2rem;
        padding-right: 1.6rem;
        font-size: 1.4rem;
        line-height: 2rem;

        ~.btn-popup {
          margin-top: 1rem;
        }
      }
    }

    .payment-caution {
      margin-top: 1rem;
      padding-top: 3rem;

      &__title {
        padding-bottom: 0.9rem;
        font-size: 1.8rem;
        line-height: 2.7rem;
      }

      .payment-sect__head {
        .btn-toggle {
          top: 0.52rem;
          right: 0;
          background-size: 1.8rem auto;
        }
      }

      .txtList {
        color: #999;
        font-size: 1.4rem;
        line-height: 2rem;

        &+.txtList {
          margin-top: 0.8rem;
        }

      }
    }

    .payment-foot {
      position: static;
      margin-top: 6rem;

      .btn {
        height: 6rem;
        padding: 0;
        border-radius: 0 0.8rem 0.8rem 0.8rem;
        font-size: 2rem;
        line-height: 6rem;
      }
    }
  }

  .order-foot {
    padding: 0 0 2rem;
    z-index: 900;

    &__inner {
      display: flex;
      justify-content: center;
    }

    &__top-wrap {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 47rem;
      margin: 0 auto;
      padding: 2.4rem 0 1.2rem;

      &--price {
        padding: 2rem 0 1.2rem;
      }
    }

    &__bottom-wrap {
      display: block;
      width: 47rem;
      margin: 0 auto;
    }

    &__line {
      width: 47rem;
      margin: 0 auto 1.2rem;

      &--hidden {
        display: none;
      }
    }

    &__price-wrap {
      display: flex;
      align-items: center;
      position: relative;

      p {
        font-size: 1.4rem;
        line-height: 1.67;
        vertical-align: sub;

        strong {
          font-size: 3rem;
          font-weight: $bold;
          line-height: 1.35;
          color: #111111;
          vertical-align: sub;
        }
      }

      .red {
        position: unset;
        padding-top: 0.5rem;
        margin-left: 0.6rem;
      }
    }

    &__price-description {
      font-size: 1.5rem;
      margin-right: 1rem;

      img {
        margin-right: 5rem;
      }
    }

    &__bill-flag {
      display: flex;
      width: unset;
      align-items: center;
      margin-bottom: 1.2rem;

      label {
        min-height: 2rem;
        padding: 0 0 0 2.7rem;
        font-size: 1.5rem;
        line-height: 2.1rem;

        &:before {
          background-image: url($imgURL + '/order/ico_check_white_pc.png');
          background-size: 1rem auto;
          border-radius: 0.4rem;
          top: 0;
        }

      }
    }

    .bill-info__inner {
      bottom: 17rem;
      left: 33%;

      &--pc {
        bottom: 13rem;
      }
    }

    .btn {
      width: 100%;
      height: 6rem;
      padding: 0;
      border-radius: 0 0.8rem 0.8rem 0.8rem;
      font-size: 2rem;
      line-height: 6rem;
    }

    &__button {
      width: 100% !important;
    }
  }
}

.order-recommendation {
  position: relative;
  margin-top: 1.5rem;
  padding: 2rem 2rem 2rem 4rem;
  border-radius: 1rem;
  background-color: #F8F8F8;
  text-align: left;
  color: #555555;
  font-size: 1.3rem;
  font-weight: $regular;
  line-height: 1.5;

  b {
    font-weight: $bold;
  }

  &:before {
    content: '';
    position: absolute;
    top: 2.2rem;
    left: 2rem;
    width: 100%;
    height: 2rem;
    background: url($imgURL + '/order/ico_caution_purple@2x.png') no-repeat 0 0;
    background-size: auto 1.5rem;
  }

  @include desktop {
    margin-top: 3rem;
    padding: 2rem;
    background-color: #F0F0F0;
    text-align: center;
    font-size: 1.6rem;

    &:before {
      content: '';
      position: relative;
      display: inline-block;
      top: 0.4rem;
      left: 0;
      padding-right: 0.5rem;
      width: 2rem;
      height: 2rem;
      background: url($imgURL + '/order/ico_caution_purple@2x.png') no-repeat 0 0;
      background-size: auto 2rem;
    }
  }
}

.order-warining {
  position: relative;
  margin-top: 1.5rem;
  padding: 2rem 2rem 2rem 4rem;
  border-radius: 1rem;
  background-color: #F8F8F8;
  text-align: left;
  font-size: 1.2rem;
  font-weight: $regular;
  line-height: 1.5;
  color: #777777;
  letter-spacing: -0.1rem;
  word-break: keep-all;

  @include desktop {
    background-color: #F0F0F0;
    padding: 2rem 14rem 2rem 16.5rem;
    font-size: 1.6rem;
  }

  b {
    font-weight: $regular;
    color: #FC4C4C;
    background: linear-gradient(to top, transparent 15%, rgba(252, 76, 76, 0.2) 15%, rgba(252, 76, 76, 0.2) 40%, transparent 40%);
  }

  &:before {
    content: '';
    position: absolute;
    top: 2.1rem;
    left: 2rem;
    width: 100%;
    height: 2rem;
    background: url($imgURL + '/order/ico_caution.png') no-repeat 0 0;
    background-size: auto 1.5rem;

    @include desktop {
      top: 2rem;
      left: 14rem;
      width: 100%;
      height: 2rem;
      background-size: auto 2rem;
    }
  }
}