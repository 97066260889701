@import '../../common/_mixin';
@import '../../common/commonCode.scss';

.purchase-banner{
  height: 7.5rem;
  border-radius: 1rem;
  cursor: pointer;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  
  &__title{
    padding:2rem 0 0 2.5rem;
    font-size: 1.7rem;
    font-weight: $bold;
    
    &--white {
      color: #ffffff;
    }
    
    &--black {
      color: #111111;
    }
  }
  &__description{
    opacity: 0.7;
    padding: 0.5rem 0 0 2.5rem;
    font-size: 1.2rem;
    
    &--white {
      color: #ffffff;
    }
    
    &--black {
      color: #555555;
    }
  }
  
  @include desktop {
    height: 11rem;
    border-radius: 2rem;
    &__title{
      padding:3rem 0 0 3.7rem;
      font-size: 2.4rem;
    }
    &__description{
      padding: .8rem 0 0 3.7rem;
      font-size: 1.7rem;
    }
  }
}

