@import "../common/_mixin";
@import "../common/commonCode.scss";

.chkbox {
	width: 1.5rem;
	height: 1.5rem;
}

label {
	display: block;
	margin: 1.5rem 0;
	font-size: 1.4rem;

	input {
		margin-right: 1.0rem;
	}
}

.logoLink{
  z-index: 1;
	display: block;
	width: 9rem;
	height: 2.375rem;
	img{
		width: 100%;
	}
	@include desktop {
		width: 10.6rem;
		height: 2.4rem;
	}

}

.join, .login, .idPw{
	input.error{
		font-size: 1.6rem;
		color: #111
	}
	.alertBox{
		@include desktop {
			padding-top: 3rem !important;
			max-width: 34rem !important;
			min-height: 18.8rem !important;
		}

		.tit{
			margin: 0 !important;
		}

		.desc{
			word-break: keep-all !important;
			@include desktop {
				padding: 0 3rem !important;
				font-size: 1.4rem !important;
				color: #111 !important;
			}
		}

		.btnWrap {
			@include desktop {
				height: 5rem !important;
			}
			span {
				@include desktop {
					font-size: 1.6rem !important;
					padding-right: 3rem !important;
				}
			}
		}

	}

}

.join{
	position: relative;
	margin: -5rem -2rem -5rem;

	.mainEmailBox {
		position: relative;
	}

	.mainEmail {
		margin-bottom: 0.1rem;
	}

	h2{
		margin: 1.5rem 0 6.0rem;
	}
}

.p1.join {

	.join-step1 {
		display: flex;
		flex-direction: column;
		position: relative;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);

		@include desktop {
			display: block;
			max-width: 450px;
			height: auto;
			margin: 0 auto;
		}

		.logoLink {
			position: absolute;
			left: 2rem;
			top: 2.2rem;

			@include desktop {
				top: 30px;
				left: 25px;
			}
		}

		&__free-lesson{
			position: relative;
			width: 100%;
			flex-grow: 1;
			overflow: hidden;
			padding: 0 3.85rem;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			box-sizing: border-box;

			@include desktop {
				padding: 126px 0 50px;
			}

			.mo-vis,
			.pc-vis
			{
				width: auto;
				height: 33.9rem;
				max-height: 85%;
				outline: none;
				margin-top: 4.5rem;

				@include desktop {
					height: 424px;
					max-height: inherit;
					margin: 0;
				}
			}

			.mo-vis {

				@include desktop {
					display: none;
				}
			}

			.pc-vis {
				display: none;

				@include desktop {
					display: inline-block;
				}
			}
		}

		&__fress-lesson{

		}
		&__buttons{
			width: 100%;

			@include desktop {
				padding: 25px;
			}
		}

		&__button{
			border:none;
			background: none;
			cursor: pointer;
			&--kakao{
				position: relative;
				width: 100%;
				height: 5rem;
				background-color:#FEE500 ;
				color: #111;
				border-radius: 1rem;
				font-weight: $medium;
				font-size: 1.5rem;
				@include desktop {
					font-size: 1.6rem;
					border-radius: 8px;
				}
			}

			&--naver, &--apple{
				width:4rem;
				height:4rem;
				@include desktop {
					width:5rem;
					height:5rem;
				}
				img{
					width:100%
				}
			}

			&--naver{
				position: relative;
				margin-right: 1rem;

				.naver-button{
					position: absolute;
					top:0;
					left:0;
					width: 100%;
					height: 100%;
					> a {
						display: block;
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		&__button-wrap{
			position: relative;
			width: 100%;
			padding: 1.5rem 2rem;
			background-color: #FAFAFA;
			@include desktop {
				padding: 0;
				background-color: transparent;
			}

			&.is-top {
				border-bottom: 1px solid #F1F1F1;
				@include desktop {
					border-bottom: none;
					padding: 0;
				}
			}

			&.is-bottom {
				display: flex;
				justify-content: space-between;
				align-items: center;
				@include desktop {
					margin-top: 20px;
				}
			}
		}

		&__circle-button{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__button-image-wrap{
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			padding: 1.3rem 1.45rem 1.3rem 1.3rem;
			padding-top: 1.7rem;
			border-right: solid 0.1rem rgba(255,255,255,0.4);

			@include desktop {
				padding: 1.75rem 2rem 1.3rem 2rem;
			}
			> img{
				width: 1.9rem;
				height: 1.75rem;
			}
		}

		&__text-buttons{
			display: flex;
			align-items: center;
			transform: translateY(0.15rem);

			@include desktop {
				transform: translateY(-0.34rem);
			}

			.text-button{
				list-style: none;
				font-size: 1.3rem;
				color:#555555;
				letter-spacing: -0.02em;
				cursor: pointer;

				@include desktop {
					font-size: 1.6rem;
				}

				&--login{
					margin-right: 0.4rem;
					color:#000000;
					font-weight: $bold;


					@include desktop {
						margin-right: 4px;
						font-weight: $medium;
					}
				}
			}
			.link__button__line{
				width:0.1rem;
				height:1.4rem;
				margin: 0 1rem;
				background-color: #CCCCCC;

				@include desktop {
					width: 2px;
					height: 16px;
				}
			}
		}

	}

	#lottie_login_signup_bg {
		display: block;
		width: 100%;
	}

	#lottie_login_signup_top {

		position: absolute;
		top:50%;
		left:50%;
		width: 100%;
		max-width: 47.628vh;
		max-height: 100%;
		height: auto;
		padding-top: 6.3%;
		outline: none;
		transform: translate(-50%, -50%);

		@include desktop {
			max-width: 90%;
			padding-top: 54px;
			transform: translate(-50%, -50%);
		}
	}
}

.join.p2 {
	height: auto;
	padding: 2.2rem 2rem 6rem;

	@include desktop {
		max-width: 400px;
		margin: -5rem auto 0;
		padding: 30px 0 100px;
	}

	.join-step2 {

		@include desktop {
		}
	}

	.logoLink {
		margin-bottom: 4rem;

		@include desktop {
			margin-bottom: 50px;
		}
	}

	h1{
		margin-bottom: 1.0rem;
	}
	.contentBox{
		margin: -1.4rem 0 4.9rem;

		label {
			margin: 0;
			font-size: 1.4rem;
			color: #555;
			@include desktop {
				color: #999;
			}
			&.labelchkAll {
				border-bottom: .1rem solid #EEE;
				font-size: 1.6rem;
				font-weight: $medium;
				color: #111;
				padding-bottom: 1rem;
				margin-bottom: .7rem;
				@include desktop {
					font-weight: $regular;
					font-size: 1.8rem;
					padding-bottom: .8rem;
					margin-bottom: .9rem;
				}
			}
		}
	}

	.btn {
		&.complete {
			border: 0;
			margin: 0 auto;
			@include desktop {
				margin: 0;
			}
		}
	}

	.policyWrap {
		@include desktop {
			margin: 4rem 0 0;
		}
	}
}

.join.p3 {
	.step3 {
		font-size: 2rem;
		text-align: center;
		padding: 2rem
	}
}

.labelWrap {
	position: relative;
	margin-top: 0.15rem;
	.info {
		position: absolute;
		top: 0;
		right: 0;
		//width: 6.0rem;
		height: 3.6rem;
		font-size: 1.4rem;
		color: #999;
		line-height: 3.6rem;
		text-align: right;
		cursor: pointer;
	}
	@include desktop {
		margin-top: -.1rem;
	}
}

.contentBox input:before {
	content: '';
}

.contentBox input.cellPhone{
	font-family: 'Roboto', sans-serif; // ios에서 숫자와 -가 폰트가 달라서 인풋이 움직이는 현상을 막기위해 폰트 통일
}

.authNumWrap {
	position: relative;

	.authNumBox{
		display: flex;
		justify-content: space-between;
	}
	.timer {
		position: absolute;
		display: block;
		font-size: 1.2rem;
		width: 10.0rem;
		right: 1.3rem;
		top: 7.9rem;
		color: #FF613C;
		@include desktop {
			font-size: 1.4rem;
			width: 12rem;
			top: 7.7rem;
		}
	}
	.authInput {
		margin-top: 1rem;
	}
}
.authInput, .msClear {
	@include desktop {
		&::-ms-clear,
		&::-ms-reveal {display: none; width : 0; height: 0;}
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration { display: none; }
	}
}

.authInfo {
	margin-top: 0.9rem;
	font-size: 1.2rem;
	line-height: 1.7;
	color:#555;

	.title {
		font-weight: 600;
		margin-bottom: 0.2rem;
	}

	.list {
		font-weight: 400;
	}
}

.authInfo.hide {
	display: none;
}

.companyWrap, .employeeNoWrap.true, .employeeDivWrap {
	position: relative;

	.subArea {
		position: absolute;
		top: .5rem;
		right: 0;
		font-size: 1.4rem;
		font-weight: 300;

		.openCode {
			margin-top: -0.4rem;
			display: block;
			img {
				width: 1.15rem;
				vertical-align: 0.3rem;
				margin-left: 0.1rem;
			}
			@include desktop {
				margin-top: -0.1rem;
			}
		}

		.clsCode {
			display: none;
			margin-top: -0.4rem;
			img {
				width: 1.15rem;
				vertical-align: 0.3rem;
				margin-left: 0.1rem;
			}
		}
	}

	select {
		width: 100%;
		height:5rem;
		border: none;
		padding: 0 1.5rem;
		font-size: 1.5rem;
		color: #999999;
		border-radius: 1rem;
		background: url($imgURL + '/join/ico_select.svg') no-repeat 95% 50% #F4F4F4;
	}

	select:nth-child(3){
		margin-top: 1rem;
	}

	//.inputBox:not(.view), .btn:not(.view) {
	//	display: none;
	//}

	.inputBox, .btn {
		display: inline-block;
	}

	.inputBox {
		width: 70%;
	}

	.btn {
		width: 28%;
		margin: 0 0 0 2%;
		&.inputBtn{
			background: #333;
			border-color: #555555;
			border-radius: 1.0rem;
			&.disable{
				background: #555;
				border-color: #555;
				color: #999;
			}
		}
	}

	&.true{
		.openCode {
			display: none;
		}

		.clsCode, .inputBox, .btn {
			display: inline-block;
		}
	}
}


.policyWrap {
	display: none;
	position: fixed;
	background-color: #fff;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	top: 0;
	left: 0;
	padding: 0 2rem 2rem 2rem;
	overflow-y: auto;

	@include desktop {
		position: static;
		width: 100%;
		border: 1px solid #ccc;
		margin-bottom: 2rem;
		height: 19rem;
		border-radius: .8rem;
		padding: 1.5rem 1.5rem 1rem 1.9rem;
	}

	.title {
		display: flex;
		justify-content: center;
		position: relative;
		margin-bottom: -0.1rem;
		background-color: #fff;
		font-size: 1.7rem;
		font-weight: $medium;
		color: #111;
		height: 5rem;
		align-items: center;

		@include desktop {
			justify-content: left;
			padding: 1.25rem 0 0;
			font-size: 1.8rem;
			font-weight: $regular;
			height: auto;
		}
		.closeBtn{
			position: absolute;
			width: 2.4rem;
			height: 2.4rem;
			font-size: 0;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			background: #fff url($imgURL + '/web/btn_gnb_close.png') no-repeat 0 0 / 100%;
			border: 0;

			@include desktop {
				display: none;
			}
		}
	}
	.content {
		font-size: 1.3rem;
		text-align: left;
		line-height: 1.63;
		color: #555;
		padding: 1.2rem 0 0 0;
		&.tg {
			@include desktop {
				margin-top: 1.2rem;
				line-height: 1.8;
			}
			b {
				margin: 0 0 .7rem 0;
				display: block;
				color: #111;
				@include desktop {
					margin: 0;
				}
			}
		}
		@include desktop {
			font-size: 1.4rem;
			line-height: 1.9;
			color: #999;
			padding: 0;
		}
	}
	.clsBtn {
		position: fixed;
		top: 1.5rem;
		right: 2.0rem;
		width: 3.4rem;
		height: 3.4rem;
	}
	.info1, .info2, .info3, .info4 {
		display: none;
	}

}

.mainTxt {
	margin: 0 0 3rem;
	font-size: 2.6rem;
	font-weight: $bold;
	text-align: left;
	line-height: 1.5;
	@include desktop {
		font-size: 3.6rem;
		margin: 5rem 0 3rem;
	}
}

.policyWrap.info1,
.policyWrap.info1 .info1,
.policyWrap.info2,
.policyWrap.info2 .info2,
.policyWrap.info3,
.policyWrap.info3 .info3,
.policyWrap.info4,
.policyWrap.info4 .info4 {
	display: block;
	z-index: 200;
	.title {
		position: sticky;
		top: 0;

		@include desktop {
			position: static;
		}
	}
}

.bulStep1 {
	width: 4.4rem;
	height: 1.5rem;
	//background: url("$imgURL + /join/bul_join_step01.png");
}

.bulStep2 {
	width: 4.4rem;
	height: 1.5rem;
	//background: url("$imgURL + /join/bul_join_step2.png");
}


.pwChkList.on {
	color: #4C69FC;
}

.pwChkList.on svg {
	color: #4C69FC;
}

.dsmFilterBg {
	background: rgba(0,0,0,0);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
}
.dsmFilter {
	display: none;
	position: absolute;
	width: 100%;
	margin-top: .2rem;
	padding: 0.5rem 1.5rem;
	background: #fff;
	border: .1rem solid #ccc;
	border-radius: 1.0rem;
	font-size: 1.5rem;
	line-height: 1.4;
	z-index: 20;
	@include desktop {
		width: calc(440px - 4rem);
		margin-top: 0;
	}

	p{
		margin: 0.5rem auto;
		>span {
			color: #999;
		}
	}
}

.block {
	display: block;
}

.none {
	display: none;
}

.name {
	ime-mode: active
}

.bottomWrap {
	position: fixed;
	bottom: 5.0rem;
	left: 0;
	width: 100%;
	padding: 0 2.0rem;
}

.viewIcon {
	position: absolute;
	width: 4.0rem;
	height: 4.0rem;
	padding: 1.1rem;
	top: .8rem;
	right: .1rem;
	@include desktop {
		top: .7rem;
		right: .8rem;
	}
}

.completeTxt {
	//color: #50CBB5;
	color: #4C69FC;
	font-size: 1.3rem;
	margin-top: 1rem;

	&.adj {
		font-size: 1.2rem;
		margin-top: 1.2rem;
	}
}

.login {
	.pageTit {
		font-size: 3.3rem;
		font-weight: $bold;
		margin: 3rem 0 ;

		@include desktop {
			font-size: 4rem;
			letter-spacing: -0.02em;
			line-height: 1.4;
			color: #111;
			margin: 7rem 0 4rem;
		}
	}

	.loginContent{
		display: flex;
		flex-direction: column;
		// justify-content: center;
		height: 100%;
		margin: 2rem 0;
		min-height: 40rem;
		@include desktop {
			margin: 0 0 4.5rem;
		}
	}
	.loginForm {
		margin-bottom: 2rem;
		@include desktop {
			margin-bottom: 5rem;
		}
		input[name='uid']+.errorMsg {
			margin-bottom: -0.1rem;
		}
		.box+.errorMsg {
			//margin-bottom: -1.1rem;
		}
		.btn {
			margin: 2rem 0 2.2rem;
			border: 0;
			@include desktop {
				margin-bottom: 2.4rem;
			}
		}

		.login-corner {
			width: 100%;
			text-align: center;

			&__button {
				border: 1px solid #EEEEEE;
				border-radius: 1rem;
				@include desktop {
					border-radius: 0.8rem;
				}
				background: transparent;
				cursor: pointer;
				margin: 0;
				padding: 0;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 1rem;

				& > img {
					margin: 1.45rem;
					width: 2.1rem;
					@include desktop {
						margin: 0.65rem 1rem;
					}
				}

				& > p {
					width: 100%;
					text-align: center;
					font-size: 1.5rem;
					font-weight: $regular;
					padding: 1.3rem 3.1rem 1.3rem 0;
					border-left:  1px solid #EEEEEE;
					@include desktop {
						font-size: 1.6rem;
					}
				}

        &--kakao {
          border: none;
          background-color: #FEE500;
          position: relative;

        & > p {
          font-weight: $medium;
          border-left:  1px solid rgba(255,255,255,.5);
          }
        }

			}
		}
	}
	//.nalBtn {
	//	//margin: 9.8rem 0 0;
	//	margin: 2.8rem 0 0;
	//	@include desktop {
	//		margin: 9rem 0 0;
	//	}
	//}
	.memCont {
		overflow: visible;
		padding-bottom: 2rem;
	}
}

.idPw {

	&.resetpw{ // 비밀번호 재설정
		height: auto !important;
	}

	.mainTxt {
		margin: 4rem 0 -1.3rem;
		@include desktop {
			margin: 5rem 0 3rem;
		}
		&.email{
			 margin-bottom: 1.8rem;
		}
	}
	.step1 {
		.inputTitle {
			&.adj {
				@include desktop {
					margin-top: 4.4rem;
				}
			}
			@include desktop {
				margin-top: 3.9rem;
			}
		}
		input[name='name']+.inputTitle {
			@include desktop {
				margin-top: 4.4rem;
			}
		}
		input[name='name']+.errorMsg+.inputTitle {
			@include desktop {
				margin-top: 5.3rem;
			}
		}
		input.authInput+.errorMsg {
			//margin-bottom: -1.1rem;
		}
		.dsmFilter+.errorMsg {
			//margin-bottom: -1.1rem;
		}
		.errorMsg.adj {
			//margin-bottom: -1.1rem;
		}
		.btn {
			&.authBtn {
				border: 0;
				@include desktop {
					vertical-align: 0.2rem;
				}
			}
			&.find {
				margin-top: 3rem;
				margin-bottom: 6rem;
				border: 0;
				@include desktop {
					margin-top: 8rem;
					margin-bottom: 4.5rem;
				}
			}
			&.adj {
				margin-bottom: 6rem;
				@include desktop {
					margin-top: 2.2rem;
				}
			}
		}
	}
	.step2 {
		.mainTxt {
			&.adj1 {
				margin: 3rem 0 0;
			}
			//&.email {
			//	margin: -0.6rem 0 0;
			//}
			&.adj2 {
				margin: -0.6rem 0 0;
			}
			&.adj3+.inputTitle {
				@include desktop {
					margin-top: 3.9rem;
				}
			}
		}

		.mainEmail{
			font-size: 2.1rem;
			margin-top: 1rem;
			&:first-child{
				margin-top: 0;
			}
			@include desktop {
				font-size: 2.6rem;
			}
		}

		.subEmailBox{
			margin-top: 3rem;
			padding-top: 3rem;
			border-top: 1px solid #EEE;
			text-align: left;
			@include desktop {
				padding-top: 4rem;
			}
			>p{
				margin-bottom: 1.3rem;
				font-size: 1.3rem;
				color: #999;
				@include desktop {
					margin-bottom: 1.8rem;
					font-size: 1.4rem;
				}
			}
			.subEmail{
				font-size: 1.8rem;
				margin-bottom: .75rem;
				&:last-child{
					margin-bottom: 0;
				}
				@include desktop {
					font-size: 2.2rem;
					margin-bottom: .8rem;
				}
			}
		}

		.mainEmail, .subEmail{
			color: #555;
			font-weight: $medium;
			text-align: left;

			&.NAVER, &.APPLE, &.KAKAO{
				position: relative;
				padding-left: 2.3rem;
				@include desktop {
					padding-left: 2.8rem;
				}
				&:before{
					content: '';
					position: absolute;
					width: 1.7rem;
					height: 1.7rem;
					top: 50%;
					left: 0;
					transform: translateY(-32%);
					@include desktop {
						width: 2.2rem;
						height: 2.2rem;
					}
				}
			}
      &.KAKAO:before{
				background: url($imgURL + '/join/account_kakao.svg') no-repeat 0 0 / 100%;
			}
			&.NAVER:before{
				background: url($imgURL + '/join/account_naver.svg') no-repeat 0 0 / 100%;
			}
			&.APPLE:before{
				background: url($imgURL + '/join/account_apple.svg') no-repeat 0 0 / 100%;
			}
		}

		.info{
			margin-top: 3rem;
			font-size: 1.4rem;
			line-height: 1.5;
			text-align: left;
			color: #555;
			letter-spacing: -.5px;
			@include desktop {
				font-size: 1.3rem;
				letter-spacing: 0;
			}
		}

		.fixBottom{
			margin-bottom: 6rem;
			@include desktop {
				margin-bottom: 4.5rem
			}
		}

		.btn {
			font-weight: $medium;
			&.border0 {
				margin-top: 6rem;
			}
			&.subBtn, &.mb0 {
				margin-bottom: 0;
			}
			&.mb0 {
				margin: 6rem 0;
				@include desktop {
					margin: 6rem 0 4.5rem;
					border: 0;
				}
			}
		}
	}
}
.socialLine{
	position: relative;
	margin: 4rem 0 3rem;
	border: 0;
	border-bottom: 1px solid #D9D9D9;
	&:after{
		content:'or';
		position: absolute;
		background: #fff;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		padding: 0 1rem;
		color: #CCCCCC;
		font-size: 1.2rem;
		@include desktop {
			font-size: 1.4rem;
		}
	}
}

.release{
	text-align: left;

	h1{
		margin: 4rem 0 0;
		font-size: 2.6rem;
		letter-spacing: -.5px;

		@include desktop {
			margin: 5rem 0 0;
			font-size: 3.6rem;
		}
	}
	h2{
		margin: 1rem 0 6rem;
		font-size: 1.3rem;
		line-height: 1.5;
		text-align: left;
		color: #555;
		letter-spacing: -.5px;

		span{
			color: #FF613C;
		}

		@include desktop {
			margin: 2.8rem 0 6rem;
			font-size: 1.4rem;
		}
	}

	.btnLine{
		margin-top: 20rem;
		text-align: center;
		font-size: 1.2rem;
		font-weight: $regular;
		color: #555;
		text-decoration: underline;
		padding-bottom: 2rem;

		@include desktop {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 6rem;
			margin-top: 1rem;
			padding-bottom: 0;
			background: #fff;
			color: #9732fc;
			font-size: 2rem;
			font-weight: 700;
			border-radius: 0 .8rem .8rem .8rem;
			border: .1rem solid;
			text-decoration: none;
			text-align: center;
			cursor: pointer;
		}
	}

}

.socialBox{
	display: flex;
	justify-content: center;
	align-items: center;
	>div{
		margin-right: 1.5rem;
		@include desktop {
			margin-right: 1.7rem;
		}
		&:last-child{
			margin: 0;
		}
	}

	.nalBtn, .aalBtn, .kakaoBtn{
		position: relative;
		width: 4.5rem;
		height: 4.5rem;
		@include desktop {
			width: 4.9rem;
			height: 4.9rem;
			cursor: pointer;
		}
		>img{
			width: 100%;
			height: 100%;
		}

		.naverBtn a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.appleBtn{
			border: 0;
			background: none;
			height: 100%;
			width: 100%;
			padding: 0;
			> img{
				width: 100%;
				height: 100%;
			}
		}
	}
}

.link__button__wrap{
  display: flex;
  justify-content: center;
  width:100%;
  padding: 1.5rem 0 6rem;
  @include desktop {
    padding: 2rem 0 7rem;
  }
  
 .link__button{
   color: #555555;
   font-size: 1.4rem ;
   text-decoration: underline;
 }

 .link__button__line{
  width:0.1rem;
  height:1.4rem;
  margin: 0 1rem; 
  background-color: #EEEEEE;
 }
}

.form-description {
	color: #555555;
	font-size: 1.2rem;
	padding: 1.3rem 0 1.2rem;
	letter-spacing: -0.027em;
	margin-bottom: -1.1rem;
	@include desktop {
		font-size: 1.4rem;
		padding: 1.2rem 0 1.3rem;
	}
}


.checkbox-container {
	.labelWrap {
		.sectionTitle {
			margin: 3.5rem 0 1.7rem;
			font-size: 1.6rem;
			color: #111;
			line-height: 1;
			&--mem {
				padding-bottom: .4rem;
			}
		}
		.sectionInfo {
			position: absolute;
			bottom: 0;
			right: 0;
			line-height: 1;
			text-align: right;
			font-size: 1.4rem;
			color: #999;
			cursor: pointer;
		}
	}
}

@media screen and (min-width: 540px) and (max-width: 1024px){
	#uphone .join.p1 {

		.join-step1 {
			&__free-lesson {
				.mo-vis,
				.pc-vis {
					max-height: 70%;
				}
			}
		}
	}
}


@media screen and (max-width: 1024px) and (orientation: landscape) {
	#uphone .join.p1 {

		.join-step1 {
			height: auto;

			&__free-lesson {
				height: 48.4rem;
				padding: 0;
			}

			#lottie_login_signup_top {
				max-height: 33.4rem;
			}
		}
	}
}
