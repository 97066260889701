@import "../../common/commonCode.scss";
@import "../../common/_mixin";

.trialComplete {
	display: block;
	margin: -5rem -2rem calc(-5rem - env(safe-area-inset-bottom));
  background-color: #fff;
	height: 100vh;
  padding: 5rem 2rem 4rem;
  letter-spacing: -0.02em;
  @include desktop{
		width: 400px;
		margin: -5rem auto;
		padding: 3rem 0 0 0;
		min-height: revert;
  }
  .logo{
    display: none;
    @include desktop{
      display: block;
      height: auto;
      margin: 0 0 60px;
    }
  }
  &__title {
    font-size: 2.15rem;
    letter-spacing: -0.02em;
    line-height: 1.45;
    text-align: center;
    font-weight: $regular;
    white-space: nowrap;
    @include desktop{
      font-size: 26px;
      line-height: 1.42;
    }
    .lesson-time{
      font-weight: $bold;
      color: $purple;
    }
    .bcm-number{
      font-weight: $bold;
    }
    .icon{
      width:7.5rem;
      height:7.5rem;
      margin: 1.5rem 0 1.3rem;
      @include desktop{
        width:82px;
        height:82px;
        margin: 28px 0 15px;
      }
    }
  }
  
  &__info {
    width: 100%;
    padding: 1rem 2.75rem;
    background-color: #F9F9F9;
    border-radius: 1rem;
    letter-spacing: -0.02em;
    @include desktop {
      height: 269px;
      padding: 12px 30px;
      border-radius: 20px;
    }
    .info-title{
      padding: 0 0 1rem;
      text-align: center;
      font-size: 1.4rem;
      font-weight: $bold;
      border-bottom: 0.05rem solid #E2E2E2 ;
      @include desktop {
        font-size: 16px;
        padding: 0 0 14px;
      }
    }
    .info-box{
      padding: 1rem 0;
      @include desktop {
        padding: 14px 0;
      }
      .info-wrap{
        display: flex;
        width: 100%;
        margin: 0 2rem 0 0;
        color: #666666;
        font-size: 1.4rem;
        line-height: 1.84;
        @include desktop {
          font-size: 16px;
          line-height: 1.6;
        }
        // &:last-of-type .value {
        //   // line-height: 1.35;
        // }
      }
      .label{
        padding-left: .75rem;
        width: 8rem; 
        font-weight: $medium;
        white-space: nowrap;
        @include desktop {
          width: 10rem; 
          padding-left: 13px;
        }
  
      }
      .value{
        width: 22rem;
        margin: 0;
      }
    }
  }

  &__notice {
    margin-top: 3rem;
    @include desktop {
      margin-top: 4rem;
    }
    &--title {
      position: relative;
      padding-left: 1.4rem;
      color: #FC4C4C;
      font-size: 1.5rem;
      font-weight: $medium;
      line-height: 2.4rem;
      @include desktop {
        padding-left: 1.6rem;
        font-size: 1.8rem;
      }
      &:before {
        content: '';
        width: 1.2rem;
        height: 2.4rem;
        background: url($imgURL + '/trial/complete/ico_check@2x.png') no-repeat center;
        background-size: 1.2rem;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include desktop {
          width: 1.3rem;
          background-size: 1.3rem;
        }
      }
    }
    &--content {
      margin-top: 1.05rem;
      color: #555555;
      font-size: 1.4rem;
      line-height: 2.2rem;
      @include desktop {
        margin-top: 1.2rem;
        font-size: 1.6rem;
        line-height: 2.5rem;
      }
    }
  }
  
  &__book{
    width: 100%;
    height: 5.6rem;
    margin: 2rem 0 4rem;
    border: none;
    font-size: 1.7rem;
    font-weight: $medium;
    color: #ffffff;
    background: $gra1;
    border-radius: 0 1rem 1rem 1rem;
    cursor: pointer;
    @include desktop {
      height: 60px;
      margin: 20px 0 50px; 
      font-size: 20px;
    }
  }
  
  &__app{
    padding: 3rem 0 0;
    border-top: .1rem solid #EEEEEE;
    @include desktop {
      padding: 36px 0 0;
    }
    .title{
      margin:0 0 1rem 0;
      font-size: 1.5rem;
      font-weight: $medium;
      @include desktop {
        font-size: 18px;
        margin:0 0 13px 0;
      }
    }
    .description{
      position: relative; 
      font-size: 1.4rem;
      color: #555555;
      line-height: 1.57;
      padding: 0 0 0 0.8rem;
      @include desktop {
        font-size: 16px;
      }
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 1rem;
        width: 0.2rem;
        height: 0.2rem;
        background: #555;
        border-radius: 50%;
      }
      &:last-child{
        margin-top: .65rem;
      }
    }
    .download-button{
      width: 100%;
      height: 5.6rem;
      margin: 2rem 0 6rem;
      border: none;
      font-size: 1.7rem;
      font-weight: $medium;
      color: #ffffff;
      background-color: #333333;
      border-radius: 0 1rem 1rem 1rem;
      cursor: pointer;
      @include desktop {
        height: 60px;
        font-size: 20px;
      }
    }
  }
  
  &__app-popup{
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32rem;
    height: 27.75rem;
    padding: 5rem 2.5em 4rem; 
    background-color: #ffffff;
    border-radius: 0 2rem 2rem 2rem;
    text-align: center;
    letter-spacing: -0.02em;
    @include desktop{
      width: 40rem;
      height: 45.6rem;
      padding: 6rem 2rem 5rem;
    }
    .close-button{
      position: absolute;
      top:1.3rem;
      right: 1.3rem;
      width: 2.4rem;
      height: 2.4rem;
      background: url($imgURL + '/bottomPopClose.svg') no-repeat center;
      background-size: cover;
      border: none;
      cursor: pointer;
    }
    .popup-notice{
      margin: 0 0 0.5rem 0;
      font-size: 2.3rem;
      line-height: 1.34;
      @include desktop{
        margin: 0 0 8px 0;
        font-size: 26px;
        line-height: 1.38;
      }
    }
    .popup-text{
      font-size: 1.5rem;
      line-height: 1.53;
      @include desktop{
        font-size: 16px;
        line-height: 1.5;
      }
      strong {
        color: #FC4C4C;
      }
    }
    .popup-mobile-button{
      display: block;
      width: 100%;
      height: 5.6rem;
      padding:1.5rem 0 0;
      margin: 2.5rem 0 0;
      border: none;
      font-size: 1.7rem;
      font-weight: $medium;
      color: #ffffff;
      line-height: 1.44;
      background: $gra1;
      border-radius: 0 1rem 1rem 1rem;
      cursor: pointer;
      @include desktop{
        display: none;
      }
    }
    .popup-pc-qr{
      display: none;
      @include desktop{
        display: block;
        width: 100%;
        height: 212px;
        margin:17px 0 0;
        padding: 20px 0 16px;
        background-color: #EFEFEF;
        border-radius:10px ;
      }
    }
    .qr-code{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      background-color: #ffffff;
      border-radius:4px ;
      canvas {
      width: 94px!important;
      height: 94px!important;
      }
    }
    .qr-description{
      margin: 14px 0 0 0;
      font-size: 14px;
      line-height: 1.57;
      color: #999999;
    }
  }
  
  &__dimmed{
    width: 100%;
    height: 100vh;
    position: fixed;
    inset: 0 0 0 0;
    background: rgba(0,0,0,.7);
  }
  
  &__footer {
		display: none;
		@include desktop {
			display: block;
			margin: 0 auto;
			padding-bottom: 18rem;
			color: #B9B9B9;
			letter-spacing: -0.02rem;
			line-height: 1.5;
      text-align: center;
			&--slogan {
				font-size: 1.4rem;
				font-weight: $regular;
				b {
					font-weight: $bold;
				}
			}
			&--copyright {
				font-size: 1.1rem;
				margin-top: 0.5rem;
			}
		}
	}
}

.trialCompleteTypeB {
  &__title {
    font-size: 2.6rem;
    font-weight: $bold;
    line-height: 3.8rem;
    letter-spacing: -0.05rem;
    @include desktop {
      font-size: 3rem;
      line-height: 4.4rem;
    }
  }
  &__progress {
    position: relative;
    padding-left: 2rem;
    margin: 3.5rem 0 1.5rem;
    list-style: none;
    @include desktop {
      padding-left: 2.4rem;
    }

    &-item {
      position: relative;
      padding-left: 4rem;
      @include desktop {
        padding-left: 4.8rem;
      }
      &:not(:last-of-type) {
        padding-bottom: 3.5rem;
      }
      &:not(:last-of-type):before {
        display: inline-block;
        position: absolute;
        content: '';
        top: 5rem;
        left: -0.05rem;
        width: 0.1rem;
        height: calc(100% - 6rem);
        background-color: #EEEEEE;
        @include desktop {
          top: 5.8rem;
          left: -0.05rem;
          height: calc(100% - 6.8rem);
        }
      }
      .number {
        position: absolute;
        top: 0;
        left: -2rem;
        width: 4rem;
        height: 4rem;
        background-color: #EDF6FD;
        border-radius: 50%;
        color: #111111;
        font-size: 2rem;
        font-weight: $bold;
        line-height: 4rem;
        text-align: center;
        @include desktop {
          left: -2.4rem;
          width: 4.8rem;
          height: 4.8rem;
          line-height: 4.8rem;
        }
      }
      .title {
        height: 4rem;
        font-size: 2rem;
        font-weight: $bold;
        line-height: 4rem;
        @include desktop {
          font-size: 2.2rem;
          line-height: 4.8rem;
        }
      }
      .card {
        position: relative;
        padding: 3rem;
        margin-top: 0.8rem;
        background-color: #EDF6FD;
        border-radius: 0 2rem 2rem 2rem;
        color: #111111;
        &__date-title {
          font-size: 1.5rem;
          font-weight: $medium;
          line-height: 2.3rem;
          letter-spacing: -0.05rem;
          @include desktop {
            font-size: 1.6rem;
            line-height: 2.6rem;
          }
        }
        &__date-content {
          margin-bottom: 1rem;
          font-size: 1.6rem;
          font-weight: $bold;
          line-height: 2.3rem;
          letter-spacing: -0.05rem;
          @include desktop {
            font-size: 2rem;
            line-height: 2.9rem;
          }
        }
        &__content {
          font-size: 1.4rem;
          letter-spacing: -0.05rem;
          line-height: 2rem;
          @include desktop {
            font-size: 1.6rem;
            line-height: 2.4rem;
          }
        }
        &__change {
          margin-top: 1rem;
          color: #4C69FC;
          font-size: 1.4rem;
          line-height: 2rem;
          letter-spacing: -0.05rem;
          text-decoration: underline;
          @include desktop {
            font-size: 1.5rem;
            line-height: 2.1rem;
          }
        }
        &__download {
          position: absolute;
          top: 50%;
          right: 2rem;
          transform: translateY(-50%);
          width: 3.5rem;
          height: 3.5rem;
          background-color: transparent;
          background-image: url($imgURL + '/trial/complete/btn_download_circle@2x.png');
          background-size: cover;
          background-repeat: no-repeat;
          border: none;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

