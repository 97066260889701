@import "../common/_mixin";
@import "../common/commonCode.scss";

:root {
  --vh: 100%;
}

#uphone {
	padding: 5rem 2rem 0;
	margin:auto;
	.login, .idPw, .release {
    //max-width: 400px;
	  margin: -2.8rem auto 0;
		height: calc(90vh - 5rem);
		display: flex;
		flex-direction: column;
		position: relative;

		@include desktop {
			max-width: 400px;
			margin: -2rem auto 0;
			height: auto;
		}
   }
}

.topInfo {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	@include desktop {
		display: none; //pc에서 다른 gnb사용
		//top: 4rem;
		//left: 50%;
		//max-width: 440px;
		//margin-left: -220px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.1);
		top: 0;
	}

	.title {
		margin-top: 1.5rem;
		font-size: 1.7rem;
		text-align: center;
		@include desktop {
			display: none;
		}
	}
	.topLogo {
		position: absolute;
		top: 2rem;
		left: 2rem;
		img {
			width: auto;
			height: 2.3rem;
			@include desktop {
				height: 2.4rem;
			}
		}
	}
	&.fixed {
		background: #fff;
		height: 5rem;
	}
	&.topInfoPc { //pc gnb
		display:none;
		width: 100%;
		height: 5rem;
		background-color: rgba(0,0,0,.1);
		@include desktop {
			display: block;
			//position: fixed;
		}
		.topWrap {
			width: 100rem;
			margin: 0 auto;
			img.gnbLogoIco {
				float: left;
				margin-top: 1.2rem;
			}
			.topGnbWrap {
				float: left;
				&:before, &:after {
					content: '';
					display: table;
					clear: both;
				}
				li {
					cursor: pointer;
					float: left;
					font-size: 1.5rem;
					color: #fff;
					line-height: 5rem;
					margin-left: 4rem;
				}
				li:first-child {
					margin-left: 18.5rem;
				}
			}
			.topOrderBtnPc {
				cursor: pointer;
				float: right;
				font-size: 1.3rem;
				color: rgba(255,255,255,.8);
				line-height: 5rem;
			}
			&:before, &:after {
				content: '';
				display: table;
				clear: both;
			}
		}
	}
}


.loginForm {
	//margin: auto;
	/*padding: 4.0rem;*/
}

.loginForm .box {
	margin: 1.0rem 0 0;
}

.logoImg {
	width: 4.5rem;
	margin: 7vh 0;
}
.logo {
	font-size: 3.8rem;
}

.desc {
	font-size: 1.4rem;
	color: #999999;
	line-height: 2;
}
.infoTxt {
	font-size: 1.2rem;
	color: #555;
	line-height: 1.83;
	font-weight: $regular;
	margin-bottom: -0.3rem;
	&.tit {
		font-weight: $medium;
		margin: 5.9rem 0 0.2rem;
		@include desktop {
			margin-bottom: 0.4rem;
		}
	}
	@include desktop {
		font-size: 1.4rem;
		line-height: 1.6;
	}
}

.memCont {
	//overflow:hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	//height: 5.0rem;
	a {
		font-size:1.4rem;
		color:#999;
		text-decoration: none;
		&:last-child {
			color:#555;
			font-weight: $medium;
			@include desktop {
				color: #111;
				margin-top: -0.1rem;
			}
		}
		@include desktop {
			font-size: 1.6rem;
		}
	}
	.bar {
		//float: left;
		display: inline-block;
		margin: .1rem 1.05rem 0 1rem;
		img {
			width: .12rem;
			vertical-align: -.1rem;
		}
	}
}


button.start {
	margin:8.2rem auto 18.1rem;
}
.goJoin {
    border: none;
    background: none;
	color:#111111;
}

.fl {
	float:left;
}
.fr {
	float: right;
}

.step1, .step2, .step3, .step4 {display: none;}

.p1 .step1 {
	display: block;
}
.p2 .step2 {
	display: block;
}
.p3 .step3 {
	display: block;
}
.p4 .step4 {
	display: block;
}

.step1.i {
	margin: 10vh 0;
	.logoImg {
		margin: 1vh 0;
	}
	.logo {
		margin-bottom: 10vh;
	}
}

.loadMsk {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 99999;

	img {
		position: relative;
		margin: 20rem auto 0;
		width: 10rem;
		height: 10rem;
	}
	.loadImg {
		height: 40px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -20px 0 0 -50px;
		& span{
			transition: all 500ms ease;
			background: #4A72DA;
			box-shadow: 1px 2px 3px #999;
			height: 10px;
			width: 10px;
			display: inline-block;
			border-radius: 10px;
			animation: wave 2s ease  infinite;
			&:nth-child(1){  animation-delay: 0; }
			&:nth-child(2){  animation-delay: 100ms; }
			&:nth-child(3){  animation-delay: 200ms; }
			&:nth-child(4){  animation-delay: 300ms; }
			&:nth-child(5){  animation-delay: 400ms; }
			&:nth-child(6){  animation-delay: 500ms; }
			&:nth-child(7){  animation-delay: 600ms; }
			&:nth-child(8){  animation-delay: 700ms; }
		}
		@keyframes wave{
			0%, 40%, 100% {
				transform: translate(0, 0);
				background-color: #4A72DA;
			}
			10% {
				transform: translate(0, -15px);
				background-color: red;
			}
		}
	}
}
