@import "../common/commonCode.scss";
@import "../common/_mixin";
#step2 {
	.classN {
		@include desktop {
			padding-bottom: 10rem;
			margin-bottom: 5rem;
			border-bottom: 1px solid #f1f1f1;
		}
	}
}
.classPage {
	margin-top: -5rem;
	padding: 5rem 0 15rem;
	.classN {
		margin: 1.2rem 0 5rem;
		text-align: left;
		@include desktop {
			margin:  1.2rem 0 10rem;
		}
		&:before, &:after {
			@include desktop {
				content: '';
				display: table;
				clear: both;
			}
		}

		.infoWrap {
			position: relative;
			/*width: 100vw;
			margin-left: -2rem;
			padding: 0 2rem;*/
			background-color: #fff;
			z-index: 10;

			.title {
				font-size: 3.3rem;
				line-height: 1.4;
				font-weight: 600;
			}

			&.top{

				.title{
					height: 5rem;
					font-size: 1.7rem;
					line-height: 5rem;
				}
				.info{
					display: none;
				}
			}

			.info {
				margin-top: 2rem;
				font-size: 1.4rem;
				line-height: 3.3rem;

				span {
					//vertical-align: middle;
				}

				.st {
					font-weight: $medium;
				}

				.bar {
					margin: 0 1.5rem;
					vertical-align: middle;

					img {
						width: 0.1rem;
						height: 1.5rem;
					}
				}

				.cnt {
					color: #999;
					font-weight: $regular;
				}

				.black {
					color: $black;
				}
			}

			.infoBtn {
				position: absolute;
				bottom: 0;
				right: 0;
				.changeClass{
					display: none;
					position: absolute;
					top: 4rem;
					right: 1rem;
					width: 9rem;
					height: 4rem;
					font-size: 1.3rem;
					line-height: 3.7rem;
					text-align: center;
					border-radius: 1rem;
					background-color: #fff;
					border: 1px solid #ddd;
					box-shadow: 0.7rem 0.4rem 1.3rem 0.2rem #ddd;
				}
				&.true .changeClass{
					display: block;
				}

				img {
					width: 3.3rem;
				}
			}

			&.ns{
				padding-top: 6rem;
				margin-top: 6rem;
				border-top: 1px solid #eee;
			}
			&.stiky{
				.float {
					position: fixed;
					width: 100%;
					margin: 0 -2rem;
					padding: 0 2rem;
					top: 0;
					background-color: #fff;
					height: 5rem;

					.title {
						font-size: 1.7rem;
						line-height: 5rem;
					}

					.info {
						display: none;
					}

					.infoBtn {
						bottom: 0.8rem;
						right: 2rem;
					}
				}
				.dum{
					height: 10rem;
				}
			}
			&.pt{

				margin-bottom: -4rem;

				.title {
					font-size: 1.7rem;
					line-height: 5rem;
				}
				&.stiky{
					.float {
						position: fixed;
						width: 100%;
						margin: 0 -2rem;
						padding: 0 2rem;
						top: 0;
						background-color: #fff;
						height: 5rem;
					}
					.dum{
						height: 5rem;
					}
				}
			}
		}


		.listWrap {
			position: relative;
			margin: 5rem 0 -.1rem;
			@include desktop {
				width: 45rem;
				float: left;
				margin: 5rem 0 7.9rem;
			}

			.left {
				display: inline-block;
				position: relative;
				height: 19rem;
				@include desktop {
					float: left;
				}

				.lImg {
					width: 13.5rem;
					height: 19rem;
					background: url($imgURL + '/classroom/test01.png');
					background-size: cover;
					border-radius: 0 2rem 2rem 2rem;
					overflow: hidden;
					@include desktop {
						width: 17rem;
						height: 24rem;
					}
				}

				.badg {
					position: absolute;
					top: 1.5rem;
					left: -0.5rem;
					width: 7rem;
					height: 1.5rem;
					background: #555;
					border-radius: 0 0 0.5rem 0;
					text-align: center;
					@include desktop {
						width: 11rem;
						height: 3rem;
						border-radius: 0 0 1rem 0;
						top: 3rem;
					}
					.txt {
						color: #fff;
						font-size: 1.1rem;
						font-weight: $bold;
						line-height: 1.5rem;
						@include desktop {
							font-size: 1.7rem;
							line-height: 2.8rem;
						}
					}

					&.ft{
						width:8.5rem;
						color: #fff;
						background: $badge02;
					}
				}
			}

			.right {
				position: relative;
				float: right;
				width: 18rem;
				margin-left: 2rem;
				height: 19rem;
				@include desktop {
					//float: left;
					width: 25rem;
					//margin-left: 3.5rem;
				}
				.Ktxt{
					height: 10.35rem;
					font-size: 1.3rem;
					color: #555;
					line-height: 2.2rem;
				}

				.t {

					.date {
						margin: 0.8rem 0;
						color: #999;
						font-size: 1.3rem;
						font-weight: $regular;
					}

					.date.m01 {
						font-weight: $medium;
					}

					.title {
						font-size: 1.9rem;
						font-weight: $bold;
						line-height: 2.3rem;
						@include desktop {
							font-size: 2.4rem;
							line-height: 2.8rem;
						}
					}

				}

				.bt {
					position: absolute;
					width: 100%;
					bottom: -0.1rem;
					@include desktop {
						bottom: -1rem;
					}

					.l {
						position: relative;
						height: 3.8rem;
						line-height: 3.8rem;
						border-bottom: 0.1rem solid #eee;
						@include desktop {
							height: 5rem;
							line-height: 5rem;
						}
						.lh {
							font-size: 1.3rem;
							@include desktop {
								font-size: 1.7rem;
							}
						}

						.li {
							position: absolute;
							top: 0;
							right: 0;
							font-size: 1.3rem;
							@include desktop {
								font-size: 1.7rem;
							}

							img {
								width: 1.1rem
							}
						}

						.li.f28 {
							color: #999;
							font-size: 1.4rem;
						}

						.li.BEFORE {
							color: #999;
						}

						.li.survey {
							text-decoration: underline;
						}
					}

					.l:last-child {
						height: 3.45rem;
						border-bottom: none;
					}
				}
			}

			.left.disable{
				.badg{
					background: #555555;
				}
			}
			.right.disable{
				.bt{
					position: inherit;
					margin-top: 1rem;
					font-size: 1.6rem;
					color: #555;
					line-height: 1.4;
					@include desktop {
						font-size: 1.7rem;
						color: #555;
						line-height: 2.5rem;
					}
				}
			}

			.ct {
				position: sticky;
				width: 33.5rem;
				height: 10rem;
				background: url($imgURL + '/classroom/ctB.svg');
				background-size: cover;
				color: #fff;
				font-weight: $medium;

				.title {
					padding: 2rem 2rem 0;
					font-size: 1.9rem;
					font-weight: $bold;
				}

				.txt02 {
					display: none;
					padding: 1rem 2rem 0;
					font-size: 1.3rem;
				}

				.date {
					display: none;
					padding: 1rem 2rem 0;
					font-weight: $regular;
				}

				.state {
					display: none;
					position: absolute;
					top: 4.7rem;
					right: 2.5rem;
					color: #FFF820;
					font-size: 1.5rem;
				}
			}

			.ct.OFF {
				background-image: url($imgURL + '/classroom/ctBD.svg');

				.title {
					color: #111;
					font-weight: $medium;
				}

				.txt02 {
					display: block;
					color: #999;
				}

				.date {
					display: none;
				}
			}

			.ct.complete {
				background-image: url($imgURL + '/classroom/ctBC.svg');

				.date {
					display: block;
				}

				.state {
					display: block;
				}
			}
		}

		.recmdCard{
			position: relative;
			height: 9.1rem;
			padding: 2rem 2.5rem;
			margin: 2rem 0;
			border-radius: 0 2rem 2rem 2rem;
			color:#fff;
			.grade{
				font-size: 1.3rem;
				font-weight: $regular;
			}
			.className{
				padding: 1rem 0;
				font-size: 1.9rem;
				font-width: $bold;
			}
			.recmdBtn{
				position: absolute;
				width: 4.6rem;
				height: 3.1rem;
				padding: 0;
				top: 3rem;
				right: 2.25rem;
				background-color: #fff;
				border-radius: 1rem;
				border: none;
				font-size: 1.4rem;
				font-weight: $medium;
			}
		}

		.BannerWrap{
			margin: 3rem -2rem;
			padding: 2.7rem 3rem 0;
			height: 9.5rem;
			background: #fff url($imgURL + '/classroom/NEW.png');
			background-size: cover;
			color: #fff;
			&.END.Incomplete{
				background-image: url($imgURL + '/classroom/ENDnone.png');
			}
			&.END.Complete{
				background-image: url($imgURL + '/classroom/ENDcomplete.png');
			}
			&.HOLDING{
				background-image: url($imgURL + '/classroom/HOLDING.png');
			}
			.title{
				margin-bottom: .7rem;
				font-size: 1.6rem;
				font-weight: $bold;
			}
			.txt01{
				font-size: 1.3rem;
				font-weight: $regular;
			}
		}
		.listWrap.previewListWrap {
			.left {
				.badg {
					background: $badge01;
				}
			}
			.right {
				.tHeight {
					height: 3rem;
					@include desktop {
						height: 3.5rem;
					}
				}
				.bt {
					bottom: -0.1rem;
					@include desktop {
						bottom: -5rem;
					}
					.l {
						.lh {
							color: #555;
						}
						.li {
							cursor: pointer;
							color: #999;
							img.raIco{
								width: .9rem;
								height: 1.4rem;
								vertical-align: -.2rem;
								margin-left: .5rem;
								@include desktop {
									width: 1.2rem;
									height: 1.9rem;
									vertical-align: -.3rem;
									margin-left: .8rem;
								}
							}
						}
					}
				}
			}
		}
	} // classN


	.recommendClassWrap{

		clear: both;
		position: relative;
		font-size: 1.8rem;
		font-weight: $medium;
		color: #111;
		.recommendTitle{

		}
		.classChgBtn{
			position: absolute;
			font-size: 1.5rem;
			top: 0;
			right: 0;
			text-decoration: underline;
		}
	}

	.lockWrap{
		position: relative;
		padding: 2rem 3rem;
		border-radius: 0 2rem 2rem 2rem;
		background-color: #F7F7F7;
		img{
			width: 4rem;
		}
		.text01{
			position: absolute;
			left: 8.5rem;
			top: 2rem;
			font-size: 1.3rem;
			font-weight: $regular;
			line-height: 2rem;
		}
	}

	// 과정소개랑 별도로 강의실에서만 사용될 영역
	&.classRoom {
		padding-top: 6rem;
		.listWrap{
			.right .bt {
				bottom: auto;
			}

			&:nth-child(odd) {
				@include desktop {
					float: right;
				}
			}
			&.cLectList {
				.badg {
					background: $badge01;
				}
			}
		}


		@include desktop {
			max-width: 1000px;
			padding-top: 10rem;
			margin: 0 auto;

			& + .footer {
				margin: 0 -2rem;
			}

		}

	}

}

.pull-down-header{
	left:0;
}
.pull-down-container{
	overflow: inherit !important;
}

.bottomPop{
	.contents{

		text-align: left;
		max-height: calc(100vh - 20rem);
		overflow-y: scroll;

		& .wrap{
			//display: none;
		}
		& .wrap.servey .servey, & .wrap.class week, & .wrap.classSelect week, & .wrap.classComplete week{
			display: block;
		}
		& .wrap.servey .week, & .wrap.class .servey, & .wrap.classSelect .servey, & .wrap.classComplete .servey {
			display: none;
		}

		& .week.timezone, & .week .complete{
			display: none;
		}
		& .classSelect .week.timezone, & .classComplete .complete{
			display: block;
		}




		.popTitle{
			font-size: 2.3rem;
			font-weight: $bold;
		}
		.complete{
			padding-bottom: 3rem;
			margin-bottom: 1rem;
			font-size: 1.2rem;
			color: #555;
			border-bottom: 2px solid #eee;
		}
		.title{
			margin-top: 2rem;
			font-size: 1.4rem;
		}

		.lecInfo{
			margin: 3rem 0 1rem;
			height: 6rem;
			border-radius: 0 2rem 2rem 2rem;
			background-color: #F7F7F7;
			font-size: 1.4rem;
			line-height: 6rem;
			text-align: center;
		}

		.teacher{
			font-size: 2.3rem;
			&.sub{
				margin: 0.5rem 0 2rem;
				padding-bottom: 3rem;
				font-size: 1.3rem;
				border-bottom: 1px solid #EEE;
			}
		}
		.starWrap{
			text-align: center;
			img{
				margin: 0 .4rem;
				width:4rem;
			}
		}

		.btnWrap{
			.txt{
				font-size: 1.4rem;
				line-height: 1.6;
			}

			.info{
				margin: 3rem 0 1rem;
				font-size: 1.6rem;
			}
			.btn{
				margin-top: 0.5rem;
				background: none;
			}
			.textArea{
				margin-top: -0.1rem;
			}
		}
		&.list .txt{
			padding-left: 4rem;
			text-align: left;
		}
		.class.servey{
			min-height: 40rem;
		}
	}
	.textArea {
		padding-left: 1.5rem;
		text-align: left;
		width: 100%;
		height: 10rem;
	}
}

.videoBnr{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	background: #fff;
	box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.1);
	text-align: center;
	z-index: 20;
	.txtWrap{
		margin: 2rem 0;
		color: #555;
		font-size: 1.3rem;
		font-weight: $regular;
		.title{
			margin-bottom: 1rem;
			color: #111;
			font-size: 1.7rem;
			font-weight: $bold;
		}
	}
	.btn{
		margin: 0;
		border-radius: 0;
	}
}


.tutorialActive{
	z-index : 102 !important;
}

.tutorialMsk{
	.tutorialMskBox{
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 100vh;
	}
	&.msk{
		//height: calc(100% + env(safe-area-inset-top));
		//top :  calc(env(safe-area-inset-top) * -1);
		z-index: 101;
		top: -100vh;
		height: 200vh;
		background: rgba(0, 0, 0, 0.7);
	}
	&.msk.off{
		position: static;
		top: 0;
		height: auto;
		.tutorialMskBox{
			padding-top: 0;
		}
	}
	&.msk.transparent{
		background: rgba(0, 0, 0, 0);
	}
	&.msk.sub{
		top: 53%;
	}

	.tutorialMessage{
		position: relative;
		padding: 1.1rem 2rem;
		background: #9732FC;
		border-radius: 1rem;
		font-size: 1.4rem;
		color: #fff;
		line-height: 1.4;
		z-index: 103;
		&:after, &:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border: 6px solid transparent;
		}
		&.tail_top:after{
			bottom: 100%;
			left: 50%;
			transform: translateX(-50%);
			border-bottom-color: #9732FC;
		}
		&.tail_bottom:after{
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			border-top-color: #9732FC;
		}
		&.tail_right{
			text-align: left;
		}
		&.tail_right:after{
			top: 50%;
			left: 100%;
			transform: translateY(-50%);
			border-left-color: #9732FC;
		}
		&.tail_left:after{
			top: 50%;
			right: 100%;
			transform: translateY(-50%);
			border-right-color: #9732FC;
		}

	}
	.infoSwipeIcon{
		position: relative;
		width: 10rem;
		height: 10rem;
		background: rgba(0,0,0,0.6);
		border-radius: 50%;
		margin-bottom: 1rem;
		&:after{
			content: '';
			position: absolute;
			top: 25%;
			left: 25%;
			width: 6rem;
			height: 5rem;
			background: url($imgURL + '/classroom/info_swipe.svg') no-repeat center/cover;
		}
	}
}
