@import "../common/_mixin";
@import "../common/commonCode.scss";

.teacher{
	//margin: -5rem -2rem 0;
	.header {
		margin: -5rem -2rem 0;
		position: relative;
		z-index:100;
		height: 38rem;
		background: url($imgURL + "/web/t_top_bg.png") no-repeat right top;
		background-size: cover;
		@include desktop {
			height: 540px;
			background: url($imgURL + "/web/t_top_bg.png") no-repeat center top;
		}

		&__top {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			@include desktop {
				background-color: rgba(1, 1, 1, 0.1);
			}

			&-wrapper {
				height: 8rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				@include desktop {
					margin: 0 auto;
					width: 1000px;
				}
			}

			.logo{
				margin-left: 2rem;
				display:inline-block;
				vertical-align:middle;
				width:14.5rem;
				height:2.6rem;
				background:url('https://img.uphone3.com/web/img_logo_white.svg') no-repeat 0 0;
				background-size:100%;

				@include desktop {
					margin-left: 0;
				}
			}

			@include desktop {
				&:hover {
					border-bottom:1px solid #EEE;
					background:#FFF;
					color: #555;
					& .logo {
						background-image:url('https://img.uphone3.com/web/img_logo.svg');
					}
					& .menu {
						color: #111111;
					}
				}
			}

			.menu {
				display: none;
				font-size: 1.6rem;
				color: #ffffff;
				font-weight: $regular;
				li {
					display: inline-block;
					padding: 1rem 2rem;
					opacity: 0.7;
					cursor: pointer;
					&.active {
						opacity: 1;
						font-weight: $medium;
					}
					&:hover {
						color: $purple;
						opacity: 1;
					}
				}
				@include desktop {
					display: block;
				}
			}
		}

		&__content {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			color: #fff;
			padding: 2rem 2rem 0.5rem;
			@include desktop {
				margin: 0 auto;
				width: 1000px;
			}
			h1 {
				font-size: 5rem;
				font-weight: $bold;
				line-height: 1.4;
			}
			h2 {
				margin: 1rem 0 4rem;
				font-size: 1.5rem;
				font-weight: $regular;
				line-height: 1.66;
			}
			h3 {
				word-wrap: break-word;
				font-size: 1rem;
				font-weight: $regular;
				line-height: 1.5;
				opacity: .6;
				&.mobile {
					display: block;
				}
				&.pc {
					display: none;
				}
			}
			@include desktop {
				h1 {
				font-size: 7.2rem;
			}
				h2 {
					margin: 1.9rem 0 9.7rem;
					font-size: 1.8rem;
					line-height: 1.66;
				}
				h3 {
					font-size: 1.2rem;
					&.mobile {
						display: none;
					}
					&.pc {
						display: block;
					}
				}
			}
		}
	}

	.menu-mobile {
		margin: 0 -2rem;
		font-size: 1.3rem;
		color: #555;
		font-weight: $medium;
		border-bottom: 1px solid #999;
		text-align:center;
		li {
			display: inline-block;
			padding: 2rem 2rem;
			opacity: 0.7;
			cursor: pointer;
			&.active {
				opacity: 1;
				font-weight: $bold;
			}
			&:hover {
				color: $purple;
				opacity: 1;
			}
		}
		@include desktop {
			display: none;
		}
	}

	.content{
		.list__wrap{
			margin-top: 4rem;
			width: 100%;
			color: #111;
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: 5rem;
			column-gap: 2rem;
			@include desktop {
				margin: 8rem auto 0;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				row-gap: 5rem;
				column-gap: 10rem;
				padding: 0;
				width: 1000px;
			}
			.item {
				.picture{
					position: relative;
					width: 100%;
					padding-bottom: 100%;
					border-radius: 0 2rem 2rem 2rem;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					.btnPlayer{
						position: absolute;
						right: 1.8rem;
						bottom: 1.8rem;
						cursor: pointer;
					}
				}
				.name {
					margin: 2.1rem 0;
					font-size: 2.2rem;
					font-weight: $bold;
				}
				.row {
					display: flex;
					align-items: start;
					margin-top: 1.2rem;
					overflow: hidden;
					line-height: 1.2;
					.title {
						font-size: 1.3rem;
						line-height: 1.8rem;
						font-weight: $medium;
						color: #999;
					}
					.desc {
						flex: 1;
						padding-left: 1rem;
						font-size: 1.4rem;
						line-height: 1.8rem;
						font-weight: $regular;
						color: #555;
					}
				}
			}
		}
		.pageWrap{
			clear: both;
			text-align: center;
			margin: 8rem 0 5rem;
			span{
				display: inline-block;
				line-height: 5.6rem;
				height: 5.6rem;
				vertical-align: middle;
			}
			.num {
				font-size: 1.6rem;
				font-weight: $regular;
				color: #999;
				margin: 0 0.7rem;
				cursor: pointer;
				&:first-child{
					margin-left: 0;
				}
				&.b{
					font-weight: $bold;
					color: #111;
				}
			}
			.button{
				display: inline-block;
				width: 4rem;
				height: 4rem;
				margin: 0 2rem;
				&.p{
					img {
						transform: rotate(180deg);
					}
				}
				img{
					width: 100%;
					cursor: pointer;
				}
			}
		}
	}
}
