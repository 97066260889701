@import "../common/_mixin";
@import "../common/commonCode.scss";

.personal-popup {
  position: fixed;
  background: #fff;
  z-index: 999;
  padding: 2rem;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 2rem 2rem 0 0;
  max-height: 100%;
  overflow-y: auto;

  @include desktop {
    margin-top: 1.5rem;
  }

  @include desktop {
    padding: 1.5rem 3rem 3rem 3rem;
    left: 50%;
    top: 50%;
    width: 34rem;
    border-radius: 2rem;
    transform: translate(-50%, -50%);
    height: 48rem;
  }

  &__close {
    z-index: 1000;
    cursor: pointer;
    position: sticky;
    top: 0;
    height: 2rem;
    text-align: right;

    img {
      width: 1.98rem;
      height: 1.98rem;
    }

    @include desktop {
      margin-right: -1.5rem;
    }
  }

  &__title {
    margin: 2rem 0 2rem;
    font-size: 3rem;
    line-height: 1.5;
    text-align: center;
    font-weight: $bold;
    letter-spacing: -0.02rem;
    color: #111;
    word-break: keep-all;
    word-wrap: break-word;

    @include desktop {
      margin-top: 3rem;
      font-size: 2.4rem;
    }
  }

  &__card {
    background: linear-gradient(90deg, #9732FC 0%, #9732FC 20%, #FC4C4C 80%, #FC4C4C 100%);
    border-radius: 0 1rem 1rem 1rem;
    padding: 3rem 3rem 2rem;
    color: #fff;
    text-align: center;

    &--top {
      display: flex;
      align-content: center;
      align-self: center;
      margin-bottom: 1.5rem;

      &--week {
        font-size: 4rem;
        font-weight: $bold;
        flex: 1;
        text-align: left;

        span {
          font-size: 1.5rem;
          margin-left: 0.2rem;

          @include desktop {
            font-size: 1.4rem;
          }
        }

        @include desktop {
          font-size: 3.8rem;
        }
      }

      ul {
        width: 16rem;
        display: flex;
        justify-content: space-between;

        @include desktop {
          width: 14.4rem;
        }
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 1rem;
        padding: 0.2rem;
        width: 5rem;
        height: 5rem;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.2;

        @include desktop {
          width: 4.4rem;
          height: 4.4rem;
        }

        &:first-child {
          color: #DC4484;
        }

        &:nth-child(2) {
          color: #FA4C51;
        }

        &:last-child {
          color: #F64A55;
          font-size: 1rem;
        }
      }
    }

    &--desc {
      border-top: 0.1rem rgba(255, 255, 255, 0.2) solid;
      padding-top: 1.5rem;
      font-size: 1.7rem;
      line-height: 1.5;

      @include desktop {
        font-size: 1.4rem;
      }

      b {
        font-weight: $bold;
      }
    }
  }

  &__button {
    color: #9732FC;
    font-size: 1.7rem;
    font-weight: $medium;
    text-align: center;
    cursor: pointer;
    padding: 2rem;
    margin: 3rem 0;
    width: 100%;
    border-radius: 0 1rem 1rem 1rem;
    border: 0.1rem solid #ccc;
    border-color: #9732fc;
    border-width: 0.15rem;
    box-shadow: 0 0.15rem 0.3rem rgba(0, 0, 0, .16);

    @include desktop {
      font-size: 1.6rem;
    }
  }
}

.personal-popup-dimmed {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 980;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
}