@import "../../common/commonCode.scss";
@import "../../common/_mixin";

.apply-form {
	@include desktop {
		margin: -5rem -2rem 0;
	}

	&__wrapper {
		margin: -5rem -2rem 0;
		height:calc(var(--vh, 1vh) * 100);
		width: 100vw;
		padding: 0;
		@include desktop {
			margin: 0;
			width: 100%;
			height: auto;
		}
	}

	&-head {
		position: relative;
		background: url($imgURL + '/web/trial/reserve/mo_bg_trial_2024@2x.png') no-repeat 0 -4rem;
		background-size: 100% auto;
		height: 100vh;
		color: #111111;
		text-align: center;

		@include desktop {
			width: 100%;
			background: url($imgURL + '/web/trial/reserve/pc_bg_trial_2024@2x.png') no-repeat 50% 0;
			background-size: cover;
			height: 31rem;
			overflow: hidden;
			z-index: 10;
		}

		@media (min-width: 1400px) {
			background: url($imgURL + '/web/trial/reserve/pc_bg_trial_2024@2x.png') no-repeat 50% 0;
			background-size: cover;
		}

		@media (min-width: 1921px) {
			background: url($imgURL + '/web/trial/reserve/pc_bg_trial_2024@2x.png') no-repeat 50% 50%;
			background-size: cover;
		}

		&.junior {
			background: none;
			background-color: #0A236A;
      color: #ffffff;

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 24.75rem;
				background: url($imgURL + '/freelesson/mo_bg_freelesson_junior.png') no-repeat 15% 0;
				background-size: contain;

				@include desktop {
					width: 100%;
					height: 200px;
					background: url($imgURL + '/freelesson/pc_bg_freelesson_junior.png') no-repeat 50% 0;
					background-size: auto;
				}
			}
		}

		&__inner {
			position: relative;
			@include desktop {
				width: 400px;
        min-height: 700px;
				margin: 0 auto;
			}
		}

		.lnk-home {
			display: block;
			position: absolute;
			left: 2rem;
			top: 2.5rem;
			width: 10rem;
			@include desktop {
				left: 0.5rem;
				top: 3rem;
				width: 10rem;
			}
		}

		&__title {
			font-size: 2.6rem;
			font-weight: $bold;
      padding: 7.7rem 0 1.5rem 0;
			@include desktop {
				font-size: 3.6rem;
        padding: 10rem 0 1.5rem 0;
			}
		}
		&__desc {
      width: 27.7rem;
      margin: 0 auto;
			color: #555555;
			font-size: 1.4rem;
			line-height: 1.3;
			font-weight: $regular;
      &.junior {
        color: #ffffff;
        opacity: .7;
      }
			@include desktop {
        width: 308px;
				margin-top: 0.8rem;
			}
		}
    
    &__review{
      .review-content{
        display: -webkit-box;
        -webkit-line-clamp: 3; 
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        height: 6.4rem;
        line-height: 1.57;
        @include desktop {
          width: 300px;
          height: 65px;
          line-height: 1.56;
        }
      }
      .review-info{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0 0 0;
        opacity: .7;
      }
      .member-icon{
        width: 1.05rem;
        height: .9rem;
        margin: 0 .4rem 0 0;
        background: url($imgURL + '/web/trial/reserve/ico_review_member_gray.svg') no-repeat center/cover;
        &.junior {
          background: url($imgURL + '/trial/reserve/ico_report_member.svg') no-repeat center/cover;
        }
      }
      .review-tab{
        width:.1rem ;
        height:.75rem;
        margin: 0 1rem;
        background-color: rgba(85,85,85,.7);
      }
    }
    
		&__contents {
			padding: 2.5rem 3rem;
			display: flex;
			justify-content: space-between;
			@include desktop {
				padding: 4rem 2rem;
			}
		}

		.step-item {
			position: relative;
			width: 8.5rem;
			height: 8rem;
			text-align: center;
			@include desktop {
				width: 10rem;
				height: 9rem;
			}
			&__icon {
				width: 3rem;
				height: 3rem;
				margin: auto;
				img {
					width: 3rem;
					height: 3rem;
					max-width:3rem;
					max-height:3rem;
				}

				@include desktop {
					width: 3.5rem;
					height: 3.5rem;
					img {
						width: 3.5rem;
						height: 3.5rem;
						max-width: 3.5rem;
						max-height: 3.5rem;
					}
				}
			}
			&__description {
				position: absolute;
				width: 8.5rem;
				bottom: 0;
				left: 0;
				font-size: 1.3rem;
				line-height: 1.8rem;
				opacity: 0.6;
				@include desktop {
					font-size: 1.5rem;
					width: 10rem;
				}
			}
		}
	}

	&-body {
    z-index: 1;
		width:100%;
		max-width:100%;
		top: 25.3rem;
		height:calc(var(--vh, 1vh) * 100 - 5rem);
		padding: 2rem 2rem 6rem 2rem;
		background: #fff;
		border-radius: 2rem 2rem 0 0;
		position: fixed;
		width: 100%;
		overflow-x:hidden;
		overflow-y:scroll;
		overscroll-behavior-y: none;
		@include desktop {
			position: static;
			height: auto;
			overflow-y:hidden;
		}
    
    .lottieLoading{
      transform: translateY(-10rem);
    };
    
		&__inner {
			@include desktop {
				width: 400px;
				margin: 0 auto;
			}
		}
	}

	.titleBox {
		position: relative;
		padding-top: 4rem;
		padding-bottom: 1.5rem;
		&.slim {
			margin: 1rem;
		}
		&__title {
			font-size: 1.8rem;
			color: #111;
			font-weight: $bold;
			&--mem {
				padding-bottom: .4rem;
			}
		}
    
    &__link {
      position: absolute;
			bottom: 1.5rem;
			right: 0;
      margin-right: 0.2rem;
      font-size: 1.3rem;
      color: #999;
      text-align: right;
      
      span {
        display: inline-block;
        border-bottom: 1px solid #999;
        color: #555555;
        margin-top: 0.5rem;
      }
      img {
        width: 0.6rem;
      }
    }
    
		&__button {
			position: absolute;
			bottom: 1.5rem;
			right: 0;
			line-height: 1.8rem;
			text-align: right;
			font-size: 1.4rem;
			color: #000;
			&:after {
				content: '';
				display: inline-block;
				width: 0.6rem;
				height: 1rem;
				background: url($imgURL + ("/mypage/arrow_right_gray.svg")) no-repeat center/auto 1.4rem;
				margin-left: 0.4rem;
			}
		}
		&__close {
			position: absolute;
			bottom: 0;
			right: 0;
			line-height: 1;
			text-align: right;
			font-size: 1.4rem;
			color: #000;
			&:after {
				content: '';
				display: inline-block;
				width: 1.75rem;
				height: 1.75rem;
				background: url($imgURL + '/order/ico_up.svg') no-repeat 50% 50%;
				background-size: contain;
				margin-left: 0.4rem;
			}
		}
		&__open {
			position: absolute;
			bottom: 0;
			right: 0;
			line-height: 1;
			text-align: right;
			font-size: 1.4rem;
			color: #000;
			&:after {
				content: '';
				display: inline-block;
				width: 1.75rem;
				height: 1.75rem;
				background: url($imgURL + '/order/ico_up.svg') no-repeat 50% 50%;
				background-size: contain;
				transform: rotate(180deg);
				margin-left: 0.4rem;
			}
		}
	}

	.lessonMode {
		margin-top: 1rem;
		&__list {
			display: flex;
			justify-content: space-between;
			margin-bottom: 2rem;
		}
		&__item {
			flex:1;
			padding: 2.1rem;
			border: .1rem solid #ccc;
			border-radius: 1rem;
			margin-right: 1rem;
			font-size: 1.7rem;
			font-weight: $medium;
			color: #111;
			text-align: center;
			cursor: pointer;

			&:last-child{
				margin-right: 0;
			}
			&--active {
				border-width: 0.15rem;
				border-color: $purple;
				color: #111;
				font-weight: $medium;
				box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);
			}
		}
		&__description {
			margin-top: 1.3rem;
			color: #999;
			height: 1.5rem;
			font-size: 1.3rem;
		}
	}

	.levelCode {
		margin-top: 1rem;
		&__item {
			display: flex;
			margin-bottom: 1.5rem;
			width: 100%;
			padding: 1.5rem 2rem;
			border: .1rem solid #ccc;
			border-radius: 1rem;
			margin-bottom: 1rem;
			font-weight: $regular;
			color: #111;
			text-align: left;
			line-height: 2rem;
			cursor: pointer;

			&:last-child{
				margin-bottom: 0;
			}
			&--name {
				font-size: 1.7rem;
				font-weight: $medium;
				width: 5.5rem;
				&--wrap {
					display: inline-block;
					position: relative;
					.bul:after {
						content: '';
						display: inline-block;
						position: absolute;
						right:  -0.4rem;
						top: -0.2rem;
						width: .4rem;
						height: .4rem;
						border-radius: 50%;
						background: #FC4C4C;
					}
				}
			}
			&--desc {
				flex: 1;
				font-size: 1.55rem;
				color: #333;
				&__extra {
					display: list-item;
					list-style-type: "· ";
					margin: 0.5rem 0 0 0.5rem;
					font-size: 1.25rem;
					line-height: 2rem;
					color: #999;
					word-break: keep-all;
				}
			}
			&--minute {
				font-size: 1.4rem;
				text-align: right;
				width: 3.5rem;
				color: #aaaaaa;
			}

			&--active {
				border-width: 0.15rem;
				border-color: $purple;
				box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);
			}
		}
	}

	.topic {
		margin-top: 1rem;
		&__item {
			position: relative;
			display: flex;
			width: 100%;
			height: 7rem;
			vertical-align: middle;
			margin-bottom: 1.5rem;
			border: none;
			box-sizing: border-box;
			font-weight: $regular;
			color: #111;
			text-align: left;
			line-height: 2rem;
			cursor: pointer;

			&:last-child{
				margin-bottom: 0;
			}
			&--name {
				display: inline-block;
				border-top: 0.1rem solid #ccc;
				border-left: 0.1rem solid #ccc;
				border-bottom: 0.1rem solid #ccc;
				border-radius:  1rem 0 0 1rem;
				padding: 2rem 1.8rem;
				flex: 1;
				font-size: 1.7rem;
				line-height: 3rem;
				color: #333;
				align-self: center;
				font-weight: $medium;
				height: 7rem;
			}
			&--image {
				display: inline-block;
				font-size: 1.6rem;
				text-align: right;
				color: #aaaaaa;
				width: 10.5rem;
				height: 7rem;
				border-radius:  0 1rem 1rem 0;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				border: .15rem solid transparent;
				border-radius: 1rem;
				box-sizing: border-box;
			}
			&--active {
				&:after {
					border: 0.15rem solid $purple;
					box-shadow: 0 0.15rem 0.3rem rgba(0, 0, 0, .16);
				}
			}
		}
	}

	.request-time {
		width: 100%;
		background: #F4F4F4 url($imgURL + '/freelesson/select_arrow_bottom.svg') no-repeat right 1.5rem top 52%;
		background-size: 1rem auto;

		font-size: 1.5rem;
		border-radius: 1rem;
		font-weight: $regular;
		height: 5rem;
		line-height: 5rem;
		
		padding-left: 1.5rem;

		&__unselect {
			color: #999999;
		}

		&__selected {
			color: #111111;
		}
	}
	.requestTime__choose {
		width: 100%;
		background: #333;
		color: #fff;
		font-size: 1.5rem;
		border-radius: 1rem;
		font-weight: $regular;
		height: 5rem;
		line-height: 5rem;
		text-align: center;
		cursor: pointer;
	}

	.requestTime__item {
		position: relative;
		width: 100%;
		padding: 2.2rem 0;
		font-size: 2.2rem;
		line-height: 2.2rem;
		font-weight: $bold;
		border: 0.15rem solid $purple;
		border-radius: 1rem;
		box-shadow: 0 8px 8px rgba(0, 0, 0, 0.02);
		color: #111;
		text-align: center;
		display: inline-block;
		span {
			&:before {
				content: "";
				width: 2rem;
				height: 2rem;
				display: inline-block;
				margin-right: 0.8rem;
				background-image: url(https://img.uphone3.com/icon/check_on_lt.svg);
				background-repeat: no-repeat;
				background-size: 2rem 2rem;
			}
		}

	}

	.memberInfo{
		font-size: 1.2rem;
		color: #999;
	}

	.gap{
		height: .8rem;
		margin: 4rem -2rem 3rem;
		background-color: #FAFAFA;
		@include desktop{
			margin: 4rem 0;
			height: 1px;
			background-color: #EEEEEE;
		}
	}

	.content{
		.listTitle{
			margin-top: 3.5rem;
			font-size: 1.8rem;
			color: #111;
			font-weight: $bold;
			line-height: 2.7rem;
			@include desktop{
				margin-top: 5rem;
				font-size: 2rem;
				font-weight: 500;
			}

			&--info {
				padding-bottom: .4rem;
				@include desktop{
					padding-bottom: 1rem;
				}
			}


		}
		.listDesc {
			font-size: 1.3rem;
			color: #555;
			margin: 0.2rem 0 1.45rem;
			line-height: 1.95rem;
			letter-spacing: -0.01rem;
			@include desktop{
				margin: 0.4rem 0 2rem;
				font-size: 1.4rem;
				line-height: 2rem;
			}
		}

		.select{
			margin: 1.5rem 0 0;
			button{
				display: block;
				position: relative;
				width: 100%;
				padding: 1.825rem 1.8rem 1.825rem 6.8rem;
				background: #fff;
				border: .1rem solid #ccc;
				border-radius: 1rem;
				margin-bottom: 1rem;
				font-size: 1.5rem;
				font-weight: $regular;
				color: #555;
				line-height: 2.25rem;
				text-align: left;
				cursor: pointer;

				@include desktop{
					padding: 1.9rem 1.9rem 1.9rem 6.9rem;
					font-size: 1.6rem;
					line-height: 2.4rem;
				}

				&:last-child{
					margin-bottom: 0;
				}

				strong {
					position: absolute;
					left: 1.85rem;
					top: 1.825rem;
					font-weight: inherit;
					line-height: inherit;
					@include desktop{
						top: 1.9rem;
					}
				}

				&.on{
					border-width: 0.15rem;
					padding-left: 6.75rem;
					border-color: $purple;
					color: #111;
					font-weight: $medium;
					box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);

					&[disabled] {
						border-color: #555;
					}

					@include desktop{
						padding-left: 6.9rem;
						border-width: 0.1rem;
						line-height: 2.4rem;
						box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
					}

					strong {
						left: 1.8rem;
						font-weight: $medium;
						line-height: 2.15rem;
						@include desktop{
							left: 1.85rem;
							top: 2.05rem;
						}
					}
				}
			}
			&.edit{
				button {
					cursor: inherit;
					-webkit-tap-highlight-color:rgba(0,0,0,0);
					&.on {
						background: #f4f4f4;
						border-color: #555;
					}
				}
			}
		}

		.labelcheckAll{
			border-bottom: 1px solid #eee;
			padding-bottom: 1rem;
			margin:3rem 0 1rem;
			@include desktop{
				margin-top: 5rem;
			}
			span{
				color: #111111;
				font-size: 1.8rem;
				font-weight: $bold;

				@include desktop{
					font-size: 2rem;
					font-weight: $medium;
				}
			}
		}

		.labelWrap{

			label{
				margin: 0;

				@include desktop{
					cursor: pointer;
				}
				span{
					font-size: 1.3rem;
					font-weight: $regular;
					letter-spacing: -.12rem;
					color: #555;
					@include desktop{
						font-size: 14px;
						color: #999;
					}
				}
			}
			.info{
				font-size: 1.3rem;
				@include desktop{
					cursor: pointer;
					font-size: 14px;
				}
			}
		}
	}

	.btn.typeB{
		margin: 5.2rem auto 2rem;

		&:not(.disable) {
			cursor: pointer;
		}

		@include desktop{
			position: relative;
			margin: 7rem 0 0;
			border-radius: 0 .8rem .8rem .8rem;
		}
	}

	.memberInput{
		position: relative;
		margin: 1rem 0;
		label{
			position: absolute;
			top: .2rem;
			left: 1.5rem;
			color: #999;
			transition: all .15s;
			@include desktop{
				top: 1px;
				font-size: 16px;
				font-weight: $regular;
			}
		}
		input{
			padding: 0 1.5rem 0;

			&[name="appDate"]:read-only{
				background: #f4f4f4;
			}

			&:read-only,
			&[disabled] {
				background: #e2e2e2;
				color: #afafaf;
				-webkit-text-fill-color:#afafaf;
				-webkit-opacity:1;
			}

			@include desktop{
				height: 50px;
			}
		}
		.timer{
			position: absolute;
			top: 1.9rem;
			right: 1.5rem;
			font-size: 1.2rem;
			color: #FC4C4C;
		}
		&.t2{

			input{
				width: 63.58%;

				&.input--tel[disabled] {
					width: 100%;
				}
				@include desktop{
					width: 64%;

				}
			}
			.inputBtn{
				position: absolute;
				top: 0;
				right: 0;
				width: 34.92%;
				margin: 0;
				background: #555;
				letter-spacing: -0.01rem;
				@include desktop{
					width: 33.5%;
				}
			}
		}
		&.v label, input:focus~label{
			display: none;
		}
	}
	.telChangeMap {
		color: #999;
		font-size: 1.2rem;

		@include desktop{
			font-size: 1.3rem;
		}
	}
}

.titleBox {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 1.5rem;
  &.slim {
    margin: 1rem;
  }
  &__title {
    font-size: 1.8rem;
    color: #111;
    font-weight: $bold;
    text-align: left;
    &--mem {
      padding-bottom: .4rem;
    }
  }
  
  &__link {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    margin-right: 0.2rem;
    font-size: 1.3rem;
    color: #999;
    text-align: right;
    
    span {
      display: inline-block;
      border-bottom: 1px solid #999;
      color: #555555;
      margin-top: 0.5rem;
    }
    img {
      width: 0.6rem;
    }
    
  }
  
  &__button {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    line-height: 2rem;
    text-align: right;
    font-size: 1.4rem;
    color: #000;
    &:after {
      content: '';
      display: inline-block;
      width: 0.6rem;
      height: 1rem;
      background: url($imgURL + ("/mypage/arrow_right_gray.svg")) no-repeat center/auto 1.4rem;
      margin-left: 0.4rem;
    }
  }
  &__close {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    text-align: right;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #000;
    &:after {
      content: '';
      display: inline-block;
      width: 1.8rem;
      height: 1.8rem;
      background: url($imgURL + '/order/ico_up.svg') no-repeat 50% 100%;
      background-size: contain;
      margin-left: 1rem;
    }
  }
  &__open {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    line-height: 1;
    text-align: right;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #000;
    &:after {
      content: '';
      display: inline-block;
      width: 1.8rem;
      height: 1.8rem;
      background: url($imgURL + '/order/ico_up.svg') no-repeat 50% 0;
      background-size: contain;
      transform: rotate(180deg);
      margin-left: 1rem;
    }
  }
}

.lessonMode {
  margin-top: 1rem;
  &__list {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    flex:1;
    padding: 1.5rem;
    border: .1rem solid #ccc;
    border-radius: 1rem;
    margin-right: 1rem;

    text-align: center;
    p{
      font-size: 1.7rem;
      font-weight: $medium;
      color: #111111;
      line-height: 1.6;
    }
    span{
      font-size: 1.3rem;
      color: #999999;
      line-height: 1.6;
    }
    
    &:last-child{
      margin-right: 0;
    }
    &--active {
      border-width: 0.15rem;
      border-color: $purple;
      color: #111;
      font-weight: $medium;
      box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);
    }
  }
  &__description {
    margin-top: 1.3rem;
    color: #999;
    height: 1.5rem;
    font-size: 1.3rem;
  }
}

.freelessonGuide {
	position: relative;
	background-color: #F7F7F7;
	border-radius: 2rem;
	height: 35.3rem;
	@include desktop {
		height: 42rem;
	}
	&__slide {
		width: 100%;
		height: 35.3rem;
		background-size: cover;
		background-position: center bottom;
		background-repeat: no-repeat;
    margin-left: -26%;
		@include desktop {
			height: 42rem;
		}
	}
	&__number {
		position: absolute;
		top: 2rem;
		left: 2rem;
		display: inline-block;
		width: 2.4rem;
		height: 2.4rem;
		line-height: 2.4rem;
		text-align: center;
		border-radius: 50%;
		background-color: #111111;
		color: #fff;
		font-size: 1.2rem;
		@include desktop {
			top: 2.5rem;
			left: 2.5rem;
			width: 3rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1.5rem;
		}
	}
	&__message {
		position: absolute;
		top: 2.6rem;
		left: 0;
		text-align: center;
		width: 100%;
		font-size: 1.7rem;
		letter-spacing: -0.05rem;
		line-height: 1.3;
		color: #111111;
		b {
			font-weight: $bold;
			color: #111111 !important;
		}
		@include desktop {
			font-size: 1.8rem;
			top: 4rem;
		}
	}
	.swiper-wrapper {
		.swiper-slide {
			opacity: 0.1;
		}
		.swiper-slide-active {
			opacity: 1;
		}
    
    .swiper-slide-active .freelessonGuide__slide {
      margin: 0;
    }
    
    .swiper-slide-prev{
      .freelessonGuide__slide {
        margin-left: 26%;
      }
    }
	}

	.swiper-container {
		&.swiper-container-horizontal > .swiper-pagination-bullets {
			top: 8.7rem;
			left: 50%;
			transform: translateX(-50%);
			@include desktop {
				top: 11rem;
			}

			.swiper-pagination-bullet {
				width: .6rem;
				height: .6rem;
				margin: 0 0.35rem 0 0;
				background: #000;
				opacity: 0.1;

				@include desktop {
					width: .8rem;
					height: .8rem;
				}

				&.swiper-pagination-bullet-active {
					background: #000;
					opacity: 0.5;
				}
			}
		}

		&.pagination-none {
			height: 9.15rem;
			padding-bottom: 0;

			.swiper-pagination-bullets {
				display: none;
			}
		}
	}
}

.lessonBook{
  margin-top: 1rem;
  &__item{
    margin-bottom: 1.5rem;
    width: 100%;
    padding: 2.5rem;
    border: .1rem solid #ccc;
    border-radius: 1rem;
    margin-bottom: 1rem;
    font-weight: $regular;
    color: #111;
    text-align: left;
    line-height: 2rem;
    &:last-child{
      margin-bottom: 0;
    }
    
    &--active {
      border-width: 0.15rem;
      border-color: $purple;
      box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);
    }
  }
  .course{
    font-size: 1.6rem;
    margin-bottom: .4rem;
  }
  .subject{
    font-size: 1.8rem;
    font-weight: $bold;
  }
}

.levelCode {
  margin-top: 1rem;
  &__item {
    display: flex;
    margin-bottom: 1.5rem;
    width: 100%;
    padding: 1.5rem 2rem;
    border: .1rem solid #ccc;
    border-radius: 1rem;
    margin-bottom: 1rem;
    font-weight: $regular;
    color: #111;
    text-align: left;
    line-height: 2rem;
    &:last-child{
      margin-bottom: 0;
    }
    &--name {
      font-size: 1.7rem;
      font-weight: $medium;
      width: 5.5rem;
      &--wrap {
        display: inline-block;
        position: relative;
        .bul:after {
          content: '';
          display: inline-block;
          position: absolute;
          right:  -0.4rem;
          top: -0.2rem;
          width: .4rem;
          height: .4rem;
          border-radius: 50%;
          background: #FC4C4C;
        }
      }
    }
    &--desc {
      flex: 1;
      font-size: 1.55rem;
      color: #333;
      &__extra {
        display: list-item;
        list-style-type: "· ";
        margin: 0.5rem 0 0 0.5rem;
        font-size: 1.25rem;
        line-height: 2rem;
        color: #999;
        word-break: keep-all;
      }
    }
    &--minute {
      font-size: 1.4rem;
      text-align: right;
      width: 3.5rem;
      color: #aaaaaa;
    }

    &--active {
      border-width: 0.15rem;
      border-color: $purple;
      box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);
    }
  }
}


// 수업 일시

.recommend-time-list {
  display: flex;
  flex-wrap: wrap;
  gap:10px;
}

.recommend-time-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: calc(50% - 5px);
    height: 6rem;
    background-color: #fff;
    border: 0.1rem solid #ccc;
    border-radius: 1rem;
    text-align: center;
    font-weight: $regular;
    font-size: 1.55rem;
    
    span {
      margin-left: 0.3rem;
      font-size: 1.7rem;
      font-weight: $medium;
    }
  
    &__lottie {
      width: 2.6rem;
      height: 2.6rem;
      margin-right: 1rem;
    }
    
    &__txt {
      img {
        position: relative;
        top:0.2rem;
      }
    }
    
     // 추천 타임 1개
    &.one-times { 
      flex-basis: 100%;
      font-size: 1.75rem;
    }
 
    &.selected, &.popup-selected {
      border: 0.15rem solid #9732FC;
    	box-shadow: 0 0.15rem 0.3rem rgba(0, 0, 0, .16);
    }
    
    &.no-slot, &.loading, &.unselected {
      font-size: 1.5rem;
      font-weight: $regular;
      line-height: 1.6;
      background-color: #F4F4F4;
      color: #999999;
      border-radius: 1rem;
      border:none;
      
      img {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.5rem;
      }
    }
    
    &.no-slot {
      height: 8.2rem;
    }
    
};

.recommend-time-more{
  width: 100%;
  height: 4.5rem;
  margin-top: 1rem;
  padding: 1.3rem 0 1.35rem;
  text-align: center;
  vertical-align: middle;
  border: 0.1rem solid #ccc;
  border-radius: 1rem;
  font-size: 1.3rem;
  line-height: 1.42;
  color: #555555;
  background-color: #fff;
  cursor: pointer;
  
  img {
    width: 0.65rem;
    height: 1rem;
    margin-left: 0.4rem;
  }
}

.recommend-time-description{
  margin-top: 1.3rem;
  color: #999;
  height: 1.5rem;
  font-size: 1.3rem;
}

.reserve-button {
  width: 100%;
  height: 5.6rem;
  margin: 5.2rem auto 2rem;
  color: #ffffff;
  background: $gra1;
  border: .1rem solid;
  border-radius: 0 1.0rem 1.0rem 1.0rem;
  font-size: 1.7rem;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  position: relative;
  
  &.disabled{
    background: #555555;
    border-color: $btnDisableColor;
    color: #999999;
    cursor: default;
  }
}

// 모든 시간 보기 팝업

.bottomPop.freeSlotPopup {
	transition: .3s;
	padding: 0 2rem;
	background-color: #F9F9F9;
	z-index: 910;
  display: block;
  overflow: hidden;  
  height: calc(100vh - 15rem);
  
	@include desktop {
		width: 44rem;
		bottom: calc(50vh - 30rem);
		height: 57.4rem;
		border-radius: 2rem;
    overflow: hidden;
    
    @media (max-height: 768px) {
      height: 90vh;
      top:5%;
      bottom: 5%;
    }
	}

	.clsBtn {
		width: 2.4rem;
		height: 2.4rem;
		//background-color: #eee;
	}

	.topWrap {
		height: 5rem;

	}
	.scroll {
    max-height: 100%;
    height: 100%;
		overflow: hidden;
		overflow-y: auto;
		@include desktop {
			height: calc(100vh - 20rem);
        
      @media (max-height: 768px) {
        height: 100%;
      }
		}
	}

	.contents {
    height: 100%;
		margin: 0 -2rem;
		max-height: calc(100vh - 20rem);

		@include desktop {
			border-radius: 0 0 2rem 2rem;
			max-height: unset;
		}

		color: #111111;
		overflow: hidden;

		position: relative;

		&.c {
			text-align: center;
		}

		.recmTitle {
			font-size: 1.8rem;
			font-weight: $bold;
		}

		.recmTxt01 {
			margin: 1rem 0 2rem;
			color: #999999;
			font-size: 1.4rem;
		}

		.recmWrap {

			margin: 0 2rem;

			.recmDate {
				position: relative;
				width: 33.5rem;
				height: 8.6rem;
				line-height: 8.6rem;
				text-align: center;
				font-size: 3.4rem;
				font-weight: $bold;
				background-color: #fff;
				border: 1px solid #eeeeee;
				border-radius: 1rem;
				box-shadow: 0 8px 8px rgba(0, 0, 0, 0.02);

				&.on {
					color: $purple;
					border: 1px solid $purple;
				}

				&-time {
          flex-basis: calc(50% - 5px);
					height: 5.5rem;
					line-height: 5.5rem;
          font-weight: $regular;
          font-size: 1.55rem;
          
          span {
            margin-left: 0.3rem;
            font-size: 1.7rem;
            font-weight: $medium;
          }
				}

				&.disabled {
					color: #CCCCCC;
					text-decoration-line: line-through;
					-webkit-text-decoration-line: line-through;
				}
			}

			.recommend-time-box {
				margin-top: 0.5rem;
				display: flex;
        gap: 5px;
        flex-wrap: wrap;
			}
		}

		.allWrap {
			margin: 4rem 0 0;
			padding: 4rem 2rem 0;
			border-top: 1px solid #EEEEEE;

			.see-more-time {
				&__hidden {
					display: none;
				}

				& > button {
					// * Button CSS를 리셋
					border: none;
					margin: 0;
					padding: 0;
					width: auto;
					overflow: visible;

					background: transparent;

    			/* inherit font & color from ancestor */
					color: inherit;
					font: inherit;

    			/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
					line-height: normal;

    			/* Corrects font smoothing for webkit */
					-webkit-font-smoothing: inherit;
					-moz-osx-font-smoothing: inherit;

    			/* Corrects inability to style clickable `input` types in iOS */
					-webkit-appearance: none;
				}

				&__click-button {
					font-size: 1.3rem;
					font-weight: $regular;
					color: #555;
					cursor: pointer;

					& > img {
						margin-left: 0.3rem;
						width: 1rem;
						height: 0.65rem;
					}
				}
			}
		}

		.dateWrap {
			&__hidden {
				display: none;
			}

			margin-bottom: 2.5rem;
			padding-bottom: 1.5rem;
			border-bottom: 1px solid #eee;

			.date {
				margin-bottom: 1.5rem;
				color: $purple;
				font-size: 1.1rem;
				font-weight: $medium;
				letter-spacing: 0;
			}

			ul {
				li {
					display: inline-block;
					width: 15.65%;
					height: 3.25rem;
					margin: 0 1.2% 1rem 0;
					font-size: 1.3rem;
					font-weight: $medium;
					color: #555;
					text-align: center;
					background-color: #fff;
					border-radius: .5rem;

					&.time__hidden {
						display: none;
					}

					&:nth-child(6n) {
						margin-right: 0;
					}

					span {
						display: table;
						width: 100%;
						height: 100%;

						span {
							display: table-cell;
							vertical-align: middle;
						}
					}

					&.disable {
						color: #CCCCCC;
						font-weight: $regular;
						span > span {
							text-decoration-line: line-through;
							-webkit-text-decoration-line: line-through;
						}
					}

					&.on {
						color: $purple;
						border: 1px solid $purple;
					}
				}
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}
}

.mask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 901;
	display: none;
	&.on {
		display: block;
	}
}

// userInfo
.memberInput{
  position: relative;
  margin: 1rem 0;
  label{
    position: absolute;
    top: .2rem;
    left: 1.5rem;
    color: #999;
    transition: all .15s;
    @include desktop{
      top: 1px;
      font-size: 16px;
      font-weight: $regular;
    }
  }
  input{
    padding: 0 1.5rem 0;

    &[name="appDate"]:read-only{
      background: #f4f4f4;
    }

    &:read-only,
    &[disabled] {
      background: #e2e2e2;
      color: #afafaf;
      -webkit-text-fill-color:#afafaf;
      -webkit-opacity:1;
    }

    @include desktop{
      height: 50px;
    }
  }
  .timer{
    position: absolute;
    top: 1.9rem;
    right: 1.5rem;
    font-size: 1.2rem;
    color: #FC4C4C;
  }
  &.t2{

    input{
      width: 63.58%;

      &.input--tel[disabled] {
        width: 100%;
      }
      @include desktop{
        width: 64%;

      }
    }
    .inputBtn{
      position: absolute;
      top: 0;
      right: 0;
      width: 34.92%;
      margin: 0;
      background: #555;
      letter-spacing: -0.01rem;
      @include desktop{
        width: 33.5%;
      }
    }
  }
  &.v label, input:focus~label{
    display: none;
  }
}

.telChangeMap {
  color: #999;
  font-size: 1.2rem;

  @include desktop{
    font-size: 1.3rem;
  }
}

.trial-book-popup{
  position: fixed;
  inset: 0 0 0 0;
	z-index: 10;
	&__close {
		position: fixed;
		top: 2rem;
		right: 2rem;
		width: 4rem;
		height: 4rem;
		z-index: 3;
		@include desktop {
			left: calc(50% + 19rem);
			top: calc(10vh + 2rem);
		}
	}
	&__inner {
		border-radius: 3rem;
		position: fixed;
		overflow: hidden;
		z-index: 2;
		left: 0;
		top: 0;
    width: 100%;
		@include desktop {
			left: calc(50% - 25rem);
			top: 10vh;
      width: 500px;
		}
	}
	&__scroll {
		overflow-y: scroll;
		height: 100vh;
		@include desktop {
			height: 80vh;
			left: calc(50% - 25rem);
			top: 10vh;
		}
	}
	&__dim {
		position: fixed;
		inset: 0 0 0 0;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 1;
	}
  
  .closing{
    .trialbenefit{
      width: 100%;
      white-space: nowrap;
    }
  }
}
