@import "./variables";


@mixin bcmMobile {
  @media (min-width: 0px) and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin landscape {
  @media all and (orientation:landscape) {
    @content;
  }
}

@mixin maxHeight768 {
  @media (max-height: 768px) {
    @content;
  }
}

@mixin IE {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin Edge {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}
