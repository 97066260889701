@import "../common/_mixin";
@import "../common/commonCode.scss";

/* dimmed alert layer popup */
.dimmed {
	display: block;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	transform:translateZ(999px);
	width: 100%;
	height: 100%;
	text-align: center;
	background: rgba(0, 0, 0, 0.7);
	&--low {
		z-index: 980;
	}
	.alertBox {
		line-height: 1.47;
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		//height: 20.5rem;
		min-height: 16.5rem;
		//height: auto;
		background: #fff;
		border-radius: 0 2.0rem 2.0rem 2.0rem;
		z-index: 10;
		transform:translateZ(10px);
		position: relative;
		text-align: left;
		padding: 1.8rem 0 5.8rem;
		max-width: 31.5rem;
		.tit {
			font-size: 1.7rem;
			font-weight: 500;
			letter-spacing: -0.02rem;
			color: #111;
			margin: 0 0 1.7rem 0;
			padding: 0 2rem;
			word-break: keep-all;
			word-wrap: break-word;
		}
		.desc {
			font-size: 1.3rem;
			letter-spacing: -0.02rem;
			color: #555;
			line-height: 1.65;
			margin: 0 0 1.1rem 0;
			padding: 0 2rem;
			word-break: keep-all;
			word-wrap: break-word;
			span {
				color: $mainColor02;
				display: block;
			}
			.red2 {
				font-size: 1.6rem;
			}
			.gray {
				color: #ccc;
				font-size: 1.2rem;
			}
			.ticket-replace-description{
				font-size: 1.4rem;
				font-weight: $regular;
				color: #999999;
				padding-bottom: 1.5rem;
				@include mobile{
					font-size: 1.3rem;
					padding-bottom: 2rem;
				}
			}
		}
		.chkWrap {
			margin: 0 0 .4rem 0;
			padding: 0 2rem;
			label {
				display: inline-block;
				margin: 0.5rem 0 0;
			}
			span {
				padding-top: 0.7rem;
				padding-bottom: 0.8rem;
				padding-left: 2.5rem;
				letter-spacing: -0.02rem;
				font-size: 1.3rem;
				line-height: 1.6;
				word-break: keep-all;
				@include desktop {
					font-size: 1.4rem;
				}
			}
			&.gray {
				span {
					color: #999;
				}
			}
		}
		.btnWrap {
			display: block;
			width: 100%;
			border-top: .1rem solid #eee;
			position: absolute;
			left: 0;
			bottom: 0;
			overflow: hidden;
			span {
				display: inline-block;
				padding: 1.7rem 2.0rem 1.6rem;
				font-size: 1.5rem;
				font-weight: 500;
				letter-spacing: -0.02rem;
				color: #555;
			}
			.yes {
				float: right;
				color: #ccc;
			}
			.yes.active {
				color: $purple;
			}
			.confirm {
				float: right;
				color: $purple;
			}
		}
	}
	//아래 중복 alerBox는 새로 pc 수강신청 만들면서 기존 사이드 이펙트 파악을 위해 덮어쓰기 용으로 작성함 - 2020.02.04 : 남인식
	.alertBox{
		max-width: 34rem;
		min-height: 18.8rem;
		padding-top: 3rem;

		@include bcmMobile {
			padding-top: 1.8rem;
		}
		.tit,
		.desc{
			padding-left: 3rem;
			padding-right: 3rem;

			@include bcmMobile {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
		.tit{
			margin-bottom: 1rem;
			font-size: 1.8rem;
		}
		.desc{
			font-size: 1.4rem;
			color: #111;
		}
		.btnWrap{
			.confirm{
				padding-top: 1.2rem;
				padding-bottom: 1.4rem;
				font-size: 1.6rem;
			}
			span {
				cursor: pointer;
			}
		}
		.chkWrap{
			padding-left: 3rem;
			margin-bottom: 2rem;

			@include bcmMobile {
				padding-left: 2rem;
			}
			
			label {
				cursor: pointer;
			}
		}


		&.type01 {
			padding: 3.5rem 0 3rem;
			.tit {
				font-size: 2rem;
				font-weight: $bold;
				letter-spacing: -0.03em;
				margin-bottom: 0;
			}
			.desc {
				font-size: 1.5rem;
				line-height: 1.5;
				letter-spacing: -0.02em;
				margin-bottom: 2.4rem;
			}
			ul {
				padding-top: 2.5rem;
				padding-bottom: 1.2rem;
				margin: 0 2rem 2.5rem;
				border: 0.1rem solid #9732FC;
				border-radius: 1rem;
				box-shadow: 0 .3rem .6rem rgba(0, 0, 0, 0.16);
				li {
					font-size: 1.4rem;
					letter-spacing: -0.03em;
					color: #111;
					margin: 0 0 1.2rem 1.8rem;
					padding-left: 2.1rem;
					background: url($imgURL + '/order/ico_check_purple.svg') no-repeat left top/1.2rem 2rem;
					span {
						color: #9732FC;
						font-size: 1.1rem;
					}
				}
			}
			.btn {
				width: calc(100% - 4rem);
				height: 6.5rem;
				margin: 0 2rem;
				font-size: 1.7rem;
				font-weight: $medium;
				letter-spacing: -0.02em;
				.price {
					display: block;
					font-size: 1.3rem;
					font-weight: $regular;
				}
			}
			.btnLine {
				margin: 1.5rem 2rem 0;
				text-align: center;
				span {
					font-size: 1.3rem;
					color: #555;
					text-decoration: underline;
					cursor: pointer;
					.price {
						display: block;
					}
				}
			}
			.cautionTxt {
				padding: 1.2rem 2rem 1rem;
				margin: 2.9rem auto -3rem;
				background: #F7F7F7;
				border-radius: 0 0 2rem 2rem;
				color: #999;
				font-size: 1.1rem;
				letter-spacing: -0.02em;
				text-align: center;
			}
			@include desktop {
				padding: 4rem 0 2.9rem;
				.tit {
					font-size: 2rem;
					font-weight: $bold;
					letter-spacing: -0.03em;
				}
				.desc {
					font-size: 1.6rem;
					letter-spacing: -0.03em;
					margin-bottom: 3rem;
				}
				ul {
					margin: 0 2.8rem 2rem 3.2rem;
					border-radius: 1.5rem;
					li {
						margin-bottom: 1.3rem;
						padding-left: 2rem;
					}
				}
				.btn {
					width: calc(100% - 6rem);
					height: 7rem;
					margin: 0 3rem;
					border: none;
					border-radius: 0 1.5rem 1.5rem 1.5rem;
					font-size: 1.6rem;
					font-weight: $medium;
					.price {
						font-size: 1.4rem;
						margin-top: 0.4rem;
					}
				}
				.btnLine {
					margin: 2.1rem 2rem 0;
					span {
						font-size: 1.4rem;
						letter-spacing: -0.03em;
					}
				}
				.cautionTxt {
					margin-top: 2.6rem;
					padding: 1.3rem 2rem;
					font-size: 1.2rem;
				}
			}

			@include tablet {
				padding: 2rem 0;
				.desc {
					margin-bottom: 1.5rem;
				}
				ul {
					padding-top: 2rem;
					margin: 0 2rem 1.5rem;
					li {
						font-size: 1.3rem;
						margin: 0 0 1rem 1.8rem;
					}
				}
				.btnLine {
					margin: 1.3rem 2rem 0;
				}
				.cautionTxt {
					padding: 1.1rem 2rem 0.9rem;
					margin: 1.8rem auto -3rem;
					font-size: 1rem;
				}
			}
		}
	}

	.popupWrap {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		border-radius: 2rem 2rem 0 0;
		padding: 2rem 2rem 8rem;
		text-align: left;
		max-height: 100vh;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		.btnClose {
			width: 2.15rem;
			height: 1.9rem;
			background: url($imgURL + '/common/btn_close.svg') no-repeat center/cover;
			position: absolute;
			top: 1.5rem;
			right: 2.1rem;
		}
		.btnBottom {
			position: fixed;
			left: 0;
			bottom: 0;
			background: $gra1;
			height: calc(5.6rem + calc(env(safe-area-inset-bottom) / 2));
			width: 100%;
			font-size: 1.7rem;
			font-weight: $medium;
			color: #fff;
			line-height: 5.6rem;
			text-align: center;
		}
		.tit {
			font-size: 2.3rem;
			font-weight: $bold;
			letter-spacing: -0.02em;
			color: #111;
			margin: 3.3rem 0 2.4rem;
		}
		.point {
			color: $mainColor02;
		}
		ul.desc {
			li {
				font-size: 1.4rem;
				letter-spacing: -0.02em;
				line-height: 1.6;
				color: #555;
				position: relative;
				margin-bottom: 0.4rem;
				padding-left: 0.7rem;
				word-break: keep-all;
				word-wrap: break-word;
				&:before {
					content: '';
					display: inline-block;
					position: absolute;
					left: 0;
					top: 1.1rem;
					width: 0.2rem;
					height: 0.2rem;
					background: #555;
					border-radius: 50%;
				}
			}
		}
		.subDesc {
			font-size: 1.2rem;
			letter-spacing: -0.02em;
			color: #999;
			line-height: 1.2;
			margin: 1.6rem 0 3.8rem;
			padding-top: 1.2rem;
			border-top: 0.1rem solid #F1F1F1;
		}
	}
}
.dimmed:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -.25rem;
}

/* toast popup */
.toastPopup {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	transform:translateZ(999px);
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: calc(5rem + env(safe-area-inset-top));
	padding-top: env(safe-area-inset-top);
	font-size: 1.4rem;
	font-weight: 500;
	letter-spacing: -0.02rem;
	text-align: center;
	color: #fff;
	line-height: 3.57;
	opacity: 1;
	-moz-transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
	-ms-transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
	transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}
.toastPopup.off {
	top: calc(-5rem - env(safe-area-inset-top));
	//opacity: 0;
	-moz-transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
	-ms-transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
	transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}