@import "../common/commonCode.scss";
@import "../common/_mixin";

.order-pledge {
  padding-top: 3rem;
  padding-bottom: 6rem;

  &__title {
    margin-bottom: 1.9rem;
    font-size: 2.7rem;
    font-weight: 700;
    line-height: 3.85rem;
    text-align: left;
  }

  &__info {
    padding: 1.94rem 2.1rem;
    border: 0.1rem solid #ddd;
    border-radius: 1.2rem;

    li {
      color: #555;
      font-size: 1.3rem;
      text-align: left;
      line-height: 1.9rem;

      & + li {
        margin-top: 0.34rem;
      }
    }
  }

  &__notice {
    margin: 3rem 0 0.95rem;
    color: #111;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.75rem;
    text-align: left;
  }

  &__cont {
    padding: 2rem;
    background: #f8f8f8;
    border-radius: 1.2rem;
    color: #555;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-align: left;
    br {
      margin-bottom: 1.8rem;
    }
    li {
      & + li {
        margin-top: 1.9rem;
      }
    }
  }

  &__foot {
    margin: 1.5rem 0 5.9rem;
    text-align: center;
  }

  &__date,
  &__writer {
    display: block;
    color: #111;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.75rem;
  }

  &__confirm {
    display: flex;
    justify-content: space-between;

    li {
      width: 48.5%;
    }

    .btn {
      margin: 0;

      &--cancel {
        background: #fff;
        border-color: #9732FC;
        color: #9732FC;
      }
    }
  }

  .btn-close {
    display: none;
  }
}

@include desktop {

  .order-pledge {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 40rem;
    padding: 0;
    background: #fff;
    border-radius: 2rem;
    transform: translate(-20rem, -50%);
    z-index: 990;

    &--hide {
      display: none;
    }

    &__title {
      padding: 0 4rem;
      margin-bottom: 0;
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 5rem;
      text-align: center;
    }

    &__inner {
      height: 55vh;
      max-height: 56rem;
      padding: 3rem 2rem 0;
      overflow-x: auto;
    }

    &__info {
      padding: 1.9rem;
      border-radius: 0.8rem;

      li {
        font-size: 1.4rem;
        line-height: 2rem;

        & + li {
          margin-top: 0.38rem;
        }
      }
    }

    &__notice {
      margin: 3rem 0 1rem;
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: left;
    }

    &__cont {
      border-radius: 0.8rem;

      li {
        line-height: 2rem;
      }
    }

    &__foot {
      margin: 1rem 0 3rem;
    }

    &__date,
    &__writer {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    &__confirm {
      padding: 0 2rem 2rem;
    }

    .btn-close {
      display: block;
      position: absolute;
      top: 1.3rem;
      right: 1.3rem;
      width: 2.4rem;
      height: 2.4rem;
      background: url($imgURL + '/web/btn_gnb_close.png') no-repeat 50% 50%;
      background-size: contain;
      border: 0;
      cursor: pointer;
    }
  }
}