@import "../common/commonCode.scss";
@import "../common/_mixin";

.textbookPage {
	background: #000;
	margin: -5rem -2rem;
}

.textbook {
	line-height: 1.7;

	.previewBook {
		position: absolute;
		top: 0;
		width: 100%;
	}
	.conWrap {
		position: relative;
		height: 100vh;
		background: linear-gradient(180deg,#9732fc,#fc4c4c);
		border-radius: 3rem;
		color: #fff;
		text-align: center;
	}

	.trial-closing {
		position: relative;
		min-height: 100vh;
		background: linear-gradient(180deg,#9732fc,#fc4c4c);
		border-radius: 3rem;
		color: #fff;
		padding: 5rem 2rem;
		text-align: center;
		@include desktop {
			min-height: 80vh;
		}
		.closing {
			padding: 4.1rem 0 5rem;
			font-size: 3.3rem;
			line-height: 4.8rem;
			font-weight: $bold;

		}
		.trialbenefit {
			position: relative;
			padding: 2.5rem;
			background-color: rgba(255, 255, 255, 0.95);
			border-radius: 2rem;
			@include desktop {
				width: 38rem;
				margin: 4rem auto 0;
			}

			&-title {
				font-size: 1.8rem;
				color: #000000;
				font-weight: 700;
				margin-bottom: 1.5rem;
				letter-spacing: -0.02rem;
				border-bottom: 1px rgba(0, 0, 0, 0.5) solid;
				text-align: left;
				padding-bottom: 1rem;
			}

			&-card {
				position: relative;
				height: 8.5rem;
				font-size: 1.6rem;
				color: #111111;
				@include desktop  {
					font-size: 1.8rem;
				}

				&__icon {
					position: absolute;
					top: 1rem;
					left: 0;
					width: 6.5rem;
				}

				&__sub {
					position: absolute;
					top: 1.8rem;
					left: 8rem;
					@include desktop  {
						top: 1.6rem;
					}
				}

				&__title {
					position: absolute;
					bottom: 1.8rem;
					left: 8rem;
					font-weight: bold;
					@include desktop {
						bottom: 1.6rem;
					}
				}
			}
		}
	}
	.greeting1 {
		padding: 16vh 3rem 0;
		font-size: 2.8rem;
		font-weight: 700;
		line-height: 1.5;
	}
	.greeting2 {
		margin: 6.5rem auto .3rem;
		font-size: 1.5rem;
		opacity: 0.8;
	}
	.greeting3 {
		padding: 0 3rem;
		font-size: 2.05rem;
		font-weight: 700;
	}
	.defaultWrap {
		margin: .15rem auto;
		padding: 5.3rem 2rem 7rem;
		background-color: #FAFAFA;
		border-radius: 3rem;
	}
	.title {
		display: inline-block;
		padding-bottom: .2rem;
		font-size: 2.6rem;
		font-weight: 700;
		background: linear-gradient(90deg,#9732fc,#fc4c4c);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@media print {
			-webkit-text-fill-color: #000;
		}
	}
	.subTitle {
		margin-top: 0.2rem;
		font-size: 1.3rem;
		color: #999;
	}
	.titleWrap {
		margin-top: 2rem;
		font-size: 1.4rem;
		font-weight: 700;
	}
	.titleWrapNoMargin {
		font-size: 1.4rem;
		font-weight: 700;
	}
	.situationWrap {
		font-size: 1.4rem;
		color: #999;
		font-weight: 500;
	}
	.contentWrap {
		font-size: 1.4rem;
		font-weight: 500;
	}
	.scriptWrap {
		margin-top: 2rem;
		font-size: 1.4rem;
		font-weight: 500;
		white-space: pre-wrap;
	}
	.contentBox {
		position: relative;
		margin-top: 2rem;
		padding: 5.5rem 2.5rem 3rem 2.5rem;
		background-color: #fff;
		border-radius: 0 2rem 2rem 2rem;
	}
	.no {
		position: absolute;
		top: 1rem;
		left: -.55rem;
		width: 4.3rem;
		height: 3.3rem;
		line-height: 3.3rem;
		background-color: #f2f2f2;
		border-radius: .3rem;
		font-size: 1.5rem;
		font-weight: 700;
		text-align: center;
		z-index: 2;
	}
	.questionNo {
		position: absolute;
		top: 1rem;
		left: -.55rem;
		width: 10rem;
		height: 3.3rem;
		padding-top: .8rem;
		background-color: #f2f2f2;
		border-radius: .3rem;
		font-size: 1.5rem;
		font-weight: 700;
		text-align: center;
		z-index: 2;
	}
	.aiWrap {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-top: 1.5rem;
		.aiBox {
			width: 5px;
			margin:0 3rem;
			background:#D9D9D9;
		}
		.flex {
			flex: 1;
		}
	}
	.boxSh {
		display: block;
		content: '';
		position: absolute;
		top: 1.3rem;
		left: -.9rem;
		width: .9rem;
		height: 3.3rem;
		background-color: #d9d9d9;
		border-radius: .3rem 0 0 .3rem;
		z-index: 1;
	}
	.mainSentence {
		font-size: 2.5rem;
		line-height: 1.4;
		font-weight: 700;
	}
	.mainDesc {
		margin-top:0.7rem;
		font-size: 1.2rem;
		color: #555;
	}
	.subBadge {
		display: inline-block;
		margin: 1.5rem 0 .4rem;
		padding: .2rem .8rem;
		border: 1px solid #ddd;
		font-size: 1.2rem;
		color: #999;
	}
	.subSentence {
		font-size: 1.5rem;
		color: #555;
	}
	.subSentence b {
		color: #111111;
	}

	.speakerWrap {
		margin-top:15px;
		overflow: hidden;
	}
	.speakerWrap .speaker {
		margin-bottom: 2rem;
		font-size: 1.6rem;
		line-height: 1.4;
		color: #111;
	}
	.speakerWrap .A {

		text-align: right;
	}
	.speakerWrap .B {
		text-align: left;
	}
	.speakerWrap .A .sentence {
		max-width: 88%;
		display: inline-block;
		padding: 1.5rem 2rem;
		text-align: left;
		background-color: #fff;
		border-radius: 2rem .5rem 2rem 2rem;
		font-weight: 500;
	}
	.speakerWrap .B .sentence {
		max-width: 88%;
		display: inline-block;
		padding: 1.5rem 2rem;
		text-align: left;
		background-color: #F5F5F5;
		border-radius: .5rem 2rem 2rem 2rem;
		font-weight: 500;
	}
	.speakerWrap .speaker .part {
		margin-bottom: 1rem;
		font-size: 1.1rem;
		color: #999;
	}
	.speakerWrap span {
		display: inline-block;
		width: 2.2rem;
		height: 2.2rem;
		padding: 0.2rem 0 0;
		font-size: 1.3rem;
		font-weight: 700;
		color: #fff;
		text-align: center;
		border-radius: 1.1rem;
	}
	.speakerWrap .speaker .a span {
		margin-left: .5rem;
		background-color: #9732FC;
	}
	.speakerWrap .speaker .b span {
		margin-right: .5rem;
		background-color: #111;
	}

	.exSentence-a {
		font-size: 1.5rem;
		color: #111;
		margin-bottom: 1.6rem;
		font-weight: 700;
	}
	.exSentence-b {
		font-size: 1.5rem;
		color: #111;
		margin-bottom: 1.6rem;
		font-weight: 700;
	}
	.exSentence-a span {
		display: inline-block;
		width: 2.2rem;
		height: 2.2rem;
		font-size: 1.3rem;
		font-weight: 700;
		color: #111;
		text-align: center;
		border: #111 1px solid;
		border-radius: 1.1rem;
		margin-right: 1rem;
		background-color: #fff;
		line-height: 2rem;
	}
	.exSentence-b span {
		display: inline-block;
		width: 2.2rem;
		height: 2.2rem;
		font-size: 1.3rem;
		font-weight: 700;
		color: #fff;
		text-align: center;
		border-radius: 1.1rem;
		margin-right: 1rem;
		background-color: #111;
		line-height: 2.2rem;
	}

	@include desktop {
		max-width: 500px;
		margin: 0 auto;
	}
}