@import "../common/commonCode.scss";
@import "../common/_mixin";

.orderCodePage {
	.header {
		position: relative;
		z-index: 10;
		margin-top: -2.85rem;
		img {
			width: 100%;
			height: auto;
			max-width: 11.009rem;
			//height: 2.05rem;
			cursor: pointer;
		}
	}
	.titWrap {
		text-align: center;
		margin-top: -6.6rem;
		margin-left: 2.4rem;
		img {
			width: 100%;
			height: auto;
			max-width: 29.8045rem;
		}
	}
	.conWrap {
		margin-bottom: 5rem;
	}
	.subTit {
		margin: 1rem auto 2.6rem;
		font-size: 1.3rem;
		letter-spacing: -0.02em;
		line-height: 1.5;
		color: #111;
		text-align: center;
	}
	.inputBox {
		input {
			text-align: center;
			margin-bottom: 0.4rem;
		}
		::-webkit-input-placeholder {
			color: #808080;
			text-align: center;

		}

		:-moz-placeholder {
			color: #808080;
			text-align: center;

		}
		&~.info {
			margin: 2.5rem auto;
			font-size: 1.2rem;
			color: #111;
			letter-spacing: -0.05em;
			text-align: center;
			text-indent: 0.3rem;
			.mark {
				display: inline-block;
				width: 3.45rem;
				height: 1.8rem;
				margin-right: 0.4rem;
				background: #111;
				color: #fff;
				font-size: 1.1rem;
				font-weight: $bold;
				letter-spacing: -0.055em;
				border-radius: 0.9rem;
				line-height: 1.8rem;
				text-align: center;
			}
			.bold {
				font-weight: $bold;
				border-bottom: 0.1rem solid #111;
				cursor: pointer;
			}
		}
	}
	&~.notice {
		background: #222;
		color: #fff;
		margin: 0 -2rem;
		padding: 3.9rem 4.6rem 4.4rem;
		.tit {
			height: 1.6rem;
			padding-left: 2rem;
			margin-bottom: 1.7rem;
			background: url($imgURL + '/order/ico_info.svg') no-repeat left center/1.6rem 1.6rem;
			font-size: 1.5rem;
			font-weight: $bold;
			color: #999;
			letter-spacing: -0.05em;
		}
		ul {
			li {
				position: relative;
				padding-left: 0.8rem;
				margin-bottom: 0.45rem;
				font-size: 1.2rem;
				color: #999;
				letter-spacing: -0.06em;
				line-height: 1.4;
				word-break: keep-all;
				word-wrap: break-word;
				&:before {
					content: '';
					display: inline-block;
					position: absolute;
					left: 0.1rem;
					top: 0.7rem;
					width: 0.2rem;
					height: 0.2rem;
					background: #999;
					border-radius: 50%;
				}
			}
		}
	}

	@include desktop {
		&~.footer {
			margin: 0 -2rem;
		}

		.header {
			max-width: 40rem;
			margin: -2rem auto 0;
			img {
				max-width: 14.586rem;
			}
		}

		.titWrap {
			margin-top: -10.5rem;
			margin-left: 4.3rem;
			img {
				max-width: 54.125rem;
			}
		}

		.conWrap {
			max-width: 40rem;
			margin: 0 auto 8rem;
		}

		.subTit {
			margin: 1.8rem auto 3.9rem;
			font-size: 1.8rem;
			letter-spacing: -0.025em;
			line-height: 1.6;
		}

		.inputBox {
			input {
				margin-bottom: 1rem;
			}
			&~.info {
				margin: 4rem;
				font-size: 1.6rem;
				text-indent: -0.2rem;
				.mark {
					width: 5.4rem;
					height: 2.8rem;
					margin-right: 0.6rem;
					border-radius: 1.4rem;
					font-size: 1.5rem;
					letter-spacing: -0.03em;
					line-height: 2.8rem;
				}
			}
		}

		&~.notice {
			padding: 4.9rem 0 4.7rem;
			.listWrap {
				max-width: 40rem;
				margin: 0 auto;
				padding: 0 0.7rem;
			}
			.tit {
				height: 2.8rem;
				padding-left: 3.3rem;
				margin-bottom: 2.3rem;
				font-size: 2.2rem;
				background-size: 2.6rem 2.8rem;
				background-position-x: 0.1rem;
			}
			ul {
				li {
					margin-bottom: 0.95rem;
					font-size: 1.4rem;
				}
			}
		}

	}
}
