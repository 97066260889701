@import "../../common/commonCode.scss";
@import "../../common/_mixin";

.purchase-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 900;
}

.purchase-popup {
  width: 100%;
  height: calc(100% - 12.5rem);
  overflow-y: hidden;
  border-radius: 2rem 2rem 0 0;
  position: fixed;
  bottom: 0;
  z-index: 910;

  @include desktop {
    top: 0;
    left: 0;
    right: 0;
    width: 40rem;
    height: 61rem;
    margin: auto;
    border-radius: 2.0rem;
    scrollbar-arrow-color: #9D9992;
    scrollbar-3dlight-color: #D4D0C8;
    scrollbar-darkshadow-color: #D4D0C8;
    scrollbar-face-color: #FFFFFF;
    scrollbar-hightlight-color: #FFFFFF;
    scrollbar-shadow-color: #FFFFFF;
    scrollbar-track-color: #EAE7E1;

    &::-webkit-scrollbar {
      width: 40px;
      /* 스크롤바 너비 */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9D9992;
      /* 스크롤바 색상 */
    }

    &::-webkit-scrollbar-track {
      background-color: #EAE7E1;
      /* 스크롤바 트랙 색상 */
    }

    &.WIDE {
      width: 80rem;
    }
  }

  &--image {
    img {
      width: 100%;
      margin-top: -2rem;
    }
  }

  &__container {
    width: 100%;
    text-align: center;
    min-height: 100%;
  }

  &__scroll-wrap {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: calc(6rem + env(safe-area-inset-bottom));

    @include desktop {
      padding-bottom: calc(7rem + env(safe-area-inset-bottom));
    }

    &--image {
      padding-bottom: 0;
    }

    &-nopadding {
      padding-bottom: 0;
    }
  }


  &__close-button {
    position: sticky;
    top: 1.6rem;
    right: 2.1rem;
    width: 2.15rem;
    height: 1.9rem;
    float: right;
    z-index: 2;
    border: none;

    @include desktop {
      cursor: pointer;
      top: 1.5rem;
      right: 1.5rem;
      z-index: 20;
    }

    &--white {
      background: url("https://img.uphone3.com/common/btn_close_w.svg") no-repeat center/cover
    }

    &--black {
      background: url($imgURL + '/common/btn_close.svg') no-repeat center/cover;
    }

    &--gray {
      background: url($imgURL + '/order/close_btn_cccccc.png') no-repeat center/cover;
    }
  }

  &__title {
    width: 100%;
    padding-top: 6.5rem;
    font-size: 3rem;
    font-weight: $bold;
    letter-spacing: -0.02em;
    line-height: 1.3;
    color: #111;

    @include desktop {
      padding-top: 7rem;
      font-size: 3.2rem;
    }
  }

  &__inner-descriptions {
    position: relative;
    margin: 2.9rem 0.2rem -0.3rem 0;
    padding: 2.5rem 0 0 0.7rem;
    font-size: 1.5rem;
    color: #555;
    line-height: 1.62;
    text-align: left;
    border-top: 0.1rem solid #F5F5F5;

    .description-title {
      margin: 0;
      padding: 0 0 1rem 0;
      font-size: 1.8rem;
      font-weight: $bold;
      color: #111111;

      @include desktop {
        font-size: 1.9rem;
      }
    }

    ul {
      list-style-position: inside;
    }

    li {
      position: relative;
      padding: 0 0 0 0.8rem;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 1rem;
        width: 0.2rem;
        height: 0.2rem;
        background: #555;
        border-radius: 50%;
      }

      &.num {
        padding: 0 0 0 1.5rem;
        text-indent: -1.5rem;
        line-height: 1.5;

        &:not(:first-of-type) {
          margin-top: 0.5rem;
        }

        &:before {
          display: none;
        }
      }
    }

    dd {
      position: relative;
      padding: 0 0 0 1.5rem;

      &:before {
        content: '-';
        display: inline-block;
        position: absolute;
        left: 0.8rem;
        top: 0;
        width: 0.2rem;
        height: 0.2rem;
      }
    }

  }

  &__outer-descriptions {
    position: relative;
    margin: 0 2.5rem -0.3rem 2.5rem;
    padding: 2.5rem 0 0 0.7rem;
    color: #555;
    line-height: 1.62;
    text-align: left;
    font-size: 1.3rem;

    ul {
      list-style-position: inside;
    }

    li {
      position: relative;
      padding: 0 0 0 0.8rem;
      font-size: 1.3rem;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 1rem;
        width: 0.2rem;
        height: 0.2rem;
        background: #555;
        border-radius: 50%;
      }

      &.num {
        padding: 0 0 0 1.5rem;
        text-indent: -1.5rem;
        line-height: 1.5;

        &:not(:first-of-type) {
          margin-top: 0.5rem;
        }

        &:before {
          display: none;
        }
      }
    }

    dd {
      position: relative;
      padding: 0 0 0 1.5rem;

      &:before {
        content: '-';
        display: inline-block;
        position: absolute;
        left: 0.8rem;
        top: 0;
        width: 0.2rem;
        height: 0.2rem;
      }
    }
  }

  &__contents {
    background: #fff;
    box-shadow: 0 .3rem 2rem rgba(0, 0, 0, 0.04);
    border-radius: 2rem;
    padding: 4.5rem 2rem;
    margin: 5rem 2rem 0;

    @include desktop {
      padding: 5rem 2rem;
    }

    .content-item {
      padding-bottom: 4.3rem;
      margin-bottom: 4.5rem;
      border-bottom: 0.1rem solid #F5F5F5;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      @include desktop {
        padding-bottom: 4.8rem;
        margin-bottom: 5rem;
      }

      &__num {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6.3rem;
        height: 2.4rem;
        margin: 0 auto;
        border: 0.1rem solid #999;
        border-radius: 2rem;
        font-size: 1.1rem;
        font-weight: $bold;
        line-height: 2.1;
        letter-spacing: -0.02rem;

        @include desktop {
          width: 6.8rem;
          height: 2.6rem;
          font-size: 1.2rem;
        }

        strong {
          font-weight: $bold;
        }

        span {
          margin-left: 0.3rem;
          font-size: 1.25rem;
          letter-spacing: -0.03rem;

          @include desktop {
            font-size: 1.3rem;
          }
        }
      }

      &__title {
        font-size: 2.4rem;
        font-weight: $bold;
        letter-spacing: -0.02rem;
        color: #111;
        line-height: 1.3;
        margin: 1.1rem 0 0;
        white-space: nowrap;

        @include desktop {
          font-size: 2.5rem;
        }

        span {
          font-size: 2.5rem;

          &.adj {
            font-size: 2.6rem;
          }
        }
      }

      &__sub-title {
        font-size: 1.8rem;
        font-weight: $medium;
        letter-spacing: -0.02em;
        margin: 0;
        padding-bottom: 2rem;
        line-height: 1.33;
        color: #111111;

        @include desktop {
          font-size: 1.9rem;
          padding-bottom: 2.2rem;
          ;
        }
      }

      &__description {
        padding: 0.5rem 0 2.5rem;
        font-size: 1.5rem;
        color: #999999;
        line-height: 1.5;

        @include desktop {
          padding: 0.8rem 0 3rem;
        }
      }

      &__description-wrap {
        position: relative;
        margin: 2.5rem 1rem;
        font-size: 1.5rem;
        color: #555;
        line-height: 1.62;
        text-align: left;

        @include desktop {
          margin: 3rem 1rem;
        }

        li {
          position: relative;
          padding: 0 0 0 0.8rem;

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 1rem;
            width: 0.2rem;
            height: 0.2rem;
            background: #555;
            border-radius: 50%;
          }

          &:not(:first-of-type) {
            margin-top: 1rem;
          }

          &.num {
            &:before {
              display: none;
            }
          }
        }

        dd {
          position: relative;
          padding: 0 0 0 1.5rem;
          font-size: 1.3rem;
          color: #777777;

          &:before {
            content: '-';
            display: inline-block;
            position: absolute;
            left: 0.8rem;
            top: 0;
            width: 0.2rem;
            height: 0.2rem;
          }
        }
      }

      &__image {
        width: calc(100% + 4rem);
        margin: 0 -2rem
      }

      &__time {
        padding: 0 1.5rem;

        li {
          height: 6.3rem;
          font-size: 1.3rem;
          font-weight: $medium;
          line-height: 6.1rem;
          background: #F8F8F8;
          color: #CECECE;
          border-radius: 1.2rem;

          &:first-child {
            opacity: .6;
            margin-bottom: 1rem;
          }

          span {
            font-size: 2.5rem;
            font-weight: $bold;
            vertical-align: -0.4rem;
            color: #6188F2;
            margin-left: 9rem;

            @include desktop {
              font-size: 2.7rem;
            }
          }
        }
      }

      .image-wrap {
        width: calc(100% + 4rem);
        margin: 0 -2rem;
        height: 14.9rem;
        position: relative;

        @include desktop {
          height: 16rem;
        }

        // 할인 쿠폰 색상별
        &.pink {
          background: url($imgURL + '/order/benefit/bg_coupon_pink@2x.png') no-repeat top left 0 / 100% auto;
        }

        &.blue {
          background: url($imgURL + '/order/benefit/bg_coupon_blue@2x.png') no-repeat top left 0rem / 100% auto;
        }

        &.yellow {
          background: url($imgURL + '/order/benefit/bg_coupon_yellow@2x.png') no-repeat top left 0rem / 100% auto;
        }

        &__title {
          font-size: 1.45rem;
          font-weight: $medium;
          letter-spacing: -0.06rem;
          color: rgba(255, 255, 255, .4);
          position: absolute;
          top: 1.75rem;
          right: 7rem;

          @include desktop {
            right: 8rem;
            font-size: 1.5rem;
          }
        }

        &__price {
          font-size: 5rem;
          font-weight: $bold;
          letter-spacing: -0.15rem;
          color: #fff;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: baseline;
          top: 6.5rem;
          left: 7rem;

          @include desktop {
            top: 7rem;
            left: 7.5rem;
          }

          strong {
            font-size: 5rem;
            font-weight: $bold;
            letter-spacing: -0.15rem;

            @include desktop {
              font-size: 5.4rem;
            }
          }

          span {
            font-size: 1.5rem;

            @include desktop {
              font-size: 1.6rem;
            }
          }
        }

        &__rate {
          font-size: 5.65rem;
          font-weight: $bold;
          letter-spacing: -0.15rem;
          color: #fff;
          position: absolute;
          top: 6rem;
          left: 7rem;

          @include desktop {
            font-size: 6rem;
            top: 6.5rem;
            left: 8rem;
          }
        }
      }
    }
  }
}