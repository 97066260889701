@import "../common/_mixin";
@import "../common/commonCode.scss";

.freelessonBook__wrapper {
	margin: -5rem -2rem;
	background-color: #000;
	text-align: center;
	&__inner {
		margin: 0 auto;
		width: 100%;
		border-radius: 2rem;
		height: 100vh;
		@include desktop {
			width: 550px;
		}
	}
}

.freelessonBook{
	position: relative;
	top: 0;
	width: 100%;
	height: 100%;
	text-align: left;
	border-radius: 2rem;
	overflow-y: auto;
	@include desktop {
		&::-webkit-scrollbar {
			width: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #999;
		}
		&::-webkit-scrollbar-track {
			background-color: #000;
		}
	}

	.circleButton {
		width: 3.8rem;
	}

	&__top {
		position: sticky;
		width: 100%;
		height: 0;
		top: 0;
		left: 0;
		z-index: 10;

		&--menu{
			position: absolute;
			left: 1rem;
			top: 1rem;
		}
		&--close{
			position: absolute;
			right: 1rem;
			top: 1rem;
		}
	}

	&__menu {
		position: sticky;
		top:0;
		left:0;
		width: 100%;
		height: 39rem;
		top: 0;
		background-color: #fff;
		border-radius: 3rem;
		overflow: hidden;
		z-index: 1010;
		transition: .2s;
		margin-bottom: -39rem;
		&.hidden{
			margin-bottom: 0;
			display:block !important;
			height: 0;
		}
		.close--button{
			position: absolute;
			top: 1rem;
			left: 1rem;
		}
		ul{
			padding: 5.6rem 0 3.5rem;

			li{
				height: 7.5rem;
				padding-top: 1.5rem;
				font-size: 1.7rem;
				color: #111;
				text-align: center;
				span{
					font-size: 1.45rem;
					color:#999;
				}
				&.on{
					background-color: #FBFBFB;
					color: #9732FC;
					span{
						color: #9732FC;
					}
				}

				&.on{
					background-color: #fbfbfb;
					span{
						color: $purple;
					}
				}
			}
		}
	}

	&__greeting{
		position: relative;
		min-height: 100vh;
		margin-top: 0;
		background: $gra2;
		border-radius: 3rem;
		color: #fff;
		text-align: center;
		.txt1{
			padding: 18vh 3rem 0;
			font-size: 3.3rem;
			font-weight: $bold;
			line-height: 1.5;
			letter-spacing: -0.02rem;
			@include desktop {
				padding: 16rem 3rem 0;
				font-size: 4.8rem;
			}
		}
		.txt2{
			margin: 6.5rem auto 1rem;
			font-size: 1.5rem;
			font-weight: $regular;
			opacity: 0.8;
			@include desktop {
				margin-top: 10rem;
				font-size: 2.5rem;
			}
		}
		.txt3{
			padding: 0 2rem;
			font-size: 2rem;
			font-weight: $medium;
			letter-spacing: -0.02rem;
			line-height: 1.2;
			@include desktop {
				font-size: 3rem;
			}
		}
		.balloon{
			width: 6.5rem;
			margin-top: 3rem;
			padding-bottom: 15rem;
			@include desktop {
				margin-top: 3.5rem;
				width: 10rem;
			}
		}
		.arrow{
			position: absolute;
			width: 2.6rem;
			left: 50%;
			bottom: 10rem;
			margin-left: -1.3rem;
		}
		.hoLine{
			width: 5rem;
			margin: 4rem auto 3rem;
			border-bottom: 1px solid #fff;
			@include desktop {
				width: 7rem;
				margin: 6rem auto 4rem;
				border-bottom: 1px solid #fff;
			}
		}
		.afterbenefit  {
			padding: 2rem 4.25rem 6rem;
			@include desktop {
				padding: 3rem 6rem 6rem;
			}
			&-title {
				font-size: 1.9rem;
				font-weight: $medium;
				margin-bottom: 1.5rem;
				letter-spacing: -0.02rem;
				@include desktop {
					font-size: 2.7rem;
					margin-bottom: 2.5rem;
				}
			}
			&-card {
				position: relative;
				height: 9.5rem;
				background-color: #fff;
				border-radius: 2rem;
				margin-bottom: 1rem;
				font-size: 1.6rem;
				color: #111111;
				@include desktop {
					height: 14rem;
					border-radius: 3rem;
					font-size: 2.3rem;
				}
				&__icon {
					position: absolute;
					top: 1.25rem;
					left: 2.725rem;
					width: 7rem;
					@include desktop {
						top: 1.5rem;
						left: 3.5rem;
						width: 11rem;
					}
				}
				&__sub {
					position: absolute;
					top: 2.75rem;
					left: 10.725rem;
					@include desktop {
						top: 4rem;
						left: 16rem;
					}
				}
				&__title {
					position: absolute;
					bottom: 2.75rem;
					left: 10.725rem;
					font-weight: $bold;
					@include desktop {
						bottom: 4rem;
						left: 16rem;
					}
				}
			}
		}
	}

	&__unit {
		margin: .15rem auto;
		padding: 5.3rem 2rem 7rem;
		background-color: #FAFAFA;
		border-radius: 3rem;

		.title{
			display: inline-block;
			padding-bottom: .2rem;
			font-size: 2.6rem;
			font-weight: $bold;
			background: $gra1;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		.subTitle{
			margin-top: 0.2rem;
			font-size: 1.3rem;
			color: #999;
			font-weight: $regular;
		}

		.contentBox{
			position: relative;
			margin-top: 2rem;
			padding: 3rem 2.5rem;
			background-color: #fff;
			border-radius: 0 2rem 2rem 2rem;

			&.withNumber {
				padding-top: 5rem;
			}

			.smallTitle {
				font-size: 1.5rem;
				color: #999;
				text-align: center;
			}
			.subject{
				padding-bottom: 3rem;
				margin-bottom: 2.5rem;
				font-size: 3rem;
				font-weight: $bold;
				color: #111;
				text-align: center;
				border-bottom: 1px solid #eee;
			}
			.tip{
				margin-bottom: 1rem;
				font-size: 1.3rem;
				line-height: 1.8;
				padding-left: 3.9rem;
				text-indent: -3.9rem;
				word-break: keep-all;
				span{
					margin-right: 0.5rem;
					padding: 0.2rem 0.6rem;
					background-color: #111;
					border-radius: 0 .5rem .5rem .5rem;
					font-size: 1.2rem;
					font-weight: $bold;
					color: #fff;
					text-align: center;
				}
			}
			ul{

				li{
					padding: 0.5rem 0;
					font-size: 1.5rem;
					color: #555;
					span{
						margin-left: .5rem;
						font-size: 1.3rem;
						color: #999;
						font-weight: $regular;
					}
					&:before{
						content: '\00B7';
						display: inline-block;
						margin-right: 5px;
					}
				}
			}

			.no{
				position:absolute;
				top: 1rem;
				left: -.55rem;
				width: 4.3rem;
				height: 3.3rem;
				padding-top: .8rem;
				background-color: #f2f2f2;
				border-radius: .3rem;
				font-size: 1.5rem;
				font-weight: $bold;
				text-align: center;
				z-index: 2;
			}
			.boxSh{
				display: block;
				content: '';
				position: absolute;
				top: 1.3rem;
				left: -.9rem;
				width: .9rem;
				height: 3.3rem;
				background-color: #d9d9d9;
				border-radius: .3rem 0 0 .3rem;
				z-index: 1;
			}
			.sentence {
				font-size: 3rem;
				line-height: 1.4;
				font-weight: $bold;
			}
			.translation {
				font-size: 1.4rem;
				color: #999;
				line-height: 2;
				&.off {
					display: none;
				}
			}
			.subBadge{
				display: inline-block;
				margin: 1.5rem 0 .4rem;
				padding: .2rem .8rem;
				border: 1px solid #ddd;
				font-size: 1.2rem;
				font-weight: $regular;
				color: #999;
			}
			.subSentence{
				font-size: 1.5rem;
				line-height: 1.5;
				font-weight: $regular;
				color: #555;
				padding-left: 1rem;
				text-indent: -0.5rem;

				span{
					font-weight: $medium;
					color: #111;
				}
				&:before{
					display: inline-block;
					content: '\00b7';
					margin-right: 0.5rem;
				}
			}
			.image {
				margin-top: 1rem;
				img {
					width: 100%;
				}
			}
		}

		.testBox {
			margin-top: 5rem;
			.question {
				font-size: 1.5rem;
				color: #111111;
				font-weight: $medium;
				margin-bottom: 0.5rem;
			}
			.sentence {
				font-size: 1.6rem;
				line-height: 1.4;
				color: #555555;
				font-weight: $regular;
			}
			.image {
				img {
					width: 100%;
				}
			}
		}

		.questionBox {
			position: relative;
			margin-top: 2rem;
			padding: 6rem 2.5rem 3.5rem;
			background-color: #fff;
			border-radius: 0 2rem 2rem 2rem;
			box-shadow: 0 .5rem 1.5rem #f0f0f0;

			.no {
				position: absolute;
				top: 1rem;
				left: -.55rem;
				width: 10rem;
				height: 3.3rem;
				padding-top: .8rem;
				background-color: #f2f2f2;
				border-radius: .3rem;
				font-size: 1.5rem;
				font-weight: $bold;
				text-align: center;
				z-index: 2;
			}

			.boxSh {
				display: block;
				content: '';
				position: absolute;
				top: 1.3rem;
				left: -.9rem;
				width: .9rem;
				height: 3.3rem;
				background-color: #d9d9d9;
				border-radius: .3rem 0 0 .3rem;
				z-index: 1;
			}

			.sentence {
				font-size: 2.5rem;
				line-height: 1.3;
				font-weight: $bold;
			}

			.image {
				margin-top: 2rem;
				img {
					max-width: 100%
				}
			}
		}

		.trans{
			width: 5.3rem;
			height: 3rem;
			margin: 1.5rem 0 1rem;
			padding-top: .5rem;
			border: .1rem solid #ccc;
			border-radius: 1.5rem;
			font-size: 1.5rem;
			font-weight: $medium;
			text-align: center;

			&.tt~.contentBox .desc{
				display:block;
			}
			.t{
				display: none;
			}
			&.tt .e{
				display: none;
			}
			&.tt .t{
				display: block;
			}
		}

		&__dialog {
			margin-top: 3rem;
			.speaker{
				margin-bottom: 2rem;
				font-size: 1.6rem;
				font-weight: $regular;
				line-height: 1.4;
				color: #111;
				&:last-child{
					margin-bottom: 0;
				}
				.part{
					margin-bottom: 1rem;
					font-size: 1.1rem;
					font-weight: $regular;
					color: #999;
					span{
						display: inline-block;
						width: 2.2rem;
						height: 2.2rem;
						padding: 0.2rem 0 0;
						font-size: 1.3rem;
						font-weight: $bold;
						color: #fff;
						text-align: center;
						border-radius: 1.1rem;
					}
					&.a span{
						margin-left: .5rem;
						background-color: #9732FC;
					}
					&.b span{
						margin-right: .5rem;
						background-color: #111;
					}
				}
				&.A{
					text-align: right;
					.bubble{
						background-color: #fff;
						border-radius: 2rem .5rem 2rem 2rem;

					}
				}
				&.B{
					text-align: left;
					.bubble{
						background-color: #F5F5F5;
						border-radius: .5rem 2rem 2rem 2rem;

					}
				}
				.bubble {
					display: inline-block;
					padding: 1.5rem 2rem;
					text-align: left;
				}

				.description {
					color: #111;
				}

				.description {
					margin-top: 1rem;
					font-size: 1.3rem;
					color: #999;
				}

			}
		}
	}
}