@import '../../common/_mixin';
@import '../../common/commonCode.scss';

.quotation-section {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  border-top: 0.1rem solid #EEE;
  background: #fff;
  z-index: 90;
  transition: 0.3s ease-out;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

  &__wrapper {
    @include desktop {
      width: 47rem;
      margin: 0 auto;
    }
  }

  .price-list {
    position: relative;
    display: flex;
    padding: 1.2rem 0;
    align-items: center;
    font-size: 1.3rem;
    @include desktop {
      font-size: 1.5rem;
    }
    &:not(:first-of-type) {
      border-top: 1px solid #EEEEEE;
    }
    &.icon {
      padding-left: 3rem;
    }
    &.open {
      background: url(https://img.uphone3.com/order/quotation_arrow_up.svg) no-repeat 0 0.8rem;
      background-size: 2rem 2rem;
      @include desktop {
        cursor: pointer;
      }
    }
    &.close {
      background: url(https://img.uphone3.com/order/quotation_arrow_down.svg) no-repeat 0 0.8rem;
      background-size: 2rem 2rem;
      @include desktop {
        cursor: pointer;
      }
    }
    .pointer {
      @include desktop {
        cursor: pointer;
      }
    }
    &__checkbox {
      position: absolute;
      left: 0;
      margin: 0 1rem 0 0;
      border-radius: 0.5rem !important;
      &--checked {
        width: 2rem;
        height: 2rem;
        background: #4C69FC url($imgURL + '/order/order_quotation_checked.svg') no-repeat -0.1rem 0 !important;
        background-size: 2rem 2rem !important;
      }
      &--unchecked {
        width: 2rem;
        height: 2rem;
        background: #CCCCCC url($imgURL + '/order/order_quotation_unchecked.svg') no-repeat -0.1rem 0 !important;
        background-size: 2rem 2rem !important;
      }
    }
    &__title {
      font-weight: $bold;
      color: #111111;
      span {
        margin-left: 0.4rem;
        color: #FC4C4C;
        font-weight: $bold;
      }
    }
    &__price {
      flex: 1;
      text-align: right;
      font-weight: $bold;
      color: #111111;
    }
  }

  .price-detail {
    padding: 0.2rem 0 0.8rem 3rem;
    margin-top: -1rem;
    &__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      color: #555555;
      line-height: 1.8;
      @include desktop {
        font-size: 1.3rem;
      }
      &-title {
        &:before{
          content:'ㄴ';
          display: inline-block;
          margin-right: 0.3rem;
        }
      }
      &-price {
        flex: 1;
        text-align: right;
      }
      &-notice {
        flex: 1;
        text-align: right;
        color: #FC4C4C;
      }
    }
  }

  .price-tooltip {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    &__button {
      position: relative;
      width: 1.8rem;
      height: 1.8rem;
      border: none;
      margin: auto 0;
      background: url($imgURL + '/order/circle_question_mark.svg') no-repeat 0 0;
      background-size: 1.8rem 1.8rem;
      @include desktop {
        cursor: pointer;
      }
    }
    &__buttonex {
      position: relative;
      width: 1.8rem;
      height: 1.8rem;
      border: none;
      margin: auto 0;
      background: url($imgURL + '/order/circle_exclamation_mark.svg') no-repeat 0 0;
      background-size: 1.8rem 1.8rem;
      @include desktop {
        cursor: pointer;
      }
    }
    &__message {
      position: absolute;
      bottom: 2.4rem;
      width: 24rem;
      left: -11.1rem;
      padding: 1.4rem 2.35rem 1.4rem 1rem;
      background-color: #ffffff;
      border-radius: 1rem;
      border: 0.1rem solid #00BFE5;
      @include desktop {
        width: 32rem;
        left: -15.1rem;
      }
      &-close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 1rem;
        height: 1rem;
        @include desktop {
          cursor: pointer;
        }
      }
      &-text {
        font-size: 1.1rem;
        color: #777777;
        text-align: left;
        line-height: 1.6;
        @include desktop {
          font-size: 1.2rem;
        }
        b {
          font-weight: $bold;
          color: #555555;
        }
        ul {
          margin-left: 0.2rem;
          li {
            position: relative;
            color: #777777;
            list-style: none;
            font-weight: $regular;
            padding-left: 0.8rem;
            word-break: keep-all;
            &:before {
              content: '\00B7';
              position: absolute;
              left: 0.1rem;
            }
          }
        }
        span {
          font-size: 1rem;
          color: #777777;
        }
      }
    }
  }

  .price-summary {
    position: relative;
    padding: 1.4rem 0 1rem;
    border-top: 1px solid #EEEEEE;
    &__amount {
      display: flex;
      align-items: center;
      &-title {
        font-size: 1.4rem;
        font-weight: $bold;
        @include desktop {
          font-size: 1.5rem;
        }
      }
      &-price {
        flex:1;
        text-align: right;
        font-size: 2.2rem;
        font-weight: $bold;
        @include desktop {
          font-size: 3rem;
        }
      }
      &-unit {
        font-size: 1.4rem;
        font-weight: $bold;
        @include desktop {
          font-size: 1.5rem;
          padding-top: 0.5rem;
        }
      }
    }
    &__description {
      display: flex;
      align-items: center;
      text-align: left;
      font-size: 1.1rem;
      margin-top: 0.5rem;
      font-weight: $bold;
      color: #111111;
      &-warning {
        color: #F9424D;
        font-size: 1.1rem;
        @include desktop {
          font-weight: $medium;
        }
      }
      &-text {
        flex:1;
        text-align: right;
        font-size: 1.1rem;
        padding-right: 0.5rem;
      }
      &-price {
        font-size: 1.5rem;
        @include desktop {
          font-size: 1.8rem;
        }
      }
      &-unit {
        font-size: 1.4rem;
        @include desktop {
          font-size: 1.5rem;
        }
      }
    }
  }

  &__pay-button {
    width: 100%;
    height: 5.6rem;

    border: none;
    border-radius: 0 1rem 1rem 1rem;

    font-size: 1.7rem;
		font-weight: $medium;
		color: #FFFFFF;
    @include desktop {
      cursor: pointer;
    }

    &--none-recurring {
      background: $gra1;
    }

    &--recurring {
      background: $gra4;
    }
  }
}