@import "../common/commonCode.scss";
@import "../common/_mixin";

.book-page {
	margin: -5rem -2rem;
	background: #000000;
}

.book {
	@include desktop {
		max-width: 500px;
		margin: 0 auto;
	}
	.shortcut {
		z-index: 3;
		position: sticky;
		top: 0;
		left: 0;
		height: 0;
		.close {
			z-index: 1;
			position: absolute;
			top: 1.5rem;
			right: 1rem;
			width: 3.8rem;
		}
		&-button {
			z-index: 3;
			position: absolute;
			top: 1.5rem;
			left: 1rem;
			width: 3.8rem;
		}
		&-body {
			z-index: 2;
			overflow-y: hidden;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			background-color: #fff;
			border-radius: 3rem;
			&__inner {
				overflow-y: auto;
				max-height: calc(100vh - 4.9rem);
				padding-top: 5.8rem;
				padding-bottom: 3.5rem;
			}
		}
		&-dim {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 100vh;
			background-color: rgba(0, 0, 0, 0.7);
		}
		&-item {
			padding-top: 1.5rem;
			padding-bottom: 1.9rem;
			text-align: center;
			&__name {
				font-size: 1.7rem;
				line-height: 2.3rem;
			}
			&__desc {
				color: #999999;
				font-size: 1.3rem;
				letter-spacing: -0.05rem;
			}
			&.active {
				background-color: #FBFBFB;
				color: #9732FC;
				.shortcut-item__name {
					font-weight: $bold;
				}
				.shortcut-item__desc {
					color: #9732FC;
				}
			}
		}
	}
	.greeting {
		display: flex;
		flex-direction: column;
		position: relative;
		min-height: 100vh;
		padding: 2rem 2rem 10rem;
		background: linear-gradient(180deg, #9732fc, #fc4c4c);
		border-radius: 3rem;
		color: #fff;
		text-align: center;
		&.leveltest {
			background: linear-gradient(180deg, #00e599, #00bfe5);
		}

		.subject {
			position: absolute;
			top: 4rem;
			left: 0;
			width: 100%;
			padding: 0 2rem;
			font-size: 1.3rem;
			text-align: center;
		}

		.greeting1 {
			margin-top: 8.5rem;
			font-size: 3.3rem;
			font-weight: 700;
			line-height: 1.5;
		}

		.greeting2 {
			margin: 6.5rem 0 1rem;
			font-size: 1.5rem;
			opacity: 0.8;
		}

		.greeting3 {
			font-size: 2rem;
			font-weight: 500;
			line-height: 1.5;
		}

		.arrow {
			position: absolute;
			bottom: 3rem;
			left: calc(50% - 1rem);
			width: 2rem;
		}
	}
	.closing {
		display: flex;
		flex-direction: column;
		position: relative;
		min-height: 100vh;
		padding: 2rem 2rem 10rem;
		background: linear-gradient(180deg, #9732fc, #fc4c4c);
		border-radius: 3rem;
		color: #fff;
		text-align: center;
		&.leveltest {
			background: linear-gradient(180deg, #00e599, #00bfe5);
		}
		.closing1 {
			margin-top: 8.5rem;
			font-size: 3.3rem;
			font-weight: 700;
			line-height: 1.5;
		}
		.closing2 {
			margin: 6.5rem 0 1rem;
			font-size: 1.5rem;
			opacity: 0.8;
		}
		.closing3 {
			font-size: 2rem;
			font-weight: 500;
			line-height: 1.5;
		}
		.closing-trial {
			margin-top: 6.5rem;
			font-size: 3rem;
			font-weight: 700;
			line-height: 1.45;
		}
		.trialbenefit {
			position: relative;
			padding: 2.5rem;
			margin-top: 4rem;
			background-color: rgba(255, 255, 255, 0.95);
			border-radius: 3rem;
			@media (min-width: 768.1px) {
				width: 38rem;
				margin: 4rem auto 0;
			}

			&-title {
				padding-bottom: 1rem;
				margin-bottom: 1.5rem;
				border-bottom: 1px rgba(0, 0, 0, 0.5) solid;
				color: #000000;
				font-size: 1.8rem;
				font-weight: 700;
				text-align: left;
				letter-spacing: -0.02rem;
			}

			&-card {
				position: relative;
				height: 8.5rem;
				color: #111111;
				font-size: 1.6rem;
				@media (min-width: 768.1px) {
					font-size: 1.8rem;
				}

				&__icon {
					position: absolute;
					top: 1rem;
					left: 0;
					width: 6.5rem;
				}

				&__sub {
					position: absolute;
					top: 2.3rem;
					left: 8rem;
					@media (min-width: 768.1px) {
						top: 2.2rem;
					}
				}

				&__title {
					position: absolute;
					bottom: 2.3rem;
					left: 8rem;
					font-weight: bold;
					@media (min-width: 768.1px) {
						bottom: 2.2rem;
					}
				}
			}
		}
	}
	.page {
		min-height: 100vh;
		padding: 5.5rem 2rem;
		margin: 0.1rem 0;
		background-color: #FAFAFA;
		border-radius: 3rem;
		color: #111;

		img {
			max-width: 100%;
		}

		/* 컴포넌트명(수업모드) */
		.component-name {
			display: inline-block;
			padding-bottom: 0.2rem;
			background: linear-gradient(90deg, #9732fc, #fc4c4c);
			font-size: 2.6rem;
			font-weight: 700;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		/* 컴포넌트명(레벨테스트) */
		.leveltest-component-name {
			display: inline-block;
			padding-bottom: 0.2rem;
			background: linear-gradient(90deg, #00e599, #00bfe5);
			font-size: 2.6rem;
			font-weight: 700;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		/* 컴포넌트설명 */
		.component-desc {
			margin-top: 0.5rem;
			margin-bottom: 2.55rem;
			color: #999999;
			font-size: 1.3rem;
			line-height: 1.5;
		}
		&.VO,&.TS1,&.TS2,&.TS3,&.TS4,&.TS6,&.AI,&.TI,&.TC,&.SO {
			.component-desc {
				margin-bottom: 5.05rem;
			}
		}
		&.TE,&.OD {
			.component-desc {
				margin-bottom: 3rem;
			}
		}

		/* 본문 */
		.content {
			color: #111111;
			font-size: 1.8rem;
			line-height: 2.4rem;
			&:not(:first-of-type) {
				margin-top: 1rem;
			}
		}

		/* Switch */
		.switch {
			display: inline-block;
			padding: 8px 12px;
			background: transparent;
			border: 2px solid #cccccc;
			border-radius: 24px;
			color: #111111;
			font-size: 1.5rem;
			font-weight: 500;
			text-decoration: none;
			img {
				width: 1.5rem;
				margin-right: 0.5rem;
			}
			margin-bottom: 2.5rem;
		}

		/* 다이얼로그 */
		.dialogue-wrapper {
			.speaker {
				margin-bottom: 1.5rem;
				font-size: 1.1rem;
				line-height: 1.4;
				.part {
					margin-bottom: 1rem;
					font-size: 1.1rem;
					span {
						font-size: 1.3rem;
						width: 2.3rem;
						height: 2.3rem;
					}
					&.a span,
					&.as span {
						margin-left: 0.5rem;
						background-color: #9732fc;
					}

					&.b span,
					&.bs span {
						margin-right: 0.5rem;
						background-color: #111;
					}
				}
			}
			.A {
				text-align: right;
				.bubble {
					display: inline-block;
					max-width: 88%;
					padding: 1rem 1.6rem;
					background-color: #ffffff;
					border-radius: 2rem 0.5rem 2rem 2rem;
					text-align: left;
				}
			}
			.B {
				text-align: left;
				.bubble {
					display: inline-block;
					max-width: 88%;
					padding: 1rem 1.6rem;
					background-color: #f5f5f5;
					border-radius: 0.5rem 2rem 2rem 2rem;
					text-align: left;
				}
			}
			.sentence {
				font-size: 1.6rem;
				font-weight: 500;
			}
			.description {
				margin-top: 1rem;
				color: #999;
				font-size: 0.8rem;
			}
			span {
				display: inline-block;
				width: 2rem;
				height: 2rem;
				padding: 0.3rem 0 0;
				border-radius: 1.1rem;
				color: #fff;
				font-size: 2.2rem;
				font-weight: 700;
				text-align: center;
			}
		}

		/* 라벨이 있는 박스 */
		.content-box {
			position: relative;
			padding: 5.5rem 2.5rem 3.5rem 2.5rem;
			background-color: #ffffff;
			border-radius: 0 2rem 2rem 2rem;
			&:not(:last-of-type) {
				margin-bottom: 1rem;
			}

			&:after {
				position: absolute;
				content: '';
				top: 1.3rem;
				left: -0.9rem;
				width: 0.9rem;
				height: 3.3rem;
				background-color: #d9d9d9;
				border-radius: 0.3rem 0 0 0.3rem;
			}

			&.mt-5 {
				margin-top: 5rem;
			}

			.label {
				z-index: 1;
				position: absolute;
				top: 1rem;
				left: -0.55rem;
				width: 4.4rem;
				height: 3.3rem;
				background-color: #f2f2f2;
				border-radius: 0.3rem;
				font-size: 1.5rem;
				font-weight: $bold;
				line-height: 3.3rem;;
				text-align: center;
				&.mid {
					width: 8.35rem;
				}
				&.long {
					width: 9.9rem;
				}
			}

			.content {
				color: #111111;
				font-size: 2.5rem;
				font-weight: $bold;
				line-height: 3rem;
				&.TI {
					margin-bottom: 2rem;
					font-size: 2rem;
					line-height: 2.5rem;
				}
				&.PD,&.VO,&.RT,&.FB,&.TE {
					font-size: 3rem;
					line-height: 4rem;
				}
				&.AI {
					margin-bottom: 2.5rem;
					font-size: 2rem;
					line-height: 2.5rem;
				}
			}

			.description {
				margin-top: 1rem;
				color: #555555;
				font-size: 1.5rem;
				letter-spacing: -0.05rem;
			}

			.badge {
				display: inline-block;
				padding: 0 0.6rem;
				margin: 2.5rem 0 1rem 0;
				border: 0.1rem solid #999999;
				color: #999999;
				font-size: 1.2rem;
				line-height: 1.9rem;
			}

			.sub-sentence {
				margin-bottom: 0.3rem;
				margin-left: 1rem;
				color: #707070;
				font-size: 1.5rem;
				line-height: 1.3;
				text-indent: -1rem;
				word-break: break-word;
				&::before {
					content: '· ';
				}
				&:last-child {
					margin-bottom: 0;
				}
				b {
					font-weight: $bold;
				}
			}

			.exercise-sentence-wrapper {
				margin-bottom: 2rem;
				.sentence-A {
					margin-left: 3rem;
					margin-bottom: 1rem;
					font-size: 1.8rem;
					font-weight: 600;
					line-height: 2.3rem;
					text-indent: -3rem;
					span {
						display: inline-block;
						width: 2.3rem;
						height: 2.3rem;
						padding: 0;
						margin-right: 0.7rem;
						background-color: #fff;
						border: #111 1px solid;
						border-radius: 1.15rem;
						color: #111;
						font-size: 1.3rem;
						font-weight: 700;
						text-align: center;
						text-indent: 0;
					}
				}
				.sentence-B {
					margin-left: 3rem;
					margin-bottom: 0.3rem;
					font-size: 1.8rem;
					font-weight: 600;
					line-height: 2.3rem;
					text-indent: -3rem;
					span {
						display: inline-block;
						width: 2.3rem;
						height: 2.3rem;
						margin-right: 0.7rem;
						background-color: #111;
						border-radius: 1.15rem;
						color: #fff;
						font-size: 1.3rem;
						font-weight: 700;
						text-align: center;
						text-indent: 0;
					}
				}
				.description {
					margin-left: 3rem;
					color: #999999;
					font-size: 1.5rem;
				}
			}

			.subtitle {
				font-size: 1.5rem;
			}
			.subcontent {
				position: relative;
				padding-left: 1rem;
				margin-top: 0.5rem;
				margin-left: 0.2rem;
				color: #555555;
				font-size: 1.6rem;
				text-indent: -0.2rem;
				li {
					color: #555555;
					font-size: 1.5rem;
					line-height: 2.2rem;
					&:not(:last-of-type) {
						margin-bottom: 0.5rem;
					}
				}
				li::before {
					position: absolute;
					content: '·';
					left: 0;
					color: #555555;
					font-size: 1.5rem;
					line-height: 2.2rem;
				}
			}
		}

		.expression-wrapper {
			margin-bottom: 2.5rem;
			&.mt {
				margin-top: 5rem
			}
			.definition {
				color: #111111;
				font-size: 3rem;
				font-weight: $bold;
				line-height: 3.6rem;
			}
			.line {
				margin: 1rem 0;
				height: 0.1rem;
				background-color: #111111;
				border: 0;
			}
			.description {
				color: #999999;
				font-size: 1.5rem;
				line-height: 2.2rem;
				letter-spacing: -0.05rem;
			}
		}

		/* Speak out loud */
		.speakout-wrapper {
			&:not(:first-of-type) {
				padding-top: 3rem;
				border-top: 0.1rem #EEEEEE solid;
			}
			&:not(:last-of-type) {
				margin-bottom: 3rem;
			}
			.title {
				color: #111111;
				font-size: 1.7rem;
				font-weight: $bold;
			}
			.text {
				color: #555555;
				font-size: 1.6rem;
				line-height: 2.2rem;
				margin-top: 0.3rem;
			}
		}

		/* Follow Question */
		.step-progress {
			position: relative;
			padding-left: 2.2rem;
			margin: 2.5rem 0 4rem;
			list-style: none;
			.follow-title {
				color: #111111;
				font-size: 1.5rem;
				font-weight: $medium;
				line-height: 2.4rem;
			}
			.follow-content {
				margin-top: 0.5rem;
				margin-bottom: 2.5rem;
				color: #555555;
				font-size: 1.6rem;
				line-height: 2.2rem;
				white-space: pre-wrap;
			}

			&__item {
				position: relative;
				counter-increment: list;
			}
			&__item:not(:last-child) {
				padding-bottom: 2rem;
			}
			&__item:not(:last-child):before {
				display: inline-block;
				position: absolute;
				content: '';
				top: 0.95rem;
				left: -1.8rem;
				width: 0.15rem;
				height: 100%;
				border-left: 2px solid #dddddd;
			}
			&__item::after {
				display: inline-block;
				position: absolute;
				content: '';
				top: 0.6rem;
				left: -2.25rem;
				width: 0.7rem;
				height: 0.7rem;
				background-color: #dddddd;
				border: 2px solid #dddddd;
				border-radius: 50%;
				color: #fff;
				text-align: center;
			}
		}

		/* 토익 컴포넌트 */
		.toeic-wrapper {
			&:not(:last-of-type) {
				margin-bottom: 3rem;
			}
			.title {
				color: #111111;
				font-size: 1.5rem;
				font-weight: $medium;
			}
			.content {
				margin-top: 0.3rem;
				margin-bottom: 3rem;
				color: #555555;
				font-size: 1.6rem;
				&:not(:last-of-type) {
					margin-bottom: 0;
				}
				&.situation {
					margin-bottom: 4rem;
					color: #999999;
				}
			}
		}
		&.TS2 {
			.toeic-wrapper {
				&:not(:last-of-type) {
					margin-bottom: 4rem;
				}
				.title {
					margin-bottom: 0.8rem;
				}
			}
		}
		&.TS4 {
			.picture {
				margin-bottom: 4rem;
			}
		}

		.discussion-wrapper {
			.situation {
				color: #111111;
				margin-top: 2.5rem;
				margin-bottom: 3rem;
				font-size: 1.8rem;
				font-weight: $medium;
				line-height: 2.4rem;
				font-style: italic;
			}
			.opinion {
				position: relative;
				padding-left: 1rem;
				color: #111111;
				font-size: 1.6rem;
				line-height: 2.1rem;
				&:before {
					content: '·';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
				}
				&:not(:first-of-type) {
					margin-top: 2rem;
				}
			}
		}

		/* 이미지 */
		.picture {
			width: 100%;
		}

		/* Small talk */
		.smalltalk {
			padding: 3rem 2.5rem 3.5rem;
			background-color: #ffffff;
			border-radius: 2rem;
			&-header {
				padding-bottom: 3rem;
				border-bottom: 0.1rem #EEEEEE solid;
				text-align: center;
				&__title {
					color: #999999;
					font-size: 1.5rem;
					letter-spacing: -0.05rem;
					line-height: 2.2rem;
				}
				&__subject {
					font-size: 3rem;
					font-weight: $bold;
					line-height: 3.6rem;
				}
			}

		}

		.tip {
			margin-top: 2.5rem;
			&__title {
				font-size: 1.3rem;
				line-height: 2rem;
				letter-spacing: -0.05rem;
				span {
					padding: 0.2rem 0.5rem;
					margin-right: 0.5rem;
					background-color: #111111;
					border-radius: 0 0.5rem 0.5rem 0.5rem;
					color: #ffffff;
					font-size: 1.2rem;
					font-weight: $bold;
				}
			}
			ul {
				position: relative;
				padding-left: 1rem;
				margin-top: 1.5rem;
				margin-left: 0.2rem;
				text-indent: -0.2rem;
			}
			li {
				color: #555555;
				font-size: 1.5rem;
				line-height: 2.1rem;
				&:not(:last-of-type) {
					margin-bottom: 0.5rem;
				}
				span {
					margin-left: 1rem;
					color: #999999;
					font-size: 1.3rem;
					line-height: 2rem;
				}
				&:before {
					position: absolute;
					content: '·';
					left: 0;
					color: #555555;
					font-size: 1.5rem;
					line-height: 2.2rem;
				}
			}
		}
	}
	.memo {
		&-button {
			display: flex;
			align-items: center;
			position: relative;
			width: 7.7rem;
			height: 3rem;
			padding-left: 1.2rem;
			margin-top: 2rem;
			border-radius: 1.5rem;
			font-size: 1.2rem;
			font-weight: bold;
			cursor: pointer;
			&.open {
				background-color: #111111;
				color: #ffffff;
				&:before {
					position: relative;
					display: inline-block;
					content: '';
					width: 1.6rem;
					height: 1.4rem;
					margin-right: 0.4rem;
					background: url($imgURL +'/icon/icon_memo_white.svg') no-repeat center / contain;
				}
				&:after {
					position: absolute;
					content: '';
					right: 1.2rem;
					bottom: 1.3rem;
					width: 0.5rem;
					height: 0.4rem;
					background: url($imgURL +'/icon/arrow_up_white.svg') no-repeat center / contain;
				}
			}
			&.close {
				background-color: #eeeeee;
				color: #111111;
				&:before {
					display: inline-block;
					position: relative;
					content: '';
					width: 1.6rem;
					height: 1.4rem;
					margin-right: 0.4rem;
					background: url($imgURL +'/icon/icon_memo_black.svg') no-repeat center / contain;
				}
				&:after {
					position: absolute;
					content: '';
					right: 1.2rem;
					bottom: 1.3rem;
					width: 0.5rem;
					height: 0.4rem;
					background: url($imgURL +'/icon/arrow_down_black.svg') no-repeat center / contain;
				}
			}
		}
		&-content {
			padding-left: 0.7rem;
			margin-top: 1rem;
			border-left: 0.4rem solid #eeeeee;
			color: #555555;
			font-size: 1.3rem;
			line-height: 1.5;
			word-break: break-word;
		}
	}
}