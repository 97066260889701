@import "../../common/commonCode.scss";
@import "../../common/_mixin";

.app-download-dimmed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  z-index: 999;
}

.is-mobile {
  display: block;
  @include desktop {
    display: none;
  }
}

.is-pc {
  display: none;
  @include desktop {
    display: block;
  }
}

.app-download-popup {
  background: #FFFFFF;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  overflow: hidden;
  width: 32rem;
  transform: translate(-50%, -50%);
  border-radius: 0 2rem 2rem 2rem;

  @include desktop {
    width: 400px;
    transform: translate(-200px, -50%);
    border-radius: 0 20px 20px 20px;
  }

  .close-button {
    background: url($imgURL + '/common/btn_close.svg') no-repeat center/cover;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 2.0rem;
    height: 2.0rem;
    border: none;

    @include desktop {
      top: 13px;
      left: 363px;
      width: 24px;
      height: 24px;
    }
  }

  .img-row {
    display: none;

    @include desktop {
      display: block;

      width: 360px;
      margin: 17px auto 50px;
      background: #F4F5F7;
      text-align: center;
      border-radius: 10px;

      img {
        margin: 20px auto 14px;
      }

      canvas {
        margin: 20px auto 14px;
      }

      p {
        color: #999999;
        line-height: 1.5;
        font-size: 1.4rem;
        font-weight: $regular;
        padding-bottom: 16px;
      }
    }
  }

  .txt-row {
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 4rem;
    line-height: 1.5;

    strong {
      display: block;
      color: #111111;
      font-size: 2.3rem;
      font-weight: $bold;
    }

    p {
      margin-top: 0.8rem;
      font-size: 1.5rem;
      font-weight: $regular;

      em {
        font-weight: $bold;
        font-style: normal;
      }
    }

    @include desktop {
      padding: 60px 20px 0px 20px;
      
      strong {
        display: block;
        color: #111111;
        font-size: 2.6rem;
        font-weight: $bold;
      }
  
      p {
        margin-top: 0.8rem;
        font-size: 1.6rem;
        font-weight: $regular;
        line-height: 24px;

        em {
          font-weight: $bold;
        }
      }
    }
  }

  .button-mobile {
    width: 27rem;
    height: 5.6rem;
    margin: 2.5rem auto 0;
    padding: 0;
    background: rgb(151,50,252);
    background: -moz-linear-gradient(90deg, rgba(151,50,252,1) 0%, rgba(151,50,252,1) 20%, rgba(252,76,76,1) 80%, rgba(252,76,76,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(151,50,252,1) 0%, rgba(151,50,252,1) 20%, rgba(252,76,76,1) 80%, rgba(252,76,76,1) 100%);
    background: linear-gradient(90deg, rgba(151,50,252,1) 0%, rgba(151,50,252,1) 20%, rgba(252,76,76,1) 80%, rgba(252,76,76,1) 100%);
    border-radius: 0 1rem 1rem 1rem;
    border: none;

    color: #FFFFFF;
    font-size: 1.7rem;
    font-weight: $medium;
    line-height: 5rem;
    text-align: center;
    box-sizing: border-box;

    display: block;

    @include desktop {
      display: none;
    }
  }
}
