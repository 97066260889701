@import "../common/_mixin";
@import "../common/commonCode.scss";

/* bcm-header  */

.bcm-header {

    .orderPage.p1 & {

        //position: fixed;

        //@include desktop {
        //    position: relative;
        //    margin: 0 -2rem;
        //}
    }

    .orderPage.p4 &{
        @include desktop {
            position: relative;
            margin: 0 -2rem;
        }
    }

    .blind {
        overflow: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        background: none;
        clip: rect(0, 0, 0, 0);
    }
}


.bcm-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 150;

    @include desktop {
        z-index: 105;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        margin-bottom: -80px;
    }

    &.is-not-fixed {
        position: absolute;
    }

    &.is-active {
        background: #fff;

        .bcm-header__logo {

            @include desktop {
                background: none;

                a {
                    background-image: url("https://img.uphone3.com/web/logo_default.svg");
                }

            }
        }

        .btn-menu {
            background-image: url('https://img.uphone3.com/web/btn_gnb.png')
        }

        .lnk-order {
            color: #555;

            @include desktop {
                background: #9732fc;
                border-color: #9732fc;
                color: #fff;
            }
        }

        .bcm-gnb {
            .first-depth {
               @include desktop {
                   color: #555;
               }
            }
        }
    }

    &.is-transparent {
        background: transparent;
    }

    &.is-active:hover,
    &.is-transparent:hover,
    &.fixed:hover,
    &:hover {

        @include desktop {
            background: #fff;
        }

        .bcm-header__logo {
            a {
                @include desktop {
                    background-image: url("https://img.uphone3.com/web/logo_default.svg");
                }
            }
        }

        .lnk-order {
            @include desktop {
                background: #9732fc;
                border-color: #9732fc;
                color: #fff;
            }
        }

        .bcm-gnb {
            .first-depth {
                @include desktop {
                    color: #111;
                }
            }
        }
    }

    //children
    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 5rem;
        padding: 0.15rem 5rem 0 2rem;
        box-sizing: border-box;

        @include desktop {
            position: static;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-width: 1000px;
            min-height: auto;
            padding: 0;
            margin: 0 auto;
        }
    }

    &__logo {
        display: none;

        @include desktop {
            display: block;
            width: 125px;
            height: 26px;
            margin: 27px 0 0;
            background: none;

            a {
                display: block;
                height: 100%;
                background: transparent url("https://img.uphone3.com/web/logo_white.svg") no-repeat 0 50%;
                background-size: contain;
            }
        }
    }

    &__page-title {
        display: inline-block;
        max-width: 70%;
        height: 2.5rem;
        color: #111;
        font-size: 1.7rem;
        font-weight: 500;
        line-height: 2.5rem;
        vertical-align: top;
        letter-spacing: -0.02em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.is-white {
            color: #fff;
        }

        @include desktop {
            display: none;
        }

    }

    .btn-menu {
        position: absolute;
        right: 2rem;
        top: 50%;
        width: 1.8rem;
        height: 2rem;
        padding: 0;
        background:url('https://img.uphone3.com/web/btn_gnb.png') no-repeat 50% 50%;
        background-size: 1.8rem auto;
        border: 0;
        transform: translateY(-1rem);
        cursor: pointer;

        &.is-white {
            background-image: url('https://img.uphone3.com/web/btn_gnb_white.png')
        }

        @include desktop{
            display: none;
        }
    }

    .add-nav {
        display: flex;
        align-items: center;
    }

    .lnk-trial-adv {
        width: 1.95rem;
        height: 2.45rem;
        margin: 0 0.25rem 0 1.12rem;
        background: url('https://img.uphone3.com/web/gnb/mo/ico_gift.png') no-repeat left 50% bottom 0.48rem;
        background-size: auto 1.95rem;
        font-size: 0;
        text-indent: -9999px;
        vertical-align: top;

        &.is-white {
            background-image: url('https://img.uphone3.com/web/gnb/mo/ico_gift_white.png')
        }
    }

    .lnk-order {
        padding: 0 0.3rem 0;
        color: #555555;
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: -0.02em;

        &.is-white {
            color: #fff;
        }

        @include desktop {
            width: 98px;
            height: 42px;
            padding: 0;
            margin-top: 19px;
            background: transparent;
            border: 1px solid #fff;
            border-radius: 60px;
            color: #fff;
            font-size: 16px;
            letter-spacing: -0.01em;
            line-height: 40px;
            text-align: center;
            box-sizing: border-box;
            transform: none;
        }
    }

    .past {

        &--mo {
            display: block;
            width: 100%;
            height: 5rem;
            padding: 0 2.3rem;
            margin: 5rem 0 0;
            background: #555 url('https://img.uphone3.com/web/gnb/ico_link_w.svg') no-repeat right 2.25rem top 1.85rem;
            background-size: 0.9rem auto;
            border-radius: 1rem;
            color: #ccc;
            font-size: 1.3rem;
            line-height: 5rem;
            box-sizing: border-box;

            @include desktop {
                display: none;
            }
        }

        &--pc {
            display: none;
            @include desktop {
                display: block;
                background-color: rgba(0, 0, 0, 0.6);
                color: #ccc;
                font-size: 13px;
                line-height: 36px;
                text-align: center;

                span {
                    display: inline-block;
                    padding-right: 12px;
                    background: url('https://img.uphone3.com/web/ico_arrow_link.png') no-repeat right 0 top 14px;
                    background-size: auto 10px;
                    letter-spacing: -0.02em;
                }

            }
        }

    }
}

.bcm-gnb {
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    text-align: left;
    z-index: 100;
    overflow-y: auto;
    transition: right .3s ease-out;
    -webkit-overflow-scrolling: touch;

    @include desktop {
        position: static;
        width: 0;
        flex-grow: 1;
        flex-basis: 0;
        background: none;
        text-align: right;
        font-size: 0;
        transition: none;
        overflow-y: visible;
    }

    &--active {
        right: 0;
    }

    &__mo-head {
        padding: 0 2rem;
        border-bottom: 1rem solid #eee;
        box-sizing: border-box;

        @include desktop {
            display: none;
        }

        .btnLogo {
            display:inline-block;
            width:9rem;
            height:2rem;
            margin: 2.2rem 0;
            overflow:hidden;
            font-size:0;
            background:url('https://img.uphone3.com/web/logo_default.svg') no-repeat 0 0;
            background-size:100%;
        }

        .mem-info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 2.6rem 0 3rem;

            .name {
                color: #111;
                font-size: 2rem;
                font-weight: 700;
                line-height: 2.75rem;
            }

        }

        .message-welcome {
            margin: 2.82rem 0 3.1rem;
            color: #111;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.35rem;

            a {
                color: #9732fc;
                text-decoration: underline;
                text-underline-position: from-font;
            }
        }

        .btnOrder {
            font-size: 1.2rem;
            width: 6.3rem;
            height: 2.2rem;
            color: #111;
            line-height: 2.2rem;
            border-radius: 1.1rem;
            text-align: center;
            box-sizing: border-box;
            border: 0.1rem solid #111111;
        }
    }

    &__list {

        @include desktop {
            margin-right: 20px;
            font-size: 0;
        }
    }

    &__item {

        @include desktop {
            display: inline-block;
            padding: 0 20px;
            vertical-align: top;
        }

        .pc-br {
            display: none;

            @include desktop {
                display: block;
            }

        }

        &--pc {
            display: none;

            @include desktop{
                display: inline-block;
            }
        }

        &--mo {
            @include desktop {
                display: none;
            }
        }

        &--classroom {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding: 2.14rem 2.2rem 2rem 2rem;

            @include desktop {
                display: none;
                padding: 0;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 2rem;
                bottom: 0;
                width: calc(100% - 4rem);
                height: 0;
                border-bottom: 0.1rem solid #eee;
            }

            .message-item {
                color: #555;
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 1.8rem;
                letter-spacing: -0.03em;

                img {
                    width: 1.3rem;
                    margin-top: 0.3rem;
                    margin-right: 0.1rem;
                    vertical-align: top;
                }
            }

            .lnk-item {
                font-size: 1.2rem;
                font-weight: 500;
                color: #111;
                line-height: 1;
                border-bottom: 0.1rem solid #111;
                letter-spacing: -0.03em;
            }
        }

        .first-depth {
            position: relative;
            display: block;
            margin: 0;
            padding: 2.33rem 2rem 2.15rem;
            color: #111;
            font-size: 1.6rem;
            line-height: 1.6rem;
            font-weight: 700;
            letter-spacing: -0.03em;
            text-align: left;
            box-sizing: border-box;

            @include desktop {
                position: static;
                display: block;
                padding: 0;
                color: #fff;
                font-size: 16px;
                font-weight: 400;
                line-height: 79px;
                letter-spacing: -0.02em;
                text-align: center;
                vertical-align: top;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 2rem;
                bottom: 0;
                width: calc(100% - 4rem);
                height: 0;
                border-bottom: 0.1rem solid #eee;

                @include desktop {
                    display: none;
                }

            }

            &--btn {
                position: relative;

                &:before {
                    @include desktop {
                        display: none;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 2.5rem;
                    top: 50%;
                    width: 1.4rem;
                    height: 2rem;
                    background:url(https://img.uphone3.com/web/ico_arrow_s.png) no-repeat 50% 50%;
                    background-size: 1.4rem;
                    transform: translateY(-1rem);

                    @include desktop {
                        display: none;
                    }
                }
            }

            &--junior {
                position: relative;

                img {
                    display: none;

                    @include desktop {
                        display: block;
                        position: absolute;
                        right: -6.5px;
                        top: 23px;
                    }
                }
            }
        }

        .second-depth-items {
            display: none;
            padding: 3rem 2rem 4rem;
            background: #F7F7F7;

            &.is-open {
                display: block;

                @include desktop {
                    display: inherit;
                }
            }

            @include desktop {
                position: absolute;
                left: 0;
                top: 100%;
                width: 100%;
                padding: 48px 0 45px;
                background: #fff;
                border-top: 1px solid #eee;
                font-size: 0;
                text-align: center;
                box-sizing: border-box;
                z-index: 100;
            }

            li + li {
                margin-top: 2rem;

                @include desktop {
                    margin-top: 0;
                }
            }

            li {
                @include desktop {
                    display: inline-block;
                    margin: 0 63.5px;
                    padding: 0;
                    color: #111;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: -0.03em;

                    &:first-child:nth-last-child(5),
                    &:first-child:nth-last-child(5) ~ li {
                        margin: 0 39px;
                    }
                }
            }

            &--why {
                @include desktop {
                    padding: 48px 0 45px;
                }

                li {
                    @include desktop {
                        margin: 0 24px;
                        vertical-align: top;

                        .second-depth {
                            line-height: 24.5px;
                        }
                    }

                    &:last-of-type {
                        @include desktop {
                            margin-right: 3px;
                        }
                    }
                }
                .voice-column {
                    @include desktop {
                        margin-left: 0;
                    }
                }

                .voice-column,
                .face-column {
                    @include desktop {

                        .second-depth {
                            line-height: 27.5px;
                        }

                    }
                }


                .face-column {
                    @include desktop {
                        position: relative;
                        margin-left: 22px;
                        margin-right: 55px;
                    }

                    &:after {
                        @include desktop {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: -43px;
                            width: 1px;
                            height: 98px;
                            background: url($imgURL + '/web/gnb/gnb_line.svg') repeat-y 0 0;
                        }
                    }
                }
            }


            &--b2b {
                li {
                    span {
                        display: none;

                        @include desktop {
                            display: block;
                            margin-top: 4px;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0;
                        }
                    }
                }
            }

        }

        .second-depth {
            color: #111;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 2.05rem;
            letter-spacing: -0.02em;

            @include desktop {
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: -0.02em;
            }

            &:hover {
                @include desktop{
                    &,
                    & * {
                        color: #9732fc;
                    }
                }
            }

            &--new {
                position: relative;

                &:after {
                    content: 'NEW';
                    position: absolute;
                    left: 103%;
                    top: 50%;
                    color: #FC4C4C;
                    font-family: inherit;
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: 1;
                    letter-spacing: 0;
                    transform: translateY(-50%);

                    @include desktop {
                        font-size: 15px;
                    }
                }
            }

            span {
                color: #333;
                font-weight: 400;
            }

            p {
                display: none;

                @include desktop {
                    display: block;
                    margin-top: 5px;
                    color: #555;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.02em;
                }
            }

        }


        &.on {

            &:after {
                display: none;
            }

            .first-depth--btn:after {
                transform: translateY(-1rem) rotate(180deg);

                @include desktop {
                    transform: none;
                }
            }
        }

        &:hover {
            .first-depth {
                @include desktop {
                    position: relative;
                    color: #111;
                    font-weight: 700;

                    &,
                    &--btn {
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            right: inherit;
                            top: inherit;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            background: #111;
                            background-size: cover;
                            z-index: 1;
                            transform: none;
                        }
                    }
                }
            }

            .second-depth-items {

                @include desktop {
                    display: block;
                    color: #555;
                    font-size: 0;
                    box-shadow: 0 4px 10px rgba(0, 0, 0 , 0.05);
                }
            }
        }
    }

    &__mo-foot {
        padding: 0 2rem 3rem;
        box-sizing: border-box;

        @include desktop {
            display: none;
        }
    }

    .btn-close {
        position:absolute;
        top:2rem;
        right:2rem;
        display:inline-block;
        width:2.4rem;
        height:2.4rem;
        border: 0;
        overflow:hidden;
        font-size:0;
        line-height:0;
        text-indent:150%;
        white-space:nowrap;
        background:url('https://img.uphone3.com/web/btn_gnb_close.png') no-repeat 0 0;
        background-size:100%;

        @include desktop {
            display: none;
        }
    }

    .btnLogout {
        display: inline-block;
        margin: 2rem 0 0;
        font-size: 1.2rem;
        color: #555555;
        line-height: 1.8rem;
        text-decoration: underline;

        @include desktop {
            display: none;
        }
    }

}


.bcm-header{
    .move-page{
        position: relative;
        margin: 0.08rem 0.3rem 0 0;
        font-size: 1.3rem;
        line-height: 2rem;
        color: #555;

        @include desktop {
            display: none;
        }

        .adj {
            font-size: 1.4rem;
        }
        .space {
            margin: 0 0.2rem;
        }
        @keyframes gnbAni1 {
            0%,40% {top:0;}
            20% {top:-0.18rem;}
        }
        span {
            position: relative;
            display: inline-block;
            animation: gnbAni1 1.2s infinite;
            letter-spacing: -0.024em;
        }
        span:nth-child(1) {animation-delay: 0.1s;}
        span:nth-child(2) {animation-delay: 0.2s;}
        span:nth-child(3) {animation-delay: 0.3s;}
        span:nth-child(4) {animation-delay: 0.4s;}
        span:nth-child(5) {animation-delay: 0.5s;}
        span:nth-child(6) {animation-delay: 0.6s;}
        span:nth-child(7) {animation-delay: 0.7s;}
        span:nth-child(8) {animation-delay: 0.8s;}
        span:nth-child(9) {animation-delay: 0.9s;}
        span:nth-child(10) {animation-delay: 1s;}
        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            top: -0.42rem;
            right: -0.4rem;
            width: 0.4rem;
            height: 0.4rem;
            border-radius: 0.2rem;
            background: #fc4c4c;
        }
    }
}
