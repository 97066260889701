@import "../common/_mixin";
@import "../common/commonCode.scss";

.landingWrap{
  margin: -5rem -2rem -45px;
  min-height: 100vh;
}

.landing {
  padding-top: 16.5%;
  min-height: 100vh;
  .mbr{
    display: block;
  }
  .emailWrap {
    margin:0 2rem 9rem;
    h2 {
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.27;
      text-align: center;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.5rem;
      font-weight: normal;
      line-height: 1.53;
      color: #707070;
      text-align: center;
      margin-bottom: 5rem;
    }
    .btns{
      height: 8rem;
      .btn {
        float: left;
        width: 48%;
        margin-left: 1rem;

        &.subBtn {
          margin-left: 0;
        }
      }
    }

  }
  @media (min-width: 1025px) {
    .mbr {
      display: none;
    }
    .emailWrap {
      h2 {
        font-size: 5rem;
        line-height: 1.14;
      }
      p{
        font-size: 1.9rem;
        line-height: 1.53;
      }
      .btns{
        width: 66rem;
        margin: auto;
        .btn{
          margin-left: 2rem;
          &.subBtn {
            margin-left: 0;
          }
        }
      }
    }
  }
  //media
}