@import "common/_mixin";
@import 'common/commonCode.scss';

//상단배너

#TopBannerLMS {

  &.order.is-showcase {
    position: relative;
  }

  ~.purchase-showcase-b2c {
    margin-top: 0;
  }

}

//공통

.purchase-showcase-b2c {
  position: relative;
  margin: -5rem -2rem 0;
  padding: 9.2rem 0 0;
  background: #000 url($imgURL + '/purchase/bg_showcase_intro.png') no-repeat 50% 3.1rem;
  background-size: 100% auto;

  @include desktop {
    padding: 176px 0 0;
    background-image: url($imgURL + '/purchase/bg_showcase_intro_pc.png');
    background-position: 50% 0;
    background-size: 1920px auto;
  }

  .blind-title,
  .hide-input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  input,
  label,
  button {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    appearance: none;
  }

  em {
    font-style: normal;
  }

  .section-title {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    line-height: 3.8rem;
    letter-spacing: -0.01em;

    @include desktop {
      font-size: 60px;
      line-height: 76px;
      letter-spacing: -0.024em;
    }
  }

  .pre-title-satisfaction {
    display: inline-block;
    position: relative;
    min-width: 9.5rem;
    padding: 0.6rem 5.15rem 1.5rem;
    background: url($imgURL + '/purchase/ico_five_stars.png') no-repeat 50% 100%;
    background-size: auto 1.25rem;
    color: #fff;
    text-align: center;

    @include desktop {
      min-width: auto;
      padding: 0 46px 14.5px;
      background-size: auto 12px;
    }

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0.15rem;
      width: 3.25rem;
      height: 6.6rem;
      background: url($imgURL + '/purchase/bg_laurel_gold.png') no-repeat 0 0;
      background-size: 19.7rem auto;

      @include desktop {
        top: -2px;
        width: 30px;
        height: 62px;
        background-size: 180px auto;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
      background-position: 100% 0;
    }

    em {
      font-style: normal;
    }

    .top-row {
      display: inline-block;
      position: relative;
      transform: translateX(0.1rem);

      @include desktop {
        transform: translateX(3px);
      }

      span {
        display: inline-block;
        font-size: 3.9rem;
        font-weight: 900;
        vertical-align: middle;
        line-height: 2rem;

        @include desktop {
          font-size: 35px;
        }
      }

      .percent {
        font-size: 3.8rem;

        @include desktop {
          margin-left: 2px;
          font-size: 34px;
        }
      }
    }

    .bottom-row {
      display: block;
      margin-top: 0.6rem;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.35rem;

      @include desktop {
        margin-top: 5px;
      }
    }
  }

  .btn-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22.5rem;
    height: 6rem;
    margin: 3rem auto 0;
    padding: 0;
    background: #FAF4FF;
    border: none;
    border-radius: 3rem;
    color: #9732FC;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    cursor: pointer;

    @include desktop {
      width: 270px;
      margin-top: 40px;
      border-radius: 60px;
      font-size: 18px;
    }
  }
}

.purchase-showcase-intro {
  &__header {
    text-align: center;

    .section-title {
      padding: 4.7rem 0 1.05rem;
      color: #fff;

      @include desktop {
        padding: 32px 0 10px;
      }
    }

    .welcome {
      color: #fff;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -0.01em;
      opacity: 0.8;

      @include desktop {
        font-size: 18px;
        line-height: 30px;
      }

      br {
        @include desktop {
          display: none;
        }
      }
    }
  }

  .week-options {
    overflow: hidden;
    position: relative;
    width: 26rem;
    margin: 4.8rem auto 4rem;
    padding-left: 6.5rem;
    background: #222;
    border-radius: 3rem;
    font-size: 0;
    text-align: left;
    white-space: nowrap;

    @include desktop {
      width: 470px;
      padding-left: 80px;
      margin: 75.5px auto 31px;
    }

    &__item {
      display: inline-block;
      position: relative;
      margin: 0 0.1rem;
      vertical-align: top;

      &:first-of-type {
        position: absolute;
        left: 0;
        top: 0;

        .tab-btn {
          @include desktop {
            padding: 0 41px;
          }
        }
      }

      &:last-of-type {
        position: absolute;
        right: 0;
        top: 0;
      }

      &:nth-of-type(2) {

      }


      & + .week-options__item {

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 0.8rem;
          width: 0.2rem;
          height: 0.2rem;
          background: #ffffff;
          border-radius: 50%;
          transform: translateY(-50%);
          z-index: 1;
          opacity: 0.6;

          @include desktop {
            left: 14px;
          }
        }
      }

      .tab-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 6rem;
        padding: 0 2.75rem;
        background: none;
        border: none;
        border-radius: 3rem;
        color: #fff;
        font-size: 1.5rem;
        line-height: 1.8rem;
        letter-spacing: -0.01em;
        opacity: 0.6;
        cursor: pointer;

        @include desktop {
          padding: 0 72px;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0;
        }

        &__sub-title {
          margin-top: 0.25rem;
          font-size: 1.3rem;
          line-height: 1;

          @include desktop {
            margin-top: 1px;
            font-size: 16px;
          }
        }
      }

      &.is-active {
        z-index: 2;

        .tab-btn {
          position: relative;
          z-index: 1;
          background: #9732FC;
          font-weight: 500;
          opacity: 1;
        }

        &:before {
          background: transparent;
        }

      }
    }
  }

  .type-options {
    text-align: center;

    &__item {
      display: inline-block;
      margin: 0 1.03rem;

      @include desktop {
        margin: 0 13.5px;
      }

      label {
        display: inline-flex;
        align-items: center;
        position: relative;
        color: #555555;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.05rem;
        letter-spacing: -0.01em;
        cursor: pointer;

        @include desktop {
          font-size: 16px;
          line-height: 24px;
        }

        &::before {
          content: '';
          display: inline-block;
          width: 1.65rem;
          height: 1.65rem;
          margin-right: 0.4rem;
          background: #161616;
          border: 0.1rem solid #494949;
          border-radius: 0.25rem;
          box-sizing: border-box;

          @include desktop {
            width: 20px;
            height: 20px;
            margin-right: 6px;
            border-width: 1px;
            border-radius: 5px;
          }
        }
      }

      :checked+label {
        color: #9732FC;

        &::before {
          background: #9732FC;
          border-color: #9732FC;
        }
      }
    }
  }

  .product-options {
    overflow: hidden;
    position: relative;
    min-height: 80rem;
    padding: 3.65rem 0 5.2rem;

    @include desktop {
      max-width: 1376px;
      margin: 0 auto;
      padding: 47px 0 105px;
    }

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: auto;
    }
  }

  .product-item {
    position: relative;
    width: 71.735%;
    margin: 0 1.467%;
    border: 0.2rem solid transparent;
    color: #a7a7a7;
    text-align: left;

    @include desktop {
      width: 328px;
      margin: 0 8px;
      border-width: 2px;
    }

    &:first-of-type {
      margin-left: -17.135%;

      @include desktop {
        margin-left: 8px;
      }
    }

    &:last-of-type {
      margin-right: -17.135%;

      @include desktop {
        margin-right: 8px;
      }
    }

    &__container {
      overflow: hidden;
      padding: 4.2rem 0 3.9rem;
      background: #242424;
      border-radius: 1rem;

      @include desktop {
        padding: 52px 0 49px;
        border-radius: 30px;
      }
    }

    &__head {
      padding: 0 2.5rem;

      @include desktop {
        padding: 0 30px;
      }
    }

    &__body {
      padding: 3.05rem 2.5rem 0;

      @include desktop {
        padding: 38px 30px 0;
      }
    }

    &.swiper-slide-active {
      .prd-timezone {
        &__graph {
          .available-bar {
            animation: timezoneAnimation .6s ease-out;

            @include desktop {
              animation: none;
            }
          }
        }
      }
    }

    &.is-best {
      overflow: hidden;
      border-color: #9732FC;
      border-radius: 1.2rem;

      @include desktop {
        border-radius: 32px;
      }

      .product-item__container {
        border-radius: 0;
      }
    }

    &.is-premium {
      .prd-name {
        span {
          background: linear-gradient(to right, #FCBF32 0%, #FC4CB0 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .prd-schedule {
        &__duration {
          span {
            background: linear-gradient(to right, #FCBF32 0%, #FC4CB0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .prd-timezone {
        &__graph {
          .available-bar {
            width: 100%;
            background: linear-gradient(to right, #FCBF32 0%, #FC4CB0 100%);
          }
        }
      }

      &.swiper-slide-active {
        .prd-timezone {
          &__graph {
            .available-bar {
              animation: timezoneAnimation2 .6s ease-out;
            }
          }
        }
      }
    }

    .best-tag {
      position: absolute;
      right: 1rem;
      top: 1rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 4.4rem;
      height: 2.5rem;
      background: linear-gradient(315deg, #9732fc 0%, #9735fc 20%, #FC4C4C 140%);
      border-radius: 0.5rem;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: -0.02em;

      @include desktop {
        right: 19px;
        top: 20px;
        width: 54px;
        height: 30px;
        border-radius: 8px;
        font-size: 14px;
      }
    }

    .prd-name {
      display: inline-block;
      margin: 0;
      padding: 0 0 1.45rem;

      @include desktop {
        padding-bottom: 10px;
      }

      span {
        display: block;
        background: linear-gradient(to right, #00E599 0%, #00BFE5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.5rem;
        font-weight: 700;

        @include desktop {
          font-size: 16px;
        }
      }
    }

    .prd-schedule {
      color: rgba(255, 255, 255, 0.6);
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3.3rem;

      @include desktop {
        font-size: 30px;
        line-height: 40px;
      }

      &__duration {
        display: inline-block;

        span {
          display: block;
          background: linear-gradient(to right, #00E599, #00BFE5);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .prd-timezone {
      padding: 2.75rem 0 3.5rem;

      @include desktop {
        padding: 20px 0 35px;
      }

      &__notice {
        margin: 0 0 0.45rem;
        font-size: 1.4rem;
        line-height: 2.05rem;
        letter-spacing: -0.038em;

        @include desktop {
          margin-bottom: 8px;
          font-size: 16px;
          line-height: 24px;
        }
      }

      &__graph {
        position: relative;
        height: 1.1rem;
        background: #494949;
        border-radius: 3.5rem;

        @include desktop {
          height: 16px;
          border-radius: 8px;
        }

        .available-bar {
          position: absolute;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          top: 0;
          left: 50%;
          width: 62.7%;
          height: 1.1rem;
          padding: 0 0.4rem;
          background: linear-gradient(to right, #00E599 0%, #00BFE5 100%);
          color: #000;
          transform: translateX(-50%);
          border-radius: 3.5rem;
          font-size: 0;
          letter-spacing: -0.01em;

          @include desktop {
            height: 16px;
            padding: 0 10px;
            border-radius: 8px;
          }

          span {
            display: inline-block;
            margin-top: 0.1rem;
            font-size: 1rem;

            @include desktop {
              font-size: 12px;
            }
          }
        }
      }
    }

    .prd-price {
      display: flex;
      align-items: flex-end;
      padding: 0 0 0.9rem;
      color: #FFF;
      font-size: 3.9rem;
      font-weight: 700;
      line-height: 4.55rem;

      @include desktop {
        padding-bottom: 10px;
        font-size: 46px;
        line-height: 54px;
      }

      .won {
        padding: 0 0 0 0.15rem;
        font-size: 3.4rem;

        @include desktop {
          padding-left: 2px;
          font-size: 40px;
        }
      }

      .per-week {
        padding: 0 0 0 0.35rem;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 3.3rem;

        @include desktop {
          padding-left: 6px;
          font-size: 21px;
          line-height: 40px;
        }
      }
    }

    .btn-start,
    .btn-soldout {
      display: block;
      width: 22.5rem;
      height: 6rem;
      margin: 0 auto;
      padding: 0;
      border-radius: 3rem;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 6rem;

      @include desktop {
        width: calc(100% - 54px);
        border-radius: 60px;
        font-size: 18px;
      }
    }

    .btn-start {
      background: #9732FC;
      border: none;
      color: #FFF;
      cursor: pointer;

      @include desktop {
        background: #494949;
        transition: background .3s ease-out;
      }

      &.is-pc-active {
        @include desktop {
          background: #9732FC;
        }
      }
    }

    .btn-soldout {
      background: transparent;
      border: 0.1rem solid #494949;
      color: #646464;
    }

    .prd-features {
      padding: 0 0.2rem;
      margin: 0;

      @include desktop {
        padding: 0 3px;
      }

      &+.prd-features {
        padding-top: 1.4rem;
        margin-top: 1.3rem;
        border-top: 0.1rem dashed #474747;

        @include desktop {
          padding-top: 15px;
          margin-top: 15px;
          border-top-width: 1px;
        }
      }

      li {
        font-size: 1.3rem;
        line-height: 2rem;



        &+li {
          margin-top: 0.55rem;

          @include desktop {
            margin-top: 8px;
          }
        }

        &.event {
          em {
            display: inline-flex;
            align-items: center;
            height: 1.5rem;
            padding: 0 1.05rem;
            margin: 0.2rem 0.4rem 0 0;
            background: #9732FC;
            border-radius: 0.75rem;
            color: #ffffff;
            font-size: 1rem;
            font-weight: $medium;
            vertical-align: top;

            @include desktop {
              margin: 0 6px 0 0;
              height: 19px;
              padding: 0 10px;
              border-radius: 15px;
              font-size: 12px;
            }
          }
        }

        &.check,
        &.checkai {
          position: relative;
          padding-left: 1.45rem;
          background: url($imgURL + '/order/ico_check_gray.png') no-repeat left 0.6rem;
          background-size: 0.85rem auto;

          @include desktop {
            padding-left: 17px;
            background-size: 8px auto;
            font-size: 14px;
            line-height: 20px;
          }
        }

        &.checkai {
          &::after {
            content: 'ai';
            display: inline-flex;
            align-items: center;
            width: 2.2rem;
            margin: 0 0 0 0.45rem;
            background: url($imgURL + '/order/ico_ai.png') no-repeat 0 35%;
            background-size: auto 1.5rem;
            vertical-align: top;
            font-size: 0;
            line-height: 2rem;
            text-indent: -9999px;

            @include desktop {
              width: 30px;
              margin: 0 0 0 7px;
              background-position: 0 -0.5px;
              background-size: auto 19px;
              line-height: 20px;
            }
          }
        }

        b {
          color: #fff;
          font-weight: 500;
        }
      }
    }

    .prd-subscribe {
      display: flex;
      align-items: center;
      margin: 1.4rem 0 0;
      padding: 1.55rem 0 0;
      border-top: 0.1rem dashed #474747;

      @include desktop {
        margin-top: 16px;
        padding-top: 20px;
        border-top-width: 1px;
      }

      &__checkbox {

        label {
          display: inline-flex;
          align-items: center;
          padding: 0 0 0 2rem;
          background: url($imgURL + '/purchase/ico_subscribe_off.png') no-repeat 0 0.25rem;
          background-size: 1.5rem auto;
          font-size: 1.4rem;
          line-height: 2rem;
          cursor: pointer;

          @include desktop {
            padding-left: 26px;
            background-image: url($imgURL + '/purchase/ico_subscribe_off_pc.png');
            background-position: 0 1.5px;
            background-size: 20px;
            font-size: 16px;
            line-height: 24px;
          }
        }

        input {
          position: absolute;
          clip: rect(0, 0, 0, 0);
          clip-path: inset(50%);
          width: 1px;
          height: 1px;
          margin: -1px;
          overflow: hidden;

          &:checked+label {
            background-image: url($imgURL + '/purchase/ico_subscribe_on.png');

            @include desktop {
              background-image: url($imgURL + '/purchase/ico_subscribe_on_pc.png');
            }
          }
        }
      }

      &__card-info {
        margin-left: 0.5rem;
        color: #AD5BFF;
        font-size: 1.4rem;
        text-decoration: underline;
        line-height: 2rem;
        cursor: pointer;

        @include desktop {
          margin-left: 8px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.purchase-showcase-sales {
  padding: 5.2rem 2rem 6rem;

  @include desktop {
    padding: 99px 0 120px;
  }

  &__header {
    text-align: center;

    .pre-title-completion {
      display: inline-block;
      position: relative;
      min-width: 11rem;
      padding: 0 4.4rem;
      color: #fff;
      text-align: center;

      @include desktop {
        min-width: auto;
        padding: 0 50px 10px;
      }

      &::before,
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0.9rem;
        width: 3.25rem;
        height: 6.6rem;
        background: url($imgURL + '/purchase/bg_laurel_dark.png') no-repeat 0 0;
        background-size: 19.7rem auto;

        @include desktop {
          top: 18px;
          width: 30px;
          height: 62px;
          background-size: 180px auto;
        }
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
        background-position: 100% 0;
      }

      &__value {
        display: inline-block;
        position: relative;
        font-size: 4rem;
        font-weight: 700;
        line-height: 1;
        transform: translateX(0.18rem);

        @include desktop {
          font-size: 44px;
          transform: translateX(5px);
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }

        .percent {
          @include desktop {
            margin-left: 4px;
            font-size: 40px;
          }
        }
      }

      &__desc {
        display: block;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.9rem;

        @include desktop {
          margin-top: 1px;
          font-size: 15px;
          line-height: 20px;
        }
      }
    }

    .title-area {
      text-align: center;
    }


    .section-title {
      padding: 3.5rem 0 4.95rem;
      line-height: 3.8rem;
      text-align: center;

      @include desktop {
        padding: 24px 0 80px;
        line-height: inherit;
      }

      .top-row {
        display: inline-block;
      }

      .is-gradient {
        display: block;
        background: linear-gradient(280deg, #7533FF 0%, #7533FF 5%, #A546E9 35%, #E85CBA 65%, #FF825B 95%, #FF825B 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(0 0 0.13rem #B400FF);

        @include desktop {
          background: linear-gradient(290deg, #7533FF 0%, #A546E9 35%, #E85CBA 65%, #FF825B 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          filter: drop-shadow(0 0 2px #B400FF);
        }

        br {
          @include desktop {
            display: none;
          }
        }
      }

      .is-white {
        display: block;
        color: #fff;

        @include desktop {
          margin-top: 3px;
          color: #fff;
          line-height: 76px;
        }
      }
    }
  }


  &__body {
    @include desktop {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  .sales-item {
    position: relative;
    padding: 3.7rem 5rem 3.75rem 0;
    background-repeat: no-repeat;
    background-position: 100% 3.6rem;
    background-size: 4rem auto;
    text-align: left;

    @include desktop {
      width: 460px;
      padding: 55px 60px 78px 0;
      background-position: 100% 52px;
      background-size: 60px auto;
    }

    &:first-of-type {
      @include desktop {
        background-position: 100.5% 52px;
      }
    }

    &:last-of-type {
      @include desktop {
        background-position: 101.2% 50px;
        background-size: 57px auto;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.1rem;
      background: linear-gradient(90deg, #FF825B 0%, #E85CBA 25%, #A546E9 70%, #7533FF 100%);

      @include desktop {
        height: 1px;
      }
    }

    &__title {
      color: #808080;
      font-size: 1.7rem;
      font-weight: 700;
      line-height: 2.5rem;

      @include desktop {
        font-size: 28px;
        line-height: 40px;
      }

      span {
        color: #fff;
      }
    }


    &__desc {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.3rem;
      line-height: 2.1rem;

      @include desktop {
        font-size: 14px;
        line-height: 20px;
      }

      .value {
        display: block;
        padding: 1.25rem 0 0.9rem;
        color: #fff;
        font-size: 4rem;
        font-weight: 700;
        line-height: 1;

        @include desktop {
          padding: 56px 0 19px;
          font-size: 70px;
        }
      }
    }
  }
}

.purchase-showcase-details {
  padding: 10.6rem 2rem 10rem;
  background: #fff;

  @include desktop {
    padding: 208px 0 248px;
  }

  .table-container {
    position: relative;
    max-height: 1000rem;

    @include desktop {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  .scroll-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 0 1rem;
    background: #fff;
    box-shadow: 0 0.15rem 1rem rgba(0, 0, 0, 0.1);
    z-index: 1;
    box-sizing: content-box;

    @include desktop {
      padding: 0 0 20px;
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .purchase-showcase-table {
    table-layout: fixed;
    width: 100%;
    margin: 1rem 0 0;
    border-collapse: separate;
    border-spacing: 0;

    &#mobileProductTable {
      @include desktop {
        display: none;
      }
    }

    &#pcProductTable {
      display: none;

      @include desktop {
        display: table;
        margin-top: 20px;
      }
    }

    th,
    td {
      margin: 0;
      padding: 0;
      border: 0;
      color: #000;
    }

    .control-head {
      padding-top: 3.2rem;
      font-size: 0;
      text-align: left;
      vertical-align: top;

      button {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        background: #F8F8F8 url($imgURL + '/purchase/ico_arrow_table_on.png') no-repeat 50% 50%;
        background-size: 0.85rem auto;
        border: none;
        border-radius: 50%;
        cursor: pointer;

        &.is-disabled {
          background-image: url($imgURL + '/purchase/ico_arrow_table_off.png');
          cursor: inherit;
        }
      }

      &__btn-prev {
        margin-right: 1rem;
        transform: rotate(180deg);
      }
    }

    thead {
      position: sticky;
      top: 0;
      background: #fff;
      will-change: transform;
      z-index: 2;

      .control-head {
        width: calc(100vw - 26rem);

        @include desktop {
          width: 200px;
        }
      }

      .sticky-item {
        position: relative;
        width: 10rem;
        padding: 1.3rem 0 1.2rem;
        margin: 1rem 0 0 1rem;
        background: #f8f8f8;
        border-radius: 1rem;
        text-align: center;

        @include desktop {
          width: 180px;
          padding: 24px 0 26px;
          margin: 20px 0 0 20px;
          border-radius: 20px;
        }

        &__title {
          color: #666;
          font-size: 1.1rem;
          font-weight: 400;
          line-height: 1.25rem;

          @include desktop {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
          }
        }

        &__price {
          display: block;
          padding: 0.3rem 0 0.6rem;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.65rem;

          @include desktop {
            padding: 5px 0 9px;
            font-size: 16px;
            line-height: 28px;
          }

          span {
            font-size: 1.4rem;
            font-weight: 700;

            @include desktop {
              font-size: 24px;
            }
          }
        }

        &__btn-start,
        &__btn-soldout {
          display: block;
          width: 7rem;
          height: 2rem;
          margin: 0 auto;
          background: #9732FC;
          border: none;
          border-radius: 1rem;
          color: #fff;
          font-size: 1.1rem;
          line-height: 2.1rem;
          cursor: pointer;

          @include desktop {
            width: 120px;
            height: 36px;
            border-radius: 60px;
            font-size: 16px;
            line-height: 36px;
          }
        }

        &__btn-soldout {
          background: #999;
        }

        &__tag-best {
          position: absolute;
          top: -0.55rem;
          left: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 3.4rem;
          background: #fff;
          border: 0.1rem solid #9500FC;
          border-radius: 0.5rem;
          color: #9500FC;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.3rem;
          transform: translateX(-50%);

          @include desktop {
            top: -14px;
            width: 48px;
            border-width: 2px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }

    tbody {

      th,
      td {
        padding: 1.5rem 0 1.28rem;
        border-top: 0.1rem solid #eee;
        color: #000;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.8rem;
        word-break: keep-all;

        @include desktop {
          padding: 16px 0 14px;
          font-size: 16px;
          line-height: 22px;
          border-top-width: 1px;
        }
      }

      th {
        text-align: left;
        letter-spacing: -0.04em;
      }

      td {
        padding-left: 1rem;
        color: #000;
        text-align: center;

        @include desktop {
          padding-left: 20px;
        }
      }

      .header-row {
        &:first-of-type {

          th,
          td {
            border-top: none;
          }
        }

        th {
          padding: 0.3rem 0 1.4rem;
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2rem;
          letter-spacing: 0;

          @include desktop {
            padding: 2px 0 17px;
            font-size: 28px;
            line-height: 41px;
          }
        }

        &~.header-row {
          th {
            padding-top: 3rem;

            @include desktop {
              padding-top: 60px;
            }
          }
        }
      }

      .body-row {
        &:last-of-type {

          th,
          td {
            border-bottom: 0.1rem solid #eee;
          }
        }

        th {
          color: #666;
        }
      }

      .body-sub-row {

        th,
        td {
          padding-top: 0.2rem;
          padding-bottom: 1.28rem;
          border-top: none;

          @include desktop {
            padding-top: 2px;
            padding-bottom: 14px;
          }
        }

        th {
          position: relative;
          padding-left: 1.1rem;
          color: #666;

          @include desktop {
            padding-left: 14px;
          }

          &:before {
            content: 'ㄴ';
            display: inline-block;
            position: absolute;
            top: 0.2rem;
            left: 0;

            @include desktop {
              top: 1px;
            }
          }
        }
      }
    }
  }
}

.purchase-showcase-review {
  overflow: hidden;
  padding: 11.2rem 0 10rem;

  @include desktop {
    padding: 218px 0 200px;
  }

  &__header {
    text-align: center;

    .section-title {
      padding: 1.6rem 0 2.95rem;
      color: #fff;
      letter-spacing: -0.02em;

      @include desktop {
        padding: 32px 0 78px;
      }
    }
  }

  .review-list {
    font-size: 0;
    white-space: nowrap;

    &.first-row {
      transform: translateX(-8.1rem);
      animation: firstRowMoAnimation 60s linear infinite;

      @include desktop {
        transform: translateX(-149px);
        animation: firstRowDesktopAnimation 60s linear infinite;
      }
    }

    &.second-row {
      margin-top: 2rem;
      animation: secondRowMoAnimation 60s linear infinite;

      @include desktop {
        animation: secondRowDesktopAnimation 60s linear infinite;
      }
    }

    &:hover {
      @include desktop {
        animation-play-state: paused;
      }
    }
  }

  .review-item {
    display: inline-block;
    width: 24.45rem;
    height: 26.95rem;
    padding: 3rem 2rem 0;
    background: #161616;
    border-radius: 2rem;
    vertical-align: top;
    text-align: left;
    white-space: normal;

    @include desktop {
      width: 320px;
      height: 349px;
      padding: 40px 30px 0;
      border-radius: 30px;
    }

    &+.review-item {
      margin-left: 2rem;
    }

    &:nth-child(2n) {
      background: #242424;
    }

    .user-info {
      padding: 1.45rem 0 1.25rem;

      @include desktop {
        padding: 20px 0 13px;
      }
    }

    .course-period {
      color: #999;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2.6rem;
      letter-spacing: -0.02em;

      @include desktop {
        font-size: 19px;
        line-height: 28px;
      }
    }

    .writer {
      color: #666;
      font-size: 1.15rem;
      font-weight: 700;
      line-height: 2rem;

      @include desktop {
        font-size: 14px;
        line-height: 31px;
      }
    }

    .points {
      img {
        height: 1.25rem;

        @include desktop {
          height: 16.5px;
        }
      }
    }

    .content {
      overflow: hidden;
      max-height: 12.6rem;
      color: #777;
      font-size: 1.4rem;
      line-height: 2.1rem;
      word-break: keep-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      line-clamp: 6;

      @include desktop {
        max-height: 162px;
        font-size: 17px;
        line-height: 27px;
      }
    }
  }
}

.purchase-showcase-faq {
  padding: 10.6rem 2rem 10rem;
  background: #fff;

  @include desktop {
    padding: 211px 0 200px;
  }

  .section-title {
    margin-bottom: 1.95rem;
    color: #111;
    text-align: left;
    letter-spacing: -0.02em;

    @include desktop {
      margin-bottom: 39px;
      text-align: center;
    }
  }

  .faq-list {
    @include desktop {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  .faq-item {
    padding: 0 0 1.1rem;
    border-bottom: 0.1rem solid #EEE;

    @include desktop {
      padding: 0 0 20px 30px;
      border-bottom-width: 1px;
    }

    &__title {
      position: relative;
      padding: 1.5rem 4rem 0 2rem;
      color: #333;
      font-size: 1.5rem;
      line-height: 2.3rem;
      letter-spacing: -0.02em;
      text-align: left;
      cursor: pointer;

      @include desktop {
        padding: 26px 50px 0 26px;
        color: #111;
        font-size: 17px;
        font-weight: 500;
        line-height: 31px;
      }

      span {
        position: absolute;
        top: 1.5rem;
        left: 0;
        color: #ccc;

        @include desktop {
          top: 24.5px;
          font-weight: 400;
        }
      }

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 2rem;
        right: 0;
        width: 1.75rem;
        height: 0.93rem;
        background: url($imgURL + '/purchase/ico_arrow_faq.png') no-repeat 0 0;
        background-size: contain;

        @include desktop {
          top: 34px;
          right: 29px;
          width: 19px;
          height: 9px;
        }
      }
    }

    &__content {
      padding: 0.85rem 0 0 2rem;
      color: #333;
      font-size: 1.5rem;
      line-height: 2.3rem;
      text-align: left;
      letter-spacing: -0.02em;
      word-break: keep-all;

      @include desktop {
        padding: 20px 0 0 26px;
        color: #111;
        font-size: 17px;
        font-weight: 500;
        line-height: 30px;
      }

      img {
        max-width: 100%;

        @include desktop {
          max-width: 300px;
        }
      }
    }

    &.is-open {
      .faq-item__title {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

  }
}

@keyframes timezoneAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 62.7%;
  }
}

@keyframes timezoneAnimation2 {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.purchase-showcase-emergency {
  &-button {
    z-index: 2;
    position: fixed;
    background: hsla(0, 0%, 100%, .9) url($imgURL + '/order/fixed/icon_emergency.png') no-repeat 1.55rem 1.6rem;
    background-size: 2.5rem auto;
    left: 2rem;
    bottom: 2rem;
    width: 5.6rem;
    height: 5.6rem;
    border: none;
    border-radius: 50%;
    box-shadow: 0 .5rem 2.5rem rgba(0, 0, 0, .15);
    backdrop-filter: blur(1.9rem) contrast(.5) brightness(115%);
    -webkit-backdrop-filter: blur(1.9rem) contrast(.5) brightness(115%);
    cursor: pointer;

    @include desktop {
      bottom: 22px;
      width: 58px;
      height: 58px;
      background-position: 50% 45%;
      background-size: 26px auto;
    }
  }

  &-popup {
    z-index: 152;
    position: fixed;
    background: #fff;
    height: 49.86vh;
    min-height: min(38.3rem, 80vh);
    max-height: 38.3rem;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 5.2rem 0 0;
    border-radius: 2rem 2rem 0 0;
    text-align: left;

    @include desktop {
      z-index: 106;
      overflow: hidden;
      bottom: 50%;
      left: 50%;
      width: 400px;
      height: auto;
      min-height: 0;
      max-height: none;
      padding: 0;
      border-radius: 20px;
      transform: translate(-200px, 50%);
    }

    &__body {
      max-height: 100%;
      padding: 0 2rem 4.4rem;
      overflow-y: auto;

      @include desktop {
        padding: 0;
        overflow-y: initial;
      }
    }

    .popup-title {
      color: #111111;
      font-size: 2.3rem;
      font-weight: $bold;
      line-height: 3.1rem;

      @include desktop {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 17px;
        font-weight: 500;
      }
    }

    .popup-content {
      margin-top: 1.58rem;
      color: #555555;
      font-size: 1.4rem;
      line-height: 3rem;
      letter-spacing: -0.05em;

      @include desktop {
        margin-top: 0;
        overflow-y: scroll;
        height: auto;
        max-height: 510px;
        padding: 20px 40px 40px;
        font-size: 1.3rem;
        line-height: 1.8;
        box-sizing: border-box;
        color: #555;
      }
    }

    .popup-close {
      position: absolute;
      top: 1.3rem;
      right: 2.2rem;
      margin: 0;
      padding: 0;
      width: 2rem;
      height: 2rem;
      background: url($imgURL + '/common/btn_close.svg') no-repeat 0 0;
      background-size: contain;
      border: 0;
      font-size: 0;
      cursor: pointer;

      @include desktop {
        top: 13px;
        right: 13px;
        width: 24px;
        height: 24px;
        background-image: url($imgURL + '/web/btn_gnb_close.png');
      }
    }

    &__dimmed {
      z-index: 151;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .5);
      width: 100vw;
      height: 100vh;

      @include desktop {
        z-index: 105;
        background: rgba(0, 0, 0, .7);
      }
    }
  }
}

.purchase-showcase-move-top {
  z-index: 2;
  position: fixed;
  background: hsla(0, 0%, 100%, .9) url($imgURL + '/order/fixed/icon_top.png') no-repeat 2.1rem 1.95rem;
  background-size: 1.4rem 1.7rem;
  right: 7.6rem;
  bottom: 2rem;
  width: 5.6rem;
  height: 5.6rem;
  border: none;
  border-radius: 50%;
  box-shadow: 0 .5rem 2.5rem rgba(0, 0, 0, .15);
  backdrop-filter: blur(1.9rem) contrast(.5) brightness(115%);
  cursor: pointer;

  @include desktop {
    right: 210px;
    bottom: 22px;
    width: 58px;
    height: 58px;
    background-position: 50% 51%;
  }
}

.purchase-showcase-channeltalk {
  z-index: 2;
  position: fixed;
  background: hsla(0, 0%, 100%, .9) url($imgURL + '/order/fixed/icon_channeltalk.png') no-repeat 1.5rem 1.5rem;
  background-size: auto 2.6rem;
  right: 1rem;
  bottom: 2rem;
  width: 5.6rem;
  height: 5.6rem;
  border: none;
  border-radius: 50%;
  box-shadow: 0 .5rem 2.5rem rgba(0, 0, 0, .15);
  backdrop-filter: blur(1.9rem) contrast(.5) brightness(115%);
  -webkit-backdrop-filter: blur(1.9rem) contrast(.5) brightness(115%)
}


@media screen and (min-width: 1025px) and (max-width: 1376px) {
  .purchase-showcase-intro {
    .product-options {
      overflow: hidden;

      .swiper-container {
        overflow: visible;
      }

      .product-item {
        &:first-of-type {
          margin-left: 20px;
        }

        &:last-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}

@keyframes firstRowMoAnimation {
  0% {
    transform: translateX(-8.1rem);
  }

  100% {
    transform: translateX(-404.85rem);
  }
}

@keyframes secondRowMoAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-396.75rem);
  }
}

@keyframes firstRowDesktopAnimation {
  0% {
    transform: translateX(-149px);
  }

  100% {
    transform: translateX(-5249px);
  }
}

@keyframes secondRowDesktopAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-5100px);
  }
}