@import "../common/_mixin";

.order-square-tab {
	display: flex;
	flex-wrap: wrap;

	& ~ & {
		margin-top: 3rem;
	}

	&__item {
		position: relative;
		width: 31.3432%;
		margin-right: 2.9852%;

		&:nth-child(3) ~ & {
			margin-top: 2.9852%;
		}

		&:nth-child(3n) {
			margin-right: 0;
		}

		.tag {
			position: absolute;
			left: 0;
			top: 0;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 1.8rem;
			border-radius: 0 0 0.5rem 0;
			color: #fff;
			font-size: 1rem;
			font-style: normal;
			line-height: 1.2rem;

			&--best {
				font-size: 0.9rem;
				font-weight: 500;
				padding: 0 1rem;
				background: rgb(151, 50, 252);
				background: linear-gradient(
												90deg,
												rgba(151, 50, 252, 1) 0%,
												rgba(252, 76, 76, 1) 100%
				);
				b {
					font-size: 1rem;
					font-weight: 700;
					margin-right: 0.3rem;
				}
				span {
					padding: 0;
				}
			}

			&--recommend {
				padding: 0 0.7rem;
				background: #9732fc;
				font-weight: 500;
			}
		}

		input {
			position: absolute;
			left: 0;
			top: 0;
			width: 0;
			height: 0;
			background: none;
			z-index: -100;
		}

		label {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 100%;
			height: 100%;
			min-height: 5.9rem;
			margin: 0;
			padding: 2.45rem 0.9rem;
			background: #fff;
			border: 0.1rem solid #ddd;
			border-radius: 0 1rem 1rem 1rem;
			color: #777;
			font-size: 1.2rem;
			line-height: 1.7rem;
			word-break: keep-all;
			cursor: pointer;
			text-align: center;

			.main-txt {
				display: inline-block;
				width: 100%;
				color: #111;
				font-size: 1.7rem;
				font-weight: 500;
				line-height: 2.5rem;
			}

			.sub-txt {
				display: inline-block;
				margin-top: 0.25rem;
				padding-left: 0;
				line-height: inherit;

				&:before {
					display: none;
				}
			}
		}

		:checked + label {
			background: #f9f2ff;
			border-color: #9732fc;
			color: #9732fc;

			.main-txt {
				color: inherit;
			}
		}

		&--align-top {
			label {
				justify-content: flex-start;
        padding-bottom: 1.35rem;

				.main-txt {
					font-size: 1.5rem;
					line-height: 2.25rem;
				}

				.sub-txt {
					margin-top: 0.3rem;
					font-size: 1.2rem;
					line-height: 1.4rem;
				}
			}
		}

		&:first-child:nth-last-child(1) {
			width: 100%;
			margin-right: 0;
		}

		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2) ~ & {
			width: 48.3582%;
			margin-right: 0;
		}

		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2) ~ &:not(:nth-child(2n)) {
			margin-right: 3.2846%;
		}

		&--asymmetry {
			&:first-child:nth-last-child(2) {
				width: 65.4688%;
				margin-right: 3.2846%;
				& + .order-square-tab__item--asymmetry {
					width: 31.2466%;
				}
			}
		}

		&:first-child:nth-last-child(3),
		&:first-child:nth-last-child(3) ~ & {
			width: 31.3432%;
			margin-right: 0;
		}

		&:first-child:nth-last-child(3),
		&:first-child:nth-last-child(3) ~ &:not(:nth-child(3n)) {
			margin-right: 2.9852%;
		}

		&:first-child:nth-last-child(4),
		&:first-child:nth-last-child(4) ~ & {
			width: 22.985%;
			margin-right: 0;
			margin-top: 0;
		}

		&:first-child:nth-last-child(4),
		&:first-child:nth-last-child(4) ~ &:not(:nth-child(4n)) {
			margin-right: 2.6866%;
		}
	}

	&--b2b {
		.order-square-tab__item {

			&:first-child:nth-last-child(1),
			&:first-child:nth-last-child(1) ~ & {
				label {
					flex-direction: row;

					.main-txt {
						width: auto;
						margin-right: 0.5rem;
					}

					.sub-txt {
						margin-top: 0;
					}
				}
			}
      
      &--align-top {
        label {
          justify-content: flex-start;
          padding-bottom: 2.45rem;
        }
      }
		}
	}

	&__strategy {
		display: flex;

		&-card {
			background: #f9f2ff;
			border: 0.1rem solid #9732fc;
			border-radius: 0 1rem 1rem 1rem;
			color: #9732fc;
			padding: 2.45rem 0.9rem;
			font-size: 1.7rem;
			font-weight: 500;
			line-height: 2.5rem;
			text-align: center;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;

			&.language {
				flex: 1;
				margin-right: 2.9852%;
				word-break: break-all;
			}

			&.course {
				position: relative;
				flex: 2;
				word-break: keep-all;
				span {
					display: -webkit-box;
					-webkit-line-clamp: 3;
					text-overflow: ellipsis;
					overflow: hidden;
					-webkit-box-orient: vertical;
					width: 100%;
				}
			}

			&.currentCourse:after {
				content: '현재 과정';
				position: absolute;
				top:0;
				left:0;
				background: #8b52e7;
				color: #ffffff;
				font-size: 0.9rem;
				font-weight: 500;
				line-height: 1;
				padding: 0.5rem 1rem;
				border-radius: 0 0 0.5rem 0;
			}
		}
		&-change {
			font-size: 1.2rem;
			color: #555555;
			text-align: right;
			margin-top: 1.5rem;
			cursor: pointer;
			&:after {
				content: '';
				display: inline-block;
				width: 0.6rem;
				height: 1.2rem;
				background: url(https://img.uphone3.com/classroom/arrow_right_darkgray.svg) no-repeat center/ auto 1.1rem;
				margin-left: 0.4rem;
				vertical-align: bottom;
			}
		}
	}
}

@include desktop {
	.order-square-tab {

		&__item {
			width: 18.4rem;
			margin-right: 2rem;

			label {
				min-height: 12rem;
				padding: 2.9rem 2rem 2.7rem;
				border-radius: 0 2rem 2rem 2rem;

				.main-txt {
					font-size: 2.4rem;
					line-height: 3.6rem;
				}

				.sub-txt {
					margin-top: 0.3rem;
					font-size: 1.5rem;
					line-height: 2.2rem;
					br {
						display: none;
					}
				}
			}
      
      &--align-top {
        label {
          padding-bottom: unset;
      }
    }

			.tag {
				height: 2.6rem;
				line-height: 1.9rem;

				&--best {
					padding: 0 2rem;
					font-size: 1.3rem;
					b {
						font-size: 1.4rem;
					}
				}
				&--recommend {
					padding: 0 1.5rem;
					font-size: 1.4rem;
				}
			}

			& + & {
				margin-right: 2rem;
			}

			&:nth-child(3n) {
				margin-right: 2rem;
			}

			&:nth-child(5n) {
				margin-right: 0;
			}

			&:nth-child(3) ~ & {
				margin-top: initial;
			}

			&:nth-child(5) ~ & {
				margin-top: 2rem;
			}

			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ &:not(:nth-child(2n)) {
				width: 49rem;
				margin-right: 2rem;
			}

			&--asymmetry {
				&:first-child:nth-last-child(2) {
					width: 66rem;
					margin-right: 2rem;
					& + .order-square-tab__item--asymmetry {
						width: 32rem;
					}
				}
			}

			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ & {
				width: 32rem;
			}

			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ &:not(:nth-child(3n)) {
				margin-right: 2rem;
			}

			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ & {
				width: 23.5rem;
			}

			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ &:not(:nth-child(4n)) {
				margin-right: 2rem;
			}

			&:first-child:nth-last-child(5),
			&:first-child:nth-last-child(5) ~ & {
				width: 18.4rem;
				margin-top: 0;
				margin-right: 0;
			}

			&:first-child:nth-last-child(5),
			&:first-child:nth-last-child(5) ~ &:not(:nth-child(5n)) {
				margin-right: 2rem;
			}
		}

		&--b2b {
			.order-square-tab__item {
				&:first-child:nth-last-child(1) {
					width: 100%;

					label .main-txt {
						margin-right: 1.2rem;
					}
				}
			}
		}

		&__strategy {
			display: flex;

			&-card {
				min-height: 12rem;
				background: #f9f2ff;
				border: 0.1rem solid #9732fc;
				border-radius: 0 1rem 1rem 1rem;
				color: #9732fc;
				padding: 2.9rem 2rem 2.7rem;
				vertical-align: center;
				font-size: 2.4rem;
				font-weight: 500;
				line-height: 2.5rem;
				text-align: center;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				&.language {
					flex: 1;
					margin-right: 2.9852%;
					word-break: break-all;
				}

				&.course {
					position: relative;
					flex: 1;
					word-break: keep-all;
				}

				&.currentCourse:after {
					content: '현재 과정';
					position: absolute;
					top:0;
					left:0;
					background: #8b52e7;
					color: #ffffff;
					font-size: 0.9rem;
					font-weight: 500;
					line-height: 1;
					padding: 0.5rem 1rem;
					border-radius: 0 0 0.5rem 0;
				}
			}
			&-change {
				font-size: 1.8rem;
				color: #555555;
				text-align: right;
				margin-top: 1.5rem;
				cursor: pointer;
				&:after {
					content: '';
					display: inline-block;
					width: 0.9rem;
					height: 1.8rem;
					background: url(https://img.uphone3.com/classroom/arrow_right_darkgray.svg) no-repeat center/ auto 1.1rem;
					margin-left: 0.4rem;
					vertical-align: bottom;
				}
			}
		}
	}
}

.strategy-popup {
	background: #ffffff;
	width: 100%;
	height: calc(100% - 12.5rem);
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	border-radius: 2rem 2rem 0 0;
	position: fixed;
	bottom: 0;
	margin-left: -2rem;
	z-index: 910;
	padding: 0;

	@include desktop {
		margin-left: 0;
		width: 400px;
		height: 70%;
		transform: translate(calc(50vw - 200px), -20%);
		border-radius: 2rem;
	}

	&__header {
		position: relative;
		text-align: center;
		padding: 1.5rem 2rem 1.5rem 2rem;
		width: 100%;
		height: 5rem;
		&-title {
			display: none;
			@include desktop {
				font-size: 1.7rem;
				line-height: 2rem;
				display: block;
			}
		}
		img {
			height: 2rem;
			position: absolute;
			top: 1.5rem;
			right: 2rem;
			cursor: pointer;
		}
	}
	&__body {
		position: relative;
		overflow-y: scroll;
		height: calc(100% - 10.1rem - min(env(safe-area-inset-bottom), 1.5rem));
		padding: 0 2rem 2rem 2rem;
	}
	&__title {
		color: #111111;
		margin-top: 1rem;
		font-size: 2.4rem;
		font-weight: 700;
		text-align: left;
		@include desktop {
			display: none;
		}
	}
	&__subtitle {
		color: #111111;
		margin : 3rem 0 1rem 0;
		font-size: 1.5rem;
		text-align: left;
	}
	&__language {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 0.8rem;
		grid-row-gap: 0.8rem;
		text-align: center;
		&-card {
			font-size: 1.5rem;
			border: 0.1rem solid #999999;
			border-radius: 1rem;
			color: #999999;
			padding: 1.5rem 1rem;
			cursor: pointer;
			&.selected {
				border-color:#994AFC;
				color: #994AFC;
			}
		}
	}

	&__course {
		&-card {
			position: relative;
			font-size: 1.5rem;
			border: 0.1rem solid #999999;
			border-radius: 1rem;
			color: #999999;
			padding: 1rem 1rem 1rem 8rem;
			display: flex;
			align-items: center;
			text-align: left;
			height: 8rem;
			margin-bottom: 1rem;
			cursor: pointer;
			&.selected {
				border-color:#994AFC;
				color: #994AFC;
			}
			img {
				width: 6rem;
				border-radius: 1rem;
				position: absolute;
				top:1rem;
				left:1rem;
			}
			&-subject {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 1.5;
			}
		}
	}

	&__button {
		position: absolute;
		width: 100%;
		text-align: center;
		bottom: 0;
		left: 0;
		font-size: 1.7rem;
		line-height: 1;
		padding: 1.7rem 0 calc(1.7rem + min(env(safe-area-inset-bottom), 1.5rem)) 0;
		height: calc(5.1rem + min(env(safe-area-inset-bottom)));
		background: #555555;
		color: #999999;
		cursor: pointer;
		&.active {
			color: #ffffff;
			background: linear-gradient(to right, #9732fc, #fc4c4c);
		}
	}
}
