@import "../common/_mixin";

.channel-talk {
  z-index: 899;
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  width: 5.6rem;
  height: 5.6rem;
  background: rgba(255, 255, 255, 0.9) url('https://img.uphone3.com/web/channelTalk/ico_mobile_question@2x.png') no-repeat 1.5rem 1.5rem;
  background-size: auto 2.6rem;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1.9rem) contrast(0.5) brightness(115%);
  -webkit-backdrop-filter: blur(1.9rem) contrast(0.5) brightness(115%);

  @include desktop{
    width: 163px;
    height: 72px;
    bottom: 65px;
    right: 40px;
    padding: 0;
    margin: 0;
    background: url('https://img.uphone3.com/web/channelTalk/channel-btn-pc.png') no-repeat center 40%;
    background-size: cover;
    border: none;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
    backdrop-filter: none;
    cursor: pointer;
  }

  &--only {

    .coursePage.p2  ~ & {
      bottom: 6.6rem;

      @include desktop {
        bottom: 22px;
      }
    }

    @include desktop {
      bottom: 13px;
    }
  }

  &--hidden {
    display: none;
  }

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -6px;
    right: -6px;
    min-width: 24px;
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    visibility: visible;
    line-height: 18px;
    border-radius: 12px;
    color: rgba(255, 255, 255, 0.9);
    background-color: rgb(233, 78, 88);
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 2px 1px, rgba(0, 0, 0, 0.08) 0 1px 2px, rgba(255, 255, 255, 0.12) 0 0 2px inset;
    animation: 0.2s cubic-bezier(0.1, 0, 0.6, 1) 0.55s 1 normal backwards running fcEaNs;
  }
}
