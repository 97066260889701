@import "../../common/commonCode.scss";
@import "../../common/_mixin";

.lesson-introduction {
  padding: 12.3rem 0 6rem;
  background: url($imgURL + '/report/freereport_blue_bg.jpg') no-repeat;
  color: #ffffff;
  text-align: center;
  letter-spacing: -0.02rem;
  background-size: cover;
  
  @media(min-width: 1025px) {
    margin: 0 auto;
  }
};

.lesson-introduction-title {
  z-index: 1;
  position: relative;
  margin: 0 0 5rem 0;
  font-size: 3rem;
  line-height: 1.33;
  &::after{
    z-index: -1;
    width: 10.695rem;
    height: 12.033rem;
    display: block;
    content: '';
    position: absolute;
    top:-8.7rem;
    left: 50%;
    transform: translateX(-50%);
    background: url($imgURL + '/report/freereport_blue_title_bg.png') no-repeat center ;
    background-size: 10.695rem;
  }
}

.introduction-card{
  &__title{
    margin-bottom: 2.5rem;
    font-weight: $bold;
    font-size: 1.7rem;
    line-height: 1.37;
    white-space: nowrap;
  }
  
  &__description {
    font-size: 1.4rem;
    line-height: 1.57;
  }
  
  &__link {
    opacity: .7;
    font-size: 1.4rem;
    line-height: 1.64;
    text-decoration: underline;
    color: #ffffff;
    cursor: pointer;
  }
  
  img {
    width: 100%;
  }
  
  &.ai {
    width: 30.5rem;
    margin:0 auto 8rem;
    
    .title {
      margin-bottom: 0;
    }
    
    .description{
      margin: 0 0 .5rem 0;
    }
    
    img {
      margin:0 0 1.4rem 0;
    }
  }
  
  &.quiz {
    width: 26.3rem;
    margin:0 auto 8rem;
    
    .description{
      margin: 2rem 0 0 0;
    }
    
  }
  
  &.habit {
    width: 37.5rem;
    margin: 0 auto 6.21rem;
  }
  
  &.lesson-review {
    width:28.5rem;
    margin:0 auto 3.6rem;
    
    .title {
      padding-top: 1.2rem;
      font-size: 2.1rem;
      line-height: 1.47
    }
    
    img{
      margin: 0 0 1.6rem 0;
    }
  }
  
}

.introduction-button{
  width: 33.5rem;
  height: 5.6rem;
  margin: 0 auto;
  border: none;
  color: #ffffff;
  font-size: 1.7rem;
  font-weight: $medium;
  text-align: center;
  line-height: 1.47;
  border-radius: 0 1.0rem 1.0rem 1.0rem;
  background: linear-gradient(to right, #00E599, #00BFE5);
  cursor: pointer;
}
